import React, { type FC } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'
import { Warning } from '@mui/icons-material'

import type { CybusRuleEngineExecutionError } from '../../../../domain'

import { AnchorProvider, Popover as BasePopover, NoReRenderOnHover, useAnchorCloser, useAnchorSetter } from '../../common'
import { RuleEngineErrorMessage, RuleEngineErrorPayload, RuleEngineErrorRule, RuleEngineErrorTitle } from '../../RuleEngine'

type Props = Readonly<{ executionError: CybusRuleEngineExecutionError }>

const wrapperStyle: SxProps<Theme> = { p: 1 }
const messageStyle: SxProps<Theme> = { maxWidth: 600, my: 1 }
const buttonStyle: SxProps<Theme> = { cursor: 'pointer' }

const Popover: FC<Props> = ({ executionError }) => {
    const close = useAnchorCloser()

    return (
        <BasePopover onClose={close} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Box sx={wrapperStyle}>
                <RuleEngineErrorTitle data-testid="mapping-rule-error-title" executionError={executionError} />
                <RuleEngineErrorMessage data-testid="mapping-rule-error-message" sx={messageStyle} executionError={executionError} />
                <NoReRenderOnHover data-testid="mapping-rule-error-input">
                    <RuleEngineErrorPayload executionError={executionError} />
                </NoReRenderOnHover>
                <NoReRenderOnHover data-testid="mapping-rule-error-rule">
                    <RuleEngineErrorRule executionError={executionError} />
                </NoReRenderOnHover>
            </Box>
        </BasePopover>
    )
}

const Button: FC = () => {
    const setPopover = useAnchorSetter()
    return <Warning data-testid="mapping-rule-error-button" sx={buttonStyle} color="error" onClick={({ currentTarget }) => setPopover(currentTarget)} />
}

export const ErrorPopover: FC<Props> = (props) => (
    <AnchorProvider>
        <Button />
        <Popover {...props} />
    </AnchorProvider>
)
