import { AppBar, Box, Stack, type SxProps, type Theme, Toolbar } from '@mui/material'
import React, { type FC } from 'react'

import { InfoOutlined, Logout, Settings, type SvgIconComponent } from '@mui/icons-material'

import { Translation } from '../../../domain'

import { DocumentationType } from '../../../application'
import { FormattedTranslation } from '../Internationalization'
import { useAppUsecase } from '../State'
import { useDocumentation } from '../Documentation'
import { AbsoluteRouteOnlyPath, PermissionedLink, useRouteFormatter, useRouting } from '../routing'
import { CybusMinimalLogo } from '../common'
import { SIDEBAR_WIDTH } from './SideNavigation'

type LinkProps = Readonly<{
    startIcon?: SvgIconComponent
    selected?: boolean
    uppercase?: boolean
    dense?: boolean
    onClick?: VoidFunction
}>

const linkStyle: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    px: 1.5,
    mx: 2,
    fontWeight: 400,
    fontSize: 'body2.fontSize',
    letterSpacing: 0.2,
    color: 'white',
}
const navigationLinkStyle: SxProps<Theme> = {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 3,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    opacity: 0.8,
}
const denseLinkStyle: SxProps<Theme> = { px: 1, mx: 1 }
const selectedLinkStyle: SxProps<Theme> = { borderBottomColor: 'white', opacity: 1 }
const uppercaseLinkStyle: SxProps<Theme> = { textTransform: 'uppercase' }
const clickableLinkStyle: SxProps<Theme> = { cursor: 'pointer' }
const linkIconStyle: SxProps<Theme> = { mr: 1 }

const ExternalLink: FC<Pick<LinkProps, 'startIcon' | 'onClick'>> = ({ startIcon: StartIcon, children, onClick, ...props }) => (
    <Box {...props} sx={[linkStyle, denseLinkStyle, clickableLinkStyle]} onClick={onClick}>
        {StartIcon && <StartIcon fontSize="small" sx={linkIconStyle} />}
        {children}
    </Box>
)

const NavigationLink: FC<{ route: AbsoluteRouteOnlyPath } & Pick<LinkProps, 'startIcon' | 'uppercase' | 'dense'>> = ({
    route,
    dense,
    uppercase,
    startIcon: StartIcon,
}) => {
    const routing = useRouting()

    const formatter = useRouteFormatter()

    const selected = routing.isAtRoute(route)

    return (
        <PermissionedLink
            path={route}
            color="inherit"
            title={formatter.formatDescription(route) ?? undefined}
            sx={[linkStyle, navigationLinkStyle, dense ? denseLinkStyle : null, selected ? selectedLinkStyle : null, uppercase ? uppercaseLinkStyle : null]}
            wrapper={({ authorized, children }) => (authorized ? <>{children}</> : null)}
        >
            {StartIcon && <StartIcon fontSize="small" sx={linkIconStyle} />}
            {formatter.formatTitle(route)}
        </PermissionedLink>
    )
}

const appBarStyle: SxProps<Theme> = { backgroundColor: 'primary.dark', boxShadow: 0 }
const toolbarContentStyle: SxProps<Theme> = { width: '100%', minHeight: 'inherit' }
const logoWrapperStyle: SxProps<Theme> = { width: SIDEBAR_WIDTH, px: 6 }
const logoStyle: SxProps<Theme> = { mt: 1, fontSize: 'h3.fontSize', width: '2em', cursor: 'pointer' }
const linksWrapperStyle: SxProps<Theme> = { flex: 1, pl: 2, pr: 6 }
const emptyBoxStyle: SxProps<Theme> = { mx: 'auto' }

export const TopNavigation: FC = () => {
    const routing = useRouting()
    const logoutUsecase = useAppUsecase('logoutUsecase')
    const openDocumentation = useDocumentation(DocumentationType.CYBUS_DOCS)

    return (
        <AppBar position="sticky" sx={appBarStyle}>
            <Toolbar disableGutters>
                <Stack direction="row" sx={toolbarContentStyle} justifyContent="space-between" data-testid="navigation-bar">
                    <Box sx={logoWrapperStyle}>
                        <CybusMinimalLogo data-testid="cybus-top-logo" sx={logoStyle} onClick={() => routing.redirectHome()} />
                    </Box>

                    <Stack direction="row" sx={linksWrapperStyle} data-testid="navigation-list">
                        <NavigationLink route={AbsoluteRouteOnlyPath.SYSTEM} uppercase />
                        <NavigationLink route={AbsoluteRouteOnlyPath.DATA} uppercase />
                        <NavigationLink route={AbsoluteRouteOnlyPath.SERVICES} uppercase />
                        <NavigationLink route={AbsoluteRouteOnlyPath.USER} uppercase />

                        <Box sx={emptyBoxStyle} />

                        <ExternalLink data-testid="documentation-top-link" startIcon={InfoOutlined} onClick={openDocumentation}>
                            <FormattedTranslation id={Translation.DOCUMENTATION} />
                        </ExternalLink>
                        <NavigationLink route={AbsoluteRouteOnlyPath.SETTINGS} startIcon={Settings} dense />
                        <ExternalLink data-testid="logout-top-link" startIcon={Logout} onClick={() => logoutUsecase.logout()}>
                            <FormattedTranslation id={Translation.LOGOUT} />
                        </ExternalLink>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}
