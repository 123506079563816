import React, { type FC, memo } from 'react'

import { Translation } from '../../../../domain'

import { useAppUsecase } from '../../State'
import { Table } from '../../common'
import { useMetadata, useMetadataFieldNames } from './State'
import { FieldInput, FieldLabel, Section } from './Commons'

const MetadataFieldLabel: FC<{ name: string }> = ({ name }) => {
    const { field } = useMetadata(name)
    return <FieldLabel label={name} optional={field.optional} noVerticalPadding />
}

const MetadataFieldInput: FC<{ name: string }> = ({ name }) => {
    const editMetadata = useAppUsecase('editServiceTemplateMetadataUsecase')
    const field = useMetadata(name)
    return <FieldInput data-testid="metadata-field-input" data={field} name={name} onChange={(value) => editMetadata.updateMetadata(name, value)} />
}

export const MetadataTable: FC = memo(() => {
    const metadataFieldNames = useMetadataFieldNames()

    return (
        <Section data-testid="service-edit-metadata-table" title={Translation.METADATA}>
            <Table
                orientation="horizontal"
                numberedRows
                dense
                borders
                borderRadius={0}
                columns={{
                    name: { customCellRender: (name) => <MetadataFieldLabel name={name} /> },
                    value: { customCellRender: (name) => <MetadataFieldInput name={name} /> },
                }}
                data={metadataFieldNames.map((name) => ({ name, value: name }))}
            />
        </Section>
    )
})
