import { objectEntries } from '../../../../../../utils'
import type { CybusAgent } from '../../../../../../domain'

import { mapAgents } from '../../../../../Connectware'
import { listenToAgentsChange, VrpcDomainType } from '../../../../utils'
import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcRemoteToListSubscriptionHandler } from '..'

type AgentsHandler = VrpcRemoteToListSubscriptionHandler<CybusAgent>
type AgentsHandlerArgs = VrpcHandlerMappingPropertiesArgs<AgentsHandler>

export class VrpcRemoteToCybusAgentHandler implements AgentsHandler {
    readonly type = SubscriptionHandlerType.REMOTE_TO_LIST

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly domains = [VrpcDomainType.DEFAULT, VrpcDomainType.EDGE]

    mapToDomain ({ remote }: AgentsHandlerArgs['DomainMapperArgs']): Promise<AgentsHandlerArgs['Domain']> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using RST method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-2118
         * @see https://cybusio.atlassian.net/browse/CC-2201 (FE ticket)
         */
        const systemInfo = remote.getSystemInformation()

        /**
         * @deprecated update code block so status is fetched externally
         * @see https://cybusio.atlassian.net/browse/CC-2118
         */
        const statusMap = new Map(objectEntries(systemInfo).map(([name, s]) => [name, s.status]))

        return Promise.resolve(mapAgents(objectEntries(systemInfo), statusMap, ([name, agent]) => [name, agent]))
    }

    onChange ({ remote, listener }: AgentsHandlerArgs['OnChangeArgs']): Promise<AgentsHandlerArgs['OnChangeUnsub']> {
        return Promise.resolve(listenToAgentsChange(remote, 200, listener))
    }
}
