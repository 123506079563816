import type { ReadonlyRecord } from '../utils'
import { type FieldValidation, isFieldValidated } from './Field'

export type ChangePasswordForm = ReadonlyRecord<'password' | 'newPassword' | 'confirmPassword', string> & ReadonlyRecord<'passwordValidation', FieldValidation>

export type PasswordAppState = Readonly<{ changePasswordForm: ChangePasswordForm | null }>

export const canPasswordBeChanged = (form: ChangePasswordForm): boolean =>
    Boolean(form.password && form.newPassword && form.confirmPassword && isFieldValidated(form.passwordValidation))

export const selectChangePasswordForm = (s: PasswordAppState): ChangePasswordForm | null => s.changePasswordForm

export const isChangePasswordFormOpen = (s: PasswordAppState): boolean => Boolean(selectChangePasswordForm(s))
