import type { ContainerInfo, ContainerInspectInfo } from 'dockerode'
import type { V1ObjectMeta, V1PodStatus, V1Volume } from '@kubernetes/client-node'

import type { ReadonlyRecord } from '../../utils'

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/3b095cf5b98981d4c99eea420d7de478dff0b82c/auth-server/src/swagger/swagger.yaml#lines-1673}
 */
export type PermissionOperation = 'read' | 'readWrite' | 'write'

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/3b095cf5b98981d4c99eea420d7de478dff0b82c/auth-server/src/swagger/swagger.yaml#lines-1679}
 */
export type PermissionContext = 'mqtt' | 'http'

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/d8576f7bfe9245b76edce87ee4f2248033ea8455/protocol-mapper/src/swagger/swagger.yaml#protocol-mapper/src/swagger/swagger.yaml-304}
 */
export type AvailableTopicsResponse = ReadonlyRecord<'endpoints' | 'mappings' | 'nodes', ReadonlyRecord<string, string[]>>

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/f8567e690904e871dbb722a3b54efcf78419b136/service-manager/src/swagger/swagger.yaml#service-manager/src/swagger/swagger.yaml-373}
 */
export type ServiceOperationRequest = Readonly<{ operation: 'disable' | 'enable' }>

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/adedb8616d822626d9c9f9b3ef07a5176fb3ee76/protocol-mapper/src/swagger/swagger.yaml#lines-165}
 */
export type EndpointOperationRequest = Readonly<{ operation: 'disable' | 'enable' }>

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/c5ad95fbf0f22f04d5d6eaed17bcd5f765e65601/container-manager/src/swagger/swagger.yaml#lines-205}
 */
export type OrchestratorResponse = Readonly<{ orchestrator: 'Docker' | 'Kubernetes' }>

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/adedb8616d822626d9c9f9b3ef07a5176fb3ee76/protocol-mapper/src/swagger/swagger.yaml#lines-59}
 */
export type ConnectionOperationRequest = Readonly<{ operation: 'connect' | 'disconnect' }>

/*
 * @see {https://bitbucket.org/cybusio/cybus/src/adedb8616d822626d9c9f9b3ef07a5176fb3ee76/protocol-mapper/src/swagger/swagger.yaml#lines-240}
 */
export type MappingOperationRequest = Readonly<{ operation: 'disable' | 'enable' }>

/*
 * @see {https://bitbucket.org/cybusio/cybus/src/9fe2d3814ca3e382b9d2c424defc519d6fed79f2/protocol-mapper/src/swagger/swagger.yaml#lines-349}
 */
export type ServerOperationRequest = Readonly<{ operation: 'disable' | 'enable' }>

/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Docker.js#lines-71
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/swagger/swagger.yaml#lines-28
 */
export type DockerContainersResponse = (Pick<ContainerInfo, 'Names' | 'Mounts'> &
    Readonly<{
        /**
         * @deprecated this will no longer be relevant once RST is introduced
         * @todo consider no longer using this
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        State: ContainerInfo['State']
    }>)[]

/**
 * @see {https://bitbucket.org/cybusio/cybus/src/c5ad95fbf0f22f04d5d6eaed17bcd5f765e65601/container-manager/src/Docker.js#lines-369,378,387}
 * @see {https://bitbucket.org/cybusio/cybus/src/4494c69ce8180efe8c9b78bb46b475b02a690160/container-manager/src/swagger/swagger.yaml#lines-98}
 */
export type DockerContainerOperationRequest = Readonly<{ operation: 'start' | 'stop' | 'restart' }>

/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Kubernetes.js#lines-46
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/swagger/swagger.yaml#lines-28
 */
export type KubernetesContainersResponse = {
    Id: V1ObjectMeta['uid']
    Names: [V1ObjectMeta['name']]
    Image: '?'
    ImageID: '?'
    Command: '?'
    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    State?: Uppercase<Exclude<V1PodStatus['phase'], undefined>>
    Mounts: {
        Type: 'volume'
        Name: V1Volume['name']
        Source: '?'
        Destination: '?'
        Mode: '?'
        RW: '?'
        Propagation: '?'
        Driver?: 'persistentVolumeClaim' | 'secret'
    }[]
}[]

/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Docker.js#lines-202
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/swagger/swagger.yaml#lines-32
 */
export type DockerContainerResponse = Pick<ContainerInspectInfo, 'Created' | 'Mounts' | 'Config' | 'HostConfig' | 'Id' | 'Name'> &
    Readonly<{
        State: Omit<ContainerInspectInfo['State'], 'Status'> &
            Readonly<{
                /**
                 * @deprecated this will no longer be relevant once RST is introduced
                 * @todo consider no longer using this
                 * @note
                 * - core containers: planned under ADR15 (related ticket/epic not yet available)
                 * - service containers: planned under epic https://cybusio.atlassian.net/browse/CC-2124
                 */
                Status: ContainerInspectInfo['State']['Status']
            }>
    }>

/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Kubernetes.js#lines-158
 */
export type KubernetesContainerResponse = Readonly<{
    Id: V1ObjectMeta['uid']
    Name: `/${V1ObjectMeta['name']}`
    /**
     * @see  V1PodStatus['startTime']
     */
    Created: string | undefined
    Mounts: Readonly<{
        Type: 'volume'
        Name: V1Volume['name']
        Source: '?'
        Destination: '?'
        Mode: '?'
        RW: '?'
        Propagation: '?'
        Driver?: 'persistentVolumeClaim' | 'secret'
    }>[]
    Config: Readonly<{ Image: string, WorkingDir: string, Labels: ReadonlyRecord<string, never>, ExposedPorts: ReadonlyRecord<string, never>, Env: string[] }>
    State: Readonly<{
        /**
         * @deprecated this will no longer be relevant once RST is introduced
         * @todo consider no longer using this
         * @note
         * - core containers: planned under ADR15 (related ticket/epic not yet available)
         * - service containers: planned under epic https://cybusio.atlassian.net/browse/CC-2124
         */
        Status: Uppercase<Exclude<V1PodStatus['phase'], undefined>> | Exclude<V1PodStatus['phase'], string>
    }>
    HostConfig: Readonly<{ CapAdd: null, Privileged: false }>
}>

/**
 * @see https://bitbucket.org/cybusio/cybus/src/b58bcc30fb609b40c6dd55df6b3ba9e5a6e2b712/service-manager/src/swagger/swagger.yaml#service-manager/src/swagger/swagger.yaml-477
 */
export type LinkedService = Readonly<{ id: string, name: string, links: Readonly<{ name: string, href: string }>[] }>

/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/system-control-server/src/swagger/swagger.yaml#lines-259
 */
export type AgentResponse = Readonly<{
    name: string
    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-2118
     * @see https://cybusio.atlassian.net/browse/CC-2201 (FE ticket)
     */
    status: string
    version: string
    hostname: string
    classes: ReadonlyRecord<string, Readonly<{ instances: string[] }>>
}>
