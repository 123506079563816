import type { VrpcProxy } from 'vrpc'

import type { VrpcInstanceToOneSubscriptionHandler } from '../handlers'

import { VrpcInstanceBaseMapper } from './BaseVrpcInstance'

/**
 * Used to map VRPC instances into a count number to be subscribed to
 */
export class InstancesToCountDataMapper<VrpcInstance extends VrpcProxy, Domain> extends VrpcInstanceBaseMapper<
    VrpcInstanceToOneSubscriptionHandler<VrpcInstance, Domain>,
    number
> {
    private value = 0

    protected getValue (): number {
        return this.value
    }

    protected addInstance (): void {
        this.value++
        this.triggerChange()
    }

    protected removeInstance (): void {
        this.value--
        this.triggerChange()
    }
}
