import React, { type FC } from 'react'
import { Stack, type SxProps, type Theme } from '@mui/material'

import { NavigationProvider } from './State'
import { TopNavigation } from './TopNavigation'
import { LinearProgressLoader } from '../LinearProgress'

const wrapperStyle: SxProps<Theme> = { height: '100vh' }
const contentWrapperStyle: SxProps<Theme> = { height: '100%', overflowY: 'hidden' }

export const AppNavigation: FC = ({ children }) => (
    <NavigationProvider>
        <Stack sx={wrapperStyle}>
            <TopNavigation />
            <LinearProgressLoader />
            <Stack flexGrow={1} sx={contentWrapperStyle} direction="row">
                {children}
            </Stack>
        </Stack>
    </NavigationProvider>
)
