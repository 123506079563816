{
    "backgrounds": { "white": "#FFFFFF", "lightblue": "#EDF4FB", "grey": "#FAFAFA" },
    "text": {
        "main": "#15415E",
        "contrast": "#FFFFFF",
        "primary": "#262830",
        "secondary": "#2D2F3699",
        "accent": "#FFCF53",
        "disabled": "#2D2F3640",
        "link": "#4076C7",
        "hover": "#6397E3",
        "active": "#405D82"
    },
    "table": { "headerbackground": "#edf4fb", "tableborder": "#D5D5D5", "cellborder": "#D9D9D9" },
    "divider": { "divider": "#D9D9D9" }
}
