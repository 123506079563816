import React, { type FC } from 'react'
import { LinearProgress } from '@mui/material'

import { selectIsLoading } from '../../../domain'
import { useAppState } from '../State'

export const LinearProgressLoader: FC = () => {
    const isLoading = useAppState(selectIsLoading)

    return isLoading ? <LinearProgress /> : null
}
