import type {
    ConnectwareError,
    CybusAgent,
    CybusConnection,
    CybusCoreContainer,
    CybusDetailedConnection,
    CybusDetailedCoreContainer,
    CybusDetailedEndpoint,
    CybusDetailedMapping,
    CybusDetailedService,
    CybusDetailedServiceContainer,
    CybusDetailedVolume,
    CybusEndpoint,
    CybusLinkedService,
    CybusMapping,
    CybusNode,
    CybusServer,
    CybusService,
    CybusServiceContainer,
    CybusServiceDataResource,
    CybusServiceDeviation,
    CybusVolume,
    PaginatedData,
    PaginationParameters,
    StatusType,
} from '../../domain'

/**
 * The subscriptions currently supported by the subscription service
 */
export type SubscriptionsTypes = Readonly<{
    agents: CybusAgent
    connection: CybusDetailedConnection
    connections: CybusConnection
    coreContainer: CybusDetailedCoreContainer
    coreContainers: CybusCoreContainer
    endpoint: CybusDetailedEndpoint
    endpoints: CybusEndpoint
    mapping: CybusDetailedMapping
    mappings: CybusMapping
    mappingStatus: StatusType
    endpointStatus: StatusType
    nodeStatus: StatusType
    nodes: CybusNode
    server: CybusServer
    servers: CybusServer
    serviceContainer: CybusDetailedServiceContainer
    serviceContainers: CybusServiceContainer
    serviceDeviations: CybusServiceDeviation
    serviceResources: CybusServiceDataResource
    service: CybusDetailedService
    services: CybusService
    servicesLinks: CybusLinkedService
    volume: CybusDetailedVolume
    volumes: CybusVolume
}>

export type CountSubscriptionsTypes = Pick<SubscriptionsTypes, 'services'>

export type SingleSubscriptionsTypes = Pick<
    SubscriptionsTypes,
    | 'connection'
    | 'coreContainer'
    | 'endpoint'
    | 'endpointStatus'
    | 'mapping'
    | 'mappingStatus'
    | 'nodeStatus'
    | 'server'
    | 'service'
    | 'serviceContainer'
    | 'volume'
>

export type PageSubscriptionsTypes = Pick<
    SubscriptionsTypes,
    | 'agents'
    | 'connections'
    | 'coreContainers'
    | 'endpoints'
    | 'mappings'
    | 'nodes'
    | 'servers'
    | 'serviceContainers'
    | 'serviceDeviations'
    | 'services'
    | 'servicesLinks'
    | 'serviceResources'
    | 'volumes'
>

export type SubscriptionFilterArgs = Readonly<{
    /**
     * If passed, should mean that only the entities related to the given service should be passed
     */
    service?: CybusService['id']
    /**
     * If passed, should mean that only the entities related to the given server should be passed
     */
    server?: CybusServer['id']
    /**
     * If passed, should mean that only the entities related to the given connection should be passed
     */
    connection?: CybusConnection['id']
}>

/**
 * Either a global error, or a list of mapped objects/errors
 */
export type SubscriptionPageEvent<T extends keyof PageSubscriptionsTypes> = PaginatedData<PageSubscriptionsTypes[T]> | ConnectwareError
export type SubscriptionEvent<T extends keyof SubscriptionsTypes> = SubscriptionsTypes[T] | ConnectwareError

export type SubscriptionPageEventHandler<T extends keyof PageSubscriptionsTypes> = (event: SubscriptionPageEvent<T>) => void
export type SubscriptionEventHandler<T extends keyof SubscriptionsTypes> = (event: SubscriptionEvent<T>) => void

export type SubscriptionPageOptions<T extends keyof PageSubscriptionsTypes> = PaginationParameters<PageSubscriptionsTypes[T]>

/**
 * The subscription to a single page of elements
 */
export interface PageSubscription<T extends keyof PageSubscriptionsTypes> {
    /**
     * Use this to update the subscription parameters
     * @param options
     */
    update(options: Partial<SubscriptionPageOptions<T>>): void

    /**
     * Listen in to the changes being subscribed to
     * @param listener
     */
    onData(listener: SubscriptionPageEventHandler<T>): VoidFunction

    /**
     * To stop the subscription
     * No onData calls will be made after this
     */
    stop(): void
}

export interface SubscriptionsService {
    /**
     * @description Subscribes to a page of events of the given type in handler
     *
     * @param options initial options to be passed to the subscription
     * @throws if there is an issue setting up the subscription a `ConnectwareError` will be thrown
     */
    subscribeToPage<T extends keyof PageSubscriptionsTypes>(
        eventName: T,
        options: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>>

    /**
     * @description Subscribes to events of the given type in handler for a single entity
     *
     * @param id the id of what will be used
     * @param listener the actual handler of the changes
     *
     * @returns an unsub callback
     * @throws if there is an issue setting up the subscription a `ConnectwareError` will be thrown
     */
    subscribeToOne<T extends keyof SingleSubscriptionsTypes>(eventName: T, id: string, listener: SubscriptionEventHandler<T>): Promise<VoidFunction>

    /**
     * @description Subscribes to the amounts of the given resource (singular and plural resources will not cause any changes)
     *
     * @param listener the actual handler of the changes
     *
     * @returns an unsub callback
     * @throws if there is an issue setting up the subscription a `ConnectwareError` will be thrown
     */
    subscribeToCount<T extends keyof CountSubscriptionsTypes>(eventName: T, listener: (count: number | ConnectwareError) => void): Promise<VoidFunction>
}
