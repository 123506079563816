import React, { type FC } from 'react'
import { Box, Grid, Paper, type SxProps, type Theme, Typography, typographyClasses } from '@mui/material'

import { isArrayNotEmpty, objectEntries } from '../../../../utils'
import { type Explorer, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { FormattedTranslation } from '../../Internationalization'

import { SmallCopy } from '../../Copy'
import { BufferDisplay, ReversedTruncatedText, SmallButton } from '../../common'
import { usePagination } from '../Pagination'

const noMessagesStyle: SxProps<Theme> = { p: 1 }
const wrapperStyle: SxProps<Theme> = { mb: 1, py: 0.5, px: 0 }
const valueWrapperStyle: SxProps<Theme> = { pr: 1 }
const topicWrapperStyle: SxProps<Theme> = {
    display: 'flex',
    my: 'auto',
    px: 1,
    [`& > *:not(.${typographyClasses.root})`]: {
        maxWidth: 0,
        opacity: 0,
        overflow: 'hidden',
        transition: ({
            transitions: {
                easing: { easeInOut },
            },
        }) => ['max-width', 'opacity'].map((p) => `${p} 100ms ${easeInOut}`).join(', '),
    },
    [`&:hover > *:not(.${typographyClasses.root})`]: { maxWidth: 64, opacity: 1, overflow: 'initial' },
}
const topicStyle: SxProps<Theme> = { overflowX: 'hidden', mr: 'auto', display: 'flex', alignItems: 'center' }
const topicActionStyle: SxProps<Theme> = { pl: 1 }
const topicValueStyle: SxProps<Theme> = { '& > :first-of-type': { overflowX: 'hidden', textOverflow: 'ellipsis', pr: 1 } }

export const TableLines: FC<Pick<Explorer, 'latestValues'>> = ({ latestValues }) => {
    const { explorerTopicsFormattingUsecase, explorerTopicManagementUsecase } = useAppUsecases()

    const lines = objectEntries(latestValues).sort(([a], [b]) => (a > b ? 1 : -1))

    const [page, button] = usePagination(lines)

    if (!isArrayNotEmpty(page)) {
        return (
            <Typography sx={noMessagesStyle} className="no-messages" variant="subtitle2" align="center">
                <FormattedTranslation id={Translation.NO_MESSAGES} />
            </Typography>
        )
    }

    return (
        <>
            {page.map(([topic, message], k) => (
                <Paper key={k} sx={wrapperStyle} variant="outlined">
                    <Grid container>
                        <Grid sx={topicWrapperStyle} title={topic} item xs={5}>
                            <Typography sx={topicStyle} className="topic-latest-label" variant="body2">
                                <ReversedTruncatedText>{topic}</ReversedTruncatedText>
                            </Typography>
                            <Box sx={topicActionStyle}>
                                <SmallButton className="topic-unsub-button" onClick={() => explorerTopicManagementUsecase.unsubTopics(message.sources)}>
                                    <FormattedTranslation id={Translation.UNSUB} />
                                </SmallButton>
                            </Box>
                            <Box sx={topicActionStyle}>
                                <SmallCopy>{topic}</SmallCopy>
                            </Box>
                        </Grid>
                        <Grid className="topic-latest-value" item xs={7} sx={valueWrapperStyle}>
                            {message.payload !== null ? (
                                <BufferDisplay
                                    sx={topicValueStyle}
                                    className={`topic-payload-${message.selectedType.toLowerCase()}`}
                                    fullWidth
                                    buffer={message.payload}
                                    types={message.possibleTypes}
                                    onChange={(type) => explorerTopicsFormattingUsecase.selectLatestValueType(topic, type)}
                                />
                            ) : (
                                <FormattedTranslation id={Translation.NO_MESSAGES} />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            ))}
            {button}
        </>
    )
}
