import React, { type CSSProperties, type FC, useEffect, useState } from 'react'
import { Box, Card, CardActions, Collapse, Grid, IconButton, Paper, type SxProps, type Theme, Typography } from '@mui/material'
import { ExpandMore, WarningAmberOutlined } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { deltaFromArray } from '../../../utils'

import {
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusServiceDeviation,
    selectDeviationNames,
    selectDeviationPage,
    selectHasDeviations,
    selectServiceDeviationPage,
    Translation,
} from '../../../domain'

import { AbsoluteRoutePathWithServiceId, usePermissionedLink } from '../routing'
import { useAppState, useAppUsecase } from '../State'
import { FormattedTranslation, useTranslator } from '../Internationalization'
import { useShadowEffect } from '../Shadow'
import { CircularLoader, Table, useTablePageChangeCallback } from '../common'

const DEVIATIONS_KEY = 'deviations'

const rootStyle: SxProps<Theme> = { color: 'inherit', background: 'inherit', maxWidth: 400, minWidth: 400 }
const actionsStyle: SxProps<Theme> = { p: 0, justifyContent: 'space-between' }
const linkStyle: SxProps<Theme> = { ml: 1, color: 'inherit' }
const expandStyle: SxProps<Theme> = {
    color: 'inherit',
    p: 2,
    transform: 'rotate(0deg)',
    transition: ({ transitions }) => transitions.create('transform', { duration: transitions.duration.shortest }),
}
const expandOpenStyle: SxProps<Theme> = { transform: 'rotate(180deg)' }
const newDeviationStyle: SxProps<Theme> = { background: 'secondary.main' }
const deviationListStyle: SxProps<Theme> = { flexDirection: 'column', flexWrap: 'nowrap', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }
const notificationStyle: CSSProperties = { paddingLeft: '12px', paddingRight: '12px' }
const headerTitleIconStyle: SxProps<Theme> = { display: 'flex' }
const titleStyle: SxProps<Theme> = { fontWeight: 'bold', pl: 1 }
const nameStyle: SxProps<Theme> = { color: 'primary.main' }

const useServiceLink = (): FC<Readonly<{ name: string }>> => {
    const PermissionedLink = usePermissionedLink()

    return ({ name }) => (
        <PermissionedLink sx={linkStyle} path={AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE} id={name}>
            <Typography data-testid="deviation-label" sx={nameStyle}>
                {name}
            </Typography>
        </PermissionedLink>
    )
}

const useDeviationTable = (): FC => {
    const ServiceLink = useServiceLink()
    const manageDeviations = useAppUsecase('manageDeviationsUsecase')

    return () => {
        const deviationsNotificationPage = useAppState(selectServiceDeviationPage)
        const onCustomized = useTablePageChangeCallback<CybusServiceDeviation>((s) => manageDeviations.updateParameters(s), [manageDeviations])

        if (deviationsNotificationPage === null) {
            return <CircularLoader data-testid="no-data-loader" />
        }

        return (
            <Table
                search={false}
                hideHeader
                columns={{
                    name: {
                        label: Translation.SERVICE_ID,
                        customCellRender: (name) => <ServiceLink name={name} />,
                    },
                }}
                pagination={{
                    pageSize: deviationsNotificationPage.pageSize,
                    pageSizeOptions: [deviationsNotificationPage.pageSize],
                    hideJumpToPage: true,
                    page: deviationsNotificationPage.page,
                    totalCount: deviationsNotificationPage.totalCount,
                }}
                data={deviationsNotificationPage.current}
                onCustomized={onCustomized}
            />
        )
    }
}

const useDeviationMessages = (): FC => {
    const DeviationTable = useDeviationTable()

    return () => {
        const { enqueueSnackbar } = useSnackbar()
        const translator = useTranslator()
        const [expanded, setExpanded] = useState(false)
        const [oldDeviationCount, setOldDeviationCount] = useState<number>(0)

        // state to provide visual effect whenever a new deviation appears
        const [highlightNewDeviation, setHighlightNewDeviation] = useState(false)
        const deviationsNotificationPage = useAppState(selectServiceDeviationPage)
        const deviationNames = useAppState(selectDeviationNames)
        const deviationPage = useAppState(selectDeviationPage)

        useEffect(() => {
            /** Check if the page data is available */
            if (deviationsNotificationPage === null) {
                return
            }

            const deviationCount = deviationsNotificationPage.totalCount

            /** Check if the new deviation count has changed from the previous */
            if (deviationCount !== oldDeviationCount) {
                setOldDeviationCount(deviationCount)

                /** Highlight deviation only if there's differ from a previous non-zero deviation count */
                if (oldDeviationCount !== 0) {
                    setHighlightNewDeviation(true)
                }
            }
        }, [deviationsNotificationPage, oldDeviationCount])

        useShadowEffect<[string[], number]>(
            ([oldDeviationNames, oldDeviationPage]) => {
                /**
                 * Prevents triggering the resolved deviation notification during a page change
                 * Reason: During a page change, the deviation names are updated.
                 */
                if (oldDeviationPage !== deviationPage) {
                    return
                }

                const [resolved] = deltaFromArray(oldDeviationNames, deviationNames)

                /**
                 * show snackbar for resolved deviations
                 */
                for (const resolvedId of resolved) {
                    enqueueSnackbar(
                        <div data-testid="resolved-deviation">{translator.formatTranslation(Translation.DEVIATION_RESOLVED, { id: resolvedId })}</div>,
                        { key: resolvedId, variant: 'info' }
                    )
                }
            },
            [deviationNames, deviationPage]
        )

        const handleExpanded: VoidFunction = () => {
            if (highlightNewDeviation) {
                setHighlightNewDeviation(false)
            }
            setExpanded(!expanded)
        }

        return (
            <Card sx={rootStyle} elevation={0}>
                <CardActions
                    data-testid="deviation-card-actions"
                    className={highlightNewDeviation ? 'newDeviation' : ''}
                    sx={[actionsStyle, highlightNewDeviation && newDeviationStyle]}
                >
                    <Box sx={headerTitleIconStyle}>
                        <WarningAmberOutlined />
                        <Typography sx={titleStyle}>
                            <FormattedTranslation id={Translation.DEVIATION_DETECTED} values={{ count: deviationsNotificationPage?.totalCount }} />
                        </Typography>
                    </Box>

                    <Box>
                        <IconButton onClick={handleExpanded} sx={[expandStyle, expanded && expandOpenStyle]}>
                            <ExpandMore />
                        </IconButton>
                    </Box>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper>
                        <Grid data-testid="deviations-list" container sx={deviationListStyle}>
                            <DeviationTable />
                        </Grid>
                    </Paper>
                </Collapse>
            </Card>
        )
    }
}

const selectCanReadDeviations = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICE_DEVIATIONS_READ)

export const Notifications: FC = () => {
    const canReadDeviations = useAppState(selectCanReadDeviations)
    /** Subscribe to deviations */
    const manageDeviations = useAppUsecase('manageDeviationsUsecase')

    useEffect(() => {
        if (!canReadDeviations) {
            return
        }

        return manageDeviations.subscribe({ short: true, throttle: 200 })
    }, [manageDeviations, canReadDeviations])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const DeviationMessages = useDeviationMessages()
    /** Track deviations and their changes */
    const showDeviations = useAppState(selectHasDeviations)
    const deviationsNotificationPage = useAppState(selectServiceDeviationPage)

    useEffect(() => {
        /**
         * This condition prevents closing the deviation notification during a page change
         * Reason: Pagination data (total count & current) temporarily becomes null during page changes
         * Note: Remove this condition when we fetch the deviation count separately
         */
        if (deviationsNotificationPage === null) {
            return
        }

        if (showDeviations) {
            enqueueSnackbar(<DeviationMessages />, {
                key: DEVIATIONS_KEY,
                variant: 'warning',
                persist: true,
                hideIconVariant: true,
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                style: notificationStyle,
            })
        } else {
            closeSnackbar(DEVIATIONS_KEY)
        }
    }, [showDeviations, deviationsNotificationPage])

    useEffect(() => {
        /** return cleanup function that removes the snackbar when component unmounts  */
        return () => closeSnackbar(DEVIATIONS_KEY)
    }, [])

    return <></>
}
