import React, { type FC } from 'react'

import { InputAdornment, Link, Tooltip } from '@mui/material'
import { ReportProblem } from '@mui/icons-material'

import { type ConnectwareError, Translation } from '../../../../domain'

import { DocumentationType } from '../../../../application'

import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { useDocumentation } from '../../Documentation'
import { ErrorMessage } from '../../ErrorMessage'

import { EntryInput } from '../Modal'

type Props = Readonly<{
    mqttPublishPrefix: string | null
    disabled: boolean
    usecase: { update(update: Pick<Props, 'mqttPublishPrefix'>): void }
}>

export const MqttPublishPrefixInput: FC<Props> = ({ mqttPublishPrefix, disabled, usecase }) => {
    const openMqttPrefixDocument = useDocumentation(DocumentationType.MQTT_PREFIX)
    const translator = useTranslator()

    return (
        <>
            <EntryInput
                data-testid="mqtt-publish-prefix"
                value={mqttPublishPrefix || ''}
                disabled={disabled}
                onChange={({ target }) => usecase.update({ mqttPublishPrefix: target.value.trim() || null })}
                placeholder={translator.formatTranslation(Translation.MQTT_PUBLISH_PREFIX)}
                startAdornment={
                    <InputAdornment position="start">
                        <Tooltip title={<FormattedTranslation id={Translation.MQTT_PUBLISH_PREFIX_WARNING} />}>
                            <ReportProblem color="error" />
                        </Tooltip>
                    </InputAdornment>
                }
            />

            <Link onClick={openMqttPrefixDocument} data-testid="mqtt-prefix-documentation" variant="body2">
                <FormattedTranslation id={Translation.ABOUT_MQTT_PREFIX} />
            </Link>
        </>
    )
}

export const MqttPublishPrefixInputValidation: FC<{ mqttPublishPrefixValidation: ConnectwareError | null }> = ({ mqttPublishPrefixValidation }) => {
    if (mqttPublishPrefixValidation === null) {
        return null
    }

    return <ErrorMessage data-testid="mqtt-publish-prefix-validation" titleVariant="body2" error={mqttPublishPrefixValidation} customTitle={(e) => e.message} />
}
