export type CSVData = (string | number | Date | null)[][]
export interface FileService {
    /**
     * Downloads the given file
     */
    download(file: File): void

    /**
     * Generate CSV file
     * @param fileName - without file extension (.csv)
     * @param columns - header
     * @param data - body
     */
    generateCSV(fileName: string, head: string[], data: CSVData): File
}
