import type { Optional } from 'utility-types'

import React, { createRef, type FC, useState } from 'react'
import { Box, Button, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../../domain'

import { createClickerHandler } from '../..'
import { FormattedTranslation } from '../../Internationalization'
import { HiddenRawFileUpload, type HiddenRawFileUploadProps } from './Raw'

const wrapperStyle: SxProps<Theme> = { display: 'flex', overflow: 'hidden', '& > *': { my: 'auto' } }
const buttonStyle: SxProps<Theme> = { px: 2, mr: 1, minWidth: 120, overflow: 'hidden' }
const labelStyle: SxProps<Theme> = { textOverflow: 'ellipsis', overflow: 'hidden', display: 'flex', alignItems: 'center' }

type Props = Readonly<{
    /**
     * Does not really loads the files, but pretends to with the label
     */
    initialValue?: File[]
}> &
    Optional<HiddenRawFileUploadProps, 'onChange'>

/**
 * Just a localized material UI implementation of `<input type="file" />`
 * @todo replace style with sx when File Upload is implemented in Material-UI
 * @see https://mui.com/material-ui/discover-more/roadmap/#main-content
 */
export const FileUpload: FC<Props> = ({ initialValue = [], onChange, ...props }) => {
    const ref = createRef<HTMLInputElement>()
    const [{ length, 0: firstFile }, setFakeValue] = useState(() => initialValue)

    return (
        <Box sx={wrapperStyle}>
            <Button sx={buttonStyle} variant="outlined" size="small" onClick={createClickerHandler(ref)}>
                <FormattedTranslation id={Translation.FILE_UPLOAD_LABEL} />
            </Button>

            <Typography sx={labelStyle} variant="body2">
                <FormattedTranslation id={Translation.FILE_UPLOAD} values={{ length, firstFileName: firstFile?.name }} />
            </Typography>

            <HiddenRawFileUpload
                {...props}
                ref={ref}
                onChange={(files) => {
                    setFakeValue(files)
                    onChange?.(files)
                }}
            />
        </Box>
    )
}
