import React, { createRef, type FC, useCallback, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import { Grid, Link, type SxProps, type Theme, Typography } from '@mui/material'

import { isArrayNotEmpty } from '../../../utils'
import {
    canServiceBeDisabled,
    Capability,
    CommissioningFileFormState,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusService,
    isCommissioningFileFormInState,
    selectDeletableLoadedServices,
    selectDisablableLoadedServices,
    selectEditTemplate,
    selectServicesPage,
    type StatusType,
    Translation,
} from '../../../domain'

import { createClickerHandler } from '..'
import { RedirectingResourcesTable } from '../Resources'
import { AbsoluteRouteOnlyPath, Redirect, RelativeRoutePathWithId, usePermissionedLink } from '../routing'
import { createFormatter, FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppState, useAppUsecase } from '../State'
import { useAsyncCallback } from '../Callback'
import { DetailsHeader, HiddenRawFileUpload, Status, useClearTableSelection } from '../common'
import { AddOrUpdateModal } from './AddOrUpdate'
import { BulkDeletionModal, BulkDisablingModal } from './Bulk'
import { ServiceLinks } from './Links'
import { UploadServiceButton, UploadTemplateButton } from './Buttons'

const SNACKBAR_TIMEOUT = 3000

const EditTemplateRedirect: FC = () => {
    const isInitial = useAppState((s) => isCommissioningFileFormInState(selectEditTemplate(s), CommissioningFileFormState.INITIAL))
    return isInitial ? null : <Redirect to={AbsoluteRouteOnlyPath.SERVICES_TEMPLATE_EDIT} />
}

const Modals: FC = () => {
    const clear = useClearTableSelection()
    const { enqueueSnackbar } = useSnackbar()
    const PermissionedLink = usePermissionedLink()

    return (
        <>
            <BulkDeletionModal resourceSelector={selectDeletableLoadedServices} onConfirmed={clear} />
            <BulkDisablingModal resourceSelector={selectDisablableLoadedServices} onConfirmed={clear} />

            <AddOrUpdateModal
                mode="CREATE"
                onConfirmed={(id) =>
                    enqueueSnackbar(
                        <div key={id} data-testid="installed-service-item">
                            <Grid container justifyContent="space-between" spacing={6} alignItems="baseline">
                                <Grid data-testid="installed-service-name" item>
                                    {id}
                                </Grid>
                                <Grid data-testid="installed-service-link" item>
                                    <PermissionedLink color="inherit" path={RelativeRoutePathWithId.SERVICE} id={id}>
                                        <FormattedTranslation id={Translation.MORE} />
                                    </PermissionedLink>
                                </Grid>
                            </Grid>
                        </div>,
                        { key: id, variant: 'info', autoHideDuration: SNACKBAR_TIMEOUT }
                    )
                }
            />
        </>
    )
}

const selectCanEditServicesTemplate = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICE_TEMPLATE_EDIT)
const selectCanInstallServices = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICES_CREATE_OR_UPDATE)
const selectCanManageServices = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICES_MANAGE)

const buttonStyle: SxProps<Theme> = { cursor: 'pointer' }

const NoServicesMessage: FC = () => {
    const canInstallServices = useAppState(selectCanInstallServices)
    const addServiceUsecase = useAppUsecase('addServiceUsecase')
    const link = createFormatter(Link, { sx: buttonStyle, onClick: () => addServiceUsecase.startCreation() })

    return <FormattedTranslation id={Translation.NO_SERVICES_INSTALLED} values={{ canInstallServices, link }} />
}

const HeaderActions: FC = () => {
    const fileUploadRef = createRef<HTMLInputElement>()

    const addService = useAppUsecase('addServiceUsecase')
    const editServiceTemplate = useAppUsecase('manageEditServiceTemplateUsecase')

    const supportedFileTypes = useMemo(() => editServiceTemplate.getSupportedCommissioningFileTypes(), [editServiceTemplate])
    const canEditServicesTemplate = useAppState(selectCanEditServicesTemplate)
    const canInstallServices = useAppState(selectCanInstallServices)

    const [loadTemplate, isLoading] = useAsyncCallback(
        ([file]: File[]) => (file ? editServiceTemplate.loadFile(file) : Promise.resolve()),
        [editServiceTemplate]
    )

    const startServiceUpload = useCallback(() => addService.startCreation(), [addService])

    return (
        <>
            <HiddenRawFileUpload ref={fileUploadRef} accept={supportedFileTypes} onChange={loadTemplate} />
            <UploadTemplateButton onClick={createClickerHandler(fileUploadRef)} disabled={!canEditServicesTemplate || isLoading} />
            <UploadServiceButton onClick={startServiceUpload} disabled={!canInstallServices} />
        </>
    )
}

export const Table: FC = () => {
    const translation = useTranslator()
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <>
            <DetailsHeader
                title={[Translation.SERVICE, { count: 0 }]}
                titleActions={<HeaderActions />}
                description={[Translation.SERVICES_DETAILS_DOCUMENTATION_TITLE, Translation.SERVICES_DETAILS_DOCUMENTATION_BODY]}
            />
            <RedirectingResourcesTable<'manageServicesUsecase', CybusService, CybusService, 'bulkDeleteServicesUsecase' | 'bulkDisableServicesUsecase'>
                subscriptionUsecase="manageServicesUsecase"
                data={selectServicesPage}
                hasManagementCapabilitiesSelector={selectCanManageServices}
                dataTableMapper={(r) => r}
                columns={{
                    id: { label: Translation.SERVICE_ID, sort: true },
                    name: { label: Translation.NAME, sort: true },
                    version: { label: Translation.VERSION, sort: true },
                    links: {
                        label: translation.formatTranslation(Translation.LINK, { count: 2 }),
                        customCellRender: (links) => (isArrayNotEmpty(links) ? <ServiceLinks links={links} /> : <Typography>-</Typography>),
                    },
                    status: { label: Translation.STATUS, customCellRender, sort: true },
                    deviation: { label: Translation.DEVIATION, customCellRender, sort: true },
                }}
                redirectOnRowclick={RelativeRoutePathWithId.SERVICE}
                selection={{
                    DELETE: { usecase: 'bulkDeleteServicesUsecase' },
                    DISABLE: { usecase: 'bulkDisableServicesUsecase', filter: canServiceBeDisabled },
                    resource: 'id',
                    usecaseMethod: 'selectServices',
                }}
                translations={{ noResultsOrEmptyTable: <NoServicesMessage /> }}
            >
                <Modals />
            </RedirectingResourcesTable>
            <EditTemplateRedirect />
        </>
    )
}
