import { type AppState, CommissioningFileFormState, ResourceType, RuleEngineType } from '../domain'

const initialResourceActionState = { ids: null, load: false } as const
const initialResourceState = {
    disable: initialResourceActionState,
    enable: initialResourceActionState,
    reenable: initialResourceActionState,
    delete: initialResourceActionState,
} as const

export const initialState: AppState = {
    changePasswordForm: null,

    loginForm: { username: '', password: '', otpSecret: null, otp: null, backup: null },
    usersManagement: {
        users: { search: '', data: null, page: 1, showInternal: false },
        roles: { search: '', data: null, page: 1, showInternal: false },
        permissions: { search: '', data: null, page: 1, showInternal: false },

        userUpdating: null,
        userCreation: null,
        viewPermission: null,
        roleUpdating: null,
        roleCreation: null,

        registry: { locked: null, data: null },
        registryRequest: null,
    },

    authentication: null,
    versions: null,

    info: null,
    license: null,

    serviceForm: null,
    service: null,
    servicesDataPage: null,
    serviceTopicsMessages: {},
    servicesPage: null,
    servicesLinksPage: null,
    serviceDeviationsPage: null,

    editTemplateForm: { type: CommissioningFileFormState.INITIAL },
    catalog: { applications: null, search: '', filtered: [], template: null, application: null },

    connection: null,
    connectionsPage: null,

    mapping: null,
    mappingEntries: null,
    mappingsPage: null,

    endpoint: null,
    endpointsPage: null,

    volume: null,
    volumesPage: null,

    serviceContainer: null,
    serviceContainersPage: null,
    coreContainer: null,
    coreContainersPage: null,

    server: null,
    serversPage: null,
    nodesPage: null,

    agentsPage: null,

    resources: {
        [ResourceType.AGENT]: initialResourceState,
        [ResourceType.CONNECTION]: initialResourceState,
        [ResourceType.CORE_CONTAINER]: initialResourceState,
        [ResourceType.ENDPOINT]: initialResourceState,
        [ResourceType.MAPPING]: initialResourceState,
        [ResourceType.SERVER]: initialResourceState,
        [ResourceType.SERVICE_CONTAINER]: initialResourceState,
        [ResourceType.SERVICE]: initialResourceState,
        [ResourceType.VOLUME]: initialResourceState,
    },

    explorer: null,

    ruleEngineSandbox: {
        type: RuleEngineType.JSON,
        selectedTopics: null,
        input: { loaded: true, topic: null, value: {} },
        endpoints: null,
        selectedEndpoints: null,
        transformation: '',
        output: { loaded: false, id: 0, value: null },
    },

    certificates: null,

    backupMaintenance: null,

    lastCopyId: null,

    mfa: null,
    mfaRequiredAuthentication: null,

    loadingCount: 0,
}

export const initialOtp = Array<string>(6).fill('')
