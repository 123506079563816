import type { CybusNode, StatusType } from '../../../domain'

import { mapResourceNames, type NodeProxyParams } from '..'
import { FilteredResourceMapper } from './Filter'

const mapCybusNode = ({ id, parentId, operation, protocol }: NodeProxyParams, status: StatusType): CybusNode => {
    const [service, name] = mapResourceNames(id)

    return { id, service, name, status, parent: parentId, operation: operation || null, protocol }
}

export class CybusNodeMapper extends FilteredResourceMapper<NodeProxyParams, CybusNode> {
    readonly nodes: CybusNode[] = []

    protected map (params: NodeProxyParams, status: StatusType): CybusNode | null {
        const { server } = this.filter

        if (!server || server === params.parentId) {
            return mapCybusNode(params, status)
        }

        return null
    }

    protected collect (node: CybusNode): void {
        this.nodes.push(node)
    }
}
