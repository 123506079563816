import React, { type FC } from 'react'
import { type SxProps, type Theme, Tooltip } from '@mui/material'
import { Label } from '@mui/icons-material'

import type { ReadonlyRecord } from '../../../utils'
import { CybusLogLevel, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'

const baseStyle: SxProps<Theme> = { width: 16, height: 16, m: 0, p: 0 }
const colors: ReadonlyRecord<CybusLogLevel, SxProps<Theme>> = {
    [CybusLogLevel.DEBUG]: { ...baseStyle, color: 'green.800' },
    [CybusLogLevel.INFO]: { ...baseStyle, color: 'primary.main' },
    [CybusLogLevel.WARN]: { ...baseStyle, color: 'orange.400' },
    [CybusLogLevel.ERROR]: { ...baseStyle, color: 'red.600' },
    [CybusLogLevel.FATAL]: { ...baseStyle, color: 'red.900' },
    [CybusLogLevel.TRACE]: { ...baseStyle, color: 'grey.600' },
}

export const LogLevel: FC<Readonly<{ level: CybusLogLevel }>> = ({ level }) => (
    <Tooltip title={<FormattedTranslation id={Translation.LOG_LEVEL} values={{ level }} />}>
        <Label sx={colors[level]} />
    </Tooltip>
)
