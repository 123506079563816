import React, { type FC, useMemo, useRef } from 'react'
import { isPending, usePromise } from 'react-sync-promise'
import { Box, Stack, type SxProps, type Theme, Typography } from '@mui/material'

import { ConnectwareError, selectCommissioningFileFormError, selectCommissioningFileFormOutput, selectEditTemplate, Translation } from '../../../../domain'

import { readFileAsText } from '../../../file'

import { useAppState } from '../../State'
import { SmallCopy } from '../../Copy'
import { ErrorMessage } from '../../ErrorMessage'
import { CircularLoader, SmallParagraph, Title } from '../../common'
import { CommissioningFileDocumentationLink } from './Documentation'

type Output = ReturnType<typeof selectCommissioningFileFormOutput>

/**
 * This is a utility hook to read from the state
 * into an object useful to the presentaational layer
 */
const useNormalisedOutput = (output: Output): Readonly<{ loading: boolean, content: string | null, valid: boolean | null, error: ConnectwareError | null }> => {
    const filePromise = usePromise<[string, ConnectwareError | null] | ConnectwareError | null, ConnectwareError>(
        useMemo(() => {
            if (Array.isArray(output)) {
                const [file, validation] = output
                return readFileAsText(file).then((content) => [content, validation])
            }

            return ConnectwareError.is(output) ? Promise.resolve(output) : Promise.resolve(null)
        }, [output])
    )

    const result = isPending(filePromise) ? null : filePromise.value

    /** This will allow the content to be cached, so the screen does not splash */
    const lastLoaded = useRef<[string | null, ConnectwareError | null]>([null, null])
    lastLoaded.current = Array.isArray(result) ? result : lastLoaded.current

    const [content, validation] = lastLoaded.current

    return {
        loading: result === null,
        content,
        /** If the content is valid */
        valid: validation === null,
        /** If there was an unexpected error */
        error: ConnectwareError.is(result) ? result : null,
    }
}

const contentWrapperStyle: SxProps<Theme> = { width: 450 }
const contentWrapperTitleStyle: SxProps<Theme> = { fontWeight: '700' }
const contentStyle: SxProps<Theme> = { textWrap: 'wrap', fontFamily: 'monospace', wordBreak: 'break-all' }
const invalidContentStyle: SxProps<Theme> = { userSelect: 'none', opacity: 0.5 }
const loadingContentStyle: SxProps<Theme> = {
    animation: 'file-output-pulse 2.5s infinite',
    '@keyframes file-output-pulse': { '20%': { opacity: 0.25 }, '50%': { opacity: 1 }, '80%': { opacity: 0.25 } },
}
export const FileOutput: FC = () => {
    const errorBeforeOutput = useAppState((s) => selectCommissioningFileFormError(selectEditTemplate(s)))
    const output = useAppState((s) => selectCommissioningFileFormOutput(selectEditTemplate(s)))
    const { valid, content, error, loading } = useNormalisedOutput(output)

    if (errorBeforeOutput) {
        return null
    }

    return (
        <Stack data-testid="service-edit-output" spacing={4} sx={contentWrapperStyle}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Title sx={contentWrapperTitleStyle} id={Translation.EDIT_SERVICE_PAGE_OUTPUT_TITLE} />
                {valid === true && <SmallCopy data-testid="service-edit-output-copy">{content}</SmallCopy>}
            </Stack>
            {loading && content === null && <CircularLoader data-testid="service-edit-output-loader" />}
            {ConnectwareError.is(error) && <ErrorMessage data-testid="service-edit-output-error" titleVariant="h6" error={error} stack extras="section" />}
            {valid === false && (
                <Box data-testid="service-edit-output-invalid">
                    <SmallParagraph id={Translation.EDIT_SERVICE_PAGE_OUTPUT_INVALID_WARNING} />
                    <CommissioningFileDocumentationLink renderer={SmallParagraph} />
                </Box>
            )}
            {typeof content === 'string' && (
                <Typography
                    data-testid="service-edit-output-content"
                    component="pre"
                    variant="body2"
                    sx={[contentStyle, valid === false ? invalidContentStyle : null, loading ? loadingContentStyle : null]}
                >
                    {content}
                </Typography>
            )}
        </Stack>
    )
}
