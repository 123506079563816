import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import { type ConnectwareError, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import type { BaseCommonProps } from '../common'

import type { ErrorMessageExtrasProps } from './Props'
import { errorMessagesClasses } from './Classes'

const codeStyle: SxProps<Theme> = {
    p: 2,
    borderRadius: 2,
    border: 'solid 1px',
    borderColor: 'error.main',
    typography: 'body2',
    fontFamily: 'monospace',
    color: 'error.dark',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
}

const errorExtraInfoWrapper: SxProps<Theme> = {
    display: 'flex',
    mb: 1,
}

const Code: FC<Pick<BaseCommonProps, 'className'>> = ({ className, children }) => (
    <Box className={className} component="pre" sx={codeStyle}>
        {children}
    </Box>
)

export const StackTrace: FC<{ error: ConnectwareError }> = ({ error }) => <Code className={errorMessagesClasses.stack}>{error.stack}</Code>

export const Extras: FC<ErrorMessageExtrasProps> = ({ variant, error, extrasTitleSuffix }) => (
    <Box className={errorMessagesClasses.extras}>
        <Code>
            <Box sx={errorExtraInfoWrapper}>
                <Typography className={errorMessagesClasses['extras-info-title']} variant={variant} color="error">
                    <FormattedTranslation id={Translation.ERROR_EXTRA_INFORMATION} />:
                </Typography>
                {extrasTitleSuffix}
            </Box>

            <Typography className={errorMessagesClasses['extras-info']} variant="body2">
                {JSON.stringify({ type: error.type, extras: error.extras }, null, 2)}
            </Typography>
        </Code>
    </Box>
)
