import React, { type FC, useEffect } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, selectInfo, StatusType, type SystemConnectivity, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'
import { CircularLoader, Status, Table } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'

const wrapperStyle: SxProps<Theme> = { py: 4 }

const Wrapper: FC = ({ children }) => {
    return (
        <Box data-testid="info-preflight" sx={wrapperStyle}>
            {children}
        </Box>
    )
}

const Reachable: FC<{ type: keyof SystemConnectivity['endpoints'], endpoints: SystemConnectivity['endpoints'] }> = ({ type, endpoints }) => (
    <FormattedTranslation id={Translation.IS_URL_REACHABLE} values={{ url: endpoints[type].url.toString(), reachable: endpoints[type].reachable }} />
)

const ReachabilityStatus: FC<{ isReachable: boolean }> = ({ isReachable }) => <Status status={isReachable ? StatusType.REACHABLE : StatusType.UNREACHABLE} />

export const InternetConnectivity: FC = () => {
    const infoUsecase = useAppUsecase('informationUsecase')
    useEffect(() => infoUsecase.loadInformation(), [])

    const info = useAppState(selectInfo)
    if (!info) return null
    const { connectivity } = info
    if (ConnectwareError.is(connectivity)) {
        return (
            <Wrapper>
                <ErrorMessage error={connectivity} extras="popover" />
            </Wrapper>
        )
    }

    if (!connectivity) {
        return (
            <Wrapper>
                <CircularLoader />
            </Wrapper>
        )
    }

    const { endpoints } = connectivity

    const tableData = [
        {
            name: <FormattedTranslation id={Translation.CYBUS_PORTAL} />,
            info: (
                <>
                    <Reachable type="cybusPortal" endpoints={endpoints} />{' '}
                    <FormattedTranslation id={Translation.CYBUS_PORTAL_STATUS} values={{ updatesAutomatically: endpoints.cybusPortal.reachable }} />
                </>
            ),
            status: <ReachabilityStatus isReachable={endpoints.cybusPortal.reachable} />,
        },
        {
            name: <FormattedTranslation id={Translation.CYBUS_REGISTRY} />,
            info: (
                <>
                    <Reachable type="cybusRegistry" endpoints={endpoints} />{' '}
                    <FormattedTranslation id={Translation.CYBUS_REGISTRY_STATUS} values={{ installable: endpoints.cybusRegistry.reachable }} />
                </>
            ),
            status: <ReachabilityStatus isReachable={endpoints.cybusRegistry.reachable} />,
        },
        {
            name: <FormattedTranslation id={Translation.DOCKER_HUB} />,
            info: (
                <>
                    <Reachable type="dockerHub" endpoints={endpoints} />{' '}
                    <FormattedTranslation id={Translation.DOCKER_HUB_STATUS} values={{ installable: endpoints.dockerHub.reachable }} />
                </>
            ),
            status: <ReachabilityStatus isReachable={endpoints.dockerHub.reachable} />,
        },
    ]

    return (
        <Wrapper>
            <Table
                data={tableData}
                columns={{
                    name: { label: Translation.NAME, sort: true },
                    info: { label: Translation.INFO },
                    status: { label: Translation.STATUS },
                }}
            />
        </Wrapper>
    )
}
