import { ConnectwareError, ConnectwareErrorType } from './Error'
import type { Loadable } from './Loadable'

export type BackupMaintenanceStatus = Readonly<{
    /** The timestamp of the file creation, an error stating the file is missing or the null for a file never being created */
    backup: Date | MissingBackupFileError | null
    /** The timestamp of the last restore or null if operation was never executed */
    restore: Date | null
}>

export type BackupAppState = Readonly<{ backupMaintenance: Loadable<BackupMaintenanceStatus> }>

export const selectBackupMaintenance = (s: BackupAppState): BackupAppState['backupMaintenance'] => s.backupMaintenance

export class MissingBackupFileError extends ConnectwareError<ConnectwareErrorType.SERVER_ERROR, Readonly<{ path: string }>> {
    static override is (e: unknown): e is MissingBackupFileError {
        return e instanceof MissingBackupFileError
    }

    constructor (path: string) {
        super(ConnectwareErrorType.SERVER_ERROR, 'Backup file is missing', { path })
    }

    get path (): string {
        return this.extras.path
    }
}
