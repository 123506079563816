import React, { type FC, useEffect } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { useAppUsecase } from '../../../State'

import { LicenseDetails } from '..'
import { PortalReachabilityError } from './Connectivity'

const wrapperStyle: SxProps<Theme> = { py: 4 }

export const LicensePage: FC = () => {
    const infoUsecase = useAppUsecase('informationUsecase')
    useEffect(() => infoUsecase.loadInformation(), [infoUsecase])

    return (
        <Box sx={wrapperStyle}>
            <PortalReachabilityError />
            <Box data-testid="license-details">
                <LicenseDetails />
            </Box>
        </Box>
    )
}
