import React, { forwardRef, type InputHTMLAttributes } from 'react'

import type { NonEmptyArray } from '../../../../utils'

export type HiddenRawFileUploadProps = Readonly<{
    /**
     * Which files types should be accepted
     *
     * @see {HTMLInputElement.accept}
     */
    accept?: NonEmptyArray<string>

    onChange: (files: File[]) => void
}> &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'hidden' | 'accept' | 'value' | 'onChange'>

export const HiddenRawFileUpload = forwardRef<HTMLInputElement, HiddenRawFileUploadProps>(({ accept, onChange, ...props }, ref) => (
    <input
        {...props}
        type="file"
        hidden
        accept={accept?.map((t) => `.${t}`).join(',')}
        ref={ref}
        onChange={(e) => {
            onChange(Array.from(e.target.files ?? []))

            /**
             * This drops the internal value
             * Forcing any further selection to also be accepted
             *
             * Before this change, a file upload (no matter the content of it)
             * would be ignored if it had the same file path
             */
            e.target.value = ''
        }}
    />
))
