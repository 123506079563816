import type { SingleSubscriptionsTypes, SubscriptionEvent } from '../../../../../application'

import { SubscriptionHandlerType } from '../handlers'
import { type DataMapper, InstanceToOneDataMapper, InstanceToVirtualOneDataMapper } from '../mappers'
import { BaseSubscription } from './Base'

export class SingleSubscription<T extends keyof SingleSubscriptionsTypes> extends BaseSubscription<T, SubscriptionEvent<T>> {
    private id: string | null = null

    /**
     * Single subscription only works for one to one mapping
     * Otherwise no mapper should be yielded
     */
    protected createMapper (): DataMapper<SubscriptionEvent<T>> | null {
        if (this.id && SingleSubscription.isHandlerOfType(this.handler, SubscriptionHandlerType.INSTANCE_ONE_TO_ONE)) {
            return new InstanceToOneDataMapper(this.id, this.handler, this.filter)
        }

        if (this.id && SingleSubscription.isHandlerOfType(this.handler, SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE)) {
            return new InstanceToVirtualOneDataMapper(this.id, this.handler, this.filter)
        }

        return null
    }

    withId (id: string): this {
        this.id = id
        return this
    }
}
