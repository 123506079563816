import { Capability, createIsAuthenticatedWithCapabilitiesSelector, type CybusLog, type CybusLogSource, Translation } from '../../../domain'

import { Usecase } from '..'
import type { LogOptions, RawLogOptions } from '../..'

const canReadLogs = createIsAuthenticatedWithCapabilitiesSelector(Capability.LOGS_READ)

export class ContainerLogsUsecase extends Usecase {
    areLogsAvailable (type: CybusLogSource, id: string): Promise<boolean> {
        if (!canReadLogs(this.getState())) {
            return Promise.resolve(false)
        }

        return this.connectwareLogsService.doesCollectLogs(type, id)
    }

    fetchLogs (type: CybusLogSource, options: LogOptions): Promise<CybusLog[]> {
        return this.connectwareLogsService.fetchLogs(type, options)
    }

    async downloadRawlogs (type: CybusLogSource, resource: RawLogOptions['resource']): Promise<void> {
        this.fileService.download(await this.connectwareLogsService.fetchAllRawLogs(type, { resource, lines: 'all' }))
    }

    downloadCSV (logs: CybusLog[], fileName: string, showServiceId: boolean): void {
        const head = [
            this.translationService.translate(Translation.LEVEL),
            this.translationService.translate(Translation.TIME),
            ...(showServiceId ? [this.translationService.translate(Translation.SERVICE, { count: 1 })] : []),
            this.translationService.translate(Translation.MESSAGE),
        ]
        const file = this.fileService.generateCSV(
            fileName,
            head,
            logs.map((log) => (showServiceId ? [log.level, log.timestamp, log.serviceId, log.message] : [log.level, log.timestamp, log.message]))
        )
        this.fileService.download(file)
    }
}
