import { deltaFromArray, executeOnce, isObjectEmpty, type ReadonlyRecord } from '../../utils'

import {
    ConnectwareError,
    ConnectwareErrorType,
    CybusPermissionContext,
    type CybusPermissionOperations,
    type CybusPersistedPermission,
    type CybusRole,
    type EditableCybusPermission,
    type EditableCybusPermissionInheritance,
    type EditableCybusPermissionWithInheritance,
} from '..'

type Permission = EditableCybusPermission | EditableCybusPermissionInheritance | CybusPersistedPermission
type ClearCacheMode = 'all' | 'permission' | 'none'

class EditableCybusPermissionWithInheritanceImpl implements EditableCybusPermissionWithInheritance {
    private readonly cache = {
        context: executeOnce(() => this.map((p) => p.context)),
        resource: executeOnce(() => this.map((p) => p.resource)),
        inheritancesRead: executeOnce(() => Object.values(this.internalInheritance).some((i) => i.read)),
        inheritancesWrite: executeOnce(() => Object.values(this.internalInheritance).some((i) => i.write)),
        inheritanceRoles: executeOnce(() => Object.values(this.internalInheritance).map((i) => i.role)),
        read: executeOnce(() => this.internalPermission?.read || this.inheritancesRead),
        write: executeOnce(() => this.internalPermission?.write || this.inheritancesWrite),
    }

    static create (): EditableCybusPermissionWithInheritanceImpl {
        return new EditableCybusPermissionWithInheritanceImpl()
    }

    private constructor (
        private internalPermission: EditableCybusPermissionWithInheritance['permission'] = null,
        private internalRequirements: CybusPermissionOperations | null = null,
        private internalInheritance: Record<CybusRole['name'], EditableCybusPermissionInheritance> = {}
    ) {}

    get permission (): EditableCybusPermissionWithInheritance['permission'] {
        return this.internalPermission
    }

    get context (): EditableCybusPermissionWithInheritance['context'] {
        return this.cache.context()
    }

    get resource (): EditableCybusPermissionWithInheritance['resource'] {
        return this.cache.resource()
    }

    get inheritancesRead (): EditableCybusPermissionWithInheritance['read'] {
        return this.cache.inheritancesRead()
    }

    get inheritancesWrite (): EditableCybusPermissionWithInheritance['write'] {
        return this.cache.inheritancesWrite()
    }

    get inheritanceRoles (): EditableCybusPermissionWithInheritance['inheritanceRoles'] {
        return this.cache.inheritanceRoles()
    }

    get read (): EditableCybusPermissionWithInheritance['read'] {
        return this.cache.read()
    }

    get write (): EditableCybusPermissionWithInheritance['write'] {
        return this.cache.write()
    }

    get required (): EditableCybusPermissionWithInheritance['required'] {
        return this.internalRequirements
    }

    private clearCache (mode: ClearCacheMode): void {
        if (mode === 'none') {
            return
        }

        if (mode === 'all') {
            this.cache.inheritancesRead.clear()
            this.cache.inheritancesWrite.clear()
            this.cache.inheritanceRoles.clear()
        }

        this.cache.context.clear()
        this.cache.resource.clear()
        this.cache.read.clear()
        this.cache.write.clear()
    }

    private refresh (mode: ClearCacheMode): boolean {
        this.clearCache(mode)
        return this.internalPermission === null && this.internalRequirements === null && isObjectEmpty(this.internalInheritance)
    }

    private map<T> (mapper: (args: Permission) => T): T {
        const first = this.internalPermission || Object.values(this.internalInheritance)[0]

        if (first) {
            return mapper(first)
        }

        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'First permission not found', {
            permission: this.internalPermission,
            inheritance: this.internalInheritance,
            requirements: this.internalRequirements,
        })
    }

    clone (): EditableCybusPermissionWithInheritanceImpl {
        return new EditableCybusPermissionWithInheritanceImpl(this.internalPermission, this.internalRequirements, this.internalInheritance)
    }

    updatePermission (addition: EditableCybusPermission): this {
        this.internalPermission = addition
        this.clearCache('permission')
        return this
    }

    updateInheritance (addition: EditableCybusPermissionInheritance): this {
        this.internalInheritance[addition.role] = addition
        this.clearCache('all')
        return this
    }

    updateRequired (addition: CybusPersistedPermission): this {
        this.internalRequirements = addition
        this.clearCache('none')
        return this
    }

    removePermission (): boolean {
        this.internalPermission = null
        return this.refresh('permission')
    }

    removeRequired (): boolean {
        this.internalRequirements = null
        return this.refresh('none')
    }

    removeInheritance (removal: EditableCybusPermissionInheritance): boolean {
        if (this.internalInheritance[removal.role] === removal) {
            delete this.internalInheritance[removal.role]
        }

        return this.refresh('all')
    }
}

/**
 * This is a domain utility class made to
 * help with the merger of permissions
 * (some inherited from roles and some not)
 *
 * It's intension is to be able to merge permissions
 * while having the minimal amount of new objects being created as possible
 */

export class CybusPermissionMerger {
    private readonly indexed = Object.values(CybusPermissionContext).reduce(
        (r, context) => ({ ...r, [context]: [] }),
        {} as Record<CybusPermissionContext, Record<EditableCybusPermissionWithInheritance['resource'], EditableCybusPermissionWithInheritanceImpl>>
    )

    constructor (
        private permissionsInput: EditableCybusPermission[],
        private requiredInput: CybusPersistedPermission[],
        private inheritedInput: EditableCybusPermissionInheritance[]
    ) {
        this.permissionsInput.forEach((p) => this.create(p, (ref) => ref.updatePermission(p)))
        this.inheritedInput.forEach((i) => this.create(i, (ref) => ref.updateInheritance(i)))
        this.requiredInput.forEach((r) => this.create(r, (ref) => ref.updateRequired(r)))
    }

    private getRefOrDefault (permission: Permission): EditableCybusPermissionWithInheritanceImpl {
        return this.indexed[permission.context][permission.resource] || EditableCybusPermissionWithInheritanceImpl.create()
    }

    private setRef (permission: Permission, impl: EditableCybusPermissionWithInheritanceImpl): void {
        this.indexed[permission.context][permission.resource] = impl
    }

    private deleteRef (permission: Permission): void {
        delete this.indexed[permission.context][permission.resource]
    }

    private create (addition: Permission, add: (ref: EditableCybusPermissionWithInheritanceImpl) => typeof ref): void {
        this.setRef(addition, add(this.getRefOrDefault(addition)))
    }

    private update (update: Permission, modify: (ref: EditableCybusPermissionWithInheritanceImpl) => typeof ref): void {
        this.setRef(update, modify(this.getRefOrDefault(update).clone()))
    }

    private remove (removal: Permission, remove: (ref: EditableCybusPermissionWithInheritanceImpl) => boolean): void {
        const impl = this.indexed[removal.context][removal.resource]

        if (impl) {
            if (remove(impl)) {
                // Is now empty, so remove it
                this.deleteRef(removal)
            } else {
                // Create a copy of what is left
                this.setRef(removal, impl.clone())
            }
        }
    }

    setAll (
        permissions: EditableCybusPermission[],
        required: CybusPersistedPermission[],
        inherited: EditableCybusPermissionInheritance[]
    ): CybusPermissionContext[] {
        /**
         * Calculate what has been changed
         */
        const [removedPermissions, , addedPermissions] = deltaFromArray(this.permissionsInput, permissions)
        const [removedInheritance, , addedInheritance] = deltaFromArray(this.inheritedInput, inherited)
        const [removedRequirement, , addedRequirement] = deltaFromArray(this.requiredInput, required)

        /**
         * Remove or add changes
         */
        removedPermissions.forEach((p) => this.remove(p, (ref) => ref.removePermission()))
        removedInheritance.forEach((i) => this.remove(i, (ref) => ref.removeInheritance(i)))
        removedRequirement.forEach((r) => this.remove(r, (ref) => ref.removeRequired()))
        addedPermissions.forEach((p) => this.update(p, (ref) => ref.updatePermission(p)))
        addedInheritance.forEach((i) => this.update(i, (ref) => ref.updateInheritance(i)))
        addedRequirement.forEach((r) => this.update(r, (ref) => ref.updateRequired(r)))

        this.permissionsInput = permissions
        this.inheritedInput = inherited
        this.requiredInput = required

        return Array.from(new Set([...addedPermissions, ...addedInheritance, ...addedRequirement].map((p) => p.context)))
    }

    getPermissions (): ReadonlyRecord<CybusPermissionContext, EditableCybusPermissionWithInheritance[]> {
        return Object.values(CybusPermissionContext).reduce(
            (r, context) => ({ ...r, [context]: Object.values(this.indexed[context]) }),
            {} as ReadonlyRecord<CybusPermissionContext, EditableCybusPermissionWithInheritance[]>
        )
    }
}
