import { LoginUsecase } from './Login'

export class LogoutUsecase extends LoginUsecase {
    /**
     * Logs user out and reloads page
     */
    override logout (): void {
        super.logout()
    }
}
