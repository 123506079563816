import type { ValuesType } from 'utility-types'

import {
    assertCommissioningFileValidatedValueType,
    CommissioningFileFormState,
    type CommissioningFileValidatedValue,
    type CommissioningFileValidatedValues,
    type CommissioningFileValues,
    mapCommissioningFileValidatedValue,
    MetadataCommissioningFileFinder,
    type StringCommissioningFileField,
} from '../../../../domain'
import { EditServiceTemplateOutputUsecase } from './Output'

export class EditServiceTemplateMetadataUsecase extends EditServiceTemplateOutputUsecase {
    updateMetadata (name: keyof CommissioningFileValues['metadata'], newValue: ValuesType<CommissioningFileValues['metadata']>): void {
        const { fields, file, values } = this.getTypedForm([CommissioningFileFormState.LOADED_OUTPUT, CommissioningFileFormState.LOADING_OUTPUT])

        type TuppleType = [CommissioningFileValidatedValues, CommissioningFileValidatedValue<StringCommissioningFileField>]
        const [field, value] = new MetadataCommissioningFileFinder<TuppleType>(fields, values).find(name)

        if (value.value === newValue) {
            /** Values are still the same, do not go forward */
            return
        }

        /** Make sure types are as expected */
        assertCommissioningFileValidatedValueType(field, newValue)

        /** Create validated value */
        const validatedValue = mapCommissioningFileValidatedValue(field, newValue)

        /** Update */
        this.setForm({
            type: CommissioningFileFormState.LOADING_OUTPUT,
            fields,
            file,
            values: { ...values, metadata: { ...values.metadata, [name]: validatedValue } },
        })

        /** And schedule the refresh finally */
        this.scheduleOutputRefresh()
    }
}
