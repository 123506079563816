import { LogoutUsecase } from '.'

export class LoginFromTokenUsecase extends LogoutUsecase {
    loginBrowserFromToken (): boolean {
        const token = this.browserService.getToken()

        if (!token) {
            // There is no token, give up
            return false
        }

        void this.updateAuthenticationFromSession(token)

        return true
    }
}
