import React, { type FC, useState } from 'react'
import { IconButton, Stack, type SxProps, type Theme, Typography } from '@mui/material'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

import { Translation } from '../../../../../domain'
import { useTranslator } from '../../../Internationalization'

import { CopyWithIconsChange as Copy } from '../../../Copy'

const containerStyle: SxProps<Theme> = { justifyContent: 'flexStart', alignItems: 'center' }
const licenseIdStyle: SxProps<Theme> = { fontFamily: 'monospace', pr: 2 }
const buttonStyle: SxProps<Theme> = {
    '&:hover': { opacity: 0.5, backgroundColor: 'transparent' },
    paddingY: 0,
}

const CHARS_TO_REVEAL = 4
const MASK_CHAR = '*'

export const LicenseId: FC<{ id: string }> = ({ id }) => {
    const translator = useTranslator()
    const [isIdHidden, toggleIdVisibility] = useState<boolean>(true)

    // mask license id with MASK_CHAR but show the last CHARS_TO_REVEAL characters (4 by default)
    const maskedId = id.slice(0, -CHARS_TO_REVEAL).replaceAll(/[^-]/g, MASK_CHAR) + id.slice(-CHARS_TO_REVEAL)

    return (
        <Stack direction="row" sx={containerStyle} data-testid="license-id">
            <Typography variant="body2" sx={licenseIdStyle}>
                {isIdHidden ? maskedId : id}
            </Typography>
            <IconButton onClick={() => toggleIdVisibility(!isIdHidden)} data-testid={isIdHidden ? 'show-license-btn' : 'hide-license-btn'} sx={buttonStyle}>
                {isIdHidden ? (
                    <VisibilityOutlined titleAccess={translator.formatTranslation(Translation.SHOW_LICENSE_ID)} />
                ) : (
                    <VisibilityOffOutlined titleAccess={translator.formatTranslation(Translation.HIDE_LICENSE_ID)} />
                )}
            </IconButton>
            <Copy title={translator.formatTranslation(Translation.COPY_LICENSE_ID)} sx={buttonStyle}>
                {id}
            </Copy>
        </Stack>
    )
}
