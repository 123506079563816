import { ExtendedMap, objectEntries, type ReadonlyRecord } from '../../../utils'
import {
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFieldType,
    type CommissioningFileValidatedValue,
    type CommissioningFileValidatedValues,
    type CommissioningFileValue,
    type CommissioningFileValues,
    CommissioningFileValueValidationError,
    ConnectionsCommissioningFileFinder,
    type ConnectwareErrorExtras,
    EndpointsCommissioningFileFinder,
    mapCommissioningFileValidatedValue,
    MetadataCommissioningFileFinder,
    type StringIndexCommissioningFileField,
} from '../..'

class StringIndexCommissioningFileFieldValidityMapper {
    private static readonly ERROR_REASON: ConnectwareErrorExtras<CommissioningFileValueValidationError>['reason'] = 'ITEMS_NOT_UNIQUE'

    private readonly usedValues = new ExtendedMap<StringIndexCommissioningFileField['name'], Set<CommissioningFileValue<StringIndexCommissioningFileField>>>()

    private static isReplaceable (validation: CommissioningFileValidatedValue<CommissioningFileField>['validation']): boolean {
        return (
            validation === true ||
            (CommissioningFileValueValidationError.is(validation) && validation.extras.reason === StringIndexCommissioningFileFieldValidityMapper.ERROR_REASON)
        )
    }

    private add (name: StringIndexCommissioningFileField['name'], entry: CommissioningFileValue<StringIndexCommissioningFileField>): boolean {
        const currentEntries = this.usedValues.getDefault(name, new Set())
        this.usedValues.set(name, currentEntries)

        const wasUsed = currentEntries.has(entry)
        currentEntries.add(entry)

        return wasUsed
    }

    appendInvalidity<F extends CommissioningFileField> (field: F, validatedValue: CommissioningFileValidatedValue<F>): CommissioningFileValidatedValue<F> {
        if (field.type !== CommissioningFileFieldType.STRING_INDEX || typeof validatedValue.value !== 'string') {
            /** Not a relevant field, or not value not set yet */
            return validatedValue
        }

        const wasUsed = this.add(field.name, validatedValue.value)

        if (StringIndexCommissioningFileFieldValidityMapper.isReplaceable(validatedValue.validation)) {
            /** Validity can be replaced, so now indicate if value is being used more than once or not */
            return {
                value: validatedValue.value,
                validation: wasUsed ? new CommissioningFileValueValidationError(StringIndexCommissioningFileFieldValidityMapper.ERROR_REASON, field) : true,
            }
        }

        return validatedValue
    }
}

type ResourceFieldFinder = Readonly<{ findField(position: number, fieldName: string): CommissioningFileField }>

const createIndexInvalidator = (): ((
    finder: ResourceFieldFinder,
    values: ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[]
) => ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[]) => {
    const indexesMapper = new StringIndexCommissioningFileFieldValidityMapper()

    return (finder, values) =>
        values.map((value, index) =>
            objectEntries(value).reduce(
                (r, [fieldName, value]) => ({ ...r, [fieldName]: indexesMapper.appendInvalidity(finder.findField(index, fieldName), value) }),
                {}
            )
        )
}

export const appendIndexInvalidities = (fields: CommissioningFileFields, values: CommissioningFileValidatedValues): CommissioningFileValidatedValues => {
    const appendInvalidation = createIndexInvalidator()

    const connectionFinder = new ConnectionsCommissioningFileFinder(fields, values)
    const endpointsFinder = new EndpointsCommissioningFileFinder(fields, values)

    return {
        ...values,
        connections: appendInvalidation(connectionFinder, values.connections),
        endpoints: appendInvalidation(endpointsFinder, values.endpoints),
    }
}

const mapCommissioningFileResourceValidatedValues = (
    finder: ResourceFieldFinder,
    values: ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>[]
): ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[] =>
    values.reduce<ReturnType<typeof mapCommissioningFileResourceValidatedValues>>(
        (value, validatedValues, index) => [
            ...value,
            objectEntries(validatedValues).reduce(
                (r, [fieldName, value]) => ({ ...r, [fieldName]: mapCommissioningFileValidatedValue(finder.findField(index, fieldName), value) }),
                {}
            ),
        ],
        []
    )

export const mapCommissioningFileValidatedValues = (fields: CommissioningFileFields, values: CommissioningFileValues): CommissioningFileValidatedValues => {
    const metadataFinder = new MetadataCommissioningFileFinder(fields, values)
    const connectionFinder = new ConnectionsCommissioningFileFinder(fields, values)
    const endpointsFinder = new EndpointsCommissioningFileFinder(fields, values)

    return appendIndexInvalidities(fields, {
        metadata: objectEntries(values.metadata).reduce<CommissioningFileValidatedValues['metadata']>(
            (r, [name, value]) => ({ ...r, [name]: mapCommissioningFileValidatedValue(metadataFinder.findField(name), value) }),
            {}
        ),
        connections: mapCommissioningFileResourceValidatedValues(connectionFinder, values.connections),
        endpoints: mapCommissioningFileResourceValidatedValues(endpointsFinder, values.endpoints),
    })
}

const mapCommissioningFileResourceValues = (
    validated: ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[]
): ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>[] =>
    validated.reduce<ReturnType<typeof mapCommissioningFileResourceValues>>(
        (r, resource) => [...r, objectEntries(resource).reduce((p, [name, { value }]) => ({ ...p, [name]: value }), {})],
        []
    )

export const mapCommissioningFileValues = ({ metadata, connections, endpoints }: CommissioningFileValidatedValues): CommissioningFileValues => ({
    metadata: objectEntries(metadata).reduce((r, [name, { value }]) => ({ ...r, [name]: value }), {}),
    connections: mapCommissioningFileResourceValues(connections),
    endpoints: mapCommissioningFileResourceValues(endpoints),
})
