import { executeOnce } from '../../../../../../utils'

import { areCybusConnectionsEquals, Capability, type CybusConnection } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { type ConnectionProxyParams, INVALID_CONNECTION_INSTANCE_PATTERN, mapConnection, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class ConnectionsStrategy extends BaseSubscriptionStrategy<'connections'> {
    readonly requiredPermissions = [Capability.CONNECTIONS_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected override areEquals = areCybusConnectionsEquals

    override retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusConnection[]> {
        return Promise.all([
            this.retrieve({
                capability: Capability.CONNECTIONS_READ,
                method: 'GET',
                path: '/api/connections',
                mapper: (j: ConnectionProxyParams[]) => j,
            }),
        ]).then(([params]) => {
            return params.reduce<CybusConnection[]>((r, c) => {
                const mapOnce = executeOnce(() => {
                    /**
                     * @deprecated update code block so status is fetched externally
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const currentStatus = mapToStatusType(c.currentState)
                    return mapConnection(c, currentStatus)
                })

                return INVALID_CONNECTION_INSTANCE_PATTERN.test(c.id) || (service && service !== mapOnce().service) ? r : [...r, mapOnce()]
            }, [])
        })
    }
}
