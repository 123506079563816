import React, { type FC } from 'react'

import { objectEntries } from '../../../../utils'

import { Capability, type CybusDetailedConnection, CybusLogSource, Translation } from '../../../../domain'

import { Table } from '../../common'

import Endpoints from '../../Endpoints'
import { Tab, Tabs } from '../../Tabs'
import Logs, { useAreLogsAvailable } from '../../Logs'

export const Content: FC<{ resource: CybusDetailedConnection }> = ({ resource: { id, extraConfiguration } }) => {
    const available = useAreLogsAvailable(CybusLogSource.CONNECTION, id)

    return (
        <Tabs>
            <Tab title={Translation.CONNECTION_LOGS} disabled={!available} requiredCapabilities={[Capability.LOGS_READ]}>
                <Logs id={id} type={CybusLogSource.CONNECTION} />
            </Tab>
            <Tab title={Translation.CONFIGURATION}>
                <Table
                    data={objectEntries(extraConfiguration).map(([name, value]) => ({ name, value }))}
                    columns={{ name: { label: Translation.NAME, sort: true }, value: { label: Translation.VALUE } }}
                />
            </Tab>
            <Tab title={Translation.ENDPOINT}>
                <Endpoints connectionId={id} />
            </Tab>
        </Tabs>
    )
}
