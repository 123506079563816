import { createIntl, type FormatListOptions, type IntlShape, type OnErrorFn } from '@formatjs/intl'

import type { ReadonlyRecord } from '../../utils'
import { ConnectwareError, ConnectwareErrorType, type Translation } from '../../domain'

import type { ListTranslationOption, LoggerService, TranslationService } from '../../application'

const locale = 'en-de'

export const createTranslationInternationalization = (translation: ReadonlyRecord<Translation, string>, logger: LoggerService): IntlShape<string> => {
    const onError: OnErrorFn = (e) =>
        logger.error(
            new ConnectwareError(ConnectwareErrorType.TRANSLATIONS, e.message.trim(), {
                code: e.code,
                descriptor: ('descriptor' in e && e.descriptor) || null,
            })
        )

    return createIntl({ messages: translation, locale, onError })
}

const listOptionsMap: ReadonlyRecord<ListTranslationOption, FormatListOptions['type']> = { list: 'conjunction', options: 'disjunction' }

/**
 * Translation patterns should be as seen here `https://formatjs.io/docs/intl-messageformat`
 */
export class FormatJSTranslationService implements TranslationService {
    constructor (private readonly intls: IntlShape<string>) {}

    private get intl (): IntlShape<string> {
        return this.intls
    }

    list (values: string[], option: ListTranslationOption = 'list'): string {
        return this.intl.formatList(values, { type: listOptionsMap[option] })
    }

    translate (id: Translation, values?: ReadonlyRecord<string, unknown>): string {
        return this.intl.formatMessage({ id }, values as ReadonlyRecord<string, string>)
    }
}
