/* eslint-disable typescript-sort-keys/string-enum */

export enum StatusType {
    AVAILABLE = 'AVAILABLE',
    CONNECTED = 'CONNECTED',
    CONNECTING = 'CONNECTING',
    CREATED = 'CREATED',
    DEAD = 'DEAD',
    DEVIATED = 'DEVIATED',
    DISABLED = 'DISABLED',
    DISABLING = 'DISABLING',
    DISCONNECTED = 'DISCONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    ENABLED = 'ENABLED',
    ENABLING = 'ENABLING',
    EXITED = 'EXITED',
    NO_DEVIATION = 'NO_DEVIATION',
    NOT_ENABLED = 'NOT_ENABLED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
    PAUSED = 'PAUSED',
    RECONNECTING = 'RECONNECTING',
    REENABLING = 'REENABLING',
    RESTARTING = 'RESTARTING',
    ROLLING_BACK = 'ROLLING_BACK',
    RUNNING = 'RUNNING',
    STARTING = 'STARTING',
    STOPPING = 'STOPPING',
    TERMINATED = 'TERMINATED',
    UNAVAILABLE = 'UNAVAILABLE',
    UPDATING = 'UPDATING',
    WAITING = 'WAITING',
    REACHABLE = 'REACHABLE',
    UNREACHABLE = 'UNREACHABLE',
    VALID = 'VALID',
    INVALID = 'INVALID',
    /**
     * @deprecated used for handling of legacy values
     */
    // eslint-disable-next-line typescript-sort-keys/string-enum
    UNKNOWN = 'UNKNOWN',
}
