import React, { type FC, useLayoutEffect } from 'react'

import { useRouting } from '../routing'
import { useAppUsecase } from '../State'

export const Workbench: FC = () => {
    const usecase = useAppUsecase('workbenchUsecase')
    const routing = useRouting()

    useLayoutEffect(() => {
        /** Open the workbench on the first render */
        usecase.open()

        /** Go back to the previous */
        routing.goBack()
    }, [])

    return <></>
}
