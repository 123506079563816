import {
    areUsersManagementTabInformationFiltersEquals,
    type ConnectwareError,
    type CybusPermission,
    type CybusRole,
    type CybusUser,
    type PaginatedData,
    selectUsersManagementTabInformation,
    type UserManagementTabsTypes,
    type UsersManagement,
} from '../../../domain'
import { UserManagementUsecase } from './Base'

export abstract class TabManagementUsecase extends UserManagementUsecase {
    private readonly pageFetchers: Readonly<{
        [P in keyof UserManagementTabsTypes]: (search: string, showInternal: boolean, page: number) => Promise<PaginatedData<UserManagementTabsTypes[P]>>
    }> = {
        users: (...args): Promise<PaginatedData<CybusUser>> => this.userService.fetchUsersPage(...args),
        roles: (...args): Promise<PaginatedData<CybusRole>> => this.userService.fetchRolesPage(...args),
        permissions: (...args): Promise<PaginatedData<CybusPermission>> => this.userService.fetchPermissionsPage(...args),
    }

    protected updateTab<S extends keyof UserManagementTabsTypes> (tab: S, update: Partial<UsersManagement[S]>): void {
        this.setUsersManagement({ [tab]: { ...selectUsersManagementTabInformation(this.getState(), tab), ...update } })
    }

    protected async loadTab<S extends keyof UserManagementTabsTypes> (selected: S, resetTab: boolean): Promise<void> {
        const previousTab = selectUsersManagementTabInformation(this.getState(), selected)

        if (resetTab) {
            /** Set the tab as loading */
            this.updateTab(selected, { data: null } as Partial<UsersManagement[S]>)
        }

        /** Finally get the data */
        const promise = this.pageFetchers[selected](previousTab.search, previousTab.showInternal, previousTab.page)
        const data = await promise.catch((e: ConnectwareError) => e)

        /** Check if filter has been updated */
        const currentTab = selectUsersManagementTabInformation(this.getState(), selected)
        if (!areUsersManagementTabInformationFiltersEquals(previousTab, currentTab)) {
            return
        }

        const page = data && 'page' in data ? data.page : null

        if (page !== null && previousTab.page !== page) {
            /** Page does not match what backend says, so update it and let the data be refreshed */
            this.updateTab<S>(selected, { page } as Partial<UsersManagement[S]>)
        } else {
            /** State hasn't been re-fetched, just update the data */
            this.updateTab<S>(selected, { data } as Partial<UsersManagement[S]>)
        }
    }
}
