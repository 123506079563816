import React, { type FC, type ReactNode, useMemo } from 'react'
import { Box } from '@mui/material'

import { type NonEmptyArray, objectKeys, type ReadonlyRecord } from '../../../utils'
import type { Translation } from '../../../domain'

import { type AbsoluteRouteOnlyPath, useHasCapabilities, useRouteFormatter, useRouting } from '../routing'
import { DetailsHeader } from '../common'

import { Tab, Tabs } from '.'
import type { TabsProps } from './Tabs'

type RouteTabsProps<R extends string,> = Readonly<{
    title: Translation
    tabs: ReadonlyRecord<R, ReadonlyRecord<'documentationTitle' | 'documentationBody', Translation> & Readonly<{ titleActions?: ReactNode }>>
    tabsWrapper?: FC
}> &
    Pick<TabsProps<R>, 'orientation'>

const defaultTabsWrapper: FC = ({ children }) => <>{children}</>

export const RouteTabs = <R extends AbsoluteRouteOnlyPath,>({
    title,
    tabs,
    orientation,
    tabsWrapper: TabsWrapper = defaultTabsWrapper,
    ...props
}: RouteTabsProps<R>): ReturnType<FC> => {
    const router = useRouting()
    const hasCapabilities = useHasCapabilities()
    const formatter = useRouteFormatter()

    const keys = objectKeys(tabs) as NonEmptyArray<R>

    const currentTab: R = useMemo(() => keys.find((route) => router.isAtRoute(route)) ?? keys[0], [router])

    const { documentationTitle, documentationBody, titleActions } = tabs[currentTab]

    return (
        <Box {...props}>
            <DetailsHeader title={[title, { count: 0 }]} titleActions={titleActions} description={[documentationTitle, documentationBody]} />

            <TabsWrapper>
                <Tabs orientation={orientation} onChange={(value) => router.redirect(value)} value={currentTab}>
                    {keys.map((route) => {
                        const disabled = !hasCapabilities(route)
                        return <Tab key={route} title={formatter.formatTitle(route)} value={route} disabled={disabled} />
                    })}
                </Tabs>
            </TabsWrapper>
        </Box>
    )
}
