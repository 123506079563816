import { useCallback } from 'react'

import type { ReadonlyRecord } from '../../../../utils'
import { CybusPermissionContext, Translation } from '../../../../domain'
import { useTranslator } from '../../Internationalization'

const contextLabels: ReadonlyRecord<CybusPermissionContext, string> = {
    [CybusPermissionContext.HTTP]: 'Http',
    [CybusPermissionContext.MQTT]: 'Mqtt',
}

const contextMap: ReadonlyRecord<CybusPermissionContext, Translation> = {
    [CybusPermissionContext.HTTP]: Translation.ENDPOINT,
    [CybusPermissionContext.MQTT]: Translation.TOPIC,
}

/**
 * This hooks centralizes the labeling of the permission context if we ever translate them
 *
 * @see {CybusPermissionContext}
 * @warning function is **not** memoized
 */
export const useContextLabel = (): ((context: CybusPermissionContext) => string) => (context) => contextLabels[context]

export const useContextResourceTranslation = (): ((context: CybusPermissionContext, count?: number) => string) => {
    const translator = useTranslator()
    return useCallback((context, count = 1): string => translator.formatTranslation(contextMap[context], { count }), [translator])
}
