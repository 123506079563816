/* eslint-disable typescript-sort-keys/string-enum */

export enum AbsoluteRouteOnlyPath {
    /** System */
    SYSTEM = 'SYSTEM',
    SYSTEM_STATUS = 'SYSTEM_STATUS',
    SYSTEM_CONTAINERS = 'SYSTEM_CONTAINERS',
    SYSTEM_INTERNET_CONNECTIVITY = 'SYSTEM_INTERNET_CONNECTIVITY',
    SYSTEM_METRICS = 'SYSTEM_METRICS',
    SYSTEM_AGENTS = 'SYSTEM_AGENTS',
    SYSTEM_LICENSE = 'SYSTEM_LICENSE',
    SYSTEM_MAINTENANCE = 'SYSTEM_MAINTENANCE',
    SYSTEM_INFORMATION = 'SYSTEM_INFORMATION',

    /** Data */
    DATA = 'DATA',
    DATA_EXPLORER = 'DATA_EXPLORER',

    /** Services */
    SERVICES = 'SERVICES',
    SERVICES_OVERVIEW = 'SERVICES_OVERVIEW',
    SERVICES_TEMPLATE_EDIT = 'SERVICES_TEMPLATE_EDIT',
    SERVICES_RESOURCES_LINKS = 'SERVICES_RESOURCES_LINKS',
    SERVICES_RESOURCES = 'SERVICES_RESOURCES',
    SERVICES_RESOURCES_SERVERS = 'SERVICES_RESOURCES_SERVERS',
    SERVICES_RESOURCES_CONTAINERS = 'SERVICES_RESOURCES_CONTAINERS',
    SERVICES_RESOURCES_VOLUMES = 'SERVICES_RESOURCES_VOLUMES',
    SERVICES_RESOURCES_CONNECTIONS = 'SERVICES_RESOURCES_CONNECTIONS',
    SERVICES_RESOURCES_ENDPOINTS = 'SERVICES_RESOURCES_ENDPOINTS',
    SERVICES_RESOURCES_MAPPINGS = 'SERVICES_RESOURCES_MAPPINGS',
    SERVICES_LOGS = 'SERVICES_LOGS',
    SERVICES_CATALOG = 'SERVICES_CATALOG',
    SERVICES_RULE_ENGINE_SANDBOX = 'SERVICES_RULE_ENGINE_SANDBOX',
    SERVICES_WORKBENCH = 'SERVICES_WORKBENCH',

    /** User */
    USER = 'USER',
    USER_MANAGEMENT = 'USER_MANAGEMENT',
    USER_MANAGEMENT_USERS = 'USER_MANAGEMENT_USERS',
    USER_MANAGEMENT_ROLES = 'USER_MANAGEMENT_ROLES',
    USER_MANAGEMENT_PERMISSIONS = 'USER_MANAGEMENT_PERMISSIONS',
    USER_CLIENT_REGISTRY = 'USER_CLIENT_REGISTRY',
    USER_CERTIFICATES = 'USER_CERTIFICATES',

    /** Settings */
    SETTINGS = 'SETTINGS',
    SETTINGS_LOGIN = 'SETTINGS_LOGIN',
}

export enum AbsoluteRoutePathWithServiceId {
    SERVICES_OVERVIEW_SERVICE = 'SERVICES_OVERVIEW_SERVICE',
}

export enum AbsoluteRoutePathWithId {
    SERVICES_RESOURCES_SERVICE = 'SERVICES_RESOURCES_SERVICE',
    SERVICES_RESOURCES_SERVER = 'SERVICES_RESOURCES_SERVER',
    SERVICES_RESOURCES_CONTAINER = 'SERVICES_RESOURCES_CONTAINER',
    SERVICES_RESOURCES_VOLUME = 'SERVICES_RESOURCES_VOLUME',
    SERVICES_RESOURCES_CONNECTION = 'SERVICES_RESOURCES_CONNECTION',
    SERVICES_RESOURCES_ENDPOINT = 'SERVICES_RESOURCES_ENDPOINT',
    SERVICES_RESOURCES_MAPPING = 'SERVICES_RESOURCES_MAPPING',
    SYSTEM_CONTAINER = 'SYSTEM_CONTAINER',
}

export enum AbsoluteRoutePathWithServiceAndResourceId {
    SERVICE_SERVER = 'SERVICE_SERVER',
    SERVICE_CONTAINER = 'SERVICE_CONTAINER',
    SERVICE_VOLUME = 'SERVICE_VOLUME',
    SERVICE_CONNECTION = 'SERVICE_CONNECTION',
    SERVICE_ENDPOINT = 'SERVICE_ENDPOINT',
    SERVICE_MAPPING = 'SERVICE_MAPPING',
}

export type AbsoluteRoutePath = AbsoluteRoutePathWithId | AbsoluteRouteOnlyPath | AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithServiceAndResourceId

export enum RelativeRoutePathWithId {
    SERVER = 'REL_SERVER',
    CONTAINER = 'REL_CONTAINER',
    VOLUME = 'REL_VOLUME',
    CONNECTION = 'REL_CONNECTION',
    ENDPOINT = 'REL_ENDPOINT',
    MAPPING = 'REL_MAPPING',
    SERVICE = 'REL_SERVICE',
}

export type RouterState = Readonly<
    | { path: AbsoluteRouteOnlyPath, resourceId: null, serviceId: null }
    | { path: AbsoluteRoutePathWithId, resourceId: string, serviceId: null }
    | { path: AbsoluteRoutePathWithServiceId, resourceId: null, serviceId: string }
    | { path: AbsoluteRoutePathWithServiceAndResourceId, resourceId: string, serviceId: string }
>

export type AbsoluteRouteUpdateArgs =
    | [onlyPath: AbsoluteRouteOnlyPath]
    | [pathWithId: AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithServiceAndResourceId, id: string]

export type RouteUpdateArgs = AbsoluteRouteUpdateArgs | [pathWithId: RelativeRoutePathWithId, id: string]
