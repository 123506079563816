import {
    AddCertificateUsecase,
    AddServiceUsecase,
    AllowClientRegistryRequestUsecase,
    BulkConnectConnectionsUsecase,
    BulkDeleteAgentsUsecase,
    BulkDeleteConnectionsUsecase,
    BulkDeleteEndpointsUsecase,
    BulkDeleteMappingsUsecase,
    BulkDeleteServersUsecase,
    BulkDeleteServiceContainersUsecase,
    BulkDeleteServicesUsecase,
    BulkDeleteVolumesUsecase,
    BulkDisableEndpointsUsecase,
    BulkDisableMappingsUsecase,
    BulkDisableServersUsecase,
    BulkDisableServicesUsecase,
    BulkDisconnectConnectionsUsecase,
    BulkEnableEndpointsUsecase,
    BulkEnableMappingsUsecase,
    BulkEnableServersUsecase,
    BulkEnableServiceUsecase,
    BulkRestartCoreContainersUsecase,
    BulkRestartServiceContainersUsecase,
    BulkStartCoreContainersUsecase,
    BulkStartServiceContainersUsecase,
    BulkStopCoreContainersUsecase,
    BulkStopServiceContainersUsecase,
    ChangePasswordUsecase,
    ContactInformationUsecase,
    ContainerLogsUsecase,
    CopyValueUsecase,
    CreateRoleUsecase,
    CreateUserUsecase,
    DeleteCertificateUsecase,
    DisableMfaUsecase,
    DocumentationUsecase,
    DownloadCertificateUsecase,
    DownloadServiceCatalogTemplateUsecase,
    DownloadServiceUsecase,
    EditRoleUsecase,
    EditServiceTemplateConnectionUsecase,
    EditServiceTemplateEndpointUsecase,
    EditServiceTemplateMetadataUsecase,
    EditUserUsecase,
    EnableMfaUsecase,
    EnforceMfaUsageUsecase,
    ExplorerResourceStatusUsecase,
    ExplorerTopicManagementUsecase,
    ExplorerTopicsFormattingUsecase,
    FilterCatalogServicesUsecase,
    FilterSandboxUsecase,
    FilterUserManagementUsecase,
    FixNetworkIssueUsecase,
    FixVersionUsecase,
    GetApplicationMetadataUsecase,
    InformationUsecase,
    InspectApplicationUsecase,
    InstallServiceCatalogApplicationUsecase,
    LicenseViewUsecase,
    LoadCatalogServicesUsecase,
    LoadCertificatesUsecase,
    LoadClientRegistryUsecase,
    LoadingUsecase,
    LoadLicenseUsecase,
    LoadMfaSettingsUsecase,
    LoadServiceDataUsecase,
    LoadUserManagementDataUsecase,
    LoginFormUsecase,
    LoginFromPersistenceUsecase,
    LoginFromTokenUsecase,
    LogoutUsecase,
    ManageAgentsUsecase,
    ManageConnectionsUsecase,
    ManageConnectionUsecase,
    ManageCoreContainersUsecase,
    ManageCoreContainerUsecase,
    ManageDeviationsUsecase,
    ManageEditServiceTemplateUsecase,
    ManageEndpointsUsecase,
    ManageEndpointUsecase,
    ManageMappingsUsecase,
    ManageMappingUsecase,
    ManageNodesUsecase,
    ManageServersUsecase,
    ManageServerUsecase,
    ManageServiceContainersUsecase,
    ManageServiceContainerUsecase,
    ManageServicesLinksUsecase,
    ManageServicesTopicsUsecase,
    ManageServicesUsecase,
    ManageServiceUsecase,
    ManageVolumesUsecase,
    ManageVolumeUsecase,
    MissingPermissionsUsecase,
    RefreshLicenseUsecase,
    RegenerateMfaBackupCodesUsecase,
    StartExplorerSubscriptionUsecase,
    StartSandboxUsecase,
    SubscribeToMappingEntriesUsecase,
    SubscribeToMessagesSandboxUsecase,
    SystemMaintenanceUsecase,
    SystemMetricsUsecase,
    ToggleLockClientRegistryUsecase,
    TransformSandboxUsecase,
    UpdateExplorerTailUsecase,
    UpdateServiceUsecase,
    UploadLicenseFileUsecase,
    VersionUsecase,
    ViewPermissionUsecase,
    WorkbenchUsecase,
} from '.'

import type { Usecase, UsecaseServices, UsecasesRegistry } from './Usecase'

export type Usecases = Readonly<{
    /**
     * General
     */
    workbenchUsecase: WorkbenchUsecase
    versionUsecase: VersionUsecase
    fixVersionUsecase: FixVersionUsecase
    contactInformationUsecase: ContactInformationUsecase
    documentationUsecase: DocumentationUsecase
    copyValueUsecase: CopyValueUsecase
    getApplicationMetadataUsecase: GetApplicationMetadataUsecase
    inspectApplicationUsecase: InspectApplicationUsecase
    fixNetworkIssueUsecase: FixNetworkIssueUsecase

    /**
     * Authentication
     */
    loginFormUsecase: LoginFormUsecase
    loginFromPersistenceUsecase: LoginFromPersistenceUsecase
    loginFromTokenUsecase: LoginFromTokenUsecase
    logoutUsecase: LogoutUsecase
    missingPermissionsUsecase: MissingPermissionsUsecase

    /**
     * User management and client registry
     */
    loadUserManagementDataUsecase: LoadUserManagementDataUsecase
    filterUserManagementUsecase: FilterUserManagementUsecase
    editUserUsecase: EditUserUsecase
    createUserUsecase: CreateUserUsecase
    editRoleUsecase: EditRoleUsecase
    createRoleUsecase: CreateRoleUsecase
    viewPermissionUsecase: ViewPermissionUsecase
    loadClientRegistryUsecase: LoadClientRegistryUsecase
    toggleLockClientRegistryUsecase: ToggleLockClientRegistryUsecase
    allowClientRegistryRequestUsecase: AllowClientRegistryRequestUsecase

    /**
     * Info page
     */
    informationUsecase: InformationUsecase

    /**
     * License
     */
    loadLicenseUsecase: LoadLicenseUsecase
    refreshLicenseUsecase: RefreshLicenseUsecase
    uploadLicenseFileUsecase: UploadLicenseFileUsecase
    licenseViewUsecase: LicenseViewUsecase

    /**
     * Connectware Services
     */
    manageServiceUsecase: ManageServiceUsecase
    manageServicesUsecase: ManageServicesUsecase
    addServiceUsecase: AddServiceUsecase
    updateServiceUsecase: UpdateServiceUsecase
    downloadServiceUsecase: DownloadServiceUsecase
    bulkDeleteServicesUsecase: BulkDeleteServicesUsecase
    bulkDisableServicesUsecase: BulkDisableServicesUsecase
    bulkEnableServiceUsecase: BulkEnableServiceUsecase
    manageServicesLinksUsecase: ManageServicesLinksUsecase
    manageDeviationsUsecase: ManageDeviationsUsecase

    manageServicesTopicsUsecase: ManageServicesTopicsUsecase
    loadServiceDataUsecase: LoadServiceDataUsecase

    manageEditServiceTemplateUsecase: ManageEditServiceTemplateUsecase
    editServiceTemplateMetadataUsecase: EditServiceTemplateMetadataUsecase
    editServiceTemplateConnectionUsecase: EditServiceTemplateConnectionUsecase
    editServiceTemplateEndpointUsecase: EditServiceTemplateEndpointUsecase

    loadCatalogServicesUsecase: LoadCatalogServicesUsecase
    filterCatalogServicesUsecase: FilterCatalogServicesUsecase
    downloadServiceCatalogTemplateUsecase: DownloadServiceCatalogTemplateUsecase
    installServiceCatalogApplicationUsecase: InstallServiceCatalogApplicationUsecase

    /**
     * Connectware Connections
     */
    manageConnectionsUsecase: ManageConnectionsUsecase
    manageConnectionUsecase: ManageConnectionUsecase
    bulkDeleteConnectionsUsecase: BulkDeleteConnectionsUsecase
    bulkConnectConnectionsUsecase: BulkConnectConnectionsUsecase
    bulkDisconnectConnectionsUsecase: BulkDisconnectConnectionsUsecase

    /**
     * Connectware Mappings
     */
    manageMappingUsecase: ManageMappingUsecase
    manageMappingsUsecase: ManageMappingsUsecase
    bulkDeleteMappingsUsecase: BulkDeleteMappingsUsecase
    bulkEnableMappingsUsecase: BulkEnableMappingsUsecase
    bulkDisableMappingsUsecase: BulkDisableMappingsUsecase
    subscribeToMappingEntriesUsecase: SubscribeToMappingEntriesUsecase

    /**
     * Connectware Endpoints
     */
    manageEndpointUsecase: ManageEndpointUsecase
    manageEndpointsUsecase: ManageEndpointsUsecase
    bulkDeleteEndpointsUsecase: BulkDeleteEndpointsUsecase
    bulkEnableEndpointsUsecase: BulkEnableEndpointsUsecase
    bulkDisableEndpointsUsecase: BulkDisableEndpointsUsecase

    /**
     * Connectware Volumes
     */
    manageVolumeUsecase: ManageVolumeUsecase
    manageVolumesUsecase: ManageVolumesUsecase
    bulkDeleteVolumesUsecase: BulkDeleteVolumesUsecase

    /**
     * Connectware Containers
     */
    manageServiceContainerUsecase: ManageServiceContainerUsecase
    manageServiceContainersUsecase: ManageServiceContainersUsecase
    bulkDeleteServiceContainersUsecase: BulkDeleteServiceContainersUsecase
    bulkRestartServiceContainersUsecase: BulkRestartServiceContainersUsecase
    bulkStartServiceContainersUsecase: BulkStartServiceContainersUsecase
    bulkStopServiceContainersUsecase: BulkStopServiceContainersUsecase

    manageCoreContainerUsecase: ManageCoreContainerUsecase
    manageCoreContainersUsecase: ManageCoreContainersUsecase
    bulkRestartCoreContainersUsecase: BulkRestartCoreContainersUsecase
    bulkStartCoreContainersUsecase: BulkStartCoreContainersUsecase
    bulkStopCoreContainersUsecase: BulkStopCoreContainersUsecase

    /**
     * Servers
     */
    manageServersUsecase: ManageServersUsecase
    manageServerUsecase: ManageServerUsecase
    bulkDeleteServersUsecase: BulkDeleteServersUsecase
    bulkDisableServersUsecase: BulkDisableServersUsecase
    bulkEnableServersUsecase: BulkEnableServersUsecase
    manageNodesUsecase: ManageNodesUsecase

    /**
     * Resources usecases
     */
    containerLogsUsecase: ContainerLogsUsecase

    /**
     * Explorer
     */
    startExplorerSubscriptionUsecase: StartExplorerSubscriptionUsecase
    updateExplorerTailUsecase: UpdateExplorerTailUsecase
    explorerTopicsFormattingUsecase: ExplorerTopicsFormattingUsecase
    explorerTopicManagementUsecase: ExplorerTopicManagementUsecase
    explorerResourceStatusUsecase: ExplorerResourceStatusUsecase

    /**
     * Rule Engine Sandbox
     */
    filterSandboxUsecase: FilterSandboxUsecase
    subscribeToMessagesSandboxUsecase: SubscribeToMessagesSandboxUsecase
    startSandboxUsecase: StartSandboxUsecase
    transformSandboxUsecase: TransformSandboxUsecase

    /**
     * System
     */
    systemMetricsUsecase: SystemMetricsUsecase
    manageAgentsUsecase: ManageAgentsUsecase
    bulkDeleteAgentsUsecase: BulkDeleteAgentsUsecase
    systemMaintenanceUsecase: SystemMaintenanceUsecase

    /**
     * Certificates
     */
    loadCertificatesUsecase: LoadCertificatesUsecase
    addCertificateUsecase: AddCertificateUsecase
    downloadCertificateUsecase: DownloadCertificateUsecase
    deleteCertificateUsecase: DeleteCertificateUsecase

    /**
     * Settings
     */
    changePasswordUsecase: ChangePasswordUsecase
    enableMfaUsecase: EnableMfaUsecase
    loadMfaSettingsUsecase: LoadMfaSettingsUsecase
    regenerateMfaBackupCodesUsecase: RegenerateMfaBackupCodesUsecase
    disableMfaUsecase: DisableMfaUsecase
    enforceMfaUsageUsecase: EnforceMfaUsageUsecase

    loadingUsecase: LoadingUsecase
}>

/**
 * @returns created use cases dependent on the given services
 */
export const createUsecases = (services: UsecaseServices): Usecases => {
    const registry: UsecasesRegistry = new Map<typeof Usecase, Usecase>()

    return {
        workbenchUsecase: new WorkbenchUsecase(registry, services),
        versionUsecase: new VersionUsecase(registry, services),
        fixVersionUsecase: new FixVersionUsecase(registry, services),
        contactInformationUsecase: new ContactInformationUsecase(registry, services),
        documentationUsecase: new DocumentationUsecase(registry, services),
        copyValueUsecase: new CopyValueUsecase(registry, services),
        getApplicationMetadataUsecase: new GetApplicationMetadataUsecase(registry, services),
        inspectApplicationUsecase: new InspectApplicationUsecase(registry, services),
        fixNetworkIssueUsecase: new FixNetworkIssueUsecase(registry, services),

        loginFormUsecase: new LoginFormUsecase(registry, services),
        loginFromPersistenceUsecase: new LoginFromPersistenceUsecase(registry, services),
        loginFromTokenUsecase: new LoginFromTokenUsecase(registry, services),
        logoutUsecase: new LogoutUsecase(registry, services),
        missingPermissionsUsecase: new MissingPermissionsUsecase(registry, services),

        loadUserManagementDataUsecase: new LoadUserManagementDataUsecase(registry, services),
        filterUserManagementUsecase: new FilterUserManagementUsecase(registry, services),
        editUserUsecase: new EditUserUsecase(registry, services),
        createUserUsecase: new CreateUserUsecase(registry, services),
        editRoleUsecase: new EditRoleUsecase(registry, services),
        createRoleUsecase: new CreateRoleUsecase(registry, services),
        viewPermissionUsecase: new ViewPermissionUsecase(registry, services),
        loadClientRegistryUsecase: new LoadClientRegistryUsecase(registry, services),
        toggleLockClientRegistryUsecase: new ToggleLockClientRegistryUsecase(registry, services),
        allowClientRegistryRequestUsecase: new AllowClientRegistryRequestUsecase(registry, services),

        loadLicenseUsecase: new LoadLicenseUsecase(registry, services),
        refreshLicenseUsecase: new RefreshLicenseUsecase(registry, services),
        uploadLicenseFileUsecase: new UploadLicenseFileUsecase(registry, services),
        licenseViewUsecase: new LicenseViewUsecase(registry, services),

        informationUsecase: new InformationUsecase(registry, services),

        manageServiceUsecase: new ManageServiceUsecase(registry, services),
        manageServicesUsecase: new ManageServicesUsecase(registry, services),
        addServiceUsecase: new AddServiceUsecase(registry, services),
        updateServiceUsecase: new UpdateServiceUsecase(registry, services),
        downloadServiceUsecase: new DownloadServiceUsecase(registry, services),
        bulkDeleteServicesUsecase: new BulkDeleteServicesUsecase(registry, services),
        bulkDisableServicesUsecase: new BulkDisableServicesUsecase(registry, services),
        bulkEnableServiceUsecase: new BulkEnableServiceUsecase(registry, services),
        manageServicesLinksUsecase: new ManageServicesLinksUsecase(registry, services),
        manageDeviationsUsecase: new ManageDeviationsUsecase(registry, services),

        manageServicesTopicsUsecase: new ManageServicesTopicsUsecase(registry, services),
        loadServiceDataUsecase: new LoadServiceDataUsecase(registry, services),

        manageEditServiceTemplateUsecase: new ManageEditServiceTemplateUsecase(registry, services),
        editServiceTemplateMetadataUsecase: new EditServiceTemplateMetadataUsecase(registry, services),
        editServiceTemplateConnectionUsecase: new EditServiceTemplateConnectionUsecase(registry, services),
        editServiceTemplateEndpointUsecase: new EditServiceTemplateEndpointUsecase(registry, services),

        loadCatalogServicesUsecase: new LoadCatalogServicesUsecase(registry, services),
        filterCatalogServicesUsecase: new FilterCatalogServicesUsecase(registry, services),
        downloadServiceCatalogTemplateUsecase: new DownloadServiceCatalogTemplateUsecase(registry, services),
        installServiceCatalogApplicationUsecase: new InstallServiceCatalogApplicationUsecase(registry, services),

        manageConnectionsUsecase: new ManageConnectionsUsecase(registry, services),
        manageConnectionUsecase: new ManageConnectionUsecase(registry, services),
        bulkDeleteConnectionsUsecase: new BulkDeleteConnectionsUsecase(registry, services),
        bulkConnectConnectionsUsecase: new BulkConnectConnectionsUsecase(registry, services),
        bulkDisconnectConnectionsUsecase: new BulkDisconnectConnectionsUsecase(registry, services),

        manageMappingUsecase: new ManageMappingUsecase(registry, services),
        manageMappingsUsecase: new ManageMappingsUsecase(registry, services),
        bulkDeleteMappingsUsecase: new BulkDeleteMappingsUsecase(registry, services),
        bulkEnableMappingsUsecase: new BulkEnableMappingsUsecase(registry, services),
        bulkDisableMappingsUsecase: new BulkDisableMappingsUsecase(registry, services),
        subscribeToMappingEntriesUsecase: new SubscribeToMappingEntriesUsecase(registry, services),

        manageEndpointUsecase: new ManageEndpointUsecase(registry, services),
        manageEndpointsUsecase: new ManageEndpointsUsecase(registry, services),
        bulkDeleteEndpointsUsecase: new BulkDeleteEndpointsUsecase(registry, services),
        bulkEnableEndpointsUsecase: new BulkEnableEndpointsUsecase(registry, services),
        bulkDisableEndpointsUsecase: new BulkDisableEndpointsUsecase(registry, services),

        manageVolumeUsecase: new ManageVolumeUsecase(registry, services),
        manageVolumesUsecase: new ManageVolumesUsecase(registry, services),
        bulkDeleteVolumesUsecase: new BulkDeleteVolumesUsecase(registry, services),

        manageServiceContainerUsecase: new ManageServiceContainerUsecase(registry, services),
        manageServiceContainersUsecase: new ManageServiceContainersUsecase(registry, services),
        bulkDeleteServiceContainersUsecase: new BulkDeleteServiceContainersUsecase(registry, services),
        bulkRestartServiceContainersUsecase: new BulkRestartServiceContainersUsecase(registry, services),
        bulkStartServiceContainersUsecase: new BulkStartServiceContainersUsecase(registry, services),
        bulkStopServiceContainersUsecase: new BulkStopServiceContainersUsecase(registry, services),

        manageCoreContainerUsecase: new ManageCoreContainerUsecase(registry, services),
        manageCoreContainersUsecase: new ManageCoreContainersUsecase(registry, services),
        bulkRestartCoreContainersUsecase: new BulkRestartCoreContainersUsecase(registry, services),
        bulkStartCoreContainersUsecase: new BulkStartCoreContainersUsecase(registry, services),
        bulkStopCoreContainersUsecase: new BulkStopCoreContainersUsecase(registry, services),

        manageServersUsecase: new ManageServersUsecase(registry, services),
        manageServerUsecase: new ManageServerUsecase(registry, services),
        bulkDeleteServersUsecase: new BulkDeleteServersUsecase(registry, services),
        bulkDisableServersUsecase: new BulkDisableServersUsecase(registry, services),
        bulkEnableServersUsecase: new BulkEnableServersUsecase(registry, services),
        manageNodesUsecase: new ManageNodesUsecase(registry, services),

        containerLogsUsecase: new ContainerLogsUsecase(registry, services),

        startExplorerSubscriptionUsecase: new StartExplorerSubscriptionUsecase(registry, services),
        updateExplorerTailUsecase: new UpdateExplorerTailUsecase(registry, services),
        explorerTopicsFormattingUsecase: new ExplorerTopicsFormattingUsecase(registry, services),
        explorerTopicManagementUsecase: new ExplorerTopicManagementUsecase(registry, services),
        explorerResourceStatusUsecase: new ExplorerResourceStatusUsecase(registry, services),

        startSandboxUsecase: new StartSandboxUsecase(registry, services),
        filterSandboxUsecase: new FilterSandboxUsecase(registry, services),
        subscribeToMessagesSandboxUsecase: new SubscribeToMessagesSandboxUsecase(registry, services),
        transformSandboxUsecase: new TransformSandboxUsecase(registry, services),

        systemMetricsUsecase: new SystemMetricsUsecase(registry, services),
        manageAgentsUsecase: new ManageAgentsUsecase(registry, services),
        bulkDeleteAgentsUsecase: new BulkDeleteAgentsUsecase(registry, services),
        systemMaintenanceUsecase: new SystemMaintenanceUsecase(registry, services),

        loadCertificatesUsecase: new LoadCertificatesUsecase(registry, services),
        addCertificateUsecase: new AddCertificateUsecase(registry, services),
        downloadCertificateUsecase: new DownloadCertificateUsecase(registry, services),
        deleteCertificateUsecase: new DeleteCertificateUsecase(registry, services),

        loadingUsecase: new LoadingUsecase(registry, services),
        changePasswordUsecase: new ChangePasswordUsecase(registry, services),

        enableMfaUsecase: new EnableMfaUsecase(registry, services),
        loadMfaSettingsUsecase: new LoadMfaSettingsUsecase(registry, services),
        regenerateMfaBackupCodesUsecase: new RegenerateMfaBackupCodesUsecase(registry, services),
        disableMfaUsecase: new DisableMfaUsecase(registry, services),
        enforceMfaUsageUsecase: new EnforceMfaUsageUsecase(registry, services),
    }
}
