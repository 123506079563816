import type { AppState } from '../../domain'

/**
 * This is the ApplicationState interface
 *
 * It holds the current state of the application
 * It also allows for the usecases to store/read information from it
 */
export interface ApplicationStateService {
    /**
     * Allows to update the state of the application
     */
    setState(newState: Partial<AppState>): void

    /**
     * Allows to read the state from the application
     */
    getState(): AppState

    /**
     * Allows to subscribe to changes to the state of the application
     *
     * @param filter return true to indicate that the value is still the same
     * @param subscriber called if the value has changed
     */
    subscribe(filter: (previous: AppState, current: AppState) => boolean, subscriber: VoidFunction): VoidFunction
}
