import React, { type FC } from 'react'

import { Button, Divider, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material'
import { Build, GppGood, LockRounded, PersonRounded, Security, Tag, Visibility, VisibilityOff, VpnKey, Warning } from '@mui/icons-material'

import {
    areUserCreationFormsEqual,
    areUserEditingFormsEqual,
    isUserCreatable,
    isUserRemovable,
    isUserUpdatable,
    selectUsersManagementUserCreation,
    selectUsersManagementUserUpdating,
    Translation,
    type UserCreationForm,
    type UserEditingForm,
    UserManagementEditMode,
} from '../../../../domain'

import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { CustomCopy } from '../../Copy'
import { Validations } from '../../common'

import { EditableWarning, EntryInput, EntryInputLabel, GridFormEntry, GridFormItem, ManagedDialog, type ManagedDialogContentProps } from '../Modal'
import { PermissionsTable, PermissionsTemplateSection } from '../Permissions'
import { RolesDropdown } from '../Roles'
import { AuthenticationMethodsForm, MqttPublishPrefixInput, MqttPublishPrefixInputValidation } from '.'

const UserModalContent = <Form extends UserEditingForm | UserCreationForm,>({
    advanced,
    loading,
    equals,
    form,
    usecase,
}: ManagedDialogContentProps<Form, 'createUserUsecase' | 'editUserUsecase'>): ReturnType<FC> => {
    const id = 'id' in form ? form.id : null
    const editMode = 'editMode' in form ? form.editMode : UserManagementEditMode.ALL
    const canUpdateAnything = editMode === UserManagementEditMode.ALL && !loading
    const canUpdateMfa = (editMode === UserManagementEditMode.ALL || editMode === UserManagementEditMode.MFA_ONLY) && !loading
    const translator = useTranslator()

    return (
        <Grid container spacing={2} alignContent="center" alignItems="center">
            <EditableWarning mode={editMode} type="user" />
            <GridFormEntry
                label={<EntryInputLabel icon={PersonRounded} label={Translation.USERNAME} />}
                endAdornmentSize={1}
                endAdornment={
                    id && (
                        <CustomCopy
                            button={(props) => (
                                <Button data-testid="username-copy-button" size="small" color="secondary" variant="contained" fullWidth {...props} />
                            )}
                        >
                            {form.username}
                        </CustomCopy>
                    )
                }
            >
                <EntryInput
                    data-testid="username"
                    placeholder={translator.formatTranslation(Translation.USERNAME)}
                    value={form.username}
                    autoComplete="off"
                    disabled={('isProtected' in form && form.isProtected) || !canUpdateAnything}
                    onChange={({ target }) => usecase.update({ username: target.value.trim() })}
                />
            </GridFormEntry>

            {form.usernameValidation !== null && (
                <GridFormEntry>
                    <Validations
                        validations={Array.isArray(form.usernameValidation) ? form.usernameValidation : [form.usernameValidation]}
                        data-testid="username-validation"
                    />
                </GridFormEntry>
            )}
            <GridFormEntry label={<EntryInputLabel icon={LockRounded} label={Translation.PASSWORD} />}>
                {form.password === null && (
                    <Button
                        data-testid="change-password-button"
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!canUpdateAnything}
                        onClick={() => usecase.update({ password: '', passwordConfirmation: '' })}
                    >
                        <FormattedTranslation id={Translation.CHANGE_PASSWORD} />
                    </Button>
                )}
                {form.password !== null && (
                    <EntryInput
                        data-testid="password"
                        type={form.passwordConfirmation === null ? 'text' : 'password'}
                        placeholder={translator.formatTranslation(Translation.PASSWORD)}
                        value={form.password}
                        autoComplete="new-password"
                        disabled={!canUpdateAnything}
                        onChange={({ target }) => usecase.update({ password: target.value })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    data-testid="change-password-change-mode-button"
                                    edge="end"
                                    onClick={() => usecase.togglePasswordMode()}
                                >
                                    {form.passwordConfirmation === null ? (
                                        <VisibilityOff data-testid="change-password-show-confirmation" />
                                    ) : (
                                        <Visibility data-testid="change-password-hide-confirmation" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                )}
            </GridFormEntry>
            <GridFormEntry expand={form.passwordConfirmation !== null} label={<EntryInputLabel icon={SvgIcon} label={Translation.CONFIRM_PASSWORD} />}>
                <EntryInput
                    data-testid="password-confirmation"
                    type="password"
                    value={form.passwordConfirmation || ''}
                    disabled={!canUpdateAnything}
                    placeholder={translator.formatTranslation(Translation.CONFIRM_PASSWORD)}
                    onChange={({ target }) => usecase.update({ passwordConfirmation: target.value })}
                    autoComplete="new-password"
                />
            </GridFormEntry>
            <GridFormEntry expand={form.password !== null}>
                <Validations
                    validations={
                        form.passwordValidation === null ? [] : Array.isArray(form.passwordValidation) ? form.passwordValidation : [form.passwordValidation]
                    }
                    data-testid="password-validation"
                />
            </GridFormEntry>

            {'isMfaEnabled' in form && form.isMfaEnabled !== null && (
                <GridFormEntry label={<EntryInputLabel icon={GppGood} label={Translation.MULTI_FACTOR_AUTHENTICATION_ACRONYM} />}>
                    <Button
                        data-testid="disable-mfa-button"
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!canUpdateMfa || !form.isMfaEnabled}
                        onClick={() => usecase.update({ isMfaEnabled: false })}
                    >
                        <FormattedTranslation id={form.isMfaEnabled ? Translation.DISABLE : Translation.DISABLED} />
                    </Button>
                </GridFormEntry>
            )}

            {form.isMfaEnforced !== null && (
                <GridFormEntry label={<EntryInputLabel icon={Warning} label={Translation.ENFORCE_MFA_USAGE} />}>
                    <Button
                        data-testid="enforce-usage-button"
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!canUpdateMfa}
                        onClick={() => usecase.update({ isMfaEnforced: !form.isMfaEnforced })}
                    >
                        <FormattedTranslation id={form.isMfaEnforced ? Translation.DISABLE : Translation.ENABLE} />
                    </Button>
                </GridFormEntry>
            )}

            <GridFormEntry label={<EntryInputLabel icon={Security} label={Translation.ROLE} values={{ count: 2 }} />}>
                <RolesDropdown disabled={!canUpdateAnything} roleInput={form.roleInput} roles={form.roles} allRoles={form.allRoles} usecase={usecase} />
            </GridFormEntry>

            <GridFormItem expand={advanced} size={12}>
                <Divider />
            </GridFormItem>

            <GridFormEntry expand={advanced} label={<EntryInputLabel icon={Build} label={Translation.ADDITIONAL_PERMISSION} values={{ count: 0 }} />} />
            <PermissionsTemplateSection
                expand={advanced}
                disabled={!canUpdateAnything}
                usecase={usecase}
                templateInput={form.templateInput}
                templates={form.templates}
                selectedTemplate={form.selectedTemplate}
            />
            <GridFormItem expand={advanced} size={12}>
                <PermissionsTable
                    disabled={!canUpdateAnything}
                    canLink={equals}
                    permissions={form.permissions}
                    inherited={form.rolesPermissions}
                    usecase={usecase}
                />
            </GridFormItem>
            <GridFormEntry labelSize={4} expand={advanced} label={<EntryInputLabel icon={VpnKey} label={Translation.AUTHENTICATION_METHODS} />}>
                <AuthenticationMethodsForm authenticationMethods={form.authenticationMethods} disabled={!canUpdateAnything} usecase={usecase} />
            </GridFormEntry>
            <GridFormEntry expand={advanced} label={<EntryInputLabel icon={Tag} label={Translation.MQTT_PUBLISH_PREFIX} />}>
                <MqttPublishPrefixInput mqttPublishPrefix={form.mqttPublishPrefix} disabled={!canUpdateAnything} usecase={usecase} />
                <MqttPublishPrefixInputValidation mqttPublishPrefixValidation={form.mqttPublishPrefixValidation} />
            </GridFormEntry>
        </Grid>
    )
}

export const UserEditModal: FC = () => (
    <ManagedDialog
        maxWidth="md"
        title={Translation.EDIT_USER}
        deleteConfirmation={Translation.DELETE_USER_CONFIRMATION}
        usecase="editUserUsecase"
        formSelector={selectUsersManagementUserUpdating}
        isRemovable={isUserRemovable}
        areEquals={areUserEditingFormsEqual}
        isConfirmable={isUserUpdatable}
        advanced={false}
    >
        {UserModalContent}
    </ManagedDialog>
)

export const UserCreationModal: FC = () => (
    <ManagedDialog
        maxWidth="md"
        title={Translation.CREATE_USER}
        usecase="createUserUsecase"
        formSelector={selectUsersManagementUserCreation}
        areEquals={areUserCreationFormsEqual}
        isConfirmable={isUserCreatable}
        advanced={false}
    >
        {UserModalContent}
    </ManagedDialog>
)
