import {
    Capability,
    type ClientRegistryAuthorizationRequest,
    type ClientRegistryData,
    ConnectwareError,
    ConnectwareErrorType,
    Translation,
} from '../../../domain'

import type { ClientRegistryService, TranslationService } from '../../../application'

import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService } from '../Base'

import type { AuthRequestsResponse, RegistrationEndpointStatusResponse } from './Types'
import { mapClientRegistryData, mapConfirmRegistrationRequest } from './mappers'

export class ConnectwareHTTPClientRegistryService extends FetchConnectwareHTTPService implements ClientRegistryService {
    constructor (options: ConnectwareHTTPServiceOptions, private readonly translationService: TranslationService) {
        super(options)
    }

    async lock (): Promise<void> {
        await this.request({
            capability: Capability.CLIENT_REGISTRY_MANAGE,
            path: '/api/client-registry/lock',
            authenticate: true,
            method: 'POST',
            handlers: { 204: () => Promise.resolve() },
        })
    }

    async unlock (): Promise<void> {
        await this.request({
            capability: Capability.CLIENT_REGISTRY_MANAGE,
            path: '/api/client-registry/open',
            authenticate: true,
            method: 'POST',
            handlers: { 204: () => Promise.resolve() },
        })
    }

    async fetchData (): Promise<ClientRegistryData> {
        return Promise.all([
            this.request({
                capability: Capability.CLIENT_REGISTRY_MANAGE,
                path: '/api/client-registry/status',
                authenticate: true,
                method: 'GET',
                handlers: { 200: (response) => response.getJson<RegistrationEndpointStatusResponse>() },
            }),
            this.request({
                capability: Capability.CLIENT_REGISTRY_MANAGE,
                path: '/api/client-registry',
                authenticate: true,
                method: 'GET',
                handlers: { 200: (response) => response.getJson<AuthRequestsResponse>() },
            }),
        ]).then(([unlockedUntilResponse, requestsResponse]) => mapClientRegistryData(unlockedUntilResponse.unlockedUntil, requestsResponse))
    }

    async authorize (request: ClientRegistryAuthorizationRequest): Promise<void> {
        await this.request({
            capability: Capability.CLIENT_REGISTRY_MANAGE,
            path: '/api/client-registry/confirm',
            authenticate: true,
            method: 'POST',
            body: mapConfirmRegistrationRequest(request),
            handlers: {
                204: () => Promise.resolve(),
                500: async (response) => {
                    const data = await response.getJson<{ message: unknown }>()
                    if (typeof data.message === 'string') {
                        switch (data.message) {
                            case 'Error: Invalid CSR':
                                return new ConnectwareError(
                                    ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                                    this.translationService.translate(Translation.INVALID_CSR)
                                )
                            case 'Error: CSR uses empty Common Name or the Common Name does not match the username from the payload.':
                                return new ConnectwareError(
                                    ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                                    this.translationService.translate(Translation.CSR_COMMON_NAME_ISSUE)
                                )
                            default:
                                return new ConnectwareError(
                                    ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                                    this.translationService.translate(Translation.CLIENT_REGISTRATION_FAILED)
                                )
                        }
                    }
                    return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, '500 Internal Server Error', { ...data })
                },
            },
        })
    }
}
