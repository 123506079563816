import React, { type FC, useEffect, useState } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import type { NonEmptyArray } from '../../../../utils'

import type { BufferType } from '../../../../domain'

import { FormattedBuffer } from './FormattedBuffer'
import { BufferTypeSelect } from './Select'
import type { BaseCommonProps } from '../Base'

const wrapperStyle: SxProps<Theme> = { display: 'flex' }
const columBaseStyle: SxProps<Theme> = { m: 'auto 0', fontSize: 'body2.fontSize' }
const columnStyle: SxProps<Theme> = { ...columBaseStyle, ml: 0.5 }
const columnFullWidthStyle: SxProps<Theme> = { ...columnStyle, ml: 'auto' }

type DisplayProps = Readonly<{
    fullWidth?: boolean
    minimized?: boolean

    buffer: Buffer
    types: NonEmptyArray<BufferType>

    onChange?: (value: BufferType) => void
}> &
    BaseCommonProps

export const Display: FC<DisplayProps> = ({ buffer, types, fullWidth = false, minimized, onChange, sx, className }) => {
    const [type, setType] = useState(() => types[0])

    useEffect(() => {
        setType(types[0])
    }, [[...types].sort().join(' ')])

    /** If the new types do not include the selected type, then don't render anything */
    if (!types.includes(type)) {
        return null
    }

    const column = fullWidth ? columnFullWidthStyle : columnStyle

    return (
        <Box className={className} sx={{ ...wrapperStyle, ...sx }}>
            <FormattedBuffer sx={columBaseStyle} buffer={buffer} type={type} minimized={minimized} />
            <BufferTypeSelect
                sx={column}
                value={type}
                types={types}
                onChange={(type) => {
                    setType(type)
                    onChange?.(type)
                }}
            />
        </Box>
    )
}
