import type { RouterProps } from 'react-router-dom'

import { isEnumOf } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../domain'

import { generateIntenalPath, mergeState } from '../utils'
import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    AbsoluteRoutePathWithServiceId,
    type AbsoluteRouteUpdateArgs,
    type RouterState,
    type RouteUpdateArgs,
} from '../Domain'
import { getBreadcrumbConfig, getRouteConfig, isRouteParent, isSecondLevelRoute, type RouteParentPath } from '../Config'
import { createRoutingHook } from './Hook'

export class RoutingHook {
    constructor (
        protected readonly history: Pick<RouterProps['history'], 'push' | 'createHref' | 'location' | 'goBack'>,
        protected readonly state: RouterState
    ) {}

    protected generateRedirectionPath (...args: RouteUpdateArgs): string {
        return generateIntenalPath(mergeState(this.state, ...args))
    }

    protected getParentAbsoluteRouteUpdateArgs (parent: RouteParentPath): AbsoluteRouteUpdateArgs {
        if (isEnumOf(AbsoluteRouteOnlyPath, parent)) {
            return [parent]
        }

        if (isEnumOf(AbsoluteRoutePathWithServiceId, parent) && this.state.serviceId) {
            return [parent, this.state.serviceId]
        }

        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not resolve parent path route', { ...this.state, parent })
    }

    redirect (...args: RouteUpdateArgs): void {
        this.history.push(this.generateRedirectionPath(...args))
    }

    redirectToParent (): void {
        const { parent } = getRouteConfig(this.state.path)

        if (!parent) {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Route does not have a parent', { ...this.state })
        }

        this.redirect(...this.getParentAbsoluteRouteUpdateArgs(parent))
    }

    redirectHome (): void {
        this.redirect(AbsoluteRouteOnlyPath.SYSTEM)
    }

    getNavigation (): [AbsoluteRoutePath, ...RouteParentPath[]] {
        const { parents } = getBreadcrumbConfig(this.state.path)
        return [this.state.path, ...parents.map((p) => p.id)]
    }

    /**
     * @returns boolean indicating if the given current route is either the parent route or its child
     */
    isAtRoute (path: AbsoluteRoutePath): boolean {
        return (
            !this.state.path ||
            /**
             * Either it is at the route it-self
             */
            this.state.path === path ||
            /**
             * Or the parent contains it
             */
            getBreadcrumbConfig(this.state.path).parents.some((parent) => parent.id === path)
        )
    }

    getFirstChildren<R extends AbsoluteRoutePath> (pool: R[]): [R[], R[]] {
        const { parents } = getBreadcrumbConfig(this.state.path)
        const [topParent] = parents.slice(-1)
        const topParentPath = topParent ? topParent.id : this.state.path

        return pool.reduce<[R[], R[]]>(
            (prev, route) => {
                // Check if the child route is not part of the parent route, then skip assigning the route
                if (!isRouteParent(route, topParentPath)) {
                    return prev
                }
                // Check if a child route is in the 2nd level, then add it to the second list
                if (isSecondLevelRoute(route)) {
                    prev[1].push(route)
                } else {
                    // otherwise, add it to the first list, considering it as a 1st level child.
                    prev[0].push(route)
                }
                return prev
            },
            [[], []]
        )
    }

    goBack (): void {
        this.history.goBack()
    }
}

export const useRouting = createRoutingHook('useRouting', RoutingHook)
