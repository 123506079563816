import React, { type FC, type ReactNode, useState } from 'react'
import { Box, Collapse, type SxProps, type Theme, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import type { Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'

const wrapperStyle: SxProps<Theme> = { display: 'flex', my: 1, mx: 0 }
const sectionTitleStyle: SxProps<Theme> = { m: 'auto', ml: 0, lineHeight: 2 }
const arrowStyle: SxProps<Theme> = { cursor: 'pointer', my: 'auto', mr: 0, ml: 1 }

const SectionTitle: FC<{ title: Translation }> = ({ title }) => (
    <Typography sx={sectionTitleStyle} className="section-title" display="inline" variant="body1">
        <FormattedTranslation id={title} />
    </Typography>
)

const SectionTitleWrapper: FC = ({ children }) => (
    <Box sx={wrapperStyle} className="section-title-wrapper">
        {children}
    </Box>
)

const ExpandArrow: FC<{ open: boolean, onClick: VoidFunction }> = ({ open, onClick }) => {
    const Icon = open ? ExpandLess : ExpandMore
    return <Icon sx={arrowStyle} className="expansion-arrow" onClick={onClick} />
}

export const Section: FC<{
    id?: string
    title: Translation
    controls?: ReactNode
    collapsable?: boolean
}> = ({ id, title, controls, collapsable = true, children }) => {
    const [open, setOpen] = useState(true)
    return (
        <div id={id}>
            <SectionTitleWrapper>
                <SectionTitle title={title} />
                {controls}
                {collapsable && <ExpandArrow open={open} onClick={() => setOpen(!open)} />}
            </SectionTitleWrapper>
            {collapsable ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            ) : (
                children
            )}
        </div>
    )
}
