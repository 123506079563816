import type { StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { type MappingProxyParams, mapToStatusType } from '../../../../../Connectware'
import { MAPPING_CLASSNAME_FILTER } from '../../../../constants'
import type { MappingProxy } from '../../../../proxies'
import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type MappingHandler<T extends keyof SubscriptionsTypes> = VrpcInstanceToOneSubscriptionHandler<MappingProxy, SubscriptionsTypes[T]>
type MappingHandlerArgs<T extends keyof SubscriptionsTypes> = VrpcHandlerMappingPropertiesArgs<MappingHandler<T>>

export abstract class VrpcMappingToOneSubscriptionHandler<T extends keyof SubscriptionsTypes, FetchedInfo> implements MappingHandler<T> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly requiredFilters = []

    readonly classNameFilter = MAPPING_CLASSNAME_FILTER

    readonly agent = null

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    abstract readonly optionalFilters: MappingHandler<T>['optionalFilters']

    abstract readonly ignoreInstanceByFilter: MappingHandler<T>['ignoreInstanceByFilter']

    abstract readonly onChange: MappingHandler<T>['onChange']

    /**
     * Async custom loading is done here
     */
    protected abstract fetchInformation (proxy: MappingProxy): Promise<FetchedInfo>

    /**
     * Custom mapping is done here
     */
    protected abstract mapMapping (params: MappingProxyParams, statusType: StatusType, extraInformation: FetchedInfo): SubscriptionsTypes[T]

    async mapToDomain ({ instance }: MappingHandlerArgs<T>['DomainMapperArgs']): Promise<MappingHandlerArgs<T>['Domain']> {
        /**
         * Fetch all information at the same time
         *
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const [params, info] = await Promise.all([instance.getParams(), this.fetchInformation(instance)])

        /**
         * @deprecated update code block so status is fetched externally
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const currentStatus = mapToStatusType(params.currentState)

        return this.mapMapping(params, currentStatus, info)
    }
}
