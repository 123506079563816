import React, { type FC, useMemo } from 'react'
import { Box, Paper, Stack, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { Background, CybusCompleteLogo } from '../common'

import { LoginForm } from './LoginForm'

const Version: FC = () => {
    const usecase = useAppUsecase('versionUsecase')
    const version = useMemo(() => usecase.getUIVersion(), [usecase])

    return (
        <Typography id="connectware-version" variant="body2" color="white">
            <FormattedTranslation id={Translation.VERSION} /> {version}
        </Typography>
    )
}

const wrapperStyle: SxProps<Theme> = { height: '100vh' }
const leftSideStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column', minWidth: 480, backgroundColor: 'primary.dark', flexBasis: 'min-content' }
const logoWrapperStyle: SxProps<Theme> = { p: 3, px: 10 }
const contentwrapperStyle: SxProps<Theme> = { px: 5, pb: 5, height: '100%' }
const backgroundStyle: SxProps<Theme> = { width: '100%' }

export const Wrapper: FC = () => (
    <Stack direction="row" sx={wrapperStyle}>
        <Paper sx={leftSideStyle} square>
            <Box sx={logoWrapperStyle}>
                <CybusCompleteLogo />
            </Box>
            <Stack sx={contentwrapperStyle} justifyContent="space-between" direction="column">
                <Typography id="connectware-slogan" variant="h3" color="white">
                    <FormattedTranslation id={Translation.CONNECTWARE_SLOGAN} />
                </Typography>
                <LoginForm />
                <Version />
            </Stack>
        </Paper>
        <Box sx={backgroundStyle}>
            <Background />
        </Box>
    </Stack>
)
