import { delay, executeOnce } from '../../../utils'
import { selectUserManagementCurrentForm, selectUserManagementInitialForm, type UserManagementTabsTypes } from '../../../domain'

import { ViewFormUsecase } from './View'

export abstract class FormUsecase<Form> extends ViewFormUsecase<[initial: Form, current: Form]> {
    private readonly getDelay = executeOnce(() => this.configurationService.getUserManagementDelay())

    protected abstract readonly selected: keyof UserManagementTabsTypes

    private isFormVisible (): boolean {
        return this.getNullableForm() !== null
    }

    protected getInitialForm (): Form {
        return selectUserManagementInitialForm(this.getForm())
    }

    protected getCurrentForm (): Form {
        return selectUserManagementCurrentForm(this.getForm())
    }

    protected setPartialForm (form: Partial<Form>): void {
        this.setForm([this.getInitialForm(), { ...this.getCurrentForm(), ...form }])
    }

    protected initializeForm (form: Form): void {
        this.setForm([form, form])
    }

    /**
     * Utility function to schedule throttled updates of the form
     */
    protected async updateFormWithDelay (
        compare: (a: Form, b: Form) => boolean,
        update: (current: Form) => Promise<Partial<Form>> | Partial<Form>
    ): Promise<void> {
        const initial = this.getCurrentForm()

        /** Wait for some time to pass before executing any post validation */
        await delay(this.getDelay())

        if (!this.isFormVisible() || !compare(initial, this.getCurrentForm())) {
            /** user name changed, stop */
            return
        }

        const updated = await update(initial)

        if (!this.isFormVisible() || !compare(initial, this.getCurrentForm())) {
            /** user name changed, stop */
            return
        }

        this.setPartialForm(updated)
    }

    protected async reload (): Promise<void> {
        /** Close all the forms */
        this.close()
        /** Load the page */
        await this.loadTab(this.selected, true)
    }

    protected abstract request (): Promise<void>

    update (update: Partial<Form>): void {
        this.setPartialForm(update)
    }

    async confirm (): Promise<void> {
        await this.request()
        await this.reload()
    }
}
