import React, { type FC, type ReactNode, useState } from 'react'
import { Box, Collapse, Divider, type SxProps, type Theme, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import type { ReadonlyRecord } from '../../../../utils'
import type { Translation } from '../../../../domain'
import { boldFormatter, FormattedTranslation } from '../../Internationalization'

const headerStyle: SxProps<Theme> = { display: 'flex', justifyContent: 'space-between', my: 2 }
const headerTyleStyle: SxProps<Theme> = {
    /**
     * Due to the flex-box parent,
     * using traditional white-space approach proved too difficult,
     * so this approach was used
     */
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflowY: 'hidden',
    wordBreak: 'break-all',
}
const actionStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', cursor: 'pointer' }

type TitleProps = Readonly<{ title: Translation | [Translation, ReadonlyRecord<string, ReactNode>], appendix?: string, actions?: ReactNode }>

const Title: FC<TitleProps> = ({ title, appendix, actions }) => {
    const [id, values] = Array.isArray(title) ? title : [title, {}]

    return (
        <Box sx={headerStyle}>
            <Typography sx={headerTyleStyle} variant="h4" component="h4">
                <FormattedTranslation id={id} values={values} />
                {Boolean(appendix) && (
                    <>
                        <>: </>
                        <>{appendix}</>
                    </>
                )}
            </Typography>
            <Box sx={actionStyle}>{actions}</Box>
        </Box>
    )
}

type DescriptionProps = Readonly<{ translations: [summary: Translation, fullText: Translation] }>

const infoWrapperStyle: SxProps<Theme> = { my: 2 }
const infoTitleStyle: SxProps<Theme> = { display: 'flex', mb: 2, color: 'grey.600', cursor: 'pointer' }
const infoIconStyle: SxProps<Theme> = { mr: 1, width: 20, height: 20, mt: 0.2 }
const infoBodyStyle: SxProps<Theme> = { color: 'grey.600', maxWidth: 600, fontSize: 'body2.fontSize', my: 2 }
const formatting = { b: boldFormatter, br: <br /> }

const Description: FC<DescriptionProps> = ({ translations: [summary, body] }) => {
    const [opened, setOpen] = useState(false)

    return (
        <Box sx={infoWrapperStyle}>
            <Box data-testid="info-title" sx={infoTitleStyle} onClick={() => setOpen(!opened)}>
                <InfoOutlined sx={infoIconStyle} />
                <Typography>
                    <FormattedTranslation id={summary} />
                </Typography>
            </Box>

            <Collapse in={opened} timeout="auto" unmountOnExit>
                <Box data-testid="info-body">
                    <Typography sx={infoBodyStyle}>
                        <FormattedTranslation id={body} values={formatting} />
                    </Typography>

                    <Divider />
                </Box>
            </Collapse>
        </Box>
    )
}

export const DetailsHeader: FC<{
    title: TitleProps['title']
    titleAppendix?: TitleProps['appendix']
    titleActions?: TitleProps['actions']
    description?: DescriptionProps['translations']
}> = ({ title, titleAppendix, titleActions, description }) => (
    <>
        <Title title={title} appendix={titleAppendix} actions={titleActions} />
        {Array.isArray(description) && <Description translations={description} />}
    </>
)
