import type { ValuesType } from 'utility-types'
import React, { type FC, Fragment, useEffect } from 'react'
import { Box, Stack, type SxProps, type Theme, typographyClasses } from '@mui/material'

import type { ReadonlyRecord } from '../../../../utils'
import {
    type AppState,
    ConnectwareError,
    type CybusService,
    type CybusServiceDataResource,
    CybusServiceDataResourceType,
    selectServicesDataPage,
    selectServiceTopicsMessages,
    Translation,
} from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'
import { PermissionedLink, RelativeRoutePathWithId } from '../../routing'
import { FormattedDateTime, FormattedTranslation, useTranslator } from '../../Internationalization'
import { ResourcesTable } from '../../Resources'
import { TiniestCopy } from '../../Copy'
import { FormattedBuffer } from '../../common'

type TopicsFC = FC<Pick<CybusServiceDataResource, 'topics'>>
type TableDataResource = Pick<CybusServiceDataResource, 'type' | 'topics'> &
    ReadonlyRecord<'message' | 'time', CybusServiceDataResource['topics']> &
    ReadonlyRecord<'id', CybusServiceDataResource>

const typeToLinkMap = {
    [CybusServiceDataResourceType.ENDPOINT]: RelativeRoutePathWithId.ENDPOINT,
    [CybusServiceDataResourceType.MAPPING]: RelativeRoutePathWithId.MAPPING,
    /** Nodes have no page */
    [CybusServiceDataResourceType.NODE]: null,
} as const

const linkStyle: SxProps<Theme> = { [`&:hover > .${typographyClasses.root}.${typographyClasses.caption}`]: { opacity: 1 } }
const entriesStyle: SxProps<Theme> = { my: 'auto', lineHeight: 1 }
const buttonStyle: SxProps<Theme> = { ...entriesStyle, opacity: 0 }
const Link: FC<CybusServiceDataResource> = ({ id, type }) => {
    const path = typeToLinkMap[type]
    return (
        <Stack direction="row" gap={1} sx={linkStyle}>
            {path ? (
                <PermissionedLink sx={entriesStyle} path={path} id={id}>
                    {id}
                </PermissionedLink>
            ) : (
                <Box sx={entriesStyle}>{id}</Box>
            )}
            <TiniestCopy sx={buttonStyle}>{id}</TiniestCopy>
        </Stack>
    )
}

const Topics: TopicsFC = ({ topics }) => (
    <>
        {topics.map((topic, k) => (
            <Fragment key={k}>
                {k > 0 && <br />}
                {topic}
            </Fragment>
        ))}
    </>
)

const useLastMessage = (topics: CybusServiceDataResource['topics']): ValuesType<AppState['serviceTopicsMessages']> | null =>
    useAppState(
        (s) => {
            const messages = selectServiceTopicsMessages(s)

            return topics.reduce<ReturnType<typeof useLastMessage>>((lastMessage, topic) => {
                const currentMessage = messages[topic] ?? null

                if (
                    lastMessage === null ||
                    ConnectwareError.is(currentMessage) ||
                    (currentMessage && lastMessage && !ConnectwareError.is(lastMessage) && currentMessage.timestamp > lastMessage.timestamp)
                ) {
                    /**
                     * If there was no message set,
                     * the new message is an error,
                     * or the new message more recent than the previous one
                     *
                     * Then use the new message
                     */
                    return currentMessage
                }

                return lastMessage
            }, null)
        },
        [topics]
    )

const Message: TopicsFC = ({ topics }) => {
    const message = useLastMessage(topics)

    if (message === null) {
        return <FormattedTranslation id={Translation.NO_MESSAGES} />
    }

    if (ConnectwareError.is(message)) {
        return <FormattedTranslation id={Translation.LIVE_DATA_SUBSCRIPTION_ERROR} />
    }

    return <FormattedBuffer buffer={message.payload} type={message.possibleTypes[0]} minimized />
}

const Time: TopicsFC = ({ topics }) => {
    const message = useLastMessage(topics)

    if (message === null || ConnectwareError.is(message)) {
        return null
    }

    return <FormattedDateTime date={message.timestamp} format="datetime" />
}

export const LiveDataTable: FC<Pick<CybusService, 'id'>> = ({ id }) => {
    const translator = useTranslator()

    const loadUsecase = useAppUsecase('loadServiceDataUsecase')
    useEffect(() => loadUsecase.invoke(), [loadUsecase])

    return (
        <ResourcesTable<'manageServicesTopicsUsecase', CybusServiceDataResource, TableDataResource>
            subscriptionUsecase="manageServicesTopicsUsecase"
            data={selectServicesDataPage}
            filter={{ service: id }}
            dataTableMapper={(resources) => resources.map((r) => ({ ...r, id: r, message: r.topics, time: r.topics }))}
            columns={{
                id: { label: Translation.RESOURCE_ID, sort: true, customCellRender: (props) => <Link {...props} /> },
                type: {
                    label: Translation.RESOURCE_TYPE,
                    sort: true,
                    customCellRender: (type) => translator.formatTranslation(Translation.SERVICE_RESOURCE_TYPE, { type }),
                },
                topics: { label: translator.formatTranslation(Translation.TOPIC, { count: 0 }), customCellRender: (topics) => <Topics topics={topics} /> },
                message: { label: Translation.MESSAGE, customCellRender: (topics) => <Message topics={topics} /> },
                time: { label: Translation.TIME, customCellRender: (topics) => <Time topics={topics} /> },
            }}
            translations={{ noResults: Translation.NO_LIVE_DATA_RESOURCES_FOUND, emptyTable: Translation.NO_LIVE_DATA_RESOURCES }}
        />
    )
}
