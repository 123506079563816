import React, { type FC } from 'react'
import { createExtendedState } from 'react-extended-state'

type AnchorState = Readonly<{ anchorEl: Element | null }>

const { Provider, useExtendedState, useExtendedStateDispatcher } = createExtendedState<AnchorState>({ ignorePropsChanges: true })

const initialState: AnchorState = { anchorEl: null }
export const AnchorProvider: FC = ({ children }) => <Provider value={initialState}>{children}</Provider>

export const useAnchor = (): AnchorState['anchorEl'] => useExtendedState((s) => s.anchorEl)

export const useAnchorSetter = (): ((el: AnchorState['anchorEl']) => void) => {
    const dispatch = useExtendedStateDispatcher()
    return (anchorEl) => dispatch({ anchorEl })
}

export const useAnchorCloser = (): VoidFunction => {
    const setOrigin = useAnchorSetter()
    return () => setOrigin(null)
}
