import React, { type FC, type PropsWithChildren } from 'react'
import { type SxProps, TableCell, TableHead, TableRow, TableSortLabel, type Theme } from '@mui/material'

import { isEnumOf, type ReadonlyRecord } from '../../../../utils'
import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

import type { Columns, Line, SortOrder } from './Types'
import { useColumn, useColumnNames, useHasNumberedRows, useSelectAll, useShouldShowHeader, useSortOrder, useSortOrderDispatcher } from './State'
import { TableCheckbox } from './Checkbox'

type SortDirection = SortOrder<never>['direction']

const directionsInversion: ReadonlyRecord<SortDirection, SortDirection> = { asc: 'desc', desc: 'asc' } as const

const SelectionCell = (): ReturnType<FC> => {
    const [selection, toggle] = useSelectAll()

    return selection ? (
        <TableCell key="table-header-checkbox" padding="checkbox">
            <TableCheckbox data-testid="checkbox-all" indeterminate={selection.onlySome} checked={selection.all} onChange={toggle} />
        </TableCell>
    ) : null
}

const numberedCellStyle: SxProps<Theme> = { minWidth: 32, textAlign: 'center', p: 0 }

const HeaderCell = <L extends Line,>({ name }: PropsWithChildren<{ name: keyof Columns<L> }>): ReturnType<FC> => {
    const currentSort = useSortOrder<L>()
    const setSort = useSortOrderDispatcher<L>()
    const { label: LabelContent, sort = false } = useColumn<L, keyof Columns<L>>(name)

    const label = isEnumOf(Translation, LabelContent) ? (
        <FormattedTranslation id={LabelContent} />
    ) : typeof LabelContent === 'function' ? (
        <LabelContent>{name}</LabelContent>
    ) : (
        LabelContent
    )

    if (!sort) {
        return <>{label}</>
    }

    const active = currentSort?.name === name

    /**
     * If active, then use configuration, otherwise, settle for ascending
     */
    const currentDirection: SortDirection | false = active ? currentSort.direction : 'asc'

    return (
        <TableSortLabel
            active={active}
            direction={currentDirection}
            onClick={() =>
                setSort({
                    name,
                    /**
                     * If active, then invert, otherwise, use the currently displayed direction
                     */
                    direction: active ? directionsInversion[currentDirection] : currentDirection,
                })
            }
        >
            {label}
        </TableSortLabel>
    )
}

export const Header = <L extends Line,>(): ReturnType<FC> => {
    const names = useColumnNames<L>()
    const numberedRows = useHasNumberedRows()
    const shouldShow = useShouldShowHeader()

    if (!shouldShow) {
        return null
    }

    return (
        <TableHead>
            <TableRow>
                {numberedRows && (
                    <TableCell data-testid="head-row-index" sx={numberedCellStyle}>
                        #
                    </TableCell>
                )}
                <SelectionCell />
                {names.map((name, colIndex: number) => (
                    <TableCell key={`table-header-${colIndex}`}>
                        <HeaderCell name={name} />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
