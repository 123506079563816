import React, { type FC } from 'react'
import { TableCell, TableRow } from '@mui/material'

import { CircularLoader } from '../Loaders'
import { useColspan } from './State'

export const LoadingTableBody: FC = () => {
    const colSpan = useColspan()

    return (
        <TableRow data-testid="loading-table">
            <TableCell colSpan={colSpan}>
                <CircularLoader />
            </TableCell>
        </TableRow>
    )
}
