import { isArrayNotEmpty, normalize, search as searchString } from '../../../../utils'

import {
    type CybusServiceCatalog,
    selectServiceCatalog,
    selectServiceCatalogApplications,
    selectServiceCatalogFiltered,
    selectServiceCatalogSearch,
} from '../../../../domain'

import { Usecase } from '../..'

export abstract class BaseCatalogUsecase extends Usecase {
    private generateFiltered (newCatalog: Partial<CybusServiceCatalog>): CybusServiceCatalog['filtered'] {
        const filtered = selectServiceCatalogFiltered(this.getState())
        let applications = selectServiceCatalogApplications(this.getState())
        let search = selectServiceCatalogSearch(this.getState())

        if (newCatalog.applications === undefined && newCatalog.search === undefined) {
            // No change occurred
            return filtered
        }

        applications = newCatalog.applications === undefined ? applications : newCatalog.applications
        search = newCatalog.search === undefined ? search : newCatalog.search

        if (!Array.isArray(applications)) {
            // Nothing has loaded, so pass empty services
            return []
        }

        let terms: string[]
        if (
            !isArrayNotEmpty(applications) ||
            !search ||
            (terms = normalize(search.trim())
                .split(/\W+/)
                .filter((t) => t)).length === 0
        ) {
            // There is no search or no searchable applications, so pass all services
            return applications
        }

        // Warning, this does not scale well, if there are issues with performance, this needs to be revised
        return applications.filter(
            ({ name, category, provider }) =>
                searchString(normalize(name), terms) || searchString(normalize(category), terms) || (provider && searchString(normalize(provider), terms))
        )
    }

    protected setCatalog (newCatalog: Partial<CybusServiceCatalog>): void {
        this.setState({ catalog: { ...selectServiceCatalog(this.getState()), ...newCatalog, filtered: this.generateFiltered(newCatalog) } })
    }
}
