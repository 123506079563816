import type { CommissioningDataResourceProperties } from '..'

/**
 * @see https://bitbucket.org/cybusio/cybus/src/e9747b43bf8d5e80b3a7976129d30a8a60e7cd8d/protocol-mapper/src/Connection.js?at=main#lines-173
 * @see https://bitbucket.org/cybusio/cybus/src/db84a1b7ca09d3d37998da0664249760ffefe8b6/protocol-mapper/src/swagger/swagger.yaml?at=main#lines-501
 */
export type ConnectionProxyParams = Readonly<{
    id: string
    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    currentState: string
}> &
    CommissioningDataResourceProperties<'Cybus::Connection'>
