import type { NonEmptyArray } from '../../../utils'
import { ReloadLicenseUsecase } from './Reload'

export class UploadLicenseFileUsecase extends ReloadLicenseUsecase {
    async upload (fileContent: string): Promise<void> {
        await this.changeLicense(() => this.systemService.uploadLicenseFile(fileContent))
    }

    getSupportedLicenseFilesExtensions (): NonEmptyArray<string> {
        return this.configurationService.getSupportedLicenseFileTypes()
    }
}
