import React, { type FC } from 'react'

import { Button as BaseButton, type ButtonProps, type SxProps, type Theme } from '@mui/material'
import { type SvgIconComponent, UploadFile } from '@mui/icons-material'

import { Translation } from '../../../domain'
import { FormattedTranslation } from '../Internationalization'

const buttonStyle: SxProps<Theme> = { ml: 2 }
const uploadTemplateStyle: SxProps<Theme> = { display: 'none' }

const Button: FC<Readonly<{ startIcon: SvgIconComponent, label: Translation }> & Pick<ButtonProps, 'disabled' | 'onClick' | 'variant' | 'sx'>> = ({
    startIcon: StartIcon,
    label,
    ...props
}) => (
    <BaseButton sx={buttonStyle} startIcon={<StartIcon />} {...props}>
        <FormattedTranslation id={label} />
    </BaseButton>
)

type Props = Readonly<{ onClick: VoidFunction, disabled: boolean }>

export const UploadTemplateButton: FC<Props> = (props) => (
    <Button
        data-testid="upload-template"
        sx={uploadTemplateStyle}
        variant="contained"
        startIcon={UploadFile}
        label={Translation.EDIT_SERVICE_UPLOAD_TEMPLATE}
        {...props}
    />
)

export const UploadServiceButton: FC<Props> = (props) => (
    <Button data-testid="add-service" variant="contained" startIcon={UploadFile} label={Translation.UPLOAD_SERVICE} {...props} />
)
