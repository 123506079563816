import React, { type FC, type PropsWithChildren } from 'react'
import { type SxProps, TableCell, TableRow, type Theme } from '@mui/material'

import { createHandlerWithoutPropagation } from '../../utils'
import type { Columns, Line } from './Types'
import { useBorders, useColumn, useColumnNames, useDense, useHasNumberedRows, useOnRowClick, useOrientation, useSelection } from './State'
import { TableCheckbox } from './Checkbox'

const createStyleHook =
    <S extends SxProps<Theme>,>(shouldUseStyleHook: () => boolean, style: S): (() => S | null) =>
    () => {
        const shouldUseStyle = shouldUseStyleHook()
        return shouldUseStyle ? style : null
    }

const useDensityStyle = createStyleHook(useDense, { p: 0 })
const useBorderStyle = createStyleHook(useBorders, { borderRightWidth: 1, borderRightStyle: 'solid' })

const baseCellStyle: SxProps<Theme> = { wordBreak: 'break-all' }
const numberedCellStyle: SxProps<Theme> = { width: 32, textAlign: 'center', p: 0 }
const clickableRowStyle: SxProps<Theme> = { cursor: 'pointer' }

const Cell = <L extends Line,>({ name, row, index }: PropsWithChildren<{ name: keyof Columns<L>, row: L, index: number }>): ReturnType<FC> => {
    const orientation = useOrientation()
    const names = useColumnNames()
    const densityStyle = useDensityStyle()
    const borderStyle = useBorderStyle()

    const { customCellRender = undefined, sx = null } = useColumn<L, keyof Columns<L>>(name)
    const colIndex = names.indexOf(name)

    return (
        <TableCell
            // If the orientation is set to horizontal, then the first column will be displayed with header styles
            variant={orientation === 'horizontal' && colIndex === 0 ? 'head' : 'body'}
            sx={{
                ...baseCellStyle,
                ...(colIndex < names.length - 1 ? borderStyle : null),
                ...densityStyle,
                ...sx,
            }}
        >
            {customCellRender ? customCellRender(row[name], row, index) : row[name] ?? '-'}
        </TableCell>
    )
}

export const Row = <L extends Line,>({ index, row }: PropsWithChildren<{ index: number, row: L }>): ReturnType<FC> => {
    const hasNumberedRows = useHasNumberedRows()
    const densityStyle = useDensityStyle()
    const names = useColumnNames()
    const borderStyle = useBorderStyle()
    const onRowClick = useOnRowClick()

    const [selected, toggleSelection] = useSelection(row)

    return (
        <TableRow
            key={`table-row-${index}`}
            onClick={onRowClick ? () => onRowClick(row) : undefined}
            hover={Boolean(onRowClick)}
            sx={onRowClick && clickableRowStyle}
        >
            {hasNumberedRows && (
                <TableCell
                    key={`table-body-numbered-${index}`}
                    data-testid="body-row-index"
                    variant="head"
                    // Since it resembles a header, it will always have a border
                    sx={[numberedCellStyle, borderStyle, densityStyle]}
                >
                    {index + 1}
                </TableCell>
            )}

            {selected !== null && (
                <TableCell key={`checkbox-${index}`} padding="checkbox" sx={[borderStyle, densityStyle]}>
                    <TableCheckbox data-testid={`checkbox-${index}`} checked={selected} onClick={createHandlerWithoutPropagation(toggleSelection)} />
                </TableCell>
            )}

            {names.map((name, colIndex) => (
                <Cell key={`table-body-${index}-${colIndex}`} name={name} row={row} index={index} />
            ))}
        </TableRow>
    )
}
