import React, { type FC } from 'react'
import { Box, Button, type SxProps, type Theme, Typography } from '@mui/material'

import { isArrayNotEmpty } from '../../../utils'
import { type CybusLinkedService, type CybusService, selectServicesLinksPage, Translation } from '../../../domain'

import { RedirectingResourcesTable } from '../Resources'
import { createHandlerWithoutPropagation } from '../utils'
import { useTranslator } from '../Internationalization'
import { RelativeRoutePathWithId } from '../routing'
import { AddOrUpdateModal } from './AddOrUpdate'

const wrapperStyle: SxProps<Theme> = { mx: -0.5, display: 'flex' }
const buttonStyle: SxProps<Theme> = { color: 'primary.700', '&:hover': { backgroundColor: 'unset' } }
const tableButtonStyle: SxProps<Theme> = { ...buttonStyle, m: 0.5, p: 0, textTransform: 'unset', fontWeight: 400 }

const onClick = createHandlerWithoutPropagation()
type ServiceLinksProps = Readonly<{ links: CybusService['links'], isDetailedView?: boolean }>

export const ServiceLinks: FC<ServiceLinksProps> = ({ links, isDetailedView = false }) => (
    <Box data-testid="service-links" sx={wrapperStyle}>
        {links.map((link, k) => (
            <Button
                key={k}
                sx={isDetailedView ? buttonStyle : tableButtonStyle}
                size="small"
                variant="text"
                color="primary"
                href={link.href}
                target="_blank"
                onClick={onClick}
            >
                {link.name}
            </Button>
        ))}
    </Box>
)

export const Links: FC = () => {
    const translation = useTranslator()

    return (
        <RedirectingResourcesTable<'manageServicesLinksUsecase', CybusLinkedService>
            subscriptionUsecase="manageServicesLinksUsecase"
            data={selectServicesLinksPage}
            dataTableMapper={(r) => r}
            columns={{
                id: { label: Translation.SERVICE_ID, sort: true },
                name: { label: Translation.NAME, sort: true },
                links: {
                    label: translation.formatTranslation(Translation.LINK, { count: 2 }),
                    customCellRender: (links) => (isArrayNotEmpty(links) ? <ServiceLinks links={links} /> : <Typography>-</Typography>),
                },
            }}
            redirectOnRowclick={RelativeRoutePathWithId.SERVICE}
            translations={{ noResultsOrEmptyTable: Translation.NO_SERVICE_LINKS_AVAILABLE }}
        >
            <AddOrUpdateModal mode="CREATE" />
        </RedirectingResourcesTable>
    )
}
