import React, { type FC, type ReactNode } from 'react'
import { ListItem, ListItemIcon, ListItemText, type ListItemTextProps, type SxProps, type Theme, Typography } from '@mui/material'
import { Star } from '@mui/icons-material'

const listItemStyle: SxProps<Theme> = { mb: 2 }
const disableMargin: SxProps<Theme> = { m: 0 }
const primaryText: SxProps<Theme> = { fontWeight: 500 }
const secondaryText: SxProps<Theme> = { mt: 2, color: null }
const nestedListItemStyle: SxProps<Theme> = { ...listItemStyle, pl: 9 }
const nestedSecondaryText: SxProps<Theme> = { ...secondaryText, mt: 1 }
const textBlockStyle: SxProps<Theme> = { wordBreak: 'break-all' }

export const ItemText: FC<Pick<ListItemTextProps, 'primary' | 'secondary' | 'sx'>> = (props) => (
    <ListItem alignItems="flex-start" sx={listItemStyle}>
        <ListItemIcon sx={disableMargin}>
            <Star />
        </ListItemIcon>
        <ListItemText
            {...props}
            primaryTypographyProps={{ variant: 'body1', sx: primaryText }}
            secondaryTypographyProps={{ variant: 'body2', sx: secondaryText }}
            sx={disableMargin}
        />
    </ListItem>
)

export const NestedItemText: FC<
    Pick<ListItemTextProps, 'primary' | 'secondary' | 'sx' | 'disableTypography'> & { icon?: ReactNode, isNormalFontWeight?: true }
> = ({ icon, isNormalFontWeight, ...props }) => (
    <ListItem alignItems="flex-start" sx={nestedListItemStyle}>
        {icon && <ListItemIcon sx={disableMargin}>{icon}</ListItemIcon>}
        <ListItemText
            {...props}
            primaryTypographyProps={{ variant: isNormalFontWeight ? 'body2' : 'body1', sx: isNormalFontWeight ? undefined : primaryText }}
            secondaryTypographyProps={{ variant: 'body2', sx: nestedSecondaryText }}
            sx={disableMargin}
        />
    </ListItem>
)

export const TextBlock: FC = ({ children }) => (
    <Typography component="span" display="block" variant="body2" sx={textBlockStyle}>
        {children}
    </Typography>
)
