import type { ReadonlyRecord } from '../../utils'
import type { AvailableTopic, ConnectwareError, LastTopicMessage, TailedTopicMessage, TopicType, UnloadedLastTopicMessage } from '..'

export type Explorer = Readonly<{
    /**
     * Available topics
     */
    topics: AvailableTopic[]

    /**
     * Stores either a topics configuration loading error (ConnectwareError) or null if no error occurred
     */
    configurationLoadError: ConnectwareError | null

    filter: Readonly<{ types: TopicType[], resources: string[] }>

    /**
     * Monitored topics
     */
    latestValues: ReadonlyRecord<string, LastTopicMessage | UnloadedLastTopicMessage>

    /**
     * History/Tail
     */
    tailSize: number
    isTailing: boolean
    tail: TailedTopicMessage[]
}>
