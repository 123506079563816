import { type AuthenticationInformation, ConnectwareError, ConnectwareErrorType } from '../../domain'
import type { BrowserService, LoggerService } from '../../application'

export class HTML5BrowserService implements BrowserService {
    readonly origin: string

    readonly host: string

    constructor (private readonly logger: LoggerService, origin: URL['origin']) {
        const url = new URL(origin)

        this.origin = url.origin
        this.host = url.host
    }

    private getUrlParameter (name: string): string | null {
        return window.location.search ? new URLSearchParams(window.location.search).get(name) : null
    }

    isInspecting (): boolean {
        return this.getUrlParameter('inspection') !== null
    }

    redirect (url: URL): void {
        window.location.replace(url.toString())
    }

    getRedirectionURL (): URL | null {
        const redirectParameter = this.getUrlParameter('redirect')
        return redirectParameter ? new URL(redirectParameter, this.origin) : null
    }

    getToken (): string | null {
        return this.getUrlParameter('apiToken')
    }

    authenticate (info: AuthenticationInformation | null): void {
        document.cookie = `X-Auth-Token=${info?.token || ''}; path=/services`
    }

    reload (): void {
        window.location.reload()
    }

    open (url: URL, name: string | null): void {
        const ref = url.toString()
        window.open(ref, name || ref)
    }

    sendEmail (target: string, subject: string | null): void {
        const url = new URL(`mailto:${target}`)

        if (subject) {
            url.searchParams.append('subject', subject)
        }

        this.open(url, null)
    }

    copy (value: unknown): string | null {
        let data: string | null = null

        switch (typeof value) {
            case 'string':
                data = value
                break
            default:
                /** Just quietly log the issue */
                this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Only string values are supported to be copied', { value }))
        }

        if (data === null) {
            return null
        }

        navigator.clipboard.writeText(data).catch(({ message, code }: DOMException) => {
            /** Just quietly log the issue */
            this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not write to clipboard', { message, code, value }))
        })

        return data
    }
}
