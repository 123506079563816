import {
    type ChangePasswordForm,
    ConnectwareError,
    ConnectwareErrorType,
    isChangePasswordFormOpen,
    selectChangePasswordForm,
    Translation,
} from '../../../domain'
import type { PasswordChangeRequest } from '../../services'

import { Usecase } from '../Usecase'

const emptyForm = { password: '', newPassword: '', confirmPassword: '', passwordValidation: null } as const

export class ChangePasswordUsecase extends Usecase {
    private setChangePasswordForm (changePasswordForm: ChangePasswordForm | null): void {
        this.setState({ changePasswordForm })
    }

    private getChangePasswordForm (): ChangePasswordForm {
        const form = selectChangePasswordForm(this.getState())

        if (!form) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Can not get change password form')
        }

        return form
    }

    private updateChangePasswordForm (form: Partial<ChangePasswordForm>): void {
        this.setChangePasswordForm({ ...this.getChangePasswordForm(), ...form })
    }

    private getPasswordChangeRequest (): PasswordChangeRequest | null {
        const form = this.getChangePasswordForm()
        const { password, newPassword, passwordValidation } = form

        /**
         * Backend validation and description at auth-server/src/models/user/index.js and auth-server/src/swagger/swagger.yaml
         */
        if (!password || !newPassword || !Array.isArray(passwordValidation) || passwordValidation.some((validation) => typeof validation !== 'string')) {
            return null
        }

        return { password, newPassword }
    }

    private async updatePasswordValidation (): Promise<void> {
        const form = this.getChangePasswordForm()
        const passwordValidation = await this.validatePassword(form).catch((e: ConnectwareError) => e)
        if (this.getChangePasswordForm() === form) {
            /** There have been no changes */
            this.updateChangePasswordForm({ passwordValidation })
        }
    }

    private async validatePassword ({ newPassword, confirmPassword }: ChangePasswordForm): Promise<(ConnectwareError | string)[]> {
        const validateMethod = this.userService.validatePassword(newPassword)

        return [this.createConfirmationValidationMessage({ newPassword, confirmPassword }), ...(await validateMethod)].filter(
            (v): v is ConnectwareError | string => Boolean(v)
        )
    }

    private createConfirmationValidationMessage ({
        newPassword,
        confirmPassword,
    }: Pick<ChangePasswordForm, 'newPassword' | 'confirmPassword'>): string | ConnectwareError {
        const matchMessage = this.translationService.translate(Translation.PASSWORD_VALIDATION, { type: 'match' })
        return newPassword === confirmPassword ? matchMessage : new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, matchMessage)
    }

    /**
     * Toggle password changing
     */
    toggle (): void {
        this.setChangePasswordForm(isChangePasswordFormOpen(this.getState()) ? null : emptyForm)
    }

    /**
     * Update the change password form
     * @param form
     */
    updateForm (form: Partial<Pick<ChangePasswordForm, 'password' | 'newPassword' | 'confirmPassword'>>): void {
        this.updateChangePasswordForm(form)

        if (form.newPassword ?? form.confirmPassword) {
            void this.updatePasswordValidation()
        }
    }

    /**
     * Update the password
     */
    async invoke (): Promise<void> {
        const request = this.getPasswordChangeRequest()

        if (request === null) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Can not update password')
        }

        await this.userService.changePassword(request)

        /** Now clear the form so password does not get remembered */
        this.updateForm(emptyForm)
    }
}
