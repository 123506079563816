import React, { type FC, type ReactNode } from 'react'
import { Box, type SxProps, type Theme, Typography, type TypographyProps } from '@mui/material'

import type { ReadonlyRecord } from '../../../utils'
import type { Translation } from '../../../domain'

import { boldFormatter, FormattedTranslation } from '../Internationalization'
import type { BaseCommonProps } from './Base'

const baseValues = { b: boldFormatter, br: <br /> }

const truncatedTextStyle: SxProps<Theme> = { textOverflow: 'ellipsis', overflowX: 'hidden', whiteSpace: 'nowrap' }

type Props = Readonly<{ id: Translation, values?: ReadonlyRecord<string, ReactNode> }> & Pick<BaseCommonProps, 'sx'>

const Base: FC<Props & Pick<TypographyProps, 'variant'>> = ({ id, values, ...props }) => (
    <Typography {...props}>
        <FormattedTranslation id={id} values={{ ...values, ...baseValues }} />
    </Typography>
)

export const Title: FC<Props> = (props) => <Base variant="h6" {...props} />
export const SmallParagraph: FC<Props> = (props) => <Base variant="body2" {...props} />

/**
 * If the text is too long and does not fit, it shows the end of the text and truncates the beginning
 * e.g.  "Lorem ipsum dolor sit amet." => "...sit amet."
 *
 * The &lrm; character is here to protect the first special character in the string from moving to the end in the rtl mode
 * e.g. in rtl for the string "$SYS/clients", the default behavior is "...S/clients$", which should be corrected to "...S/clients"
 *
 */
export const ReversedTruncatedText: FC<BaseCommonProps> = ({ children, sx, ...props }) => (
    <Box component="span" dir="rtl" sx={{ ...truncatedTextStyle, ...sx }} {...props}>
        &lrm;{children}
    </Box>
)
