import type { ReadonlyRecord } from '../utils'
import { ConnectwareError } from '.'
import type { Loadable } from './Loadable'

export type AppVersions = Readonly<{ ui: string, connectware: string }>

/**
 * This type defines the information used on the info page
 */
export type Information = Readonly<{
    /**
     * Loaded synchronously
     */
    version: string

    /**
     * Application information loded asynchronously
     */
    servicesCount: Loadable<number>
    connectivity: Loadable<SystemConnectivity>
}>

export type EndpointConnectivity = Readonly<{ url: URL, reachable: boolean }>

export type SystemConnectivity = Readonly<{
    endpoints: ReadonlyRecord<'cybusPortal' | 'cybusRegistry' | 'dockerHub', EndpointConnectivity>
    latest: Readonly<{ version: string, releaseNotes: URL, installerURL: URL }> | null
}>

export type InfoAppState = Readonly<{ info: Information | null }>

export type VersionsAppState = Readonly<{ versions: Loadable<AppVersions> }>

export const selectInfo = (s: InfoAppState): InfoAppState['info'] => s.info

export const selectVersions = (s: VersionsAppState): VersionsAppState['versions'] => s.versions

export const selectDifferingVersions = (s: VersionsAppState): AppVersions | null => {
    const versions = selectVersions(s)
    return versions && !ConnectwareError.is(versions) && versions.connectware !== versions.ui ? versions : null
}
