import React, { createRef, useEffect, useMemo, useState } from 'react'
import Editor from 'jsoneditor'

import { Box, type SxProps, type Theme } from '@mui/material'

import { EventListener } from '../../../../utils'

import 'jsoneditor/dist/jsoneditor.css'
import 'ace-builds/src-noconflict/theme-dawn'

import { ConnectwareError, ConnectwareErrorType } from '../../../../domain'

import type { JsonEditorFC } from '..'

const wrapperStyle: SxProps<Theme> = { height: 300, '& > *': { borderColor: 'grey.500' } }

export type ActingEditor = Pick<Editor, 'destroy' | 'get'>

type SupportedLocales = 'en' | 'es' | 'zh-CN' | 'pt-BR' | 'tr' | 'ja' | 'fr-FR' | 'de' | 'ru' | 'ko'

const locale: SupportedLocales = 'en'

export const JSONEditor: JsonEditorFC = ({ initialJson, onChange }) => {
    const ref = createRef<HTMLDivElement>()
    const eventListener = useMemo(() => new EventListener<void>(), [])

    const [editor, setEditor] = useState<ActingEditor | null>(null)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const editor: ActingEditor = new Editor(
            ref.current,
            {
                mode: 'code',
                onChange: () => eventListener.trigger(),
                onError: () => eventListener.trigger(),
                language: locale,
                enableSort: false,
                enableTransform: false,
                navigationBar: false,
                statusBar: false,
                mainMenuBar: false,
                theme: 'ace/theme/dawn',
            },
            initialJson
        )

        setEditor(editor)

        return () => {
            setEditor(null)
            editor.destroy()
        }
    }, [ref.current, eventListener])

    useEffect(() => {
        if (!editor) {
            return
        }

        return eventListener.on(() => {
            try {
                onChange(editor.get())
            } catch (e: unknown) {
                onChange(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Bad json input', { message: (e as Error).message }))
            }
        })
    }, [editor, eventListener])

    return <Box sx={wrapperStyle} ref={ref} />
}
