import React, { type FC } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'
import { PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material'

import {
    canContainerBeRestarted,
    canContainerBeStarted,
    canContainerBeStopped,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusCoreContainer,
    ResourceType,
    selectCoreContainersPage,
    selectRestartableLoadedCoreContainers,
    selectStartableLoadedCoreContainers,
    selectStoppableLoadedCoreContainers,
    Translation,
} from '../../../../domain'

import { RedirectingResourcesTable } from '../../Resources'
import { RestartModal, StartModal, StopModal, useContainerTableColums } from '../../Containers'
import { AbsoluteRoutePathWithId } from '../../routing'
import { useClearTableSelection } from '../../common'

const selectCanManageCoreContainers = createIsAuthenticatedWithCapabilitiesSelector(Capability.CORE_CONTAINERS_MANAGE)

const Modals: FC = () => {
    const clear = useClearTableSelection()
    return (
        <>
            <StopModal
                dimension={ResourceType.CORE_CONTAINER}
                usecase="bulkStopCoreContainersUsecase"
                containersSelector={selectStoppableLoadedCoreContainers}
                onConfirmed={clear}
            />
            <StartModal
                dimension={ResourceType.CORE_CONTAINER}
                usecase="bulkStartCoreContainersUsecase"
                containersSelector={selectStartableLoadedCoreContainers}
                onConfirmed={clear}
            />
            <RestartModal
                dimension={ResourceType.CORE_CONTAINER}
                usecase="bulkRestartCoreContainersUsecase"
                containersSelector={selectRestartableLoadedCoreContainers}
                onConfirmed={clear}
            />
        </>
    )
}

const wrapperStyle: SxProps<Theme> = { py: 2 }
export const Containers: FC = () => {
    const columns = useContainerTableColums()

    return (
        <Box sx={wrapperStyle}>
            <RedirectingResourcesTable<
                'manageCoreContainersUsecase',
                CybusCoreContainer,
                CybusCoreContainer,
                'bulkRestartCoreContainersUsecase' | 'bulkStartCoreContainersUsecase' | 'bulkStopCoreContainersUsecase'
            >
                subscriptionUsecase="manageCoreContainersUsecase"
                data={selectCoreContainersPage}
                hasManagementCapabilitiesSelector={selectCanManageCoreContainers}
                dataTableMapper={(containers) => containers}
                columns={columns}
                redirectOnRowclick={AbsoluteRoutePathWithId.SYSTEM_CONTAINER}
                selection={{
                    DISABLE: { usecase: 'bulkStopCoreContainersUsecase', filter: canContainerBeStopped, icon: PauseCircleFilled, label: Translation.STOP },
                    ENABLE: { usecase: 'bulkStartCoreContainersUsecase', filter: canContainerBeStarted, icon: PlayCircleFilled, label: Translation.START },
                    RESTART: { usecase: 'bulkRestartCoreContainersUsecase', filter: canContainerBeRestarted },
                    resource: 'id',
                    usecaseMethod: 'selectContainers',
                }}
                translations={{ noResultsOrEmptyTable: Translation.NO_CONTAINERS_AVAILABLE }}
            >
                <Modals />
            </RedirectingResourcesTable>
        </Box>
    )
}
