export type FullServiceMetadata = Readonly<{
    availableInLicense: boolean
    category: string
    createdAt: string
    description: string
    directory: string
    downloads: number
    filename: string
    homepage: string
    icon: string | null
    isTemplate: boolean
    name: string
    provider: string | null
    publiclyOffered: boolean
    updatedAt: string
    version: string
}>

export type ServiceMeta = Pick<
    FullServiceMetadata,
    'directory' | 'filename' | 'name' | 'description' | 'homepage' | 'icon' | 'version' | 'provider' | 'downloads' | 'createdAt' | 'updatedAt'
>

export type ServiceContent = Readonly<{ contentType: string, data: string, filename: string }>

export type ServiceCatalogMetaResponse = Readonly<{ meta: ServiceMeta, content: ServiceContent }>
