import { executeOnce, type ReadonlyRecord } from '../../utils'
import type { Capability } from '..'
import type { Loadable } from '../Loadable'

export type AuthenticationStatus = ReadonlyRecord<'isMfaEnabled' | 'isMfaRequired', boolean>

export type AuthenticationInformation = Readonly<{
    /**
     * The source of the token
     */
    username: string

    /**
     * The auth token
     */
    token: string

    /**
     * When the token will expire
     */
    expiresAt: Date

    /**
     * The capabilities the user has on the application
     */
    capabilities: Capability[]

    /**
     * Will be non null if the current authentication is found to be no longer true
     */
    obsolete: Loadable<AuthenticationStatus>
}>

const createSelector =
    <K extends keyof AuthenticationInformation>(k: K): ((s: Pick<AuthenticationInformation, K>) => AuthenticationInformation[K]) =>
    (a) =>
        a[k]

export const selectAuthenticationToken = createSelector('token')
export const selectAuthenticationUsername = createSelector('username')
export const selectAuthenticationExpiresAt = createSelector('expiresAt')
export const selectAuthenticationCapabilities = createSelector('capabilities')
export const selectAuthenticationObsolescence = createSelector('obsolete')

export const createAreAllCapabilitiesIncludedChecker = (
    ...[s]: Parameters<typeof selectAuthenticationCapabilities>
): ((requiredCapabilities: Capability[]) => boolean) => {
    const getIndexedCapabilities = executeOnce(() => new Set(selectAuthenticationCapabilities(s)))
    return (requiredCapabilities) => requiredCapabilities.every((p) => getIndexedCapabilities().has(p))
}
