import { ConnectwareError, ConnectwareErrorType, type CybusAgent } from '../../../../domain'
import { mapToStatusType } from '../Status'

const rejectAgents = new Set(['service-manager', 'system-control-server', 'container-manager'])

const mapAgent = (name: CybusAgent['name'], { status, version, hostname }: Readonly<{ status: string, hostname: string, version: string }>): CybusAgent => {
    const nullifiedHostname = hostname || null

    // If agent container was started without hostname argument, we get
    // just the Docker container ID here, which is somewhat confusing.
    const isHostnameContainerId = nullifiedHostname && /^[0-9a-f]{12}$/.exec(nullifiedHostname)

    return {
        name,
        status: mapToStatusType(status),
        version,
        hostname: isHostnameContainerId ? null : nullifiedHostname,
        containerId: isHostnameContainerId ? nullifiedHostname : null,
    }
}

export const mapAgents = <T>(input: T[], statusMapper: Map<string, string>, argsMapper: (i: T) => Parameters<typeof mapAgent>): CybusAgent[] =>
    input.reduce<ReturnType<typeof mapAgents>>((r, i) => {
        const [name, info] = argsMapper(i)

        if (rejectAgents.has(name)) {
            return r
        }

        const status = statusMapper.get(name)

        if (!status) {
            throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'The status for the given agent name is undefined', { name })
        }

        return [...r, mapAgent(name, { ...info, status })]
    }, [])
