import React, { type FC, useLayoutEffect } from 'react'

import { HashRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'

import {
    AbsoluteRouteOnlyPath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
    CybusRouter,
    Redirect,
    Route,
    Switch,
} from '../routing'
import { useAppUsecases } from '../State'

import { Head } from '..'

import VersionMismatchNotifier from '../VersionMismatch'
import Credentials from '../Credentials'
import { AppNavigation, RouteNavigation } from '../Navigation'
import Services, { EditTemplateForm, Details as ServiceDetails, ServiceLinks, ServicesCatalog, ServiceTemplateOutput } from '../Services'
import Explorer from '../Explorer'
import Servers, { Details as ServerDetails } from '../Servers'
import { ServiceContainerDetails, ServiceContainersTable } from '../Containers'
import Volumes, { Details as VolumeDetails } from '../Volumes'
import Endpoints, { Details as EndpointDetails } from '../Endpoints'
import Mappings, { Details as MappingDetails } from '../Mappings'
import Connections, { Details as ConnectionDetails } from '../Connections'
import Certificates from '../Certificates'
import Maintenance from '../Maintenance'
import RuleEngineSandbox from '../RuleEngine'
import { JSONEditor } from '../JsonEditor'
import { ClientRegistry, PermissionsPage, RolesPage, UsersPage } from '../Users'
import DeviationNotifications from '../DeviationNotifications'
import ResourcesTabs from '../Resources'
import ServiceLogs from '../ServiceLogs'
import { LoginSettings } from '../Settings'
import Workbench from '../Workbench'
import {
    InformationPage,
    SystemAgents,
    SystemContainerDetails,
    SystemContainers,
    SystemInternetConnectivity,
    SystemLicense,
    SystemMetrics,
    SystemStatusTabs,
} from '../SystemStatus'

const RouterAppNavigation: FC = ({ children }) => (
    <>
        <CssBaseline />
        <Credentials>
            <AppNavigation>{children}</AppNavigation>
            <DeviationNotifications />
        </Credentials>
    </>
)

export const App: FC = () => {
    const { loginFromTokenUsecase, loginFromPersistenceUsecase, inspectApplicationUsecase } = useAppUsecases()

    useLayoutEffect(() => {
        /**
         * Attempt to login from either the token on the url
         * or the persisted information from local storage
         */
        loginFromTokenUsecase.loginBrowserFromToken() || loginFromPersistenceUsecase.loginWithPersistedInformation()

        /**
         * Activate debugging of the application
         */
        inspectApplicationUsecase.invoke()
    }, [loginFromTokenUsecase, loginFromPersistenceUsecase, inspectApplicationUsecase])

    return (
        <>
            <Head />
            <HashRouter>
                <CybusRouter
                    initial={AbsoluteRouteOnlyPath.SYSTEM}
                    onNotFound={AbsoluteRouteOnlyPath.SYSTEM}
                    appNavigation={RouterAppNavigation}
                    routeNavigation={RouteNavigation}
                >
                    {/* System */}

                    <Route path={AbsoluteRouteOnlyPath.SYSTEM}>
                        <Redirect to={AbsoluteRouteOnlyPath.SYSTEM_STATUS} />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SYSTEM_STATUS}>
                        <Redirect to={AbsoluteRouteOnlyPath.SYSTEM_LICENSE} />
                    </Route>

                    <Route
                        paths={[
                            AbsoluteRouteOnlyPath.SYSTEM_CONTAINERS,
                            AbsoluteRouteOnlyPath.SYSTEM_INTERNET_CONNECTIVITY,
                            AbsoluteRouteOnlyPath.SYSTEM_METRICS,
                            AbsoluteRouteOnlyPath.SYSTEM_AGENTS,
                            AbsoluteRouteOnlyPath.SYSTEM_LICENSE,
                            AbsoluteRouteOnlyPath.SYSTEM_INFORMATION,
                        ]}
                    >
                        <SystemStatusTabs />
                        <Switch onNotFound={AbsoluteRouteOnlyPath.SYSTEM_LICENSE}>
                            <Route path={AbsoluteRouteOnlyPath.SYSTEM_CONTAINERS}>
                                <SystemContainers />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SYSTEM_INTERNET_CONNECTIVITY}>
                                <SystemInternetConnectivity />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SYSTEM_METRICS}>
                                <SystemMetrics />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SYSTEM_AGENTS}>
                                <SystemAgents />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SYSTEM_LICENSE}>
                                <SystemLicense />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SYSTEM_INFORMATION}>
                                <InformationPage />
                            </Route>
                        </Switch>
                    </Route>
                    <Route path={AbsoluteRoutePathWithId.SYSTEM_CONTAINER}>
                        <SystemContainerDetails />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SYSTEM_MAINTENANCE}>
                        <Maintenance />
                    </Route>

                    {/* Data */}

                    <Route path={AbsoluteRouteOnlyPath.DATA}>
                        <Redirect to={AbsoluteRouteOnlyPath.DATA_EXPLORER} />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.DATA_EXPLORER}>
                        <Explorer />
                    </Route>

                    {/* Services */}

                    <Route path={AbsoluteRouteOnlyPath.SERVICES}>
                        <Redirect to={AbsoluteRouteOnlyPath.SERVICES_OVERVIEW} />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICES_OVERVIEW}>
                        <Services />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICES_TEMPLATE_EDIT} rightContent={<ServiceTemplateOutput />}>
                        <EditTemplateForm />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_SERVER, AbsoluteRoutePathWithId.SERVICES_RESOURCES_SERVER]}>
                        <ServerDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONTAINER, AbsoluteRoutePathWithId.SERVICES_RESOURCES_CONTAINER]}>
                        <ServiceContainerDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_VOLUME, AbsoluteRoutePathWithId.SERVICES_RESOURCES_VOLUME]}>
                        <VolumeDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONNECTION, AbsoluteRoutePathWithId.SERVICES_RESOURCES_CONNECTION]}>
                        <ConnectionDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_ENDPOINT, AbsoluteRoutePathWithId.SERVICES_RESOURCES_ENDPOINT]}>
                        <EndpointDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_MAPPING, AbsoluteRoutePathWithId.SERVICES_RESOURCES_MAPPING]}>
                        <MappingDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE, AbsoluteRoutePathWithId.SERVICES_RESOURCES_SERVICE]}>
                        <ServiceDetails />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICES_LOGS}>
                        <ServiceLogs />
                    </Route>
                    <Route
                        paths={[
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_LINKS,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_SERVERS,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONTAINERS,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_VOLUMES,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONNECTIONS,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_ENDPOINTS,
                            AbsoluteRouteOnlyPath.SERVICES_RESOURCES_MAPPINGS,
                        ]}
                    >
                        <ResourcesTabs />
                        <Switch onNotFound={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_LINKS}>
                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_LINKS}>
                                <ServiceLinks />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_SERVERS}>
                                <Servers />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONTAINERS}>
                                <ServiceContainersTable />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_VOLUMES}>
                                <Volumes />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONNECTIONS}>
                                <Connections />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_ENDPOINTS}>
                                <Endpoints />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.SERVICES_RESOURCES_MAPPINGS}>
                                <Mappings />
                            </Route>
                        </Switch>
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICES_CATALOG}>
                        <ServicesCatalog />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICES_RULE_ENGINE_SANDBOX}>
                        <RuleEngineSandbox Editor={JSONEditor} />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICES_WORKBENCH}>
                        <Workbench />
                    </Route>

                    {/* User */}

                    <Route path={AbsoluteRouteOnlyPath.USER}>
                        <Redirect to={AbsoluteRouteOnlyPath.USER_MANAGEMENT} />
                    </Route>
                    <Route
                        paths={[
                            AbsoluteRouteOnlyPath.USER_MANAGEMENT,
                            AbsoluteRouteOnlyPath.USER_MANAGEMENT_USERS,
                            AbsoluteRouteOnlyPath.USER_MANAGEMENT_ROLES,
                            AbsoluteRouteOnlyPath.USER_MANAGEMENT_PERMISSIONS,
                        ]}
                    >
                        <Switch onNotFound={AbsoluteRouteOnlyPath.USER_MANAGEMENT_USERS}>
                            <Route path={AbsoluteRouteOnlyPath.USER_MANAGEMENT_USERS}>
                                <UsersPage />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.USER_MANAGEMENT_ROLES}>
                                <RolesPage />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.USER_MANAGEMENT_PERMISSIONS}>
                                <PermissionsPage />
                            </Route>
                        </Switch>
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.USER_CLIENT_REGISTRY}>
                        <ClientRegistry />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.USER_CERTIFICATES}>
                        <Certificates />
                    </Route>

                    {/* Settings */}

                    <Route path={AbsoluteRouteOnlyPath.SETTINGS}>
                        <Redirect to={AbsoluteRouteOnlyPath.SETTINGS_LOGIN} />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SETTINGS_LOGIN}>
                        <LoginSettings />
                    </Route>
                </CybusRouter>
            </HashRouter>
            <VersionMismatchNotifier />
        </>
    )
}
