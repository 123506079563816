import type { Mutable, NonUndefined } from 'utility-types'

import type { ArrayType, OnlyDeepPrimitive, SubProperty } from '../../../../utils'

import type { paths } from './Raw'

export type RSTPaths = keyof paths

type RawHttpMethod<P extends RSTPaths> = Extract<keyof paths[P], string>

export type RSTHttpMethod<P extends RSTPaths> = Uppercase<RawHttpMethod<P>>

type StringifiedParameter<T> = T extends string | boolean | number ? `${T}` : T extends any[] | readonly any[] ? ArrayType<Mutable<T>>[] : never
type StringifiedParameters<T> = { [P in keyof T]: StringifiedParameter<T[P]> }
type InternalParameters<P extends RSTPaths> = NonUndefined<SubProperty<SubProperty<SubProperty<paths[P], RawHttpMethod<P>>, 'parameters'>, 'query'>>

export type RSTQueryParameters<P extends RSTPaths> = StringifiedParameters<InternalParameters<P>>

export type RSTResponseStatuses<P extends RSTPaths> = Extract<keyof paths[P][RawHttpMethod<RSTPaths>]['responses'], number>

type Response<P extends RSTPaths> = paths[P][RawHttpMethod<RSTPaths>]['responses'][RSTResponseStatuses<P>]
type ResponseContent<P extends RSTPaths> = SubProperty<Response<P>, 'content'>

export type RSTJsonResponseContent<P extends RSTPaths> = OnlyDeepPrimitive<SubProperty<ResponseContent<P>, 'application/json'>>
export type RSTEventStreamResponseContent<P extends RSTPaths> = OnlyDeepPrimitive<SubProperty<ResponseContent<P>, 'text/event-stream'>>
