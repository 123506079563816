import React, { type FC, useEffect } from 'react'
import { createExtendedState } from 'react-extended-state'
import { useParams } from 'react-router-dom'

import type { PartialReadonlyRecord } from '../../../../utils'

import { mapState } from '../utils'
import type { AbsoluteRouteOnlyPath, AbsoluteRoutePath, RouterState } from '../Domain'
import { useHasCapabilities } from '../Hooks'
import { DeniedAccess } from '../DeniedAccess'

const { Provider, useExtendedStateDispatcher, useExtendedState } = createExtendedState<RouterState>()

type RawArgs = PartialReadonlyRecord<Extract<keyof RouterState, 'resourceId' | 'serviceId'>, string>

/**
 * This Route
 * - checks and handles permissions of the user
 * - Updates the `ConnectwareRouter` with the up to date id/path
 *      (These props need to be updated so the whole application has the correct routing info without re-rendering all componets again)
 */
export const ConnectwareRoute: FC<Readonly<{ path: AbsoluteRoutePath }>> = ({ path, children }) => {
    const hasRouteCapabilities = useHasCapabilities()
    const dispatch = useExtendedStateDispatcher()

    const { resourceId, serviceId } = useParams<RawArgs>()
    const state = mapState(path, resourceId || null, serviceId || null)

    /**
     * Update the whole CybusRouter
     */
    useEffect(() => dispatch(state), [state.path, state.resourceId, state.serviceId])

    /**
     * Re-declaring the provider ensures that the most up to date information will always be available
     */
    return <Provider value={state}>{hasRouteCapabilities(path) ? <>{children}</> : <DeniedAccess path={path} />}</Provider>
}

export const useConnectwareRouting = (): RouterState => useExtendedState((s) => s)

export const ConnectwareRouter: FC<{ initial: AbsoluteRouteOnlyPath }> = ({ initial, children }) => (
    <Provider value={{ path: initial, resourceId: null, serviceId: null }}>{children}</Provider>
)
