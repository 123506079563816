import { areArrayEquals, objectKeys, type ReadonlyRecord } from '..'

export const areRecordsEquals = <K extends string | number, V>(
    a: ReadonlyRecord<K, V>,
    b: ReadonlyRecord<K, V>,
    config: { equals?: (a: V, b: V, key: K) => boolean } = {}
): boolean => {
    if (a === b) {
        return true
    }

    const aKeys = objectKeys(a)
    const bKeys = objectKeys(b)

    if (!areArrayEquals(aKeys, bKeys)) {
        return false
    }

    const { equals } = config

    for (let i = 0; i < aKeys.length; i++) {
        const currKey = aKeys[i] as K
        const aValue = a[currKey]
        const bValue = b[currKey]

        if (aValue !== bValue && (equals ? !equals(aValue, bValue, currKey) : true)) {
            return false
        }
    }

    return true
}

export const areObjectsDeepEqual = <V>(a: V, b: V): boolean => {
    if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
        return a === b
    }

    return areRecordsEquals(a, b, { equals: areObjectsDeepEqual })
}
