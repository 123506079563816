import { type AppState, ConnectwareError, ConnectwareErrorType } from '../../domain'

import type {
    ApplicationStateService,
    AuthenticationPersistenceService,
    AuthenticationService,
    BrowserService,
    CertificatesService,
    ClientRegistryService,
    ConfigurationService,
    ConnectwareLogsService,
    ConnectwareResourcesManagementService,
    ConnectwareServicesCatalogService,
    ConnectwareServicesService,
    FileService,
    LoggerService,
    MfaService,
    RuleEngineService,
    SubscriptionsService,
    SystemService,
    TablePersistenceService,
    TopicsPersistenceService,
    TopicsService,
    TranslationService,
    UserService,
} from '..'

export type UsecaseServices = Readonly<{
    /**
     * General dependencies
     */
    logger: LoggerService
    applicationState: ApplicationStateService
    browserService: BrowserService
    configurationService: ConfigurationService
    systemService: SystemService
    fileService: FileService
    translationService: TranslationService
    tablePersistenceService: TablePersistenceService

    /**
     * Authentication
     */
    authenticationService: AuthenticationService
    authenticationPersistenceService: AuthenticationPersistenceService
    userService: UserService
    clientRegistryService: ClientRegistryService
    mfaService: MfaService

    /**
     * Resources
     */
    subscriptionsService: SubscriptionsService
    connectwareServicesService: ConnectwareServicesService
    connectwareLogsService: ConnectwareLogsService
    connectwareServicesCatalogService: ConnectwareServicesCatalogService
    connectwareResourcesManagementService: ConnectwareResourcesManagementService

    /**
     * Topics
     */
    topicsService: TopicsService
    topicsPersistenceService: TopicsPersistenceService

    /**
     * Rule Engine
     */
    ruleEngineService: RuleEngineService

    /**
     * Certificates
     */
    certificatesService: CertificatesService
}>

/**
 * The registry of all created usecases, so they can call each other internally if needed
 */
export type UsecasesRegistry = Map<typeof Usecase, Usecase>

/**
 * This is a base usecase
 *
 * Business related code should be run only in classes that extend this one
 */
export class Usecase {
    constructor (private readonly registry: UsecasesRegistry, private readonly services: UsecaseServices) {
        this.setUsecase(this.constructor as typeof Usecase)
    }

    protected get authenticationPersistenceService (): UsecaseServices['authenticationPersistenceService'] {
        return this.services.authenticationPersistenceService
    }

    protected get authenticationService (): UsecaseServices['authenticationService'] {
        return this.services.authenticationService
    }

    protected get browserService (): UsecaseServices['browserService'] {
        return this.services.browserService
    }

    protected get certificatesService (): UsecaseServices['certificatesService'] {
        return this.services.certificatesService
    }

    protected get configurationService (): UsecaseServices['configurationService'] {
        return this.services.configurationService
    }

    protected get connectwareLogsService (): UsecaseServices['connectwareLogsService'] {
        return this.services.connectwareLogsService
    }

    protected get connectwareResourcesManagementService (): UsecaseServices['connectwareResourcesManagementService'] {
        return this.services.connectwareResourcesManagementService
    }

    protected get connectwareServicesCatalogService (): UsecaseServices['connectwareServicesCatalogService'] {
        return this.services.connectwareServicesCatalogService
    }

    protected get connectwareServicesService (): UsecaseServices['connectwareServicesService'] {
        return this.services.connectwareServicesService
    }

    protected get fileService (): UsecaseServices['fileService'] {
        return this.services.fileService
    }

    protected get logger (): UsecaseServices['logger'] {
        return this.services.logger
    }

    protected get ruleEngineService (): UsecaseServices['ruleEngineService'] {
        return this.services.ruleEngineService
    }

    protected get subscriptionsService (): UsecaseServices['subscriptionsService'] {
        return this.services.subscriptionsService
    }

    protected get systemService (): UsecaseServices['systemService'] {
        return this.services.systemService
    }

    protected get topicsService (): UsecaseServices['topicsService'] {
        return this.services.topicsService
    }

    protected get topicsPersistenceService (): UsecaseServices['topicsPersistenceService'] {
        return this.services.topicsPersistenceService
    }

    protected get translationService (): UsecaseServices['translationService'] {
        return this.services.translationService
    }

    protected get userService (): UsecaseServices['userService'] {
        return this.services.userService
    }

    protected get clientRegistryService (): UsecaseServices['clientRegistryService'] {
        return this.services.clientRegistryService
    }

    protected get tablePersistenceService (): UsecaseServices['tablePersistenceService'] {
        return this.services.tablePersistenceService
    }

    protected get mfaService (): UsecaseServices['mfaService'] {
        return this.services.mfaService
    }

    private setUsecase<T extends typeof Usecase> (usecaseConstructor: T): void {
        this.registry.set(usecaseConstructor, this)
    }

    /**
     * To get any other registered usecases
     */
    protected getUsecase<T extends typeof Usecase> (usecaseConstructor: T): InstanceType<T> {
        const usecase = this.registry.get(usecaseConstructor)

        if (!usecase) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Could not find usecase on registry', { name: usecaseConstructor.name })
        }

        return usecase as InstanceType<T>
    }

    protected setState (state: Partial<AppState>): void {
        this.services.applicationState.setState(state)
    }

    protected getState (): AppState {
        return this.services.applicationState.getState()
    }

    protected subscribeToState (filter: (previous: AppState, current: AppState) => boolean, subscriber: VoidFunction): VoidFunction {
        return this.services.applicationState.subscribe(filter, subscriber)
    }
}
