import { ConnectwareError, ConnectwareErrorType } from '..'

export class CommissioningFileParsingError extends ConnectwareError {
    static override is (e: unknown): e is CommissioningFileParsingError {
        return e instanceof CommissioningFileParsingError
    }

    constructor (
        /**
         * The list of errors obtained while parsing something
         *
         * Map<"property path" | null, "error message">
         */
        readonly parseErrors: Map<string | null, string>
    ) {
        super(ConnectwareErrorType.BAD_REQUEST, 'Given commissioning file is invalid', { errors: Array.from(parseErrors) })
    }
}
