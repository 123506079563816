import type { Optional } from 'utility-types'
import React, { type FC } from 'react'

import { type AppState, type CybusMapping, ResourceType, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Mapping = Pick<CybusMapping, 'id' | 'name' | 'service'>

type Props = Readonly<{ mappingSelector: (s: AppState, id: string[]) => Mapping[], onConfirmed: VoidFunction }>

export const EnableModal: FC<Optional<Props, 'onConfirmed'>> = ({ mappingSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkEnableMappingsUsecase')

    return (
        <EnableResourceModal
            title={Translation.ENABLE_MAPPINGS}
            subtitle={Translation.ENABLE_MAPPINGS_SUBTITLE}
            confirm={Translation.ENABLE}
            resourceSelector={mappingSelector}
            dimension={ResourceType.MAPPING}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectMappings(null)}
            onConfirm={async () => {
                await usecase.enable()
                onConfirmed?.()
            }}
        />
    )
}

export const DisableModal: FC<Optional<Props, 'onConfirmed'>> = ({ mappingSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDisableMappingsUsecase')

    return (
        <DisableResourceModal
            title={Translation.DISABLE_MAPPINGS}
            subtitle={Translation.DISABLE_MAPPINGS_SUBTITLE}
            confirm={Translation.DISABLE}
            resourceSelector={mappingSelector}
            dimension={ResourceType.MAPPING}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectMappings(null)}
            onConfirm={async () => {
                await usecase.disable()
                onConfirmed?.()
            }}
        />
    )
}

export const DeleteModal: FC<Props> = ({ mappingSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDeleteMappingsUsecase')

    return (
        <DeleteResourceModal
            title={Translation.DELETE_MAPPINGS}
            subtitle={Translation.DELETE_MAPPINGS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={mappingSelector}
            dimension={ResourceType.MAPPING}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectMappings(null)}
            onConfirm={async () => {
                await usecase.delete()
                onConfirmed()
            }}
        />
    )
}
