import { colors, createTheme, type Shadows, type SimplePaletteColorOptions } from '@mui/material'
import type { SnackbarProviderProps } from 'notistack'

import { backgrounds, divider, table, text } from '../config/alster/colors.json'
import { elevation } from '../config/alster/style.json'

/**
 * Colors from Alster
 */
const primary: SimplePaletteColorOptions = { main: '#15415E', dark: '#0e507a', contrastText: '#FFFFFF' }

export const SnackbarConfig: Required<Pick<SnackbarProviderProps, 'className'>> & SnackbarProviderProps = {
    className: 'status-notification',
    maxSnack: 5,
    preventDuplicate: true,
}

export const cybusTheme = createTheme({
    palette: {
        ...colors,
        primary,
        secondary: { main: '#f5a119', contrastText: '#FFFFFF' },
        background: { default: '#FFFFFF', ...backgrounds },
        info: { main: '#268BD2' },
        text,
        divider: divider.divider,
    },
    components: {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: '1px solid',
                    borderColor: table.tableborder,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    ':last-child>td': {
                        borderBottom: 'none',
                    },
                },
                head: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: table.cellborder,
                    borderRightColor: table.cellborder,
                },
                sizeMedium: {
                    padding: '16px',
                },
                sizeSmall: {
                    padding: '0px 16px',
                },
                head: {
                    backgroundColor: table.headerbackground,
                    lineHeight: '22px',
                },
                body: {
                    lineHeight: '16px',
                },
                paddingCheckbox: {
                    padding: '0 13px',
                    width: 'auto',
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    verticalAlign: 'unset',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: '16px',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                container: ({ theme }) => ({
                    /** Leave space for the notification so that the dialog buttons are still visible */
                    [theme.breakpoints.down('xl')]: {
                        [`body:has(.${SnackbarConfig.className}) &`]: {
                            justifyContent: 'start',
                            maxWidth: '70vw',
                        },
                    },
                }),
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            /** Original value was 1200 */
            lg: 1280,
            xl: 1536,
        },
    },
    shadows: elevation as Shadows,
})
