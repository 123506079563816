import type { ReadonlyRecord } from '../../utils'
import type { Translation } from '../../domain'

/**
 * How the list should formatted
 *
 * @default 'list'
 * @example 'list' -> joins with 'and'
 * @example 'options' -> joins with 'or'
 */
export type ListTranslationOption = 'list' | 'options'

export interface TranslationService {
    /**
     * Formats the given values into a list
     */
    list(values: string[], type?: ListTranslationOption): string
    translate(id: Translation, values?: ReadonlyRecord<string, unknown>): string
}
