import { useMemo } from 'react'

import type { NonEmptyArray } from '../../../../utils'

import { ConnectwareError, type CybusServiceForm, selectServiceForm, type Translation } from '../../../../domain'

import type { Usecases } from '../../../../application'

import { useAppState, useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'

import { type ModalConfig, useModalConfig } from './Context'

const useUsecase = (): Usecases[ModalConfig['usecase']] => useAppUsecase(useModalConfig('usecase'))

const useServiceForm = <T, D>(selector: (form: CybusServiceForm) => T, defaultValue: D): T | D =>
    useAppState((s) => {
        const form = selectServiceForm(s)
        return form ? selector(form) : defaultValue
    })

export const useTitle = (): Translation => useModalConfig('title')
export const useConfigurationTitle = (): Translation => useModalConfig('configurationTitle')
export const useConfirmationButtonText = (): Translation => useModalConfig('confirmationButtonText')

export const isOpen = (): boolean => useServiceForm((f) => f !== null, false)
export const useRequesting = (): CybusServiceForm['requesting'] => useServiceForm((f) => f.requesting, false)
export const useManaged = (): CybusServiceForm['managed'] => useServiceForm((f) => f.managed, null)
export const useFileContent = (): string | null => useServiceForm((f) => (typeof f.file === 'string' ? f.file : null), null)
export const useError = (): ConnectwareError | null =>
    useServiceForm(({ requesting, file, schema }) => [requesting, file, schema].find((e): e is ConnectwareError => ConnectwareError.is(e)) || null, null)

export const useSchema = (): CybusServiceForm['schema'] => useServiceForm((f) => f.schema, null)
export const useParameters = (): CybusServiceForm['parameters'] => useServiceForm((f) => f.parameters, null)
export const useHasParameters = (): boolean => useServiceForm((f) => Boolean(f.parameters), false)
export const useId = (): CybusServiceForm['id'] => useServiceForm((f) => f.id, null)

export const useCancel = (): VoidFunction => {
    const usecase = useUsecase()
    return () => usecase.cancel()
}
export const useConfirm = (): VoidFunction => {
    const usecase = useUsecase()
    const onConfirmed = useModalConfig('onConfirmed')

    const [confirm] = useAsyncCallback(async () => {
        const id = await usecase.confirm()
        if (id !== null && onConfirmed) {
            onConfirmed(id)
        }
    }, [onConfirmed, usecase])

    return confirm
}

export const useSupportedCommissioningFileTypes = (): NonEmptyArray<string> => {
    const usecase = useUsecase()
    return useMemo(() => usecase.getSupportedCommissioningFileTypes(), [usecase])
}

export const useOnFileChange = (): ((file: CybusServiceForm['file']) => void) => {
    const usecase = useUsecase()
    const [onFileChange] = useAsyncCallback((file: CybusServiceForm['file']) => usecase.updateCommissioningFile(file))
    return onFileChange
}

export const useIsIdEditable = (): boolean => {
    const usecase = useUsecase()
    return useMemo(() => usecase.isCreating(), [usecase])
}

export const useUpdateServiceParameters = (): ((id: CybusServiceForm['id'], newParameters: CybusServiceForm['parameters']) => void) => {
    const usecase = useUsecase()
    return (...params) => usecase.updateServiceParameters(...params)
}

export const useFile = (): File | null => {
    const [type] = useSupportedCommissioningFileTypes()
    const id = useId()
    const content = useFileContent()

    return useMemo(() => (id && content ? new File([content], `${id}.${type}`) : null), [type, id, content])
}

export const useIsDisabled = (): boolean => {
    const usecase = useUsecase()
    const id = useId()
    const requesting = useRequesting()
    const parameters = useParameters()
    return useMemo(() => !usecase.isConfirmationRequestValid(), [usecase, id, requesting, parameters])
}
