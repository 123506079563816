import { createInterval, executeOnce } from '.'

type Listener<E> = (e: E) => void

export class EventListener<E> {
    private readonly listeners = new Set<Listener<E>>()

    constructor (private readonly shouldTrigger: (event: E) => boolean = () => true) {}

    get size (): number {
        return this.listeners.size
    }

    trigger (event: E): void {
        if (this.shouldTrigger(event)) {
            this.listeners.forEach((l) => l(event))
        }
    }

    off (listener: Listener<E>): void {
        this.listeners.delete(listener)
    }

    on (listener: Listener<E>): VoidFunction {
        this.listeners.add(listener)
        return () => this.off(listener)
    }
}

export class ChangeEventListener<Value> {
    private readonly listener = new EventListener<[Value, Value]>()

    constructor (private currentValue: Value) {}

    get value (): Value {
        return this.currentValue
    }

    onChange (listener: (state: [current: Value, next: Value]) => void): VoidFunction {
        return this.listener.on(listener)
    }

    change (value: Value): void {
        this.listener.trigger([this.currentValue, value])
        this.currentValue = value
    }
}

export class BulkEvent<Value> {
    private readonly listeners = new EventListener<Value[]>()

    private readonly triggerEager: VoidFunction = executeOnce(() => this.eager && this.trigger())

    private cancelInterval: VoidFunction | null = null

    private values: Value[] = []

    /**
     * @param cycle in milliseconds
     *  @see https://developer.mozilla.org/en-US/docs/Web/API/setInterval#sect1
     * @param eager if eager, will trigger the `onCycled` as soon as a value is appended
     */
    constructor (private readonly cycle: number, private readonly eager: boolean = false) {
        this.refreshCycle()
    }

    private trigger (): void {
        const values = this.values
        this.values = []
        this.listeners.trigger(values)
    }

    private refreshCycle (): void {
        /**
         * If there are listeners, and no interval
         * Set them up
         */
        if (this.listeners.size && !this.cancelInterval) {
            this.cancelInterval = createInterval(() => this.trigger(), this.cycle)
        }

        /**
         * If there are no listeners but an interval
         */
        if (!this.listeners.size && this.cancelInterval) {
            this.cancelInterval()
            this.cancelInterval = null
        }
    }

    /**
     * The callback will be triggered even when there is nothing in the batch
     */
    onCycled (listener: (state: Value[]) => void): VoidFunction {
        const unsub = this.listeners.on(listener)
        this.refreshCycle()
        return () => {
            unsub()
            this.refreshCycle()
        }
    }

    append (value: Value): void {
        this.values.push(value)
        this.triggerEager()
    }
}
