import React, { type FC } from 'react'

import { objectKeys } from '../../../../utils'
import { type CybusPermission, Translation } from '../../../../domain'

import { useAppUsecase } from '../../State'
import { useTranslator } from '../../Internationalization'

import { Page } from '../Page'
import { useContextLabel } from './Hooks'

export const PermissionsPage: FC = () => {
    const translator = useTranslator()
    const translateContext = useContextLabel()
    const viewPermissionUsecase = useAppUsecase('viewPermissionUsecase')

    return (
        <Page<CybusPermission>
            selected="permissions"
            columns={{
                resource: { label: translator.formatTranslation(Translation.RESOURCE, { count: 0 }) },
                context: {
                    label: Translation.CONTEXT,
                    customCellRender: translateContext,
                },
                users: {
                    label: translator.formatTranslation(Translation.USER, { count: 2 }),
                    customCellRender: (users) => translator.formatTranslation(Translation.ACTIVE_USER, { count: objectKeys(users).length }),
                },
                roles: {
                    label: translator.formatTranslation(Translation.ROLE, { count: 2 }),
                    customCellRender: (roles) => translator.formatTranslation(Translation.ACTIVE_ROLE, { count: objectKeys(roles).length }),
                },
            }}
            notFound={Translation.NO_PERMISSIONS_FOUND}
            onRowClick={(permission) => viewPermissionUsecase.show(permission)}
        />
    )
}
