import { type CybusService, StatusType } from '../../../../domain'
import type { CreatedCommissioningResourceProperties, CybusServiceProxyInfo } from '../..'

type Info = Omit<CybusServiceProxyInfo, 'state' | 'isDeviated'>

export const mapBaseService = ({ id, name, version }: Info, status: StatusType, isDeviated: boolean): Omit<CybusService, 'links'> => ({
    id,
    name,
    version: version || null,
    status,
    deviation: isDeviated ? StatusType.DEVIATED : status === StatusType.DISABLED ? StatusType.NOT_ENABLED : StatusType.NO_DEVIATION,
})

export const mapService = (
    info: Info,
    status: StatusType,
    isDeviated: boolean,
    links: CreatedCommissioningResourceProperties<'Cybus::Link'>[]
): CybusService => ({
    ...mapBaseService(info, status, isDeviated),
    links: links.map(({ physicalId: { href, name } }) => ({ href, name })),
})
