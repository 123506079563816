import { areCybusDetailedConnectionEquals, Capability, type CybusDetailedConnection } from '../../../../../../domain'
import { type ConnectionProxyParams, mapDetailedConnection, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedConnectionStrategy extends BaseSubscriptionStrategy<'connection'> {
    readonly requiredPermissions = [Capability.CONNECTION_READ]

    protected override readonly areEquals = areCybusDetailedConnectionEquals

    override retrieveOne (id: string): Promise<CybusDetailedConnection> {
        return this.retrieve({
            capability: Capability.CONNECTION_READ,
            method: 'GET',
            path: '/api/connections/+',
            pathParams: [id],
            mapper: (data: ConnectionProxyParams) => {
                /**
                 * @deprecated update code block so status is fetched externally
                 * @see https://cybusio.atlassian.net/browse/CC-1251
                 */
                const currentStatus = mapToStatusType(data.currentState)

                return mapDetailedConnection(data, currentStatus)
            },
            handleNotFound: true,
        })
    }
}
