import { Droppable, EventListener, objectKeys } from '../../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../application'

import { listenToRemote, type ManagedVrpcRemote, VrpcDomainType, type VrpcRemoteManager } from '../../../utils'
import type { VrpcSubscriptionHandler } from '../handlers'
import type { DataMapper } from '.'

/**
 * This abstract class contains some default behaviour to map out data from a VrpcRemoteManager
 * @see {VrpcRemoteManager}
 */
export abstract class VrpcRemoteManagerDataMapper<Handler extends VrpcSubscriptionHandler<any, any>, Value> implements DataMapper<Value> {
    private readonly droppable = new Droppable()

    private readonly errorListeners = new EventListener<ConnectwareError>()

    private readonly changeListeners = new EventListener<void>()

    constructor (protected readonly handler: Handler, protected readonly filter: SubscriptionFilterArgs) {
        const { optionalFilters, requiredFilters } = this.handler
        const usedFilters = objectKeys(this.filter)

        if (usedFilters.some((f) => !optionalFilters.includes(f)) || !requiredFilters.every((f) => usedFilters.includes(f))) {
            /**
             * Either
             *  - The filters used are not in the options that can be used
             *  - The filters do not include an option that is required
             */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Filter configuration not supported', {
                handler: this.handler.constructor.name,
                optionalFilters,
                requiredFilters,
                filter: this.filter,
            })
        }
    }

    protected get remoteDomains (): VrpcDomainType[] {
        return [VrpcDomainType.DEFAULT]
    }

    private async retrieveConnectedRemotes (remote: VrpcRemoteManager): Promise<ManagedVrpcRemote[]> {
        /** Retrieve all the domains relevant for this subscription */
        const domains = new Set(this.remoteDomains)

        /** Get all remotes at once */
        const remoteTupples = await Promise.all(Array.from(domains).map((domain) => remote.getManagedVrpcRemote(domain)))

        const remotes: ManagedVrpcRemote[] = []

        remoteTupples.forEach(([remote, notifyDisuse]) => {
            remotes.push(remote)
            this.onDrop(notifyDisuse)
        })

        return remotes
    }

    protected triggerError (error: ConnectwareError): void {
        this.errorListeners.trigger(error)
    }

    /**
     * In case new data is detected
     */
    protected triggerChange (): void {
        this.changeListeners.trigger()
    }

    protected onDrop (callback: VoidFunction): void {
        this.droppable.onDrop(callback)
    }

    /**
     * Called for each yielded remote
     */
    protected abstract feedManagedRemote (remote: ManagedVrpcRemote): void

    /**
     * The generated value
     */
    protected abstract getValue (): Value

    /**
     * The basic flow that deals with getting the remote manager is already dealt with
     * So only internal calls to each remote needs to taken care of
     */
    feed (remoteManager: VrpcRemoteManager): void {
        /** Get connected remotes */
        void this.retrieveConnectedRemotes(remoteManager)
            .then((promise) => Promise.all(promise))
            .then((remotes) =>
                remotes.forEach((remote) => {
                    /** For each managed remote, listen to errors */
                    this.onDrop(
                        listenToRemote(remote, 'error', (err) => {
                            switch (err.message) {
                                case 'Connection refused: Not authorized':
                                    this.triggerError(new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'Could not authenticate on VRPC'))
                                    break
                                case 'Connection refused: Server unavailable':
                                    this.triggerError(new ConnectwareError(ConnectwareErrorType.SERVER_NOT_AVAILABLE, 'Server is not available'))
                                    break
                                default:
                                    this.triggerError(
                                        new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected error thrown by VRPC', { message: err.message })
                                    )
                            }
                        })
                    )

                    /** For each managed remote, let specific implementation deal with them as they wish */
                    void this.feedManagedRemote(remote)
                })
            )
    }

    onError (listener: (error: ConnectwareError) => void): VoidFunction {
        return this.errorListeners.on(listener)
    }

    onChange (listener: (data: Value) => void): VoidFunction {
        return this.changeListeners.on(() => listener(this.getValue()))
    }

    off (): void {
        this.droppable.drop()
    }
}
