import { executeOnce } from '../../../utils'

import {
    type AuthenticationRequest,
    type AuthenticationStatus,
    type AuthenticationWithBackupcode,
    type AuthenticationWithOtpRequest,
    Capability,
    ConnectwareError,
    ConnectwareErrorType,
    InvalidOtpError,
    MfaRequiredError,
    MfaSetupRequiredError,
    SessionFromTokenNotFoundError,
    TokenExpiredError,
} from '../../../domain'

import type { AuthenticationResponse, AuthenticationService, SessionResponse } from '../../../application'

import type { OrchestratorResponse } from '../../Connectware'

import type {
    SessionResponse as BackendSessionResponse,
    InvalidOTPResponse,
    LoginRequest,
    LoginResponse,
    MfaResponse,
    Permission,
    UserBannedResponse,
} from './Types'
import { type CapabilityCheckers, mapLoginResponse, mapSessionResponse } from './mappers'
import { FetchConnectwareHTTPService } from '../Base'

const createIgnorer =
    <T>(value: T): (() => T) =>
    () =>
        value
const ignoreOrchestratorError = createIgnorer(null)
const ignoreMfaError = createIgnorer(false)

export class ConnectwareHTTPAuthenticationService extends FetchConnectwareHTTPService implements AuthenticationService {
    /**
     * These are preflight checks to validate how is Connectware running
     * They assist the service implementation mappers to drop certain capabilities in case the system can not
     * provide them
     */
    protected readonly capabilitiesCheckers = {
        orchestrator: executeOnce((token: string) =>
            this.request({
                capability: null,
                method: 'GET',
                path: '/api/containers/orchestrator',
                authenticate: token,
                handlers: {
                    200: (response) => response.getJson<OrchestratorResponse>().then(({ orchestrator }) => orchestrator),
                    400: ignoreOrchestratorError,
                    403: ignoreOrchestratorError,
                    503: ignoreOrchestratorError,
                },
            })
        ),
        mfa: executeOnce((token: string) =>
            this.request({
                capability: null,
                method: 'GET',
                path: '/api/auth/mfa',
                authenticate: token,
                handlers: {
                    /**
                     * It should return a boolean because
                     * 'unavailableMfaConfiguration' in src/infrastructure/Connectware/Capabilities.ts
                     * handles only two cases (true/false).
                     * If, for some reason, 'enabled' returns something other than a Boolean (e.g., null or undefined),
                     * then MFA could mistakenly be activated.
                     * */
                    200: (response) => response.getJson<MfaResponse>().then(({ enabled }) => Boolean(enabled)),
                    403: ignoreMfaError,
                    503: ignoreMfaError,
                },
            })
        ),
    }

    private createAuthenticationCapabilityCheckers (token: string): CapabilityCheckers {
        return { orchestrator: () => this.capabilitiesCheckers.orchestrator(token), isMfaActive: () => this.capabilitiesCheckers.mfa(token) }
    }

    private mfaLogin (request: AuthenticationWithOtpRequest | AuthenticationWithBackupcode): Promise<LoginResponse> {
        return this.request({
            capability: Capability.USE_MFA,
            method: 'POST',
            path: '/api/mfa/login',
            body: 'backupCode' in request ? request : { ...request, otp: request.otp.join('') },
            authenticate: false,
            handlers: {
                200: (response) => response.getJson<LoginResponse>(),
                400: () => new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'There were issues while authenticating the user'),
                401: async (response) => {
                    const { banDurationMinutes, triesLeft, error } = await response.getJson<InvalidOTPResponse>()
                    if (error === 'invalid') {
                        return new TokenExpiredError()
                    }

                    if (error === 'wrong OTP') {
                        return new InvalidOtpError({ minutesUntilRetry: banDurationMinutes, triesLeft })
                    }

                    return new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'Invalid backupCode')
                },
                403: async (response) => {
                    const { minutesUntilBanEnd } = await response.getJson<UserBannedResponse>()
                    return new InvalidOtpError({ minutesUntilRetry: minutesUntilBanEnd, triesLeft: null })
                },
                500: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, '500 Internal Server Error'),
            },
        })
    }

    private login (request: LoginRequest): Promise<LoginResponse> {
        return this.request<LoginRequest, LoginResponse>({
            capability: null,
            method: 'POST',
            path: '/api/login',
            body: request,
            authenticate: false,
            handlers: {
                200: async (response) => response.getJson<LoginResponse>(),
                401: () => new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'There were issues while authenticating the user'),
                403: () => new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'There were issues while authenticating the user'),
            },
        })
    }

    private fetchBackendSession (token: string): Promise<BackendSessionResponse> {
        return this.request({
            capability: null,
            method: 'GET',
            path: '/api/session',
            authenticate: token,
            handlers: {
                200: (response) => response.getJson<BackendSessionResponse>(),
                401: () => new SessionFromTokenNotFoundError(),
                403: () => new SessionFromTokenNotFoundError(),
            },
        })
    }

    async authenticate (request: AuthenticationRequest | AuthenticationWithOtpRequest | AuthenticationWithBackupcode): Promise<AuthenticationResponse> {
        const backendResponse = await ('secret' in request ? this.mfaLogin(request) : this.login(request))

        if ('needsMfa' in backendResponse) {
            throw new MfaRequiredError(backendResponse.secret)
        }

        const loginResponse = await mapLoginResponse(backendResponse, this.createAuthenticationCapabilityCheckers(backendResponse.token))

        if ('enforceMFAEnrollment' in backendResponse) {
            throw new MfaSetupRequiredError(loginResponse)
        }

        return loginResponse
    }

    fetchSession (token: string): Promise<SessionResponse> {
        return this.fetchBackendSession(token).then((response) => mapSessionResponse(response, this.createAuthenticationCapabilityCheckers(token)))
    }

    fetchAuthenticationStatus (token: string): Promise<AuthenticationStatus> {
        return this.fetchBackendSession(token).then(({ mfa: { enabled, enforced } }) => ({ isMfaEnabled: enabled, isMfaRequired: enforced }))
    }

    fetchSubscribableTopics (): Promise<string[]> {
        return this.request({
            capability: Capability.MINIMUM,
            method: 'GET',
            path: '/api/permissions',
            authenticate: true,
            handlers: {
                200: async (response) => {
                    const data = await response.getJson<Permission[]>()
                    return data.reduce<string[]>((reduced, { context, resource }) => (context === 'mqtt' ? [...reduced, resource] : reduced), [])
                },
            },
        })
    }

    async flushAuthentication (token: string): Promise<void> {
        await this.request({
            capability: null,
            method: 'POST',
            path: '/api/logout',
            body: { token },
            handlers: { 200: () => Promise.resolve() },
        })
    }
}
