import type { ValuesType } from 'utility-types'

import {
    type CommissioningFileField,
    type CommissioningFileValidatedValues,
    type CommissioningFileValue,
    EndpointsCommissioningFileFinder,
} from '../../../../domain'

import { EditServiceTemplateResourceUsecase, type ResourceFinderConstructor } from './Resource'

export class EditServiceTemplateEndpointUsecase extends EditServiceTemplateResourceUsecase {
    protected readonly resourceType: 'endpoints' = 'endpoints'

    protected readonly FinderClass = EndpointsCommissioningFileFinder as typeof EndpointsCommissioningFileFinder & ResourceFinderConstructor

    updateEndpoint<F extends CommissioningFileField> (
        position: number,
        fieldName: keyof ValuesType<CommissioningFileValidatedValues[EditServiceTemplateResourceUsecase['resourceType']]>,
        newValue: CommissioningFileValue<F> | null
    ): void {
        this.updateResource(position, fieldName, newValue)
    }
}
