import { type CybusServer, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase, BulkDisableResourceUsecase, BulkEnableResourceUsecase } from '../Resources'

export class BulkDeleteServersUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.SERVER

    selectServers (ids: CybusServer['id'][] | null): void {
        this.select(ids)
    }

    override delete (): Promise<void> {
        return super.delete()
    }
}

export class BulkEnableServersUsecase extends BulkEnableResourceUsecase {
    protected readonly type = ResourceType.SERVER

    selectServers (ids: CybusServer['id'][] | null): void {
        this.select(ids)
    }

    override enable (): Promise<void> {
        return super.enable()
    }
}

export class BulkDisableServersUsecase extends BulkDisableResourceUsecase {
    protected readonly type = ResourceType.SERVER

    selectServers (ids: CybusServer['id'][] | null): void {
        this.select(ids)
    }

    override disable (): Promise<void> {
        return super.disable()
    }
}
