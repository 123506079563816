import React, { type FC, type ReactNode } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../domain'
import { FormattedTranslation, useTranslator } from '../Internationalization'
import { useSelectedLog, useSelectedLogDispatcher } from './State'

const itemStyle: SxProps<Theme> = { p: 2, wordBreak: 'break-all' }

const Item: FC<Readonly<{ primary: Translation, secondary: ReactNode }>> = ({ primary, secondary }) => (
    <>
        <Grid container sx={itemStyle}>
            <Grid item xs={2}>
                <Typography variant="subtitle2">
                    <FormattedTranslation id={primary} />
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="subtitle2">{secondary}</Typography>
            </Grid>
        </Grid>
        <Divider />
    </>
)

export const Modal: FC = () => {
    const selectLog = useSelectedLogDispatcher()
    const log = useSelectedLog()
    const translator = useTranslator()

    if (log === null) {
        return null
    }

    const close: VoidFunction = () => selectLog(null)

    return (
        <Dialog id="log-details-modal" maxWidth="lg" fullWidth open aria-labelledby={translator.formatTranslation(Translation.LOG_DETAILS)} onClose={close}>
            <DialogTitle variant="h6">{translator.formatTranslation(Translation.LOG_DETAILS)}</DialogTitle>
            <DialogContent>
                <Paper>
                    <Item primary={Translation.TIME} secondary={log.timestamp.getTime()} />
                    {log.pid && <Item primary={Translation.PID} secondary={log.pid} />}
                    {log.hostname && <Item primary={Translation.HOSTNAME} secondary={log.hostname} />}
                    {log.className && <Item primary={Translation.CLASS_NAME} secondary={log.className} />}
                    <Item primary={Translation.MESSAGE} secondary={log.message} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    {translator.formatTranslation(Translation.CLOSE)}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
