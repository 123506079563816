import { isArrayNotEmpty } from '../../../../../../utils'
import type { SubscriptionsTypes } from '../../../../../../application'

import { SERVICE_AGENT } from '../../../../../Connectware'

import type { ServiceProxy } from '../../../../proxies'
import { SERVICE_CLASSNAME } from '../../../../constants'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'
import { VrpcServiceProxyInstanceHandler } from './Service'

type ServiceLinksHandler = VrpcInstanceToListSubscriptionHandler<ServiceProxy, SubscriptionsTypes['servicesLinks']>
type ServiceLinksHandlerArgs = VrpcHandlerMappingPropertiesArgs<ServiceLinksHandler>

export class VrpcLinkedServiceProxyInstanceHandler implements ServiceLinksHandler {
    private readonly instanceMapper = new VrpcServiceProxyInstanceHandler()

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    readonly ignoreInstanceByFilter = null

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    /**
     * @deprecated these handlings will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    async mapToDomain (args: ServiceLinksHandlerArgs['DomainMapperArgs']): Promise<ServiceLinksHandlerArgs['Domain']> {
        const { id, name, links } = await this.instanceMapper.mapToDomain(args)

        if (isArrayNotEmpty(links)) {
            return [{ id, name, links }]
        }

        return []
    }
}
