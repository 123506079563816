import type { SubscriptionsTypes } from '../../../../../../application'

import { CybusServiceDeviationMapper, SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type DeviationsHandler = VrpcInstanceToListSubscriptionHandler<ServiceProxy, SubscriptionsTypes['serviceDeviations']>
type DeviationsHandlerArgs = VrpcHandlerMappingPropertiesArgs<DeviationsHandler>

export class VrpcServiceProxyInstanceToNotificationHandler implements DeviationsHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    /**
     * listen to deviation changes to update Notifications
     * listen to state changes to clear Notifications whenever a deviated Service gets disabled
     * @deprecated these handlings will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServiceProxy>('deviation', 'state')

    mapToDomain ({ instance }: DeviationsHandlerArgs['DomainMapperArgs']): Promise<DeviationsHandlerArgs['Domain']> {
        /**
         * @deprecated this method will no longer provide info about whether service is deviated or not
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getInfo().then(({ id, isDeviated }) => {
            const mapper = new CybusServiceDeviationMapper()
            mapper.add(id, isDeviated)
            return mapper.deviations
        })
    }
}
