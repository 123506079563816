import React, { type FC, type ReactNode } from 'react'
import { Button, Paper, type SxProps, type Theme } from '@mui/material'

import type { Translation } from '../../../../domain'
import { AnchorProvider, Popover, useAnchorCloser, useAnchorSetter } from '../../common'
import { FormattedTranslation } from '../../Internationalization'

const buttonStyle: SxProps<Theme> = {
    ml: 0.5,
    borderRadius: 2,
    borderColor: 'black',
    color: 'black',
    '&:hover': { borderColor: 'black', backgroundColor: 'unset' },
}
const popoverStyle: SxProps<Theme> = { p: 1 }

type Props = Readonly<{ id: string, icon: ReactNode, text: Translation, popover: ReactNode, iconPosition?: string }>

const InnerPopoverButton: FC<Props> = ({ id, icon, text, popover, iconPosition = 'start' }) => {
    const setOrigin = useAnchorSetter()
    const close = useAnchorCloser()

    return (
        <>
            <Button
                startIcon={iconPosition === 'start' && icon}
                endIcon={iconPosition === 'end' && icon}
                id={`${id}Button`}
                variant="outlined"
                size="small"
                sx={buttonStyle}
                onClick={(e) => setOrigin(e.currentTarget)}
            >
                <FormattedTranslation id={text} />
            </Button>
            <Popover
                id={`${id}Popover`}
                onClose={close}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Paper sx={popoverStyle}>{popover}</Paper>
            </Popover>
        </>
    )
}

export const PopoverButton: FC<Props> = (props) => (
    <AnchorProvider>
        <InnerPopoverButton {...props} />
    </AnchorProvider>
)
