import type { ExcludeValues, ReadonlyRecord } from '../../utils'

import type {
    CommissioningFileParsingError,
    ConnectwareError,
    ConnectwareServiceCreationError,
    CybusCatalogServiceConfiguration,
    CybusCatalogServiceVersionData,
} from '..'
import { isLoadableLoaded, type Loadable } from '../Loadable'

export type CybusServiceSchema = ReadonlyRecord<string, unknown>

/**
 * Only supports primitive types or primitive type arrays
 */
export type CybusServiceParameters = ReadonlyRecord<string, string | number | boolean | (string | number)[]>

type ServicesCatalogMetadata = Readonly<{ new: CybusCatalogServiceVersionData, current: Pick<CybusCatalogServiceVersionData, 'version' | 'updatedAt'> }> &
    CybusCatalogServiceConfiguration

export type CybusServiceForm = Readonly<{
    /**
     * The file can be in three states
     *
     * A string of its contents, a non loaded null value or a loaded `ConnectwareError`
     */
    file: Loadable<string>

    /**
     * The schema of the service to be created
     *
     * A Record of the schema, a non loaded null value or a loaded `ConnectwareError`
     */
    schema: Loadable<CybusServiceSchema>

    /**
     * The id of the given service
     */
    id: string | null

    /**
     * The service creation parameters
     */
    parameters: CybusServiceParameters | null

    /**
     * Some services have their information (such as the commissioning file and versioning)
     * managed by the services catalog
     */
    managed: ServicesCatalogMetadata | null

    /**
     * Where the requesting process is controlled
     */
    requesting: CommissioningFileParsingError | ConnectwareServiceCreationError | ConnectwareError | boolean

    /**
     * The initial value of the form
     */
    initialFormValue: ExcludeValues<Pick<CybusServiceForm, 'file' | 'id' | 'parameters'>, null> | null
}>

export const isManagedServiceUpToDate = (managed: NonNullable<CybusServiceForm['managed']>): boolean =>
    managed.new.version === managed.current.version && managed.new.updatedAt.getTime() === managed.current.updatedAt.getTime()

export const isAddOrUpdateServiceSchemaValid = (schema: CybusServiceForm['schema']): schema is CybusServiceSchema => isLoadableLoaded(schema)

export const isAddOrUpdateServiceCommissioningFileValid = (file: CybusServiceForm['file']): file is string => isLoadableLoaded(file)
