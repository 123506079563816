import type { PaginatedData } from '..'
import type { Loadable } from '../Loadable'
import type {
    ClientRegistry,
    ClientRegistryRequestForm,
    CybusPermission,
    CybusRole,
    CybusUser,
    RoleCreationForm,
    RoleEditingForm,
    UserCreationForm,
    UserEditingForm,
} from '.'

type UserManagementTab<T> = Readonly<{
    /** The field the user uses to search */
    search: string
    /** Current page */
    page: number
    /** If internal entities should be show */
    showInternal: boolean
    /** The loaded page */
    data: Loadable<PaginatedData<T>>
}>

export type UserManagementTabsTypes = Readonly<{ users: CybusUser, roles: CybusRole, permissions: CybusPermission }>

type UserManagementTabs = Readonly<{ [P in keyof UserManagementTabsTypes]: UserManagementTab<UserManagementTabsTypes[P]> }>

type Form<T> = [initial: T, current: T]

export type UsersManagement = UserManagementTabs &
    Readonly<{
        userUpdating: Form<UserEditingForm> | null
        userCreation: Form<UserCreationForm> | null

        viewPermission: CybusPermission | null

        roleUpdating: Form<RoleEditingForm> | null
        roleCreation: Form<RoleCreationForm> | null

        registry: ClientRegistry
        registryRequest: Form<ClientRegistryRequestForm> | null
    }>

export type UsersManagementAppState = Readonly<{ usersManagement: UsersManagement }>
