import React, { type FC, memo, type PropsWithChildren } from 'react'

import type { PickByValue, PickByValueExact } from 'utility-types'

import { objectEntries, type ReadonlyRecord } from '../../../../utils'
import type {
    CommissioningFileField,
    CommissioningFileFields,
    CommissioningFileValidatedValue,
    CommissioningFileValidatedValues,
    CommissioningFileValue,
    Translation,
} from '../../../../domain'
import type { Usecases } from '../../../../application'
import { FieldInput, FieldLabel, Section, UnavailableFieldInput } from './Commons'
import { Table, type TableColumns } from '../../common'
import { type FieldData, useResourceFieldsNames, useResourcesCount } from './State'
import { useAppUsecase } from '../../State'

type ResourceTableProps = Readonly<{
    sectionTitle: Translation
    emptyResourceTranslationId: Translation
    valuesResourceName: keyof PickByValue<CommissioningFileValidatedValues, ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[]>
    fieldResourceName: keyof PickByValueExact<CommissioningFileFields, ReadonlyRecord<string, CommissioningFileField>>
    ValueComponent: FC<Readonly<{ position: number, name: string }>>
}>

type ResourceFieldInputProps<U extends keyof Usecases, F extends CommissioningFileField,> = Readonly<{
    name: string
    data: FieldData<F> | null
    usecase: U
    onChange: (usecase: Usecases[U], value: CommissioningFileValue<F>) => void
}>

export const ResourceFieldInput = <U extends keyof Usecases, F extends CommissioningFileField,>({
    usecase: usecaseName,
    name,
    data,
    onChange,
}: PropsWithChildren<ResourceFieldInputProps<U, F>>): ReturnType<FC> => {
    const usecase = useAppUsecase(usecaseName)

    return data ? (
        <FieldInput<F> data-testid="resource-field-input" data={data} name={name} onChange={(value) => onChange(usecase, value)} />
    ) : (
        <UnavailableFieldInput data-testid="resource-empty-field" />
    )
}

type ResourceTableValues = ReadonlyRecord<string, string>
export const ResourceTable: FC<ResourceTableProps> = memo(
    ({ sectionTitle, fieldResourceName, valuesResourceName, emptyResourceTranslationId, ValueComponent }) => {
        const allResourcesNames = objectEntries(useResourceFieldsNames(valuesResourceName, fieldResourceName))
        const resourceCount = useResourcesCount(valuesResourceName)

        return (
            <Section data-testid={`service-edit-${valuesResourceName}-table`} title={sectionTitle} titleValues={{ count: 0 }}>
                <Table
                    orientation="vertical"
                    numberedRows
                    dense
                    borders
                    borderRadius={0}
                    columns={allResourcesNames.reduce<TableColumns<ResourceTableValues>>(
                        (r, [columnName, optional]) => ({
                            ...r,
                            [columnName]: {
                                label: ({ children }) => (
                                    <>{typeof children === 'string' && <FieldLabel label={children} optional={optional} noHorizontalPadding />}</>
                                ),
                                customCellRender: (value, _, index) => <ValueComponent position={index} name={value} />,
                            },
                        }),
                        {}
                    )}
                    data={Array<ResourceTableValues>(resourceCount).fill(allResourcesNames.reduce((r, [name]) => ({ ...r, [name]: name }), {}))}
                    translations={{ emptyTable: emptyResourceTranslationId }}
                />
            </Section>
        )
    }
)
