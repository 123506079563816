import React, { type FC, type ReactNode } from 'react'
import { Link, type SxProps, type Theme } from '@mui/material'

import type { ReadonlyRecord } from '../../../../utils'
import type { Translation } from '../../../../domain'

import { createFormatter, FormattedTranslation } from '../../Internationalization'
import { AnchorProvider, Popover, useAnchorCloser, useAnchorSetter } from '../../common'

const linkStyle: SxProps<Theme> = { cursor: 'pointer' }

type Props = Readonly<{ linkTranslation: Translation, linkTranslationValues?: ReadonlyRecord<string, ReactNode> }>

const ErrorPopoverContent: FC<Props> = ({ linkTranslation, linkTranslationValues, children }) => {
    const setOrigin = useAnchorSetter()
    const close = useAnchorCloser()

    return (
        <>
            <FormattedTranslation
                id={linkTranslation}
                values={{
                    ...linkTranslationValues,
                    link: createFormatter(Link, { id: 'error-popover-link', sx: linkStyle, onClick: (e) => setOrigin(e.currentTarget) }),
                }}
            />
            <Popover onClose={close} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                {children}
            </Popover>
        </>
    )
}

export const ErrorPopover: FC<Props> = (props) => (
    <AnchorProvider>
        <ErrorPopoverContent {...props} />
    </AnchorProvider>
)
