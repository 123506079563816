import React, { type FC, useEffect, useState } from 'react'
import type { SxProps, Theme } from '@mui/material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { type OptionsObject, useSnackbar } from 'notistack'

import { type AuthenticationStatus, Translation } from '../../../domain'
import { useAppUsecases } from '../State'
import { useAsyncCallback } from '../Callback'
import { useRouting } from '../routing'
import { type BaseCommonProps } from '../common'
import { boldFormatter, FormattedTranslation } from '../Internationalization'

type Props = Readonly<{ actionName: Translation, onConfirm?: VoidFunction, onClose?: VoidFunction, isOpen: boolean }> & BaseCommonProps

const notificationWrapperStyle: SxProps<Theme> = { fontSize: 'body2.fontSize', fontWeight: 700 }
const wrapperStyle: SxProps<Theme> = { px: 4, pt: 3, pb: 1 }
const dialogContentStyle: SxProps<Theme> = { ...wrapperStyle, pb: 0 }
const btnWrapperStyle: SxProps<Theme> = { px: 3, pt: 0 }
const btnStyle: SxProps<Theme> = { p: 1, fontSize: 'body1.fontSize', color: 'info.main', m: 0 }
const contrastBtnStyle: SxProps<Theme> = { color: 'text.contrast', ml: 6 }

const notificationConfig: OptionsObject<'warning'> = {
    variant: 'warning',
    persist: true,
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    style: { padding: '6px 16px' },
}

const Title: FC = () => <FormattedTranslation id={Translation.REDUCED_PERMISSIONS_TITLE} />

const Warning: FC<Props> = ({ onConfirm, actionName, children, isOpen, onClose, ...props }) => (
    <Dialog open={isOpen} fullWidth maxWidth="sm" sx={wrapperStyle} data-testid="permissions-issues-modal" {...props}>
        <DialogTitle sx={wrapperStyle} variant="h6" align="left" fontWeight={700}>
            <Title />
        </DialogTitle>
        <DialogContent sx={dialogContentStyle}>{children}</DialogContent>
        <DialogActions sx={btnWrapperStyle}>
            <Button onClick={onClose} sx={btnStyle}>
                <FormattedTranslation id={Translation.DISMISS} />
            </Button>
            <Button disabled={!onConfirm} onClick={onConfirm} sx={btnStyle}>
                <FormattedTranslation id={actionName} />
            </Button>
        </DialogActions>
    </Dialog>
)

const NotificationContent: FC<Pick<Props, 'actionName' | 'onConfirm'>> = ({ onConfirm, actionName }) => (
    <Typography sx={notificationWrapperStyle} data-testid="permissions-issues-notification">
        <Title />
        <Button sx={contrastBtnStyle} disabled={!onConfirm} onClick={onConfirm}>
            <FormattedTranslation id={actionName} />
        </Button>
    </Typography>
)

export const PermissionsReducedModal: FC<{ sessionObsolenceStatus: AuthenticationStatus }> = ({ sessionObsolenceStatus: { isMfaRequired, isMfaEnabled } }) => {
    const { missingPermissionsUsecase, logoutUsecase } = useAppUsecases()
    const router = useRouting()
    const snackbar = useSnackbar()

    const [isDismissed, setDismissed] = useState<boolean>(false)

    /** Logout if MFA is enabled, otherwise it reloads the session */
    const [reload, loading] = useAsyncCallback(async () => {
        await missingPermissionsUsecase.reload()
        router.redirectHome()
    }, [missingPermissionsUsecase])

    const isMfa = !isMfaEnabled && isMfaRequired
    const actionName = isMfa ? Translation.LOGOUT : Translation.RELOAD_SESSION
    const confirm: VoidFunction = () => (isMfa ? logoutUsecase.logout() : reload())

    /** return cleanup function that removes the snackbar when component unmounts  */
    useEffect(() => {
        if (!isDismissed) {
            return
        }

        const closeSnackbar: VoidFunction = () => snackbar.closeSnackbar(snackbarKey)

        const snackbarKey = snackbar.enqueueSnackbar(
            <NotificationContent
                actionName={actionName}
                onConfirm={() => {
                    closeSnackbar()
                    confirm()
                }}
            />,
            notificationConfig
        )

        return closeSnackbar
    }, [isDismissed])

    return (
        <Warning actionName={actionName} onConfirm={!loading ? confirm : undefined} isOpen={!isDismissed} onClose={() => setDismissed(true)}>
            <FormattedTranslation
                id={isMfa ? Translation.MFA_ENFORCED_NOTIFICATION : Translation.PERMISSIONS_CHANGED_NOTIFICATION}
                values={{ b: boldFormatter }}
            />
        </Warning>
    )
}
