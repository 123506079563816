import { areArrayEquals, Droppable, isArrayNotEmpty, objectEntries, type ReadonlyRecord } from '../../../utils'

import {
    type AvailableTopic,
    Capability,
    type ConnectwareError,
    createIsAuthenticatedWithCapabilitiesSelector,
    isTopicSubscriptionResponseSuccessful,
    selectExplorer,
    selectLoadedExplorer,
    selectSelectedTopicsRawPath,
    type TopicSubscriptionError,
    TopicType,
} from '../../../domain'

import { initialState, type PersistedTopics, type TopicsSubscription } from '../..'
import { ExplorerUsecase, initialExplorerState } from './Explorer'

const canReadTopicsMetadata = createIsAuthenticatedWithCapabilitiesSelector(Capability.TOPICS_SUBSCRIPTION_METADATA)

export class StartExplorerSubscriptionUsecase extends ExplorerUsecase {
    private addCustomTopics ({ selected, custom }: PersistedTopics): void {
        if (isArrayNotEmpty(custom)) {
            this.setExplorer({
                topics: custom.map<AvailableTopic>((config) => ({
                    ...config,
                    source: TopicType.CUSTOM,
                    selected: selected.some((s) => ExplorerUsecase.areTopicPathsEquals(s.path, config.path)),
                    subscriptionErrors: [],
                })),
            })
        }
    }

    private async addConfiguredTopics (selected: PersistedTopics['selected'], droppable: Droppable): Promise<void> {
        const canRead = canReadTopicsMetadata(this.getState())
        const topicsConfigurationPromise = canRead ? this.configurationService.fetchTopicsConfiguration() : Promise.resolve([])

        await Promise.all([topicsConfigurationPromise, this.authenticationService.fetchSubscribableTopics()])
            .then(([topicsConfiguration, subscribableTopics]) => {
                if (droppable.isDropped) {
                    return
                }

                const additions = topicsConfiguration.reduce<AvailableTopic[]>((r, configured) => {
                    if (this.topicsService.isSubscribeable(configured.rawPath, subscribableTopics)) {
                        r.push({
                            ...configured,
                            selected: selected.some((s) => ExplorerUsecase.areTopicPathsEquals(s.path, configured.path)),
                            subscriptionErrors: [],
                        })
                    }

                    return r
                }, [])

                if (!isArrayNotEmpty(additions)) {
                    return
                }

                const { topics } = selectLoadedExplorer(this.getState())

                this.setExplorer({ topics: [...topics, ...additions] })
            })
            .catch((e: ConnectwareError) => {
                if (droppable.isDropped) {
                    return
                }
                this.setExplorer({ configurationLoadError: e })
            })
    }

    private listeToSubscribedTopics (subscription: TopicsSubscription): VoidFunction {
        const droppable = new Droppable()

        droppable.onDrop(
            /** Error implies that the client will be closed */
            subscription.onError((error) => this.setState({ explorer: error }))
        )

        droppable.onDrop(
            subscription.onBatch((batch) => {
                const explorer = selectLoadedExplorer(this.getState())
                let { latestValues, tail } = explorer

                batch.forEach(({ timestamp, possibleTypes, topic, payload, sources }) => {
                    let [selectedType] = possibleTypes
                    const latestValue = latestValues[topic] ?? null
                    const previousSelectedType = (latestValue && 'selectedType' in latestValue && latestValue.selectedType) || selectedType
                    selectedType = possibleTypes.includes(previousSelectedType) ? previousSelectedType : selectedType

                    /** Update latest value, without overriding the previously selected value */
                    latestValues = { ...latestValues, [topic]: { possibleTypes, payload, selectedType, sources } }

                    /** Updates the tail unless it is stopped */
                    tail = explorer.isTailing ? [{ timestamp, topic, payload, selectedType }, ...tail] : tail
                })

                this.setExplorer({ latestValues, tail })
            })
        )

        return () => droppable.drop()
    }

    private listenToStateTopicChanges (subscription: TopicsSubscription): VoidFunction {
        const droppable = new Droppable()

        const propagateChanges: VoidFunction = () => {
            const selectedTopics = selectSelectedTopicsRawPath(this.getState())

            void subscription.subscribe(selectedTopics).then((response) => {
                if (isTopicSubscriptionResponseSuccessful(response)) {
                    return
                }

                const errors: ReadonlyRecord<TopicSubscriptionError, Set<string>> = {
                    invalid: new Set(response.invalid),
                    noPermissions: new Set(response.noPermissions),
                    unknown: new Set(response.unknown),
                    shared: new Set(response.shared),
                }

                const { topics } = selectLoadedExplorer(this.getState())

                let changed = false

                /** Re-create topics with the errors */
                const withErrors = topics.map((topic) => {
                    const subscriptionErrorEntry = objectEntries(errors).find(([error]) => errors[error].has(topic.rawPath))

                    if (!subscriptionErrorEntry) {
                        return topic
                    }

                    const [subscriptionError] = subscriptionErrorEntry

                    changed = true

                    return { ...topic, subscriptionErrors: [subscriptionError] }
                })

                if (changed) {
                    /** If there are matches, then update the topics */
                    this.setExplorer({ topics: withErrors })
                }
            })
        }

        /** Listen to topic changes on the application state */
        propagateChanges()
        droppable.onDrop(
            this.subscribeToState(
                (previous, current) => areArrayEquals(selectSelectedTopicsRawPath(previous), selectSelectedTopicsRawPath(current)),
                propagateChanges
            )
        )

        return () => droppable.drop()
    }

    invoke (): VoidFunction {
        const droppable = new Droppable()

        /** Initialize the state of the explorer */
        this.setState({ explorer: initialExplorerState })

        /** Retrieve the persisted topic information and adds them */
        const { selected, custom } = this.topicsPersistenceService.retrieveTopics()
        this.addCustomTopics({ selected, custom })
        void this.addConfiguredTopics(selected, droppable)

        /** Start listneing to topic messages */
        const subscription = this.topicsService.create()

        /** Once this is dropped, drop the subscription too  */
        droppable.onDrop(() => subscription.end())

        /** Listen to the application state in case there are changes to subscribed topics */
        droppable.onDrop(this.listenToStateTopicChanges(subscription))

        /** Listen to the recieved messages from the subscribed topics */
        droppable.onDrop(this.listeToSubscribedTopics(subscription))

        /** If dropped, reset the state */
        droppable.onDrop(() => this.setState({ explorer: selectExplorer(initialState) }))

        return () => droppable.drop()
    }
}
