import { stringify } from 'csv-stringify/lib/sync'

import type { CSVData, FileService } from '../../application'

export class WebFileService implements FileService {
    download (file: File): void {
        const link = document.createElement('a')

        link.download = file.name

        /**
         * Makes blob fetchable through url
         */
        link.href = URL.createObjectURL(file)

        /**
         * Attach to document
         */
        document.body.appendChild(link)

        /**
         * Opens it (effectively downloads it)
         */
        link.click()

        /**
         * Drops garbage
         */
        document.body.removeChild(link)

        /**
         * Tells the browser they don't have to reference the object anymore
         */
        URL.revokeObjectURL(link.href)
    }

    generateCSV (fileName: string, head: string[], data: CSVData): File {
        const csvContent = stringify([head, ...data], { cast: { date: (date) => date.toISOString(), string: (value) => value.trim() } })

        return new File([new Blob([csvContent], { type: 'text/csv' })], `${fileName}.csv`, { type: 'text/csv' })
    }
}
