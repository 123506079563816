import { mapDetailedConnection } from '../../../../../Connectware'
import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'

import { VrpcBaseConnectionProxyInstanceHandler } from './Base'

export class VrpcDetailedConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connection'> {
    protected readonly mapConnection = mapDetailedConnection

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = CONNECTION_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null
}
