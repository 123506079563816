import { useMemo } from 'react'
import { ifUnresolved, usePromise } from 'react-sync-promise'

import type { NullableValues } from '../../../../utils'
import { Capability, createIsAuthenticatedWithCapabilitiesSelector, type EndpointConnectivity } from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'

const selectCanUpdateLicense = createIsAuthenticatedWithCapabilitiesSelector(Capability.SYSTEM_LICENSE_MANAGE)

export const useCanUpdateLicense = (): boolean => useAppState(selectCanUpdateLicense)

export const useIsPortalReachable = (): NullableValues<EndpointConnectivity> | null => {
    const licenseView = useAppUsecase('licenseViewUsecase')

    const promise = useMemo(() => licenseView.fetchPortalConnectivity(), [licenseView])
    return ifUnresolved(usePromise(promise), null)
}
