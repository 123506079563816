import type { GetInstanceOptions, InstanceInfo } from 'vrpc'

import { isArrayNotEmpty, type ReadonlyRecord } from '../../../../../utils'

import { listenToRemote, type ManagedVrpcRemote } from '../../../utils'
import type { SubscriptionHandlerType, VrpcSubscriptionHandler, VrpcSubscriptionHandlerArgs } from '../handlers'

import { VrpcRemoteManagerDataMapper } from './BaseRemote'

export type VrpcInstanceBaseMapperSupportedHandlers<Handler extends VrpcSubscriptionHandler<any, any>> = VrpcSubscriptionHandler<
    VrpcSubscriptionHandlerArgs<Handler>['VrpcInstance'],
    VrpcSubscriptionHandlerArgs<Handler>['Domain'],
    | SubscriptionHandlerType.INSTANCE_ONE_TO_LIST
    | SubscriptionHandlerType.INSTANCE_ONE_TO_ONE
    | SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE
    | SubscriptionHandlerType.INSTANCE_ONE_TO_PAGE
>

type InstanceRelevancyConfiguration<C> = Readonly<{
    getAgents(): string[]
    getClassNames(agent: string): string[]
    getInstanceNames(agent: string, className: string): string[]
    mapRelevant(args: ReadonlyRecord<'agent' | 'className' | 'instance', string>): C
}>

/**
 * Has the internal tooling necessary to manage one or more VRPC instances into the domain entities
 */
export abstract class VrpcInstanceBaseMapper<Handler extends VrpcInstanceBaseMapperSupportedHandlers<Handler>, Value> extends VrpcRemoteManagerDataMapper<
    Handler,
    Value
> {
    protected readonly shouldIgnoreClauses: ((instanceName: string) => boolean)[] = [
        /** There should be a source, and this is not it */
        (instanceName) => typeof this.handler.sourceInstanceName === 'string' && this.handler.sourceInstanceName !== instanceName,
        /** If there is an ignore pattern, and it yields true, then ignore the instance  */
        (instanceName) => this.handler.ignoreInstances instanceof RegExp && this.handler.ignoreInstances.test(instanceName),
        /** If ignoring by filters should happen, and it should ignore, then do it */
        (instanceName) => Boolean(this.handler.ignoreInstanceByFilter?.(instanceName, this.filter)),
    ]

    private mapRelevantInstances<C> (config: InstanceRelevancyConfiguration<C>): C[] {
        const relevant: C[] = []

        for (const agent of config.getAgents()) {
            if (!this.isAgentRelevant(agent)) {
                continue
            }

            for (const className of config.getClassNames(agent)) {
                if (!this.isClassNameRelevant(className)) {
                    continue
                }

                for (const instance of config.getInstanceNames(agent, className)) {
                    if (!this.isInstanceRelevant(instance)) {
                        continue
                    }

                    relevant.push(config.mapRelevant({ agent, className, instance }))
                }
            }
        }

        return relevant
    }

    /**
     * Helper function to listen to instance events
     */
    private onInstanceEvent (
        callback: (remote: ManagedVrpcRemote, relevantInstances: string[], options: InstanceInfo) => void,
        remote: ManagedVrpcRemote,
        instances: string[],
        options: InstanceInfo
    ): void {
        const relevantInstances = this.mapRelevantInstances({
            getAgents: () => [options.agent],
            getClassNames: () => [options.className],
            getInstanceNames: () => instances,
            mapRelevant: ({ instance }) => instance,
        })

        if (!isArrayNotEmpty(relevantInstances)) {
            return
        }

        callback(remote, relevantInstances, options)
    }

    /**
     * @returns relevant instances of relevant classes in the current remote across the configured agents
     */
    private getExistingInstancesConfiguration (remote: ManagedVrpcRemote): Parameters<ManagedVrpcRemote['getInstance']>[] {
        return this.mapRelevantInstances<Parameters<ManagedVrpcRemote['getInstance']>>({
            getAgents: () => (this.handler.agent ? [this.handler.agent] : remote.getAvailableAgents()),
            getClassNames: (agent) => remote.getAvailableClasses({ agent }),
            getInstanceNames: (agent, className) => remote.getAvailableInstances(className, { agent }),
            mapRelevant: ({ agent, className, instance }) => [instance, { agent, className }],
        })
    }

    private onRelevantNewInstances (remote: ManagedVrpcRemote, addedInstances: string[], options: InstanceInfo): void {
        for (const instanceName of addedInstances) {
            this.addInstance(remote, instanceName, options)
        }
    }

    private onRelevantGoneInstances (remote: ManagedVrpcRemote, goneInstances: string[], options: InstanceInfo): void {
        for (const instanceName of goneInstances) {
            this.removeInstance(remote, instanceName, options)
        }
    }

    private isAgentRelevant (targetAgent: string): boolean {
        const { agent } = this.handler
        return agent === null || agent === targetAgent
    }

    private isClassNameRelevant (className: string): boolean {
        const { classNameFilter } = this.handler
        return typeof classNameFilter === 'string' ? classNameFilter === className : classNameFilter.test(className)
    }

    private isInstanceRelevant (instanceName: string): boolean {
        return this.shouldIgnoreClauses.every((shouldIgnore) => !shouldIgnore(instanceName))
    }

    protected feedManagedRemote (remote: ManagedVrpcRemote): void {
        /** Listen to new instances being added and if they are relevant, introduce them */
        this.onDrop(
            listenToRemote(remote, 'instanceNew', (...args) => this.onInstanceEvent((...args) => this.onRelevantNewInstances(...args), remote, ...args))
        )

        /** Listen to new instances being removed and if they are relevant, drop them */
        this.onDrop(
            listenToRemote(remote, 'instanceGone', (...args) => this.onInstanceEvent((...args) => this.onRelevantGoneInstances(...args), remote, ...args))
        )

        /** Get current instances, and if there are none, then trigger current flow */
        const instances = this.getExistingInstancesConfiguration(remote).map(([instanceName, options]) => this.addInstance(remote, instanceName, options))

        if (!isArrayNotEmpty(instances)) {
            /** If nothing was added, then there will be no change process being triggered, so we need to manually doe it */
            this.triggerChange()
        }
    }

    /**
     * Called when a relevant instance is to be added into the fold
     */
    protected abstract addInstance (remote: ManagedVrpcRemote, instanceName: string, options?: InstanceInfo | GetInstanceOptions): void

    /**
     * Called when a relevant instance has been removed
     */
    protected abstract removeInstance (remote: ManagedVrpcRemote, instanceName: string, options: InstanceInfo): void
}
