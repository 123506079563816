import type { ReadonlyRecord } from '../../../../../../utils'
import { areDetailedCybusMappingEquals, Capability, type CybusDetailedMapping } from '../../../../../../domain'

import { mapDetailedMapping, type MappingProxyParams, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedMappingStrategy extends BaseSubscriptionStrategy<'mapping'> {
    readonly requiredPermissions = [Capability.MAPPING_READ]

    protected override readonly areEquals = areDetailedCybusMappingEquals

    override async retrieveOne (id: string): Promise<CybusDetailedMapping> {
        return Promise.all([
            this.retrieve({
                capability: Capability.MAPPING_READ,
                method: 'GET',
                path: '/api/mappings/+',
                pathParams: [id],
                mapper: (data: MappingProxyParams) => data,
                handleNotFound: true,
            }),
            this.retrieve({
                capability: Capability.MAPPING_READ,
                method: 'GET',
                path: '/api/mappings/+/endpoint-topics',
                pathParams: [id],
                mapper: (data: ReadonlyRecord<string, string[]>) => data,
                handleNotFound: true,
            }),
        ]).then(([params, dependencies]) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            return mapDetailedMapping(params, currentStatus, dependencies)
        })
    }
}
