import { areCybusDetailedServiceContainersEquals, areCybusServiceContainersEquals, Capability } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { mapDetailedServiceContainer } from '../../../../../Connectware'

import { BaseServiceContainerStrategy } from './Base'

export class ServiceContainersStrategy extends BaseServiceContainerStrategy<'serviceContainers'> {
    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    readonly requiredPermissions = [Capability.SERVICE_CONTAINERS_READ]

    /** What fetch strategy should be used */
    protected override readonly fetchAllMethod = 'fetchServiceContainers'

    protected override readonly areEquals = areCybusServiceContainersEquals
}

export class DetailedServiceContainerStrategy extends BaseServiceContainerStrategy<'serviceContainer'> {
    readonly requiredPermissions = [Capability.SERVICE_CONTAINER_READ]

    /** Maps the response to services containers, signaling to the base class that single retrieval is implemented */
    protected override readonly mapSingleRetrieval = mapDetailedServiceContainer

    protected override readonly areEquals = areCybusDetailedServiceContainersEquals
}
