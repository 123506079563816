import type { Capability, ConnectwareError } from '../../../../domain'

import type { SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../application'

export interface SubscriptionStrategy<T extends keyof SubscriptionsTypes> {
    readonly requiredPermissions: Capability[]

    supportsFilters(filter: SubscriptionFilterArgs): boolean
    retrieveAll(filter: SubscriptionFilterArgs): Promise<SubscriptionsTypes[T][]>
    retrieveOne(id: string): Promise<SubscriptionsTypes[T]>
    retrieveCount(): Promise<number>

    compareAll(oldResults: SubscriptionsTypes[T][] | ConnectwareError, newResults: SubscriptionsTypes[T][] | ConnectwareError): boolean
    compareOne(oldResult: SubscriptionsTypes[T] | ConnectwareError, newResult: SubscriptionsTypes[T] | ConnectwareError): boolean
}
