import { Usecase } from './Usecase'

export class LoadingUsecase extends Usecase {
    updateLoading (increase: boolean): void {
        let { loadingCount } = this.getState()

        if (increase) {
            loadingCount++
        } else {
            loadingCount--
        }

        this.setState({ loadingCount: Math.max(loadingCount, 0) })
    }

    async withLoading (asyncAction: Promise<void>): Promise<void> {
        try {
            this.updateLoading(true)
            await asyncAction
        } finally {
            this.updateLoading(false)
        }
    }
}
