/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/Server.js#lines-73
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/swagger/swagger.yaml#lines-556
 */
export type ServerProxyParams = Readonly<{
    id: string
    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    currentState: string
    targetState: string
    protocol: string
    /** @todo finish mapping */
    options: unknown
}>
