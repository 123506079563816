import type { Mutable, ValuesType } from 'utility-types'

import { type ArrayType, copyObjectWithoutKeys, objectEntries, objectKeys, type PartialReadonlyRecord, type ReadonlyRecord } from '../../../../../utils'
import {
    type CommissioningFileFields,
    CommissioningFileFieldType,
    type CommissioningFileValues,
    ConnectwareError,
    ConnectwareErrorType,
    type StringIndexCommissioningFileField,
} from '../../../../../domain'
import type { MinimalExpectedServiceData } from '../../types'
import type { CommissioningDataResourceType } from '../../../../Connectware'
import { resourceStrategies } from '../Strategies'

const mapDefaultString = (update: string | null | undefined, current?: string): string => (update ?? current) || ''

const mapMetadata = (
    { description, metadata }: MinimalExpectedServiceData,
    updated: CommissioningFileValues
): Pick<MinimalExpectedServiceData, 'description' | 'metadata'> => {
    const { description: updatedDescription, ...updatedMetada } = updated.metadata

    return {
        description: mapDefaultString(updatedDescription, description),

        metadata: {
            ...metadata,
            ...objectEntries(updatedMetada).reduce((r, [name, value]) => ({ ...r, [name]: mapDefaultString(value) }), {}),
        },
    }
}

/**
 * Transforms an object with dot-notated keys into the nested object structure
 *
 * @param obj The object to be transformed. In this, keys is in dot notation format, Ex, "a.b.c": 'value'
 * @returns A new object where dot-notated keys are transformed into nested objects. like, { a: { b: { c: 'value' } } }
 */
const unflattenProperties = (obj: Record<string, unknown>): ReadonlyRecord<string, unknown> =>
    objectEntries(obj).reduce<Record<string, unknown>>((acc, [key, value]) => {
        const keyParts = key.split('.')
        const lastKeyIndex = keyParts.length - 1

        keyParts.reduce((currentLevel, nestedFieldName, index) => {
            /** Set the value at the deepest level */
            if (index === lastKeyIndex) {
                currentLevel[nestedFieldName] = value ?? undefined
            } else {
                /**
                 * Ensures 'nestedFieldName' exists as an object in 'currentLevel' for deeper nesting,
                 * If not then initializes it to "{}"
                 */
                currentLevel[nestedFieldName] = currentLevel[nestedFieldName] ?? {}
            }

            return currentLevel[nestedFieldName] as ReadonlyRecord<string, unknown>
        }, acc)

        return acc
    }, {})

/**
 * This mapper merges the appended previous and current values
 * And then strives to output a json object that has the same structure as the inputted value
 * Adding new resource entries at the bottom
 */
class ServiceDataResourcesMapper {
    private static readonly mappedResourceTypes = new Set(objectKeys<PartialReadonlyRecord<CommissioningDataResourceType, unknown>>(resourceStrategies))

    private readonly previous: [name: string, type: CommissioningDataResourceType, properties?: ReadonlyRecord<string, unknown>][] = []
    private readonly current: Mutable<MinimalExpectedServiceData['resources']> = {}

    appendPrevious (...args: ArrayType<ServiceDataResourcesMapper['previous']>): void {
        this.previous.push(args)
    }

    appendCurrent (name: keyof MinimalExpectedServiceData['resources'], properties: ValuesType<MinimalExpectedServiceData['resources']>): void {
        this.current[name] = properties
    }

    /**
     * @warning do not call this function twice
     */
    flush (): MinimalExpectedServiceData['resources'] {
        /**
         * First try to update the values with same structure as it has right now
         */
        const updatedValues = this.previous.reduce<MinimalExpectedServiceData['resources']>((r, [name, type, properties]) => {
            let current = this.current[name]

            if (current) {
                /**
                 * The value was updated,
                 * drop it from the updates entries so it doesn't get added again
                 */
                delete this.current[name]
            }

            if (!ServiceDataResourcesMapper.mappedResourceTypes.has(type)) {
                /**
                 * Value is not updatable yet, so simply use the previous value
                 */
                current = { type, properties }
            }

            /**
             * If there is no current value it means
             * It was mappable but deleted
             */
            return current ? { ...r, [name]: current } : r
        }, {})

        return { ...updatedValues, ...this.current }
    }
}

const mapResources = (
    { resources }: MinimalExpectedServiceData,
    values: CommissioningFileValues,
    fields: CommissioningFileFields
): Pick<MinimalExpectedServiceData, 'resources'> => {
    const mapper = new ServiceDataResourcesMapper()

    /** First add all current entries */
    objectEntries(resources).forEach(([name, { type, properties }]) => mapper.appendPrevious(name, type, properties))

    /** Then attempt to add in the updates */
    objectEntries(resourceStrategies).forEach(([resourceType, { fieldName, valueName, fromDomainToJson: { ignoreProperties = [] } = {} }]) => {
        const strategyFields = fields[fieldName]
        const strategyUpdatedValues = values[valueName]

        const [firstIndexField, ...otherFields] = Object.values(strategyFields).filter(
            (f): f is StringIndexCommissioningFileField => f.type === CommissioningFileFieldType.STRING_INDEX
        )

        if (!firstIndexField || otherFields.length) {
            throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not valid index field', { fields: objectKeys(strategyFields) })
        }

        strategyUpdatedValues.forEach((values) => {
            const { [firstIndexField.name]: name, ...properties } = unflattenProperties(values)

            if (typeof name !== 'string') {
                throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Name could not be extracted from properties', {
                    values,
                    field: firstIndexField,
                })
            }

            mapper.appendCurrent(name, { type: resourceType, properties: copyObjectWithoutKeys(properties, ...ignoreProperties) })
        })
    })

    return { resources: mapper.flush() }
}

export const mapCommissioningFileValuesToJson = (
    raw: MinimalExpectedServiceData,
    updated: CommissioningFileValues,
    fields: CommissioningFileFields
): MinimalExpectedServiceData => ({
    ...raw,
    ...mapMetadata(raw, updated),
    ...mapResources(raw, updated, fields),
})
