import { objectEntries } from '../../../../utils'

import type { CybusResourceDeviation, CybusServiceDeviation } from '../../../../domain'

import { type CybusServiceProxyInfo, type CybusServiceProxyParams, mapToStatusType } from '../..'

/**
 * @deprecated Removed this function once we start fetching the deviations from RST
 * @see https://cybusio.atlassian.net/browse/CC-1251
 */
export const mapCybusResourceDeviations = (deviation: CybusServiceProxyParams['deviation']): CybusResourceDeviation[] =>
    objectEntries(deviation).map(([id, { expected, actual, reason }]) => ({
        resourceId: id,
        expected: mapToStatusType(expected),
        actual: mapToStatusType(actual),
        reason: reason || null,
    }))

export class CybusServiceDeviationMapper {
    readonly deviations: CybusServiceDeviation[] = []

    add (id: CybusServiceProxyInfo['id'], deviated: CybusServiceProxyInfo['isDeviated']): void {
        if (deviated) {
            this.deviations.push({ name: id })
        }
    }
}
