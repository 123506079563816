import React, { type FC } from 'react'
import { Box, Container, Paper, type SxProps, type Theme } from '@mui/material'

import { Capability, createIsAuthenticatedForRequiredMfaWithCapabilitiesSelector, Translation } from '../../../domain'

import { useAppState } from '../State'
import { boldFormatter, FormattedTranslation } from '../Internationalization'
import { Background, CybusCompleteLogo, Validation } from '../common'
import { Description } from './Description'
import { Content } from './Controls'
import { ActionButton } from './Common'

const backgroundStyle: SxProps<Theme> = { position: 'fixed', top: 0, height: '100vh', width: '100vw', zIndex: -1 }
const backgroundImageShadowStyle: SxProps<Theme> = { ...backgroundStyle, backgroundColor: 'grey.900' }
const backgroundImageStyle: SxProps<Theme> = { ...backgroundStyle, filter: 'blur(1rem)' }
const logoWrapperStyle: SxProps<Theme> = { width: '400px', my: 3, mx: 'auto' }
const formWrapperStyle: SxProps<Theme> = { p: 2 }
const validationStyle: SxProps<Theme> = { py: 2 }

const selectCanManageMfa = createIsAuthenticatedForRequiredMfaWithCapabilitiesSelector(Capability.USE_MFA)

const EnforceFormContent: FC = () => {
    const canManageMfa = useAppState(selectCanManageMfa)

    if (canManageMfa) {
        return <Content />
    }

    return (
        <Validation error sx={validationStyle}>
            <FormattedTranslation id={Translation.MFA_MISSING_PERMISSIONS_ERROR} values={{ b: boldFormatter, br: <br /> }} />
        </Validation>
    )
}

export const EnforceForm: FC = () => (
    <>
        <Box sx={backgroundImageShadowStyle} />
        <Box sx={backgroundImageStyle}>
            <Background />
        </Box>
        <Container maxWidth="sm">
            <Box sx={logoWrapperStyle}>
                <CybusCompleteLogo />
            </Box>
            <Paper data-testid="mfa-enforcement-form" sx={formWrapperStyle}>
                <Description required />
                <EnforceFormContent />
                <ActionButton data-testid="mfa-cancel-enforcement" usecase="enforceMfaUsageUsecase" method="reset" label={Translation.BACK_TO_LOGIN} dense />
            </Paper>
        </Container>
    </>
)
