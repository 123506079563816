import type { ValuesType } from 'utility-types'

import { createEqualityChecker } from '../../../utils'
import { selectUsersManagement, type UserManagementTabsTypes, type UsersManagement, type UsersManagementAppState } from '..'

export const selectUsersManagementTabInformation = <T extends keyof UserManagementTabsTypes>(s: UsersManagementAppState, tab: T): UsersManagement[T] =>
    selectUsersManagement(s)[tab]

type Tabs = Pick<UsersManagement, keyof UserManagementTabsTypes>
export const areUsersManagementTabInformationFiltersEquals = createEqualityChecker<Pick<ValuesType<Tabs>, 'page' | 'search' | 'showInternal'>>({
    page: null,
    showInternal: null,
    search: null,
})

const createUsersManagementTabInformationSelector =
    <Tab extends keyof UserManagementTabsTypes, P extends keyof UsersManagement[Tab]>(
        propName: P
    ): ((s: UsersManagementAppState, tab: Tab) => UsersManagement[Tab][P]) =>
    (s, tab) =>
        selectUsersManagementTabInformation(s, tab)[propName]

export const selectUserManagementSelectedTabData = createUsersManagementTabInformationSelector('data')
export const selectUserManagementSelectedTabSearch = createUsersManagementTabInformationSelector('search')
export const selectUserManagementSelectedTabPage = createUsersManagementTabInformationSelector('page')
export const selectUserManagementSelectedTabShowInternal = createUsersManagementTabInformationSelector('showInternal')

export const areUsersManagementSelectedTabInformationFiltersEquals = (
    tab: keyof UserManagementTabsTypes,
    a: UsersManagementAppState,
    b: UsersManagementAppState
): boolean => areUsersManagementTabInformationFiltersEquals(selectUsersManagementTabInformation(a, tab), selectUsersManagementTabInformation(b, tab))
