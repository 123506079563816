import React, { type FC } from 'react'
import { Autocomplete, Box, Chip, type SvgIconProps, type SxProps, TextField, type Theme, Typography } from '@mui/material'
import { ArrowDropDown, HelpOutline, ScatterPlot, SettingsEthernet, Timeline } from '@mui/icons-material'

import { isArrayNotEmpty, type ReadonlyRecord } from '../../../../utils'
import { type SystemTopicSource, TopicType, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { type BaseCommonProps, CybusIcon } from '../../common'

import { type FilterOption, useFilterOptions } from './FilterOptions'
import { PopoverButton } from './PopoverButton'

const autocompleteStyle: SxProps<Theme> = { minWidth: 300, maxWidth: 550 }

// Custom options
const optionStyle: SxProps<Theme> = { display: 'flex', maxWidth: '100%', '& > *': { my: 'auto', mx: 0 } }
const iconStyle: SxProps<Theme> = { pr: 1 }
const longTextStyle: SxProps<Theme> = { textOverflow: 'ellipsis', overflowX: 'hidden', whiteSpace: 'nowrap' }

// Custom chip
const chipAvatarStyle: SxProps<Theme> = { ml: 0.5, fontSize: 18 }

const avatars: ReadonlyRecord<SystemTopicSource['type'], FC<SvgIconProps>> = {
    [TopicType.ENDPOINT]: ScatterPlot,
    [TopicType.MAPPING]: SettingsEthernet,
    [TopicType.NODE]: Timeline,
}

type IconProps = FilterOption & Pick<BaseCommonProps, 'sx'>

const Icon: FC<IconProps> = ({ sx, ...option }) => {
    let Avatar: FC<SvgIconProps>

    if ('id' in option) {
        Avatar = avatars[option.type]
    } else {
        Avatar = option.types.includes(TopicType.CUSTOM) ? HelpOutline : CybusIcon
    }

    return <Avatar sx={sx} color="secondary" />
}

export const FilterTopicsButton: FC = () => {
    const manage = useAppUsecase('explorerTopicManagementUsecase')
    const options = useFilterOptions()

    if (!isArrayNotEmpty(options)) {
        return null
    }

    return (
        <PopoverButton
            id="filterTopics"
            iconPosition="end"
            icon={<ArrowDropDown />}
            text={Translation.FILTER}
            popover={
                <Autocomplete
                    sx={autocompleteStyle}
                    multiple
                    filterSelectedOptions
                    size="small"
                    value={options.filter((o) => o.selected)}
                    onChange={(_, newValue) => {
                        const resources: string[] = []
                        const types: TopicType[] = []

                        newValue.forEach((o) => {
                            if ('id' in o) {
                                resources.push(o.id)
                            } else {
                                types.push(...o.types)
                            }
                        })

                        manage.filterTopics(resources, types)
                    }}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <Box title={option.label} sx={optionStyle}>
                                <Icon {...option} sx={iconStyle} />
                                <Typography sx={longTextStyle} variant="body2">
                                    {option.label}
                                </Typography>
                            </Box>
                        </li>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                title={option.label}
                                avatar={<Icon sx={chipAvatarStyle} {...option} />}
                                color={'id' in option ? 'primary' : undefined}
                                label={option.label}
                                size="small"
                                {...getTagProps({ index })}
                                key={index}
                            />
                        ))
                    }
                    renderInput={(params) => <TextField {...params} variant="standard" label={<FormattedTranslation id={Translation.FILTER} />} />}
                    noOptionsText={
                        <Typography variant="body2">
                            <FormattedTranslation id={Translation.NO_RESULTS_FOUND} />
                        </Typography>
                    }
                    // clearText="@todo translate this"
                />
            }
        />
    )
}
