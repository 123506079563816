import React, { type FC, type ReactElement, type ReactNode, useCallback, useState } from 'react'
import { Tab as BaseTab, type TabsProps as BaseTabsProps, Divider, type SxProps, tabClasses, type Theme } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { isArrayNotEmpty, isEnumOf } from '../../../utils'
import { type Capability, Translation } from '../../../domain'

import { useHasCapabilities } from '../routing'
import { useTranslator } from '../Internationalization'
import { NoPermission } from './NoPermission'

const tabPanelStyle: SxProps<Theme> = { px: 0 }
const tabPanelNoVeritcalPaddingStyle: SxProps<Theme> = { py: 0 }

const tabSxProps: SxProps<Theme> = {
    color: 'primary.main',
    [`&.${tabClasses.selected}`]: {
        color: 'text.primary',
        bgcolor: 'background.grey',
    },
}

const buttonStyleTabSxProps: SxProps<Theme> = {
    minHeight: 42,
    borderRadius: 1,
    textTransform: 'initial',
    color: 'text.primary',
    boxShadow: 1,
    mr: 1,
    [`&.${tabClasses.selected}`]: {
        color: 'primary.contrastText',
        bgcolor: 'primary.main',
    },
}

const indicatorStyle: SxProps<Theme> = { display: 'none' }

type TabProps<TabValue,> = Readonly<{
    /** Used to translate the field */
    title: Translation | string
    /** Used to properly translate the field to singular or plural */
    count?: number
    /** Overide the disabled values given by the the capabilities check */
    disabled?: boolean
    children?: ReactNode
    requiredCapabilities?: Capability[]
    value?: TabValue
}>

export const Tab: FC<TabProps<string>> = () => <></>

export type TabsProps<TabValue,> = Readonly<{
    children: ReactElement<TabProps<TabValue>>[]
    onChange?: (value: TabValue) => void
    value?: TabValue
    /**
     * With the buttonStyle variant, the tabs component will resemble buttons.
     * Additionally, there will be no spaces between the TabList and TabPanel (content).
     * @default 'tab'
     * */
    variant?: 'tab' | 'button'
}> &
    Pick<BaseTabsProps, 'orientation'>

export const Tabs = <TabValue extends string,>({ children, onChange, value: controlledValue, variant = 'tab', ...props }: TabsProps<TabValue>): JSX.Element => {
    const translation = useTranslator()
    const hasCapabilities = useHasCapabilities()

    const enabledValues: string[] = []
    const tabs: JSX.Element[] = []
    const panels: JSX.Element[] = []

    children.forEach(({ props: { title, value: customValue, count = null, disabled, requiredCapabilities: capabilities = [], children } }, k) => {
        const value = customValue ?? String(k)
        const actuallyDisabled = disabled || (isArrayNotEmpty(capabilities) && !hasCapabilities(capabilities))

        if (!actuallyDisabled) {
            enabledValues.push(value)
        }

        const translatedTitle = isEnumOf(Translation, title) ? translation.formatTranslation(title, { count }) : title

        tabs.push(
            <BaseTab
                key={value}
                data-testid={translatedTitle}
                label={count === null || count === 0 ? translatedTitle : `${translatedTitle} (${count})`}
                value={value}
                disabled={actuallyDisabled}
                sx={variant === 'tab' ? tabSxProps : buttonStyleTabSxProps}
            />
        )

        if (children) {
            panels.push(
                <TabPanel sx={[tabPanelStyle, variant === 'button' ? tabPanelNoVeritcalPaddingStyle : null]} key={value} value={value}>
                    {children}
                </TabPanel>
            )
        }
    })

    const [selection, setValue] = useState<TabValue | null>(null)

    /** If there is a selection, and its enabled, use it, otherwise, overwrite it */
    const value = controlledValue ?? (selection && enabledValues.includes(selection) ? selection : enabledValues[0])

    const handleOnChange = useCallback((_, value: TabValue) => {
        if (onChange) {
            onChange(value)
        } else {
            setValue(value)
        }
    }, [])

    return (
        <TabContext value={value || '0'}>
            <TabList {...props} onChange={handleOnChange} TabIndicatorProps={{ sx: indicatorStyle }}>
                {tabs}
            </TabList>
            {variant === 'tab' && <Divider />}
            {value ? panels : <NoPermission />}
        </TabContext>
    )
}
