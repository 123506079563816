import React, { type FC, useMemo, useRef } from 'react'
import { FormattedDate } from 'react-intl'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import { Autorenew } from '@mui/icons-material'

import { type BackupMaintenanceStatus, ConnectwareError, Translation } from '../../../domain'

import { createClickerHandler } from '..'
import { ErrorMessage } from '../ErrorMessage'
import { FormattedDateTime, FormattedTranslation } from '../Internationalization'
import { useAppUsecase } from '../State'
import { useAsyncCallback } from '../Callback'
import { HiddenRawFileUpload } from '../common'
import { MaintenanceSection } from './Section'

const restoreMessageStyle: SxProps<Theme> = { mt: 3 }

export const Restore: FC<Pick<BackupMaintenanceStatus, 'restore'>> = ({ restore }) => {
    const systemMaintenance = useAppUsecase('systemMaintenanceUsecase')
    const uploadRef = useRef<HTMLInputElement>(null)

    const [upload, loadingUpload, valueUpload] = useAsyncCallback((file: File) => systemMaintenance.uploadBackup(file), [systemMaintenance])

    const supportedFileTypes = useMemo(() => systemMaintenance.getSupportedRestoreBackupFileExtensions(), [systemMaintenance])

    return (
        <MaintenanceSection
            data-testid="maintenance-restore"
            title={Translation.RESTORE}
            description={Translation.RESTORE_DESCRIPTION}
            buttonProps={{
                icon: Autorenew,
                label: Translation.RESTORE_BACKUP_BUTTON,
                onClick: createClickerHandler(uploadRef),
            }}
            loading={loadingUpload}
        >
            {restore && (
                <Typography data-testid="backup-restored" sx={restoreMessageStyle}>
                    <FormattedTranslation
                        id={Translation.RESTORED}
                        values={{
                            date: <FormattedDate value={restore} />,
                            time: <FormattedDateTime date={restore} format="timeOnly" />,
                        }}
                    />
                </Typography>
            )}
            {!loadingUpload && valueUpload !== null && !ConnectwareError.is(valueUpload) && (
                <Box color="green">
                    <Typography>
                        <FormattedTranslation id={Translation.RESTORE_COMPLETE} />
                    </Typography>
                </Box>
            )}
            {!loadingUpload && ConnectwareError.is(valueUpload) && <ErrorMessage id="backup-restore-error" titleVariant="body2" error={valueUpload} />}
            <HiddenRawFileUpload
                data-testid="restore-file-input"
                ref={uploadRef}
                accept={supportedFileTypes}
                onChange={([file]) => {
                    if (file) {
                        /** Read the selected file and upload its content */
                        upload(file)
                    }
                }}
            />
        </MaintenanceSection>
    )
}
