import { type DailyMetric, type HourlyMetric, type Metrics, selectLicenseId, Translation } from '../../../domain'

import { Usecase } from '../Usecase'

export class SystemMetricsUsecase extends Usecase {
    fetchMetrics (): Promise<Metrics> {
        return this.systemService.fetchMetrics()
    }

    sendMetricsToPortal (): Promise<void> {
        return this.systemService.sendMetricsToPortal()
    }

    downloadHourlyMetricCSV (metrics: HourlyMetric[]): void {
        const head = [
            this.translationService.translate(Translation.TIME),
            this.translationService.translate(Translation.NUMBER_OF_DATAPOINTS),
            this.translationService.translate(Translation.NUMBER_OF_MESSAGES_PER_HOUR),
        ]
        const file = this.fileService.generateCSV(
            this.translationService.translate(Translation.COLLECTED_HOURLY_METRICS_TITLE),
            head,
            metrics.map((metric) => [metric.timestamp, metric.numberOfConnectedDatapoints, metric.numberOfReceivedMessages])
        )
        this.fileService.download(file)
    }

    downloadDailyMetricCSV (metrics: DailyMetric[]): void {
        const head = [
            this.translationService.translate(Translation.LICENSE_ID),
            this.translationService.translate(Translation.DATE),
            this.translationService.translate(Translation.NUMBER_OF_DATAPOINTS),
            this.translationService.translate(Translation.NUMBER_OF_MESSAGES_PER_DAY),
        ]
        const licenseId = selectLicenseId(this.getState())
        const file = this.fileService.generateCSV(
            this.translationService.translate(Translation.COLLECTED_DAILY_METRICS_TITLE),
            head,
            metrics.map((metric) => [licenseId, metric.date, metric.numberOfConnectedDatapoints, metric.numberOfReceivedMessages])
        )
        this.fileService.download(file)
    }
}
