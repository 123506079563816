import React, { type FC } from 'react'
import { Box } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'
import { ResourcesList } from './ResourcesList'
import { Table, type TableLine } from '../../common'

export const Servers: FC<Pick<CybusDetailedService['configuredResources'], 'servers'>> = ({ servers }) => {
    const translator = useTranslator()

    return (
        <ResourcesList title={Translation.SERVER} subtitle={Translation.AUTHORIZE_SERVERS_CREATION} data-testid="server-creation">
            <Box className="service-server-item">
                <Table
                    data={servers.reduce<TableLine[]>(
                        (r, { name, basePath, allowsAnonymous, protocol }) => [
                            ...r,
                            {
                                server: name,
                                protocol,
                                path: basePath,
                                allowAnonymous: allowsAnonymous === null ? '' : translator.formatTranslation(Translation.BOOLEAN, { value: allowsAnonymous }),
                            },
                        ],
                        []
                    )}
                    columns={{
                        server: { label: translator.formatTranslation(Translation.SERVER, { count: 1 }), sort: true },
                        protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                        path: { label: translator.formatTranslation(Translation.PATH, { count: 1 }), sort: true },
                        allowAnonymous: { label: translator.formatTranslation(Translation.ALLOW_ANONYMOUS, { count: 1 }), sort: true },
                    }}
                    sortOrder={{ name: 'server', direction: 'desc' }}
                />
            </Box>
        </ResourcesList>
    )
}
