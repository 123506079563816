import { type NullableValues, objectEntries } from '../../../utils'
import {
    type AppState,
    areServiceParametersEquals,
    ConnectwareError,
    ConnectwareErrorType,
    type CybusCatalogServiceConfiguration,
    type CybusDetailedService,
    type CybusServiceForm,
    type CybusServiceParameters,
    isAddOrUpdateServiceCommissioningFileValid,
    isAddOrUpdateServiceSchemaValid,
    selectServiceForm,
} from '../../../domain'

import type { ServiceCreationOrUpdateRequest } from '../..'

import { CommissioningFileUsecase } from './Commissioning'

const initial: CybusServiceForm = {
    file: null,
    schema: null,
    id: null,
    parameters: null,
    requesting: false,
    managed: null,
    initialFormValue: null,
}

const catchPromise = (e: ConnectwareError): ConnectwareError => e

abstract class BaseUsecase extends CommissioningFileUsecase {
    private getNullableServiceForm (): CybusServiceForm | null {
        return selectServiceForm(this.getState())
    }

    private getRequest (): ServiceCreationOrUpdateRequest | null {
        const form = this.getNullableServiceForm()

        if (
            form === null ||
            form.requesting === true ||
            !form.id ||
            !form.parameters ||
            !isAddOrUpdateServiceCommissioningFileValid(form.file) ||
            !isAddOrUpdateServiceSchemaValid(form.schema) ||
            !this.connectwareServicesService.validate(form.schema, this.isCreating() ? form.id : null, form.parameters) ||
            (form.initialFormValue &&
                form.id === form.initialFormValue.id &&
                form.file === form.initialFormValue.file &&
                areServiceParametersEquals(form.parameters, form.initialFormValue.parameters))
        ) {
            return null
        }

        return {
            commissioningFile: form.file,
            id: form.id,
            parameters: form.parameters,
            isCreation: this.isCreating(),
            catalog: form.managed && {
                directory: form.managed.directory,
                filename: form.managed.filename,
                updatedAt: form.managed.current.updatedAt,
                version: form.managed.current.version,
            },
        }
    }

    private mergeManaged (managed: CybusServiceForm['managed'], file: string): CybusServiceForm['managed'] {
        if (!managed || managed.new.commissioningFile !== file) {
            /** Eithere there is no catalog data, or file is not coming from the catalog */
            return managed
        }

        /**
         * If service is managed and the file being submitted is the same as the new one
         * Then we override the current info
         */
        return { ...managed, current: { version: managed.new.version, updatedAt: managed.new.updatedAt } }
    }

    private getServiceFormFile (): CybusServiceForm['file'] {
        return this.getServiceForm().file
    }

    protected initializeServiceForm (serviceForm: AppState['serviceForm']): void {
        this.setState({ serviceForm })
    }

    protected getServiceForm (): CybusServiceForm {
        const form = this.getNullableServiceForm()

        if (!form) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Could not update service creation form')
        }

        return form
    }

    protected setServiceForm (form: Partial<CybusServiceForm>): void {
        this.initializeServiceForm({ ...this.getServiceForm(), ...form })
    }

    protected close (): void {
        this.initializeServiceForm(null)
    }

    protected createParameters (
        parameters: CybusServiceParameters | null,
        initialParameters: NullableValues<CybusServiceParameters> | null
    ): CybusServiceParameters | null {
        const out = parameters || initialParameters

        return (
            out &&
            objectEntries(out).reduce<CybusServiceParameters>(
                (r, [parameterKey, parameterValue]) => (parameterValue === null ? r : { ...r, [parameterKey]: parameterValue }),
                {}
            )
        )
    }

    /**
     * Merges the initial parameters with the parameters of the service
     *
     * @param current the parameters of the service
     * @param initial the initial parameters of the service
     *
     * @returns valid parameters of the service, correct for any changes between a version of a service
     */
    protected mergeParameters (current: CybusServiceParameters, initial: NullableValues<CybusServiceParameters>): CybusServiceParameters {
        return objectEntries(initial).reduce<CybusServiceParameters>((outParameters, [parameterKey, initialValue]) => {
            const currentValue = current[parameterKey]

            if (initialValue === null) {
                if (currentValue === undefined) {
                    /** There is no value there, so just sent it as is */
                    return outParameters
                }

                /** The value is set already, so use it */
                return { ...outParameters, [parameterKey]: currentValue }
            }

            if (currentValue !== undefined && typeof currentValue === typeof initialValue) {
                /** The value is set already and the value is of the same type, so use it */
                return { ...outParameters, [parameterKey]: currentValue }
            }

            /** Use the initial value */
            return { ...outParameters, [parameterKey]: initialValue }
        }, {})
    }

    abstract isCreating (): boolean

    /**
     * Update the commissioning file for the service that will be created
     * Resets current service schema/parameters in some scenarios
     */
    async updateCommissioningFile (newFile: CybusServiceForm['file']): Promise<void> {
        const oldFile = this.getServiceFormFile()

        if (oldFile === newFile) {
            /** If the new file is the same, then do nothing */
            return
        }

        if (!isAddOrUpdateServiceCommissioningFileValid(newFile)) {
            /** Commissioning file is invalid */
            this.setServiceForm({ file: newFile, schema: null, id: null, parameters: null })
            return
        }

        const { managed, parameters } = this.getServiceForm()

        /** Clear derived information and start loading */
        this.setServiceForm({ file: newFile, schema: null, parameters: null, requesting: true })

        /** Commissioning file was read, lets try to parse it */
        const schemaResolution = await this.connectwareServicesService
            .parseCommissioningFile(newFile, this.isCreating() ? null : this.getServiceForm().id)
            .then(([schema, id, parameters]) => ({ schema, id, parameters }))
            .catch((e: ConnectwareError) => ({ schema: e, id: null, parameters: null }))

        if (this.getServiceFormFile() !== newFile) {
            /** File has changed since parsing started, so stop the presses and let the last process to do their thing */
            return
        }

        const newManaged = this.mergeManaged(managed, newFile)

        /** Set state when promise yields */
        this.setServiceForm({
            ...schemaResolution,
            /** Revert update if there is a catalog, and the update was denied */
            file: newManaged && newManaged === managed ? oldFile : newFile,
            parameters:
                schemaResolution.parameters === null || parameters === null
                    ? this.createParameters(parameters, schemaResolution.parameters)
                    : this.mergeParameters(parameters, schemaResolution.parameters),
            id: this.isCreating() ? schemaResolution.id : this.getServiceForm().id,
            requesting: false,
            managed: newManaged,
        })
    }

    updateServiceParameters (id: CybusServiceForm['id'], newParameters: CybusServiceForm['parameters']): void {
        const { parameters, schema, id: previousId } = this.getServiceForm()

        if (!isAddOrUpdateServiceSchemaValid(schema) || !parameters) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Could not update service parameters')
        }

        this.setServiceForm({ id: this.isCreating() ? id : previousId, parameters: { ...parameters, ...newParameters } })
    }

    cancel (): void {
        this.close()
    }

    async confirm (): Promise<string | null> {
        const args = this.getRequest()

        if (!args) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Cannot create or update the service')
        }

        const { id } = args

        /** Set as loading */
        this.setServiceForm({ requesting: true })

        /** Finally create */
        return (
            this.connectwareServicesService
                .createOrUpdate(args)
                .then(() => {
                    /** Close dialog */
                    this.close()

                    return id
                })
                /** Record the error */
                .catch((requesting: ConnectwareError) => {
                    this.setServiceForm({ requesting })

                    return null
                })
        )
    }

    isConfirmationRequestValid (): boolean {
        return Boolean(this.getRequest())
    }
}

export class AddServiceUsecase extends BaseUsecase {
    private async fetchCatalogCreationInfo ({ filename, directory }: CybusCatalogServiceConfiguration): Promise<Partial<CybusServiceForm>> {
        const catalogData = await this.connectwareServicesCatalogService.fetchCatalogData({ filename, directory }).catch(catchPromise)

        if (ConnectwareError.is(catalogData)) {
            return { file: catalogData }
        }

        const { version, updatedAt, commissioningFile } = catalogData
        const parsedFile = await this.connectwareServicesService.parseCommissioningFile(commissioningFile, null).catch(catchPromise)

        if (ConnectwareError.is(parsedFile)) {
            return { schema: parsedFile }
        }

        const [schema, id, parameters] = parsedFile
        return {
            file: commissioningFile,
            schema,
            id,
            parameters: this.createParameters(null, parameters),
            managed: { filename, directory, new: { version, updatedAt, commissioningFile }, current: { version, updatedAt } },
        }
    }

    isCreating (): boolean {
        return true
    }

    async startCreationFromCatalog (config: CybusCatalogServiceConfiguration): Promise<void> {
        this.initializeServiceForm({ ...initial, ...(await this.fetchCatalogCreationInfo(config)) })
    }

    startCreation (): void {
        this.initializeServiceForm(initial)
    }
}

export class UpdateServiceUsecase extends BaseUsecase {
    isCreating (): boolean {
        return false
    }

    startUpdate ({ commissioningFile, id, catalog, parameters, updatedAt, version }: CybusDetailedService): Promise<void> {
        return Promise.all([
            this.connectwareServicesService.parseCommissioningFile(commissioningFile, id).catch(catchPromise),
            catalog && this.connectwareServicesCatalogService.fetchCatalogData(catalog).catch(catchPromise),
        ]).then(([parsedResponse, catalogData]) => {
            if (ConnectwareError.is(parsedResponse)) {
                this.initializeServiceForm({ ...initial, schema: parsedResponse })
                return
            }

            if (ConnectwareError.is(catalogData)) {
                this.initializeServiceForm({ ...initial, file: catalogData })
                return
            }

            const [schema, , initialParameters] = parsedResponse
            const outParameters = this.mergeParameters(parameters, initialParameters)

            this.initializeServiceForm({
                file: commissioningFile,
                schema,
                id,
                parameters: outParameters,
                managed:
                    catalogData && catalog && version && updatedAt
                        ? {
                              filename: catalog.filename,
                              directory: catalog.directory,
                              new: { version: catalogData.version, updatedAt: catalogData.updatedAt, commissioningFile: catalogData.commissioningFile },
                              current: { version, updatedAt },
                          }
                        : null,
                requesting: false,
                initialFormValue: { id, file: commissioningFile, parameters: outParameters },
            })
        })
    }
}
