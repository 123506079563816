import React, { type FC } from 'react'
import { List, ListItem, ListItemText, type SxProps, type Theme } from '@mui/material'

import type { StatusType } from '../../../../domain'

import { useStatusTranslator } from './Hooks'
import { StatusIcon } from './StatusIcon'

const fullWidthListStyle: SxProps<Theme> = { m: 'auto 0', p: 0, width: '100%', maxWidth: '150px' }
const listStyle: SxProps<Theme> = { m: 'auto 0', p: 0, maxWidth: '150px' }
const listItemTextStyle: SxProps<Theme> = { ml: 1, my: 0 }

const Status: FC<Readonly<{ status: StatusType }>> = ({ status, ...props }) => {
    const translateStatus = useStatusTranslator()

    return (
        <List {...props} sx={listStyle} dense>
            <ListItem sx={fullWidthListStyle} dense>
                <StatusIcon status={status} />
                <ListItemText sx={listItemTextStyle} primary={translateStatus(status)} />
            </ListItem>
        </List>
    )
}

export default Status
