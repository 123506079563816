import type { Certificate } from '../../domain'

export interface CertificatesService {
    /**
     * Fetches certificates
     * @throws `ConnectwareError`
     */
    fetchCertificates(): Promise<Certificate[]>

    /**
     * Deletes given certificate
     * @throws `ConnectwareError`
     */
    delete(certificate: Certificate): Promise<void>

    /**
     * Uploads new certificate
     * @throws `ConnectwareError`
     */
    upload(certificateContent: string): Promise<Certificate>

    /**
     * Returns the content of the given certificate
     * @throws `ConnectwareError`
     */
    fetchCertificateFileContent(certificate: Certificate): Promise<string>
}
