import type { NonEmptyArray } from '../../utils'
import type { CybusEndpoint, CybusMapping, CybusNode } from '..'

export enum CybusServiceDataResourceType {
    ENDPOINT = 'ENDPOINT',
    MAPPING = 'MAPPING',
    NODE = 'NODE',
}

export type CybusServiceDataResource = Readonly<{
    id: (CybusMapping | CybusEndpoint | CybusNode)['id']
    type: CybusServiceDataResourceType
    topics: NonEmptyArray<string>
}>
