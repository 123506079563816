import React, { type FC, useState } from 'react'
import { Badge, Box, Collapse, Divider, Grid, type SxProps, type Theme, Typography } from '@mui/material'
import { AddShoppingCart, ExpandLess, ExpandMore } from '@mui/icons-material'

import { isArrayNotEmpty, toggleReadonlySet } from '../../../../utils'
import { CybusCatalogApplicationCategory, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { useTranslator } from '../../Internationalization'

import { ServiceCard, TemplateCard } from './Card'
import { useCategoryTranslation, useFilteredApplications, useSearch } from './Hooks'

const categoryStyle: SxProps<Theme> = { mb: 2 }
const titleStyle: SxProps<Theme> = { display: 'flex', cursor: 'pointer', justifyContent: 'space-between' }

const noServicesStyle: SxProps<Theme> = { my: 2 }
const badgeStyle: SxProps<Theme> = { height: 30, width: 30, borderRadius: '50%' }

export const ServicesGrid: FC = () => {
    const { downloadServiceCatalogTemplateUsecase, installServiceCatalogApplicationUsecase } = useAppUsecases()

    const translator = useTranslator()
    const translateCategory = useCategoryTranslation()

    const [collapsed, setCollapsed] = useState<ReadonlySet<CybusCatalogApplicationCategory>>(() => new Set())

    const search = useSearch()
    const filtered = useFilteredApplications()

    if (filtered.isEmpty()) {
        return <Typography align="center">{translator.formatTranslation(Translation.YOUR_SEARCH_FOUND_NO_SERVICES, { search })}</Typography>
    }

    return (
        <>
            {Object.values(CybusCatalogApplicationCategory).map((category, k) => {
                const applications = filtered.getApplications(category)
                const templates = filtered.getTemplates(category)

                return (
                    <Box key={k} id={`service-catalog-category-${category.toLowerCase()}`} sx={categoryStyle}>
                        <Box sx={titleStyle} onClick={() => setCollapsed((current) => toggleReadonlySet(current, category))}>
                            <Typography data-testid={`service-catalog-category-title-${category.toLowerCase()}`} variant="h5">
                                {translateCategory(category)}
                            </Typography>
                            {collapsed.has(category) ? <ExpandMore fontSize="large" /> : <ExpandLess fontSize="large" />}
                        </Box>
                        <Divider />
                        <Collapse in={!collapsed.has(category)} timeout="auto" unmountOnExit>
                            {!isArrayNotEmpty(applications) && !isArrayNotEmpty(templates) ? (
                                <Typography sx={noServicesStyle} align="center">
                                    {translator.formatTranslation(Translation.NO_CATEGORY_SERVICES_FOUND, { category: translateCategory(category) })}
                                </Typography>
                            ) : (
                                <Grid>
                                    {applications.map((app, k) => (
                                        <Badge
                                            sx={{ badge: badgeStyle }}
                                            key={k}
                                            badgeContent={!app.available && <AddShoppingCart />}
                                            overlap="circular"
                                            color="secondary"
                                            invisible={app.available}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        >
                                            <ServiceCard service={app} onClick={() => installServiceCatalogApplicationUsecase.selectApplication(app)} />
                                        </Badge>
                                    ))}
                                    {isArrayNotEmpty(templates) && (
                                        <TemplateCard category={category} onClick={() => downloadServiceCatalogTemplateUsecase.selectCategory(category)} />
                                    )}
                                </Grid>
                            )}
                        </Collapse>
                    </Box>
                )
            })}
        </>
    )
}
