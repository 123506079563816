import { delay } from '../../../../utils'
import { CommissioningFileFormState, type ConnectwareError, mapCommissioningFileValues, selectCommissioningFileFormValues } from '../../../../domain'
import { EditServiceTemplateUsecase } from './Base'

export abstract class EditServiceTemplateOutputUsecase extends EditServiceTemplateUsecase {
    protected refreshDelay = 200

    private async updateOutput (): Promise<void> {
        const current = this.getTypedForm([CommissioningFileFormState.LOADING_OUTPUT, CommissioningFileFormState.LOADED_OUTPUT])
        const base = { type: CommissioningFileFormState.LOADED_OUTPUT, fields: current.fields, file: current.file, values: current.values } as const

        this.updateForm(
            {
                ...base,
                output: await this.connectwareServicesService
                    .generateCommissioningFileOutput(current.file, mapCommissioningFileValues(current.values))
                    .catch((e: ConnectwareError) => e),
            },
            current
        )
    }

    protected scheduleOutputRefresh (): void {
        const initialState = selectCommissioningFileFormValues(EditServiceTemplateUsecase.selectFormFromState(this.getState()))

        delay(this.refreshDelay).finally(() => {
            const newState = selectCommissioningFileFormValues(EditServiceTemplateUsecase.selectFormFromState(this.getState()))

            if (initialState !== newState) {
                return
            }

            void this.updateOutput()
        })
    }
}
