import React, { type FC, useEffect, useState } from 'react'

import { IconButton, type SxProps, type Theme } from '@mui/material'
import { CheckOutlined, ContentCopy, ContentCopyOutlined } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { createTimeout } from '../../../utils'

import { Translation } from '../../../domain'
import { useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { useIsItemCopied } from './Hooks'
import { type BaseCommonProps, SmallButton, TiniestButton } from '../common'

type ButtonProps = Readonly<{ disabled?: boolean, onClick?: VoidFunction }> & BaseCommonProps

type CopyWithSnackbarProps = Pick<ButtonProps, 'className' | 'sx'> & Readonly<{ button: FC<ButtonProps> }>
type CopyWithIconsChangeProps = { title?: string, timeout?: number } & BaseCommonProps

const iconStyle: SxProps<Theme> = { opacity: 0.5 }
const copiedIconStyle: SxProps<Theme> = { fill: 'grey' }
const copyIconStyle: SxProps<Theme> = { fill: 'black' }
const buttonStyle: SxProps<Theme> = { border: 'unset' }

const SNACKBAR_TIMEOUT = 2000
/**
 * Timeout for the copy icon to be reverted
 * in milliseconds
 */
const ICONS_CHANGE_TIMEOUT = 3200

const InternalCopyWithSnackbar: FC<CopyWithSnackbarProps> = ({ children, button: Button, ...props }) => {
    const copy = useAppUsecase('copyValueUsecase')
    const isItemCopied = useIsItemCopied(children)

    const { enqueueSnackbar } = useSnackbar()

    return (
        <Button
            {...props}
            sx={{ ...buttonStyle, ...props.sx }}
            data-testid={isItemCopied ? 'copied-id' : 'copy-id'}
            onClick={() => {
                copy.invoke(children)
                enqueueSnackbar(
                    <div data-testid="copied-to-clipboard">
                        <FormattedTranslation id={Translation.COPIED_TO_CLIPBOARD} />
                    </div>,
                    { variant: 'info', autoHideDuration: SNACKBAR_TIMEOUT }
                )
            }}
        >
            <ContentCopy sx={[iconStyle, isItemCopied ? copiedIconStyle : copyIconStyle]} />
        </Button>
    )
}

const InternalCopyWithIconsChange: FC<CopyWithIconsChangeProps> = ({ children, title, timeout = ICONS_CHANGE_TIMEOUT, ...props }) => {
    const copy = useAppUsecase('copyValueUsecase')
    const isItemCopied = useIsItemCopied(children)

    const [isCopyClicked, setCopyClicked] = useState<boolean>(false)

    const onCopy = (): void => {
        copy.invoke(children)
        setCopyClicked(true)
    }

    // Revert the copied state after a timeout
    useEffect(() => createTimeout(() => setCopyClicked(false), timeout), [isCopyClicked])

    return (
        <>
            {isItemCopied && isCopyClicked ? (
                <IconButton disabled data-testid="copied-btn" {...props}>
                    <CheckOutlined color="success" />
                </IconButton>
            ) : (
                <IconButton onClick={onCopy} data-testid="copy-btn" {...props}>
                    <ContentCopyOutlined titleAccess={title || ''} />
                </IconButton>
            )}
        </>
    )
}

export const TiniestCopy: FC<Omit<CopyWithSnackbarProps, 'button'>> = (props) => <InternalCopyWithSnackbar {...props} button={TiniestButton} />
export const SmallCopy: FC<Omit<CopyWithSnackbarProps, 'button'>> = (props) => <InternalCopyWithSnackbar {...props} button={SmallButton} />
export const CustomCopy: FC<CopyWithSnackbarProps> = (props) => <InternalCopyWithSnackbar {...props} />
export const CopyWithIconsChange: FC<CopyWithIconsChangeProps> = (props) => <InternalCopyWithIconsChange {...props} />
