import { areDetailedCybusServiceEquals, Capability, type CybusDetailedService } from '../../../../../../domain'
import {
    type CybusServiceDependencies,
    type CybusServiceProxyParams,
    mapCybusResourceDeviations,
    mapDetailedService,
    mapToStatusType,
} from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class DetailedServiceStrategy extends BaseSubscriptionStrategy<'service'> {
    readonly requiredPermissions = [Capability.SERVICE_READ]

    protected override readonly areEquals = areDetailedCybusServiceEquals

    /**
     * @deprecated this endpoint will no longer contains the currentState field
     * @todo consider using another field
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    override async retrieveOne (id: string): Promise<CybusDetailedService> {
        return Promise.all([
            this.retrieve({
                capability: Capability.SERVICE_READ,
                method: 'GET',
                path: '/api/services/+',
                pathParams: [id],
                mapper: (j: CybusServiceProxyParams) => j,
            }),
            this.retrieve({
                capability: Capability.SERVICE_READ,
                method: 'GET',
                path: '/api/services/+/dependencies',
                pathParams: [id],
                mapper: (j: CybusServiceDependencies) => j,
            }),
        ]).then(([params, dependencies]) => {
            /**
             * @deprecated update code block so resource deviations is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const resourceDeviations = mapCybusResourceDeviations(params.deviation)

            /**
             * @deprecated use the another field instead of the currentState
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentState = mapToStatusType(params.currentState)
            return mapDetailedService(params, currentState, dependencies, resourceDeviations)
        })
    }
}
