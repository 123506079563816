import React, { type FC } from 'react'
import { Button } from '@mui/material'
import { ErrorRounded, Lock, LockOpen } from '@mui/icons-material'

import { ConnectwareError, selectIsLoading, selectIsUsersManagementRegistryLocked, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'

export const LockButton: FC = () => {
    const locked = useAppState(selectIsUsersManagementRegistryLocked)
    const isLoading = useAppState(selectIsLoading)

    const usecase = useAppUsecase('toggleLockClientRegistryUsecase')

    const [toggle, toggling, toggleError] = useAsyncCallback(() => usecase.invoke(), [usecase])

    const showError = ConnectwareError.is(toggleError) && !toggling

    return (
        <Button
            data-testid="registry-lock-toggle-button"
            title={showError ? toggleError.message : undefined}
            startIcon={showError ? <ErrorRounded /> : locked ? <LockOpen /> : <Lock />}
            variant="contained"
            color={showError ? 'error' : 'primary'}
            disabled={toggling}
            onClick={isLoading ? undefined : toggle}
        >
            <FormattedTranslation id={locked ? Translation.UNLOCK : Translation.LOCK} />
        </Button>
    )
}
