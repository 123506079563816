import type { PickByValueExact } from 'utility-types'

import { Droppable, type ReadonlyRecord } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, type StatusType, TopicType } from '../../../domain'

import type { SubscriptionsTypes } from '../..'
import { LogoutUsecase } from '../Authentication'

import { ExplorerUsecase } from './Explorer'

const eventNames: ReadonlyRecord<TopicType.ENDPOINT | TopicType.MAPPING | TopicType.NODE, keyof PickByValueExact<SubscriptionsTypes, StatusType>> = {
    [TopicType.ENDPOINT]: 'endpointStatus',
    [TopicType.MAPPING]: 'mappingStatus',
    [TopicType.NODE]: 'nodeStatus',
}

export class ExplorerResourceStatusUsecase extends ExplorerUsecase {
    subscribe (source: keyof typeof eventNames, id: string, handler: (e: StatusType | ConnectwareError) => void): VoidFunction | void {
        const droppable = new Droppable()

        this.subscriptionsService
            .subscribeToOne(eventNames[source], id, (data) => handler(data))
            .then((off) => droppable.onDrop(off))
            .catch((e: ConnectwareError) => {
                const isAuth = ConnectwareError.isOfTypes(e, ConnectwareErrorType.AUTHENTICATION)

                if (isAuth) {
                    this.getUsecase(LogoutUsecase).logout()
                } else {
                    handler(e)
                }
            })

        return () => droppable.drop()
    }
}
