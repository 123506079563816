import React, { type ChangeEvent, type FC, type ReactNode } from 'react'
import { Autocomplete, type AutocompleteRenderInputParams, Box, IconButton, Stack, type SxProps, TextField, type Theme, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'

import { isArrayNotEmpty, type NonEmptyArray, type ReadonlyRecord } from '../../../../utils'
import {
    type BooleanCommissioningFileField,
    type CommissioningFileField,
    CommissioningFileFieldType,
    type CommissioningFileValidatedValue,
    type CommissioningFileValue,
    CommissioningFileValueValidationError,
    ConnectwareError,
    ConnectwareErrorType,
    type IntegerCommissioningFileField,
    type NumberCommissioningFileField,
    type OptionsCommissioningFileField,
    type StringArrayCommissioningFileField,
    type StringCommissioningFileField,
    type StringIndexCommissioningFileField,
    Translation,
} from '../../../../domain'

import { createHandlerWithoutPropagation } from '../..'
import { FormattedList } from '../../Internationalization'
import { AnchorProvider, Popover, SmallParagraph, Title, useAnchorCloser, useAnchorSetter } from '../../common'

import { type FieldData, isFieldDataOfType } from './State'

const cellInternalHorizontalPaddingStyle: SxProps<Theme> = { px: 2 }
const cellInternalVerticalPaddingStyle: SxProps<Theme> = { py: 0.5 }

export const FieldLabel: FC<
    Pick<CommissioningFileField, 'optional'> & Readonly<{ label: string, noVerticalPadding?: boolean, noHorizontalPadding?: boolean }>
> = ({ optional, label, noVerticalPadding, noHorizontalPadding }) => {
    const [last] = label.split('.').slice(-1) as NonEmptyArray<string>

    return (
        <Typography
            data-testid="field-label"
            sx={[noVerticalPadding ? null : cellInternalVerticalPaddingStyle, noHorizontalPadding ? null : cellInternalHorizontalPaddingStyle]}
            title={label}
            variant="body1"
        >
            {!optional ? <b>{last}</b> : last}
        </Typography>
    )
}

type FieldProps<F extends CommissioningFileField = CommissioningFileField,> = Readonly<{
    name: string
    data: FieldData<F>
    onChange: (value: CommissioningFileValue<F>) => void
}>

const iconButtonStyle: SxProps<Theme> = { py: 0, pr: 0 }
const hiddenWarningStyle: SxProps<Theme> = { visibility: 'hidden' }
const WarningPopoverToggler: FC<Pick<FieldProps, 'data'>> = ({ data: { validation } }) => {
    const setOrigin = useAnchorSetter()
    const isError = CommissioningFileValueValidationError.is(validation)

    return (
        <IconButton sx={iconButtonStyle} disabled={!isError} color="error" size="small" onClick={(e) => setOrigin(e.currentTarget)}>
            <Warning sx={isError ? null : hiddenWarningStyle} fontSize="small" />
        </IconButton>
    )
}

const warningContentStyle: SxProps<Theme> = { p: 2, maxWidth: 400 }
const WarningPopover: FC<Pick<FieldProps, 'data' | 'name'>> = ({ name, data: { validation } }) => {
    const close = useAnchorCloser()
    const extras = CommissioningFileValueValidationError.is(validation) && validation.extras

    return (
        <Popover
            onClose={createHandlerWithoutPropagation(close)}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            elevation={1}
        >
            <Stack gap={2} sx={warningContentStyle}>
                {extras && (
                    <>
                        <SmallParagraph
                            id={Translation.EDIT_SERVICE_INVALID_FIELD}
                            values={{
                                ...extras.field,
                                name: <b>{name}</b>,
                                allowableValues: extras.field.allowableValues && <FormattedList value={extras.field.allowableValues} type="disjunction" />,
                                reason: extras.reason,
                            }}
                        />
                        {extras.field.description && (
                            <SmallParagraph id={Translation.EDIT_SERVICE_INVALID_FIELD_MORE_INFORMATION} values={{ information: extras.field.description }} />
                        )}
                    </>
                )}
            </Stack>
        </Popover>
    )
}

const cellHeight = 32

const unavailableFieldInput: SxProps<Theme> = { backgroundColor: 'grey.200', height: cellHeight, width: '100%' }
export const UnavailableFieldInput: FC = (props) => <Box {...props} sx={unavailableFieldInput} />

const multipleChoiceInputStyle: SxProps<Theme> = { minWidth: 'max-content' }
const AutocompleteTextField: FC<AutocompleteRenderInputParams & Readonly<{ toggler: ReactNode }>> = ({ toggler, ...params }) => (
    <TextField
        {...params}
        variant="standard"
        InputProps={{
            ...params.InputProps,
            sx: [cellInternalHorizontalPaddingStyle, multipleChoiceInputStyle],
            disableUnderline: true,
            endAdornment: (
                <>
                    {toggler}
                    {params.InputProps.endAdornment}
                </>
            ),
        }}
    />
)

const correctFieldType = <F extends CommissioningFileValidatedValue<CommissioningFileField>, D,>({ validation, value }: F, defaultValue: D): F['value'] | D => {
    const typeIsInvalid = CommissioningFileValueValidationError.is(validation) && validation.extras.reason === 'INVALID_TYPE'
    return typeIsInvalid ? defaultValue : value
}

const fieldInternalInputStyle: SxProps<Theme> = { py: 0, height: cellHeight }
const FieldInternalInput = <F extends CommissioningFileField,>({
    data,
    toggler,
    onChange,
}: Pick<FieldProps<F>, 'data' | 'onChange'> & Readonly<{ toggler: ReactNode }>): ReturnType<FC> => {
    const isNumber =
        isFieldDataOfType<IntegerCommissioningFileField>(data, CommissioningFileFieldType.INTEGER) ||
        isFieldDataOfType<NumberCommissioningFileField>(data, CommissioningFileFieldType.NUMBER)
    if (
        (isFieldDataOfType<StringCommissioningFileField>(data, CommissioningFileFieldType.STRING) ||
            isFieldDataOfType<StringIndexCommissioningFileField>(data, CommissioningFileFieldType.STRING_INDEX) ||
            isNumber) &&
        !Array.isArray(data.field.allowableValues)
    ) {
        return (
            <TextField
                type={isNumber ? 'number' : undefined}
                value={correctFieldType(data, '') ?? ''}
                size="small"
                variant="standard"
                fullWidth
                onChange={({ currentTarget: { value, valueAsNumber } }: ChangeEvent<HTMLInputElement>) =>
                    onChange((isNumber ? valueAsNumber : value) as CommissioningFileValue<F>)
                }
                InputProps={{
                    sx: [fieldInternalInputStyle, cellInternalHorizontalPaddingStyle],
                    disableUnderline: true,
                    endAdornment: toggler,
                }}
            />
        )
    }

    if (
        (isFieldDataOfType<StringCommissioningFileField>(data, CommissioningFileFieldType.STRING) ||
            isFieldDataOfType<OptionsCommissioningFileField>(data, CommissioningFileFieldType.OPTIONS) ||
            isNumber) &&
        Array.isArray(data.field.allowableValues)
    ) {
        return (
            <Autocomplete<typeof data.value, false, boolean>
                value={correctFieldType(data, null)}
                onChange={(_, value) => onChange(value as CommissioningFileValue<F>)}
                // This casting is needed as autocomplete is not playing well if it
                options={data.field.allowableValues as unknown as NonNullable<typeof data.value>[]}
                disableClearable={!data.field.optional}
                renderInput={(params) => <AutocompleteTextField {...params} toggler={toggler} />}
                getOptionLabel={String}
                fullWidth
            />
        )
    }

    if (isFieldDataOfType<BooleanCommissioningFileField>(data, CommissioningFileFieldType.BOOLEAN)) {
        return (
            <Autocomplete<typeof data.value, false, boolean>
                value={correctFieldType(data, null)}
                onChange={(_, value) => onChange(value as CommissioningFileValue<F>)}
                // This casting is needed as autocomplete is not playing well if it
                options={(data.field.allowableValues as unknown as NonNullable<typeof data.value>[]) ?? [true, false]}
                disableClearable={!data.field.optional}
                renderInput={(params) => <AutocompleteTextField {...params} toggler={toggler} />}
                getOptionLabel={String}
                fullWidth
            />
        )
    }

    if (isFieldDataOfType<StringArrayCommissioningFileField>(data, CommissioningFileFieldType.STRING_ARRAY) && !Array.isArray(data.field.allowableValues)) {
        return (
            <Autocomplete<string, true, boolean, true>
                value={correctFieldType(data, []) ?? []}
                onChange={(_, value) => onChange((!isArrayNotEmpty(value) && !data.field.optional ? null : value) as CommissioningFileValue<F>)}
                options={[]}
                freeSolo
                multiple
                renderInput={(params) => <AutocompleteTextField {...params} toggler={toggler} />}
                ChipProps={{ size: 'small' }}
                getOptionLabel={String}
                fullWidth
            />
        )
    }

    throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Editing field type is not supported', data)
}

const inputWrapperStyle: SxProps<Theme> = { minWidth: 200 }
export const FieldInput = <F extends CommissioningFileField,>({ data, name, onChange, ...props }: FieldProps<F>): ReturnType<FC> => (
    <Stack {...props} sx={inputWrapperStyle} title={name} direction="row" justifyContent="space-between" alignContent="center">
        <AnchorProvider>
            <FieldInternalInput<F> data={data} onChange={onChange} toggler={<WarningPopoverToggler data={data} />} />
            <WarningPopover data={data} name={name} />
        </AnchorProvider>
    </Stack>
)

const titleStyle: SxProps<Theme> = { mb: 2 }
export const Section: FC<Readonly<{ title: Translation, titleValues?: ReadonlyRecord<string, ReactNode> }>> = ({
    title,
    titleValues = {},
    children,
    ...props
}) => (
    <Box {...props}>
        <Title sx={titleStyle} id={title} values={titleValues} />
        {children}
    </Box>
)
