import React, { type FC, useEffect } from 'react'
import { Grid, Stack, Typography } from '@mui/material'

import { RuleEngineType, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import type { JsonEditorFC } from '../../JsonEditor'
import { DetailsHeader, NoReRenderOnHover } from '../../common'

import { TypeDropdown } from './TypeDropdown'
import { useSelectedType, useTransformation } from './Hooks'
import { EndpointsDropdown } from './EndpointDropdown'
import { TopicsInput } from './TopicInput'
import { JsonInput } from './JsonInput'
import { LastMessage, LastMessageTitle } from './LastMessage'
import { TransformationInput, TransformationInputHelperText, TransformationInputTitle } from './TransformationInput'
import { TransformationOutput } from './TransformationOutput'

const Title: FC = ({ children }) => (
    <Typography marginTop={2} marginBottom={1} variant="body2" fontWeight="bold">
        {children}
    </Typography>
)

export const Sandbox: FC<{ Editor: JsonEditorFC }> = ({ Editor }) => {
    const type = useSelectedType()
    const transformation = useTransformation()

    const start = useAppUsecase('startSandboxUsecase')
    useEffect(() => start.invoke(), [start])

    return (
        <>
            <DetailsHeader
                title={Translation.RULE_ENGINE_SANDBOX}
                description={[Translation.RULE_ENGINE_SANDBOX_DOCUMENTATION_TITLE, Translation.RULE_ENGINE_SANDBOX_DOCUMENTATION_BODY]}
            />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Title>
                        <FormattedTranslation id={Translation.SELECT_TRANSFORMATION_SOURCE} />
                    </Title>
                    <TypeDropdown />
                </Grid>
                <Grid item xs={9}>
                    {type === RuleEngineType.ENDPOINT && (
                        <>
                            <Title>
                                <FormattedTranslation id={Translation.SELECT_ENDPOINT} />
                            </Title>
                            <EndpointsDropdown />
                        </>
                    )}

                    {type === RuleEngineType.MQTT_TOPIC && (
                        <>
                            <Title>
                                <FormattedTranslation id={Translation.INPUT_MQTT_TOPIC} />
                            </Title>
                            <TopicsInput />
                        </>
                    )}
                </Grid>
            </Grid>
            <Stack>
                {/* Input */}
                {type === RuleEngineType.JSON ? (
                    <>
                        <Title>
                            <FormattedTranslation id={Translation.TRANSFORMATION_INPUT} values={{ last: false }} />
                        </Title>
                        <JsonInput Editor={Editor} />
                    </>
                ) : (
                    <>
                        <NoReRenderOnHover>
                            <Title>
                                <LastMessageTitle />
                            </Title>
                        </NoReRenderOnHover>
                        <LastMessage />
                    </>
                )}

                {/* Transformation */}
                <Title>
                    <TransformationInputTitle />
                </Title>
                <TransformationInput />
                {type !== RuleEngineType.JSON && transformation && <TransformationInputHelperText />}

                {/* Output */}
                <Title>
                    <FormattedTranslation id={Translation.TRANSFORMATION_RESULT} />
                </Title>
                <TransformationOutput />
            </Stack>
        </>
    )
}
