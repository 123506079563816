import type { ReadonlyRecord } from '../../utils'

import type {
    ClientRegistryAuthorizationRequest,
    ClientRegistryData,
    ConnectwareError,
    CybusPermission,
    CybusRole,
    CybusUser,
    LdapConfiguration,
    PaginatedData,
    RoleCreationRequest,
    RoleEditingRequest,
    UserCreationRequest,
    UserEditingRequest,
} from '../../domain'

export type PasswordChangeRequest = ReadonlyRecord<'password' | 'newPassword', string>

export interface UserService {
    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchRolesPage(search: string, showInternal: boolean, page: number): Promise<PaginatedData<CybusRole>>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchRoles(search: string): Promise<CybusRole[]>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchRole(roleName: CybusRole['name']): Promise<CybusRole>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    createRole(form: RoleCreationRequest): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    updateRole(form: RoleEditingRequest): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    deleteRole(id: CybusRole['id']): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchUsersPage(search: string, showInternal: boolean, page: number): Promise<PaginatedData<CybusUser>>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchUsers(search: string): Promise<CybusUser[]>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchUser(username: CybusUser['username']): Promise<CybusUser>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    createUser(user: UserCreationRequest): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    updateUser(user: UserEditingRequest): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    deleteUser(id: CybusUser['id']): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchPermissionsPage(search: string, showInternal: boolean, page: number): Promise<PaginatedData<CybusPermission>>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    changePassword(request: PasswordChangeRequest): Promise<void>

    /**
     * @throws `ConnectwareError` only if there is a major issue
     * @see {ConnectwareError}
     *
     * Yields password validation errors {ConnectwareError} or confirmations {string}
     */
    validatePassword(password: string): Promise<(string | ConnectwareError)[]>

    /**
     * @throws `ConnectwareError` only if there is a major issue
     * @see {ConnectwareError}
     *
     * Yields username validation errors {ConnectwareError} or confirmations {string}
     */
    validateUsername(username: CybusUser['username']): Promise<(string | ConnectwareError)[]>

    /**
     * Fetch the Ldap configuration
     *  @throws `ConnectwareError`
     */
    fetchLdapConfiguration(): Promise<LdapConfiguration>
}

export interface ClientRegistryService {
    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    fetchData(): Promise<ClientRegistryData>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    lock(): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    unlock(): Promise<void>

    /**
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    authorize(request: ClientRegistryAuthorizationRequest): Promise<void>
}
