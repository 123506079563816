import { type CybusVolume, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase } from '../Resources'

export class BulkDeleteVolumesUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.VOLUME

    selectVolumes (ids: CybusVolume['id'][] | null): void {
        this.select(ids)
    }

    override delete (): Promise<void> {
        return super.delete()
    }
}
