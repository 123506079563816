import React, { type FC, useCallback, useEffect, useMemo } from 'react'
import { createExtendedState, ExtendedStateManager } from 'react-extended-state'

import type { Mutable } from 'utility-types'

import { areArrayEquals, EventListener, isArrayNotEmpty, normalize, type ReadonlyRecord, search as searchString } from '../../../../../utils'

import {
    arePermissionArrayEquals,
    CybusPermissionContext,
    CybusPermissionMerger,
    type CybusPersistedPermission,
    type EditableCybusPermission,
    type EditableCybusPermissionInheritance,
    type EditableCybusPermissionWithInheritance,
    type PermissionedForm,
} from '../../../../../domain'
import { useTranslator } from '../../../Internationalization'

type PermissionsTableState = Readonly<{
    context: CybusPermissionContext
    disabled: boolean
    canLink: boolean

    search: string

    inherited: EditableCybusPermissionInheritance[]
    required: CybusPersistedPermission[]

    merged: ReadonlyRecord<EditableCybusPermissionWithInheritance['context'], EditableCybusPermissionWithInheritance[]>

    validateNewPermission: (permission: EditableCybusPermission) => void

    onChange: (current: EditableCybusPermissionWithInheritance, updates: Pick<EditableCybusPermission, 'read' | 'write'>) => void
    onClear: () => void
    onAdd: (permission: EditableCybusPermission) => void
    onRemove: (permission: EditableCybusPermission) => void
}> &
    Pick<PermissionedForm, 'permissions'>

export type PermissionsTableProviderProps = Readonly<{
    disabled: PermissionsTableState['disabled']
    canLink?: PermissionsTableState['canLink']
    permissions: PermissionsTableState['permissions']
    required?: PermissionsTableState['required']
    inherited?: PermissionsTableState['inherited']

    usecase: { validateNewPermission(permission: EditableCybusPermission): void, update(update: Readonly<{ permissions: EditableCybusPermission[] }>): void }
}>

const { Provider, useExtendedState, useExtendedStateDispatcher } = createExtendedState<PermissionsTableState>({ ignorePropsChanges: true })

const createValidateNewPermission = (usecase: PermissionsTableProviderProps['usecase']) => (p: EditableCybusPermission) => usecase.validateNewPermission(p)

export const PermissionsTableProvider: FC<PermissionsTableProviderProps> = ({
    disabled,
    canLink = true,
    permissions,
    required = [],
    inherited = [],
    usecase,
    children,
}) => {
    const translator = useTranslator()

    const [merger, listener, manager] = useMemo(() => {
        const merger = new CybusPermissionMerger(permissions, required, inherited)

        const listener = new EventListener<EditableCybusPermission[]>()
        const manager = new ExtendedStateManager<PermissionsTableState>({
            disabled,
            canLink,

            context: CybusPermissionContext.HTTP,
            search: '',

            permissions,
            required,
            inherited,

            merged: merger.getPermissions(),

            validateNewPermission: createValidateNewPermission(usecase),

            onClear: (): void => listener.trigger(manager.getState().required),
            onChange: (p, { read, write }) => {
                if (p.permission === null) {
                    // There was no permission there, so we are actually creating one from the inherented permissions
                    listener.trigger([...manager.getState().permissions, { id: null, context: p.context, resource: p.resource, read, write }])
                } else if (isArrayNotEmpty(p.inheritanceRoles) && p.inheritancesRead >= read && p.inheritancesWrite >= write) {
                    // New version of persistence is useless so remove it
                    listener.trigger(manager.getState().permissions.filter((i) => i !== p.permission))
                } else {
                    // There is a permission, so update it
                    listener.trigger(manager.getState().permissions.map((i) => (i === p.permission ? { ...p.permission, read, write } : i)))
                }
            },
            // New version of persistence is useless so remove it
            onRemove: (p): void => listener.trigger(manager.getState().permissions.filter((i) => i !== p)),

            onAdd: (a): void => listener.trigger([...manager.getState().permissions, a]),
        })

        return [merger, listener, manager]
    }, [translator])

    /**
     * Handle listener changes without re setting all other state props
     */
    useEffect(() => listener.on((permissions) => usecase.update({ permissions })), [listener, usecase])
    useEffect(() => manager.setState({ validateNewPermission: createValidateNewPermission(usecase) }), [manager, usecase])

    /**
     * This takes care of updating the permissions internally
     */
    useEffect(() => {
        const addedContext = merger.setAll(permissions, required, inherited)
        const state: Partial<Mutable<PermissionsTableState>> = {
            merged: merger.getPermissions(),
            inherited,
            required,
            permissions,
        }
        if (isArrayNotEmpty(addedContext) && !addedContext.includes(manager.getState().context)) {
            state.context = addedContext[0]
        }
        manager.setState(state)
    }, [merger, permissions, inherited, manager])

    useEffect(() => manager.setState({ disabled, canLink }), [disabled, canLink, manager])

    return <Provider manager={manager}>{children}</Provider>
}

export const useCanLink = (): PermissionsTableState['canLink'] => useExtendedState((s) => s.canLink)

export const useDisabled = (): PermissionsTableState['disabled'] => useExtendedState((s) => s.disabled)

export const useSearch = (): PermissionsTableState['search'] => useExtendedState((s) => s.search)

export const useContext = (): PermissionsTableState['context'] => useExtendedState((s) => s.context)

export const useClearingDispatcher = (): PermissionsTableState['onClear'] | null =>
    useExtendedState((s) => (!arePermissionArrayEquals(s.permissions, s.required) ? s.onClear : null))

export const useChangeDispatcher = (): PermissionsTableState['onChange'] => useExtendedState((s) => s.onChange)

export const useAdditionDispatcher = (): PermissionsTableState['onAdd'] => useExtendedState((s) => s.onAdd)

export const useRemoveDispatcher = (): PermissionsTableState['onRemove'] => useExtendedState((s) => s.onRemove)

export const useNewPermissionValidation = (): PermissionsTableState['validateNewPermission'] => useExtendedState((s) => s.validateNewPermission)

export const usePermissions = (): [isSearching: boolean, permissions: EditableCybusPermissionWithInheritance[]] => {
    const permissions = useExtendedState(
        (s) => s.merged[s.context],
        (a: unknown[], b: unknown[]) => areArrayEquals(a, b, { sort: false })
    )
    const search = useSearch()

    return useMemo(() => {
        const normalized = normalize(search.trim())
        const isSearching = Boolean(normalized.length)
        return [isSearching, !isSearching ? permissions : permissions.filter((p) => searchString(normalize(p.resource), [normalized]))]
    }, [permissions, search])
}

export const useContextDispatcher = (): ((newContext: PermissionsTableState['context']) => void) => {
    const dispatch = useExtendedStateDispatcher()
    return useCallback((context) => dispatch({ context }), [dispatch])
}

export const useSearchDispatcher = (): ((newSearch: string) => void) => {
    const dispatch = useExtendedStateDispatcher()
    return useCallback((search) => dispatch({ search }), [dispatch])
}
