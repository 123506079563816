import type { Certificate } from '../../../domain'

import { CertificatesUsecase } from './Base'

export class DeleteCertificateUsecase extends CertificatesUsecase {
    async delete (certificate: Certificate): Promise<void> {
        /** Delete certificate */
        await this.certificatesService.delete(certificate)

        /** Remove certificates from state */
        this.updateCertificates((certificates) => certificates.filter((c) => c !== certificate))
    }
}
