import type { ReadonlyRecord } from '../Types'
import { isArrayNotEmpty } from '../Array'

export const copyWithoutKeys = <T, K extends keyof T>(original: T, ...keys: K[]): Omit<T, K> => {
    const copy = { ...original }

    for (const k of keys) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete copy[k]
    }

    return copy
}

export const keys: <T extends object>(obj: T) => (keyof T)[] = Object.keys

export const isEmpty = <T extends ReadonlyRecord<string, unknown>>(obj: T): boolean => !isArrayNotEmpty(keys(obj))
