import React, { type FC, type ReactNode } from 'react'
import { Box, Collapse, collapseClasses, Divider, IconButton, Paper, Stack, type SxProps, type Theme } from '@mui/material'
import { ExpandLessOutlined } from '@mui/icons-material'

import { useIsRightColumnOpen, useRightColumnToggler } from './State'
import { SideNavigation } from './SideNavigation'
import { Breadcrumbs } from './Breadcrumbs'

const toggleButtonStyle: SxProps<Theme> = { borderRadius: '50%', width: 'fit-content', height: 'fit-content' }
const toggleButtonIconOpenStyle: SxProps<Theme> = { transform: 'rotate(90deg)' }
const toggleButtonIconCloseStyle: SxProps<Theme> = { transform: 'rotate(270deg)' }
const ToggleButton: FC = () => {
    const open = useIsRightColumnOpen()
    const toggle = useRightColumnToggler()

    return (
        <Paper sx={toggleButtonStyle}>
            <IconButton data-testid="collapse-toggler" color="primary" size="small" onClick={toggle}>
                <ExpandLessOutlined sx={open ? toggleButtonIconOpenStyle : toggleButtonIconCloseStyle} fontSize="small" />
            </IconButton>
        </Paper>
    )
}

const toggleColumnStyle: SxProps<Theme> = {
    height: '100%',
    mx: 2,
    '& > *': { height: '100%', width: '100%', '&:not(:last-of-type)': { marginRight: '-100%' } },
}
const toggleColumnButtonWrapperStyle: SxProps<Theme> = { pt: 4 }
const ToggleColumn: FC = () => (
    <Stack direction="row" sx={toggleColumnStyle}>
        <Stack direction="row" justifyContent="center">
            <Divider orientation="vertical" />
        </Stack>
        <Stack direction="row" justifyContent="center" sx={toggleColumnButtonWrapperStyle}>
            <ToggleButton />
        </Stack>
    </Stack>
)

const PADDING = 4
const paddedContainerStyle: SxProps<Theme> = { p: PADDING }

const collapseContentStyle: SxProps<Theme> = { px: PADDING / 2 }
/**
 * Due to the possiblity of the collapsed section on the right
 * to sometimes be empty (despite a RightContent component being passed)
 *
 * This CSS gambiarra was created to check if the collapse is is empty
 */
const hiddenIfCollapseIsEmptyStyle: SxProps<Theme> = {
    [[
        /** Hide is next sibling collapsable children are empty */
        `&:has(+ * .${collapseClasses.wrapperInner}:empty)`,
        /** Hide current content if children are empty */
        `&:has(.${collapseClasses.wrapperInner}:empty)`,
        /**
         * If collapse is hidden, then finally hide it
         * collapse children can not be unmounted so the other selectors work
         */
        `&.${collapseClasses.hidden}`,
    ].join(', ')]: { display: 'none' },
}
const Collapsable: FC = ({ children }) => {
    const open = useIsRightColumnOpen()
    return (
        <Collapse sx={[paddedContainerStyle, collapseContentStyle, hiddenIfCollapseIsEmptyStyle]} in={open} timeout="auto" orientation="horizontal">
            {children}
        </Collapse>
    )
}

const columnStyle: SxProps<Theme> = { height: '100%', overflowY: 'auto' }
const expandedColumnStyle: SxProps<Theme> = { flexGrow: 1, width: 0 }
const staticColumnStyle: SxProps<Theme> = { flexShrink: 0, overflowX: 'hidden' }
export const RouteNavigation: FC<{ rightContent?: ReactNode }> = ({ rightContent, children }) => (
    <Stack flexGrow={1} direction="row">
        <Box sx={[columnStyle, staticColumnStyle]}>
            <SideNavigation />
        </Box>
        <Box sx={[columnStyle, staticColumnStyle]}>
            <Divider orientation="vertical" />
        </Box>
        <Box sx={[columnStyle, paddedContainerStyle, expandedColumnStyle]}>
            <Breadcrumbs />
            {children}
        </Box>
        <Box sx={[columnStyle, staticColumnStyle, hiddenIfCollapseIsEmptyStyle]}>
            <ToggleColumn />
        </Box>
        <Box sx={[columnStyle, staticColumnStyle, hiddenIfCollapseIsEmptyStyle]}>
            <Collapsable>{rightContent}</Collapsable>
        </Box>
    </Stack>
)
