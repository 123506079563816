import type { ReadonlyRecord } from '../utils'

export enum ConnectwareErrorType {
    /**
     * Used whenever there are authentication issues on the application
     * @example user was not authenticated to use X or Y resource
     */
    AUTHENTICATION = 'AUTHENTICATION',

    /**
     * Used whenever there was a request deemed invalid by an external service
     */
    BAD_REQUEST = 'BAD_REQUEST',

    /**
     * Used when there is some configuration error on the application
     * @example license is badly configured or some service is not properly configured to run
     */
    CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',

    /**
     * For whenever a business rule does not allow an action
     */
    GENERAL_BUSINESS_RULE_INFRACTION = 'GENERAL_BUSINESS_RULE_INFRACTION',

    /**
     * If there was an issue while mapping some external comunication response, this is the type to use
     */
    MAPPING_ERROR = 'MAPPING_ERROR',

    /**
     * Used if a resource, that was to be loaded or manipulated, was not found
     */
    NOT_FOUND = 'NOT_FOUND',

    /**
     * Generic external server error
     */
    SERVER_ERROR = 'SERVER_ERROR',

    /**
     * Error if server being contacted could not be reached
     */
    SERVER_NOT_AVAILABLE = 'SERVER_NOT_AVAILABLE',

    /**
     * Error if server runs into any kind of timeout
     */
    SERVER_TIMEOUT = 'SERVER_TIMEOUT',

    /**
     * Use when there is an issue creating a service
     */
    SERVICE_CREATION_ERROR = 'SERVICE_CREATION_ERROR',

    /**
     * Used when there is an issue manipulating the state of the application
     * and an unexpected state is found
     */
    STATE = 'STATE',

    /**
     * For translation related errors
     */
    TRANSLATIONS = 'TRANSLATIONS',

    /**
     * For other unexpected scenarios
     */
    UNEXPECTED = 'UNEXPECTED',
}

export class ConnectwareError<
    Type extends ConnectwareErrorType = ConnectwareErrorType,
    Extras extends ReadonlyRecord<string | number, unknown> = ReadonlyRecord<string | number, unknown>
> extends Error {
    readonly type: Type

    readonly extras: Extras

    static is (e: unknown): e is ConnectwareError {
        return e instanceof ConnectwareError
    }

    static isOfTypes<T extends ConnectwareErrorType> (e: unknown, ...types: T[]): e is ConnectwareError<T> {
        return this.is(e) && types.includes(e.type as T)
    }

    constructor (typeOrError: Type | ConnectwareError<Type, Extras>, message?: string, extras?: Extras) {
        /**
         * First use customized message
         * Then inherited message
         * Finally no message
         */
        super(message || (ConnectwareError.is(typeOrError) && typeOrError.message) || 'No message')
        this.type = ConnectwareError.is(typeOrError) ? typeOrError.type : typeOrError
        this.extras = extras ?? (ConnectwareError.is(typeOrError) ? typeOrError.extras : ({} as Extras))
    }
}

export type ConnectwareErrorExtras<E extends ConnectwareError> = E extends ConnectwareError<ConnectwareErrorType, infer U> ? U : never
