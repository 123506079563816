import React, { type FC } from 'react'
import { Box, Divider, linkClasses, type SxProps, type Theme } from '@mui/material'

import { executeOnce } from '../../../utils'

import { AbsoluteRouteOnlyPath, PermissionedLink, useRouteFormatter, useRouting } from '../routing'

type Props = Readonly<{ route: AbsoluteRouteOnlyPath }>

export const SIDEBAR_WIDTH = 250

const linkStyle: SxProps<Theme> = {
    display: 'block',
    lineHeight: 1,
    fontSize: 'body2.fontSize',
    borderRadius: 0.25, // 1px
    py: 1.25,
    px: 2,
    '&:hover': { backgroundColor: 'grey.100' },
}
const selectedLinkStyle: SxProps<Theme> = { backgroundColor: 'background.lightblue', fontWeight: 500 }

const NavigationLink: FC<Props> = ({ route }) => {
    const routing = useRouting()
    const formatter = useRouteFormatter()

    const selected = routing.isAtRoute(route)

    return (
        <PermissionedLink
            title={formatter.formatDescription(route) ?? undefined}
            sx={[linkStyle, selected ? selectedLinkStyle : null]}
            color="inherit"
            path={route}
            wrapper={({ authorized, children }) => (authorized ? <>{children}</> : null)}
        >
            {formatter.formatTitle(route)}
        </PermissionedLink>
    )
}

const listStyle: SxProps<Theme> = {
    my: 3.5,
    mx: 4,
    // Adding a bottom margin of 16px to all menu item links except the last one
    [`&>a.${linkClasses.root}:not(:last-child)`]: {
        mb: 2,
    },
}
const NavigationLinkList: FC<{ routes: AbsoluteRouteOnlyPath[] }> = ({ routes, ...props }) => (
    <Box sx={listStyle} {...props}>
        {routes.map((route) => (
            <NavigationLink key={route} route={route} />
        ))}
    </Box>
)

const getAllRouteOnlyPaths = executeOnce(() => Object.values(AbsoluteRouteOnlyPath))
const drawerStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column', height: '100%', width: SIDEBAR_WIDTH }
const dividerStyle: SxProps<Theme> = { mx: 4 }

export const SideNavigation: FC = () => {
    const routing = useRouting()
    const [firstLevel, secondLevel] = routing.getFirstChildren(getAllRouteOnlyPaths())

    return (
        <Box sx={drawerStyle}>
            <NavigationLinkList data-testid="side-navigation-1" routes={firstLevel} />
            {secondLevel.length > 0 && (
                <>
                    <Divider data-testid="side-navigation-divider" sx={dividerStyle} />
                    <NavigationLinkList data-testid="side-navigation-2" routes={secondLevel} />
                </>
            )}
        </Box>
    )
}
