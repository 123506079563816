import React, { type FC, useEffect, useState } from 'react'
import { Box, CircularProgress, type SxProps, type Theme, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { isEnumOf, type ReadonlyRecord } from '../../../../utils'

import { ConnectwareError, StatusType, type SystemTopicSource, TopicType, Translation } from '../../../../domain'

import { AbsoluteRoutePathWithId, PermissionedLink } from '../../routing'
import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { useIfHookedCallback } from '../../Droppable'

import { createHandlerWithoutPropagation } from '../..'
import { Popover, Status, useAnchorCloser, useAnchorSetter } from '../../common'
import { ErrorMessage, errorMessagesClasses } from '../../ErrorMessage'

const resourcesWrapperStyle: SxProps<Theme> = { p: 1 }
const resourceWrapperStyle: SxProps<Theme> = { mb: 1, '&:last-of-type': { mb: 0 } }
const infoStyle: SxProps<Theme> = { display: 'flex', '& > *': { m: 'auto 0' } }
const statusStyle: SxProps<Theme> = { mr: 'auto' }
const errorStyle: SxProps<Theme> = { [`& .${errorMessagesClasses.header}`]: { ml: 1 }, [`& .${errorMessagesClasses['popover-button']}`]: { mr: 1 } }

const routeMap: ReadonlyRecord<Exclude<TopicType, TopicType.CUSTOM>, AbsoluteRoutePathWithId | null> = {
    [TopicType.MAPPING]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_MAPPING,
    [TopicType.ENDPOINT]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_ENDPOINT,
    // There is no where to go
    [TopicType.NODE]: null,
}

const ResourceInfo: FC<{ source: SystemTopicSource }> = ({ source: { type, id } }) => {
    const [status, setStatus] = useState<StatusType | ConnectwareError | null>(null)
    const isHooked = useIfHookedCallback()

    const subscribeUsecase = useAppUsecase('explorerResourceStatusUsecase')

    /** Subscribe to the status changes of the given resource */
    useEffect(() => subscribeUsecase.subscribe(type, id, (status) => isHooked(() => setStatus(status))), [subscribeUsecase, type, id])

    const path = routeMap[type]

    return (
        <Box sx={resourceWrapperStyle}>
            <Typography data-testid="topics-resource-info-title" variant="subtitle1">
                {path ? (
                    <PermissionedLink
                        path={path}
                        id={id}
                        wrapper={({ authorized, children }) =>
                            authorized ? <FormattedTranslation id={Translation.GO_TO} values={{ destination: children }} /> : <>{children}</>
                        }
                    >
                        {id}
                    </PermissionedLink>
                ) : (
                    id
                )}
            </Typography>
            <Box sx={infoStyle}>
                <Typography sx={statusStyle} variant="body1">
                    <FormattedTranslation id={Translation.STATUS} />
                </Typography>
                {status === null && <CircularProgress data-testid="topics-resource-info-loader" size={16} />}
                {isEnumOf(StatusType, status) && <Status data-testid="topics-resource-info-status" status={status} />}
                {ConnectwareError.is(status) && (
                    <ErrorMessage sx={errorStyle} data-testid="topics-resource-info-error" titleVariant="body2" extras="popover" error={status} />
                )}
            </Box>
        </Box>
    )
}

const stopPropagation = createHandlerWithoutPropagation()

export const ResourcesPopover: FC<{ source: SystemTopicSource[] }> = ({ source }) => {
    const setAnchor = useAnchorSetter()
    const close = useAnchorCloser()

    return (
        <>
            <InfoOutlined
                className="show-topic-info"
                color="action"
                fontSize="medium"
                onClick={createHandlerWithoutPropagation((e) => setAnchor(e.currentTarget))}
            />
            <Popover
                id="topics-resource-info"
                onClick={stopPropagation}
                onClose={createHandlerWithoutPropagation(close)}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                elevation={1}
            >
                <Box sx={resourcesWrapperStyle}>
                    {source.map((source, k) => (
                        <ResourceInfo key={k} source={source} />
                    ))}
                </Box>
            </Popover>
        </>
    )
}
