import { BelatedThrottler, ChangeEventListener, Droppable } from '../../../../utils'
import type { ConnectwareError } from '../../../../domain'
import type {
    CountSubscriptionsTypes,
    PageSubscription,
    PageSubscriptionsTypes,
    SingleSubscriptionsTypes,
    SubscriptionEvent,
    SubscriptionEventHandler,
    SubscriptionFilterArgs,
    SubscriptionPageOptions,
    SubscriptionsService,
    SubscriptionsTypes,
    TranslationService,
} from '../../../../application'

import { PageSubscriptionAdapter, type SubscriptionListEvent } from '../../../Connectware'
import type { VrpcRemoteManager } from '../../utils'
import { getVrpcSubscriptionHandler, type VrpcInstanceTypes, type VrpcSubscriptionHandler } from './handlers'
import { CountSubscriptionMapperBuilder, ListSubscriptionMapperBuilder, PageSubscriptionMapperBuilder, SingleSubscriptionMapperBuilder } from './builders'
import type { DataMapper } from './mappers'

/**
 * This service implementation works with mapping VRPC Resources (Such as remotes or instances)
 * And maps them (as dictated by the handlers' implementation) into usable data that can be sent to the subscription listeners
 *
 * At the top level, a handler is matched with a mapper,
 * which is then fed the remote, so it internaly maps whatever responses it has into domain events
 *
 * @see {DataMapper}
 *
 * @deprecated VRPC is in the process of being removed, proceed with caution
 * @todo remove when possible
 */
export class VrpcSubscriptionsService implements SubscriptionsService {
    private readonly pageSubscriptionAdapter = new PageSubscriptionAdapter(async (eventName, filter, listener) => {
        return this.createSimpleSubscription<typeof eventName, SubscriptionListEvent<typeof eventName>>(
            eventName,
            (handler) => new ListSubscriptionMapperBuilder(handler).withFilter(filter).build(),
            listener
        )
    }, this.translationService)

    protected throttleRate = 50

    protected readonly getHandler: <T extends keyof SubscriptionsTypes>(eventName: T) => VrpcSubscriptionHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]> =
        getVrpcSubscriptionHandler

    constructor (private readonly remote: VrpcRemoteManager, private readonly translationService: TranslationService) {}

    private createSubscription<T extends keyof SubscriptionsTypes, Data, Subscription> (
        handlerOrEventName: VrpcSubscriptionHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]> | T,
        createTupple: (
            throttler: BelatedThrottler,
            handler: VrpcSubscriptionHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]>
        ) => [DataMapper<Data>, Subscription]
    ): Subscription {
        const throttler = new BelatedThrottler(this.throttleRate)

        const handler = typeof handlerOrEventName === 'string' ? this.getHandler(handlerOrEventName) : handlerOrEventName

        /**
         * Create the objects that will
         * - do the converation from remote to their respective output
         * - Be yielded as a subscription
         */
        const [mapper, subscription] = createTupple(throttler, handler)

        /**
         * Feed in the remove so the mappert can do its thing
         */
        mapper.feed(this.remote)

        /**
         * Yield the subscription
         */
        return subscription
    }

    /**
     * @returns a simple subscription that can be undone by calling the returned function
     */
    private createSimpleSubscription<T extends keyof SubscriptionsTypes, Data> (
        eventName: T,
        createMapper: (handler: VrpcSubscriptionHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]>) => DataMapper<Data>,
        listener: (events: Data | ConnectwareError) => void
    ): Promise<VoidFunction> {
        return Promise.resolve(
            this.createSubscription(eventName, (throttler, handler) => {
                const mapper = createMapper(handler)

                const droppable = new Droppable()

                droppable.onDrop(mapper.onError(listener))
                droppable.onDrop(mapper.onChange((data) => throttler.run(() => listener(data))))
                droppable.onDrop(() => mapper.off())

                return [mapper, () => droppable.drop()]
            })
        )
    }

    async subscribeToPage<T extends keyof PageSubscriptionsTypes> (
        eventName: T,
        allOptions: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>> {
        const handler = this.getHandler(eventName)

        if (ListSubscriptionMapperBuilder.supports(handler.type)) {
            /**
             * This is not a true page subscription,
             * but a list subscription behind the adapter pagination
             */
            return this.pageSubscriptionAdapter.subscribeToPage(eventName, allOptions)
        }

        const { search, sort, pagination, ...filter } = allOptions

        /**
         * This pagination is handled internally on VRPC
         */
        return this.createSubscription(handler, (throttler, handler) => {
            const changeListener = new ChangeEventListener<SubscriptionPageOptions<T>>({ search, sort, pagination })

            const mapper = new PageSubscriptionMapperBuilder(handler).withFilter(filter).withChangeListener(changeListener).build()

            return [
                mapper,
                {
                    update: (args) => changeListener.change({ ...changeListener.value, ...args }),
                    onData: (listener) => {
                        const droppable = new Droppable()

                        droppable.onDrop(mapper.onError(listener))
                        droppable.onDrop(mapper.onChange((data) => throttler.run(() => listener(data))))

                        return () => droppable.drop()
                    },
                    stop: () => mapper.off(),
                },
            ]
        })
    }

    async subscribeToOne<T extends keyof SingleSubscriptionsTypes> (eventName: T, id: string, listener: SubscriptionEventHandler<T>): Promise<VoidFunction> {
        return this.createSimpleSubscription<T, SubscriptionEvent<T>>(
            eventName,
            (handler) => new SingleSubscriptionMapperBuilder(handler).withId(id).build(),
            listener
        )
    }

    async subscribeToCount<T extends keyof CountSubscriptionsTypes> (eventName: T, listener: (count: number | ConnectwareError) => void): Promise<VoidFunction> {
        return this.createSimpleSubscription<T, number | ConnectwareError>(
            eventName,
            (handler) => new CountSubscriptionMapperBuilder(handler).build(),
            listener
        )
    }
}
