import type { Mutable } from 'utility-types'

import { areArrayEquals, Droppable, objectEntries, type ReadonlyRecord } from '../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    type CybusServiceDataResource,
    selectServicesDataTopics,
    selectServiceTopicsMessages,
    type ServicesAppState,
    type SortableColumn,
    type TopicMessageEvent,
    type TopicSubscriptionError,
    type TopicSubscriptionResponse,
} from '../../../domain'

import { initialState } from '../..'
import { ResourcePageSubscriptionUsecase, Usecase } from '..'

export class ManageServicesTopicsUsecase extends ResourcePageSubscriptionUsecase<'serviceResources'> {
    protected readonly pageName = 'serviceResources'

    protected readonly pageAddress = 'servicesDataPage'

    protected readonly initialSortColumn: SortableColumn<CybusServiceDataResource> = 'id'
}

export class LoadServiceDataUsecase extends Usecase {
    private static generateInitialMessages (topics: string[], response: TopicSubscriptionResponse): ServicesAppState['serviceTopicsMessages'] {
        const errorEntries = objectEntries<ReadonlyRecord<TopicSubscriptionError, Set<string>>>({
            invalid: new Set(response.invalid),
            noPermissions: new Set(response.noPermissions),
            unknown: new Set(response.unknown),
            shared: new Set(response.shared),
        })

        return topics.reduce<ServicesAppState['serviceTopicsMessages']>((r, topic) => {
            const errorEntry = errorEntries.find(([, topics]) => topics.has(topic))

            if (!errorEntry) {
                return r
            }

            const [error] = errorEntry

            return {
                ...r,
                [topic]: new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Had issues subscribing to the topic', { topic, error }),
            }
        }, {})
    }

    private generateBatchMessages (batch: TopicMessageEvent[]): ServicesAppState['serviceTopicsMessages'] {
        const messages: Mutable<ServicesAppState['serviceTopicsMessages']> = {}

        for (const message of batch) {
            for (const topic of message.sources) {
                const serializedTocic = this.topicsService.serializeTopic(topic)

                messages[serializedTocic] = { payload: message.payload, possibleTypes: message.possibleTypes, timestamp: message.timestamp }
            }
        }

        return messages
    }

    private setServiceTopicsMessages (update: ServicesAppState['serviceTopicsMessages'], clear: boolean): void {
        const previousMessages = clear ? {} : selectServiceTopicsMessages(this.getState())
        this.setState({ serviceTopicsMessages: { ...previousMessages, ...update } })
    }

    invoke (): VoidFunction {
        const droppable = new Droppable()

        const subscription = this.topicsService.create()

        /** Listen to changes to the page and if updates occur, then re-subscribe to new topics */
        droppable.onDrop(
            this.subscribeToState(
                (previous, current) => areArrayEquals(selectServicesDataTopics(previous), selectServicesDataTopics(current), { sort: false }),
                () => {
                    const topics = Array.from(new Set(selectServicesDataTopics(this.getState())))

                    void subscription
                        .subscribe(topics)
                        .then((response) =>
                            droppable.ifNotDropped(() => this.setServiceTopicsMessages(LoadServiceDataUsecase.generateInitialMessages(topics, response), true))
                        )
                }
            )
        )

        /** Listen to the topic batches and records the messages into the state */
        droppable.onDrop(subscription.onBatch((batch) => droppable.ifNotDropped(() => this.setServiceTopicsMessages(this.generateBatchMessages(batch), false))))

        /** When dropping, kill the subscription */
        droppable.onDrop(() => subscription.end())

        /** Set state back to initial */
        droppable.onDrop(() => this.setServiceTopicsMessages(selectServiceTopicsMessages(initialState), true))

        return () => droppable.drop()
    }
}
