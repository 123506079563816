import React, { type FC, type ReactNode } from 'react'

import { Capability, createIsAuthenticatedWithCapabilitiesSelector, Translation } from '../../../domain'

import { useAppState } from '../State'
import { DetailsHeader } from '../common'

import { MfaSettings } from './MFA'
import { Sections } from './Section'
import { ChangePasswordSettings } from './ChangePassword'

const selectCanManageMfa = createIsAuthenticatedWithCapabilitiesSelector(Capability.USE_MFA)

export const LoginSettings: FC = () => {
    const canUseMfa = useAppState(selectCanManageMfa)

    const elements: ReactNode[] = []

    elements.push(<ChangePasswordSettings key={elements.length} />)
    if (canUseMfa) {
        elements.push(<MfaSettings key={elements.length} />)
    }

    return (
        <>
            <DetailsHeader
                title={Translation.LOGIN_SETTINGS}
                description={[Translation.LOGIN_SETTINGS_DOCUMENTATION_TITLE, Translation.LOGIN_SETTINGS_DOCUMENTATION_BODY]}
            />
            <Sections>{elements}</Sections>
        </>
    )
}
