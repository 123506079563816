import React, { type FC } from 'react'
import { Breadcrumbs as BaseBreadcrumbs, Link, type SxProps, type Theme } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'

import { PermissionedLink, useRouteFormatter, useRouting } from '../routing'

const linkStyle: SxProps<Theme> = { color: 'info.main', cursor: 'pointer' }

export const Breadcrumbs: FC = () => {
    const routing = useRouting()
    const routeFormatter = useRouteFormatter()

    const [current, ...parents] = routing.getNavigation()
    const currentTitle = routeFormatter.formatTitle(current)

    return (
        <BaseBreadcrumbs data-testid="breadcrumbs" separator={<NavigateNext fontSize="small" />}>
            {parents.reverse().map((route) => {
                const title = routeFormatter.formatTitle(route)
                return (
                    <PermissionedLink key={route} sx={linkStyle} parent={route} title={title}>
                        {title}
                    </PermissionedLink>
                )
            })}
            <Link color="inherit" underline="none" title={currentTitle}>
                {currentTitle}
            </Link>
        </BaseBreadcrumbs>
    )
}
