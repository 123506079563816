import React, { type FC } from 'react'

import { type CybusRole, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { useTranslator } from '../../Internationalization'
import { useAsyncCallback } from '../../Callback'

import { Page } from '../Page'

export const RolesPage: FC = () => {
    const translator = useTranslator()
    const { editRoleUsecase, createRoleUsecase } = useAppUsecases()
    const [onAdd] = useAsyncCallback(() => createRoleUsecase.start(), [createRoleUsecase])
    const [onRowClick] = useAsyncCallback((role: CybusRole) => editRoleUsecase.start(role), [editRoleUsecase])

    return (
        <Page
            selected="roles"
            columns={{
                name: { label: Translation.NAME },
                users: {
                    label: translator.formatTranslation(Translation.USER, { count: 2 }),
                    customCellRender: (users) => translator.formatTranslation(Translation.ACTIVE_USER, { count: users.length }),
                },
                permissions: {
                    label: translator.formatTranslation(Translation.PERMISSION, { count: 0 }),
                    customCellRender: (permissions) =>
                        `${permissions.length} ${translator.formatTranslation(Translation.PERMISSION, { count: permissions.length }).toLowerCase()}`,
                },
            }}
            notFound={Translation.NO_ROLES_FOUND}
            onAdd={onAdd}
            addLabel={Translation.ADD_ROLE}
            onRowClick={onRowClick}
        />
    )
}
