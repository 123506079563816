import type { SubscriptionsTypes } from '../../../../../../application'

import { mapCybusResourceDeviations, mapDetailedService, mapToStatusType, SERVICE_AGENT } from '../../../../../Connectware'

import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type DetailedServiceHandler = VrpcInstanceToOneSubscriptionHandler<ServiceProxy, SubscriptionsTypes['service']>
type DetailedServiceHandlerArgs = VrpcHandlerMappingPropertiesArgs<DetailedServiceHandler>

export class VrpcDetailedServiceProxyInstanceHandler implements DetailedServiceHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    /**
     * @deprecated these handlings will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    async mapToDomain ({ instance }: DetailedServiceHandlerArgs['DomainMapperArgs']): Promise<DetailedServiceHandlerArgs['Domain']> {
        const [params, dependencies] = await Promise.all([instance.getParams(), instance.getDependencies()])

        /**
         * @deprecated update code block so resource deviations is fetched externally
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const resourceDeviations = mapCybusResourceDeviations(params.deviation)

        /**
         * @deprecated this method will no longer provide currentState field
         * @todo consider using another field
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const currentState = mapToStatusType(params.currentState)
        return mapDetailedService(params, currentState, dependencies, resourceDeviations)
    }
}
