import type { ManagedVrpcRemote } from '../../../../utils'
import type { VrpcHandlerMappingPropertiesArgs, VrpcRemoteToListSubscriptionHandler } from '../../handlers'

import { BaseResourceManager } from './Base'

/**
 * Maps remotes into many
 */
export class RemoteHandlerManager<Domain> extends BaseResourceManager<VrpcRemoteToListSubscriptionHandler<Domain>, ManagedVrpcRemote> {
    protected readonly errorMessage = 'Error while interacting with remote'

    protected getMapToDomainArgs (): Promise<VrpcHandlerMappingPropertiesArgs<VrpcRemoteToListSubscriptionHandler<Domain>>['DomainMapperArgs']> {
        return Promise.resolve({ remote: this.aux })
    }

    protected getOnChangeArgs (): Promise<VrpcHandlerMappingPropertiesArgs<VrpcRemoteToListSubscriptionHandler<Domain>>['OnChangeArgs']> {
        return Promise.resolve({ remote: this.aux, listener: this.createValueUpdater() })
    }
}
