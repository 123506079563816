import { isLoadableLoaded, type Loadable } from './Loadable'

export type ConnectwareLicense =
    | Readonly<{ id: null, activated: false, account: null, name: null, class: null, expiration: null, valid: false }>
    | Readonly<{ id: string, activated: true, account: string, name: string, class: string, expiration: Date, valid: boolean }>

export type LicenseAppState = Readonly<{ license: Loadable<ConnectwareLicense> }>

export const selectLicense = (s: LicenseAppState): LicenseAppState['license'] => s.license

const createLicenseSelector =
    <P extends keyof ConnectwareLicense>(prop: P) =>
    (s: LicenseAppState): ConnectwareLicense[P] | null => {
        const license = selectLicense(s)
        return isLoadableLoaded(license) ? license[prop] : null
    }

export const selectLicenseId = createLicenseSelector('id')
export const selectIsLicenseValid = createLicenseSelector('valid')
