import type { ReadonlyRecord } from '../../../utils'
import { CybusPermissionContext } from '../../../domain'

import type { PermissionContext } from '..'

const contextsBackendMap: ReadonlyRecord<PermissionContext, CybusPermissionContext> = { http: CybusPermissionContext.HTTP, mqtt: CybusPermissionContext.MQTT }
const contextsFrontendMap: ReadonlyRecord<CybusPermissionContext, PermissionContext> = {
    [CybusPermissionContext.HTTP]: 'http',
    [CybusPermissionContext.MQTT]: 'mqtt',
}

export const mapContextToPermissionContext = (context: PermissionContext): CybusPermissionContext => contextsBackendMap[context]
export const mapPermissionContextToContext = (context: CybusPermissionContext): PermissionContext => contextsFrontendMap[context]
