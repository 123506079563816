import type { EventListener } from '../../../utils'
import { type Capability, ConnectwareError, ConnectwareErrorType } from '../../../domain'
import type {
    CountSubscriptionsTypes,
    PageSubscription,
    PageSubscriptionsTypes,
    SingleSubscriptionsTypes,
    SubscriptionEventHandler,
    SubscriptionFilterArgs,
    SubscriptionPageOptions,
    SubscriptionsService,
    SubscriptionsTypes,
    TranslationService,
} from '../../../application'

import { PageSubscriptionAdapter } from '../../Connectware'
import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService } from '../Base'
import { Fetcher } from './Fetcher'
import { getSubscriptionStrategyFactory, type SubscriptionStrategy } from './strategies'

type SubscriptionsServiceOptions = Readonly<{
    getCapabilities: () => Capability[]
    translationService: TranslationService
    resourceChangeListeners: Pick<EventListener<void>, 'on'>
}>

/**
 * The vrpc alternative to subscribing to the changes of resources on the Connectware
 * Currently it is very limited, to only simple services list
 */
export class ConnectwareHTTPSubscriptionsService extends FetchConnectwareHTTPService implements SubscriptionsService {
    private readonly pageSubscriptionAdapter: PageSubscriptionAdapter
    private readonly getCapabilities: SubscriptionsServiceOptions['getCapabilities']
    private readonly resourceChangeListeners: SubscriptionsServiceOptions['resourceChangeListeners']

    constructor ({ resourceChangeListeners, getCapabilities, translationService, ...options }: SubscriptionsServiceOptions & ConnectwareHTTPServiceOptions) {
        super(options)

        this.getCapabilities = getCapabilities
        this.resourceChangeListeners = resourceChangeListeners

        this.pageSubscriptionAdapter = new PageSubscriptionAdapter(
            (eventName, filter, listener) =>
                this.subscribe(eventName, (strategy) =>
                    new Fetcher(
                        () =>
                            strategy.supportsFilters(filter)
                                ? strategy.retrieveAll(filter)
                                : Promise.reject(
                                      new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'This strategy does NOT support this filter', { filter })
                                  ),
                        (...args) => strategy.compareAll(...args)
                    ).onChange(listener)
                ),
            translationService
        )
    }

    private subscribe<T extends keyof SubscriptionsTypes, F> (
        eventName: T,
        fetcherCreator: (strategy: SubscriptionStrategy<T>) => Fetcher<F>
    ): Promise<VoidFunction> {
        const Strategy = getSubscriptionStrategyFactory(eventName)
        const strategy = new Strategy((args) => this.request(args))

        const userPermissions = new Set(this.getCapabilities())
        const { requiredPermissions } = strategy

        if (requiredPermissions.some((p) => !userPermissions.has(p))) {
            return Promise.reject(
                new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, "Can't subscribe to this type due to permissioning issues", {
                    userPermissions: Array.from(userPermissions),
                    requiredPermissions,
                    eventName,
                })
            )
        }

        const fetcher = fetcherCreator(strategy).start(this.getPollingInterval())

        /** Setup listener for any changes made by the current user */
        const unsubChanges = this.resourceChangeListeners.on(() => fetcher.update())

        return Promise.resolve(() => {
            /** Stop listing to external changes */
            unsubChanges()

            /** Stop regular fetcher */
            fetcher.stop()
        })
    }

    // eslint-disable-next-line class-methods-use-this
    protected getPollingInterval (): number {
        return 30_000
    }

    subscribeToPage<T extends keyof PageSubscriptionsTypes> (
        eventName: T,
        options: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>> {
        return this.pageSubscriptionAdapter.subscribeToPage(eventName, options)
    }

    async subscribeToOne<T extends keyof SingleSubscriptionsTypes> (eventName: T, id: string, listener: SubscriptionEventHandler<T>): Promise<VoidFunction> {
        return this.subscribe(eventName, (strategy) =>
            new Fetcher(
                () => strategy.retrieveOne(id),
                (...args) => strategy.compareOne(...args)
            ).onChange(listener)
        )
    }

    async subscribeToCount<T extends keyof CountSubscriptionsTypes> (eventName: T, listener: (count: number | ConnectwareError) => void): Promise<VoidFunction> {
        return this.subscribe(eventName, (strategy) =>
            new Fetcher(
                () => strategy.retrieveCount(),
                (a, b) => a === b
            ).onChange(listener)
        )
    }
}
