import {
    areArrayEquals,
    areRecordsEquals,
    type Comparators,
    createArrayComparator,
    createEqualityChecker,
    createNamedEqualityChecker,
    createPrimitiveValueComparator,
    isArrayNotEmpty,
    type ReadonlyRecord,
} from '../../utils'
import {
    areCybusAvailableResourcesEquals,
    areCybusConfiguredResourcesEquals,
    areCybusResourceDeviationsEqual,
    type CybusAvailableResources,
    type CybusCatalogServiceConfiguration,
    type CybusConfiguredResources,
    type CybusResourceDeviation,
    type CybusServiceParameters,
} from '.'
import { StatusType } from '..'

type CybusServiceLink = Readonly<{ name: string, href: string }>

type FullCybusService = Readonly<{
    id: string
    name: string
    version: string | null
    status: StatusType
    deviation: StatusType.DEVIATED | StatusType.NOT_ENABLED | StatusType.NO_DEVIATION

    icon: string | null
    description: string
    provider: string | null
    catalog: CybusCatalogServiceConfiguration | null
    updatedAt: Date | null

    /**
     * Non enconded file content
     */
    commissioningFile: string

    parameters: CybusServiceParameters

    /**
     * Service resources as configured on the commissioning file
     */
    configuredResources: CybusConfiguredResources

    /**
     * Actually enabled resources
     */
    availableResources: CybusAvailableResources

    /**
     * The configured links
     */
    links: CybusServiceLink[]

    /**
     * The deviations of the service
     */
    deviations: CybusResourceDeviation[]

    /**
     * The services dependencies related to this service
     */
    dependencies: CybusServiceDependencies
}>

export type CybusService = Pick<FullCybusService, 'id' | 'name' | 'version' | 'status' | 'deviation' | 'links'>
export type CybusLinkedService = Pick<FullCybusService, 'id' | 'name' | 'links'>
export type CybusDetailedService = Pick<FullCybusService, keyof FullCybusService>
type CybusServiceDependencies = ReadonlyRecord<'dependsOn' | 'dependent', FullCybusService['id'][]>

const areCybusServiceLinkEquals = createEqualityChecker<CybusServiceLink>({ name: null, href: null })

const cybusServiceComparators: Comparators<CybusService> = {
    id: null,
    name: null,
    version: null,
    status: null,
    deviation: null,
    links: createArrayComparator(areCybusServiceLinkEquals),
}

export const areCybusServiceEquals = createNamedEqualityChecker<CybusService>(cybusServiceComparators, 'areCybusServiceEquals')

const areCybusCatalogServiceConfigurationEquals = createEqualityChecker<CybusCatalogServiceConfiguration>({ directory: null, filename: null })

export const areServiceParametersEquals = (a: CybusDetailedService['parameters'], b: CybusDetailedService['parameters']): boolean =>
    areRecordsEquals(a, b, { equals: (pA, pB) => (Array.isArray(pA) && Array.isArray(pB) ? areArrayEquals(pA, pB, { sort: false }) : pA === pB) })

export const areDetailedCybusServiceEquals = createNamedEqualityChecker<CybusDetailedService>(
    {
        ...cybusServiceComparators,
        updatedAt: createPrimitiveValueComparator((d) => d?.getTime()),
        commissioningFile: null,
        icon: null,
        provider: null,
        description: null,
        catalog: (a, b) => Boolean(a && b && areCybusCatalogServiceConfigurationEquals(a, b)),
        parameters: areServiceParametersEquals,
        availableResources: areCybusAvailableResourcesEquals,
        deviations: createArrayComparator(areCybusResourceDeviationsEqual),
        dependencies: createEqualityChecker<CybusServiceDependencies>({ dependsOn: createArrayComparator(), dependent: createArrayComparator() }),
        configuredResources: areCybusConfiguredResourcesEquals,
    },
    'areDetailedCybusServiceEquals'
)

export const areLinkedCybusServiceEquals = createNamedEqualityChecker<CybusLinkedService>(
    { id: null, name: null, links: createArrayComparator(areCybusServiceLinkEquals) },
    'areLinkedCybusServiceEquals'
)

export const canServiceBeEnabled = (service: Pick<CybusService, 'status'>): boolean => service.status === StatusType.DISABLED
export const canServiceBeDisabled = (service: Pick<CybusService, 'status'>): boolean => service.status === StatusType.ENABLED

export const doesServiceNeedAuthorization = (service: CybusDetailedService): boolean =>
    Object.values(service.configuredResources).some((r: unknown[]) => isArrayNotEmpty(r))
