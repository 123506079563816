import React, { createRef, type FC, type HTMLInputTypeAttribute } from 'react'
import {
    TextField as BaseTextField,
    type TextFieldProps as BaseTextFieldProps,
    Box,
    inputBaseClasses,
    type SxProps,
    textFieldClasses,
    type Theme,
    Typography,
} from '@mui/material'

import type { ReadonlyRecord } from '../../../utils'

import type { Translation } from '../../../domain'

import { wasKeyHit } from '..'
import { useTranslator } from '../Internationalization'

export type TextFieldProps = Readonly<{
    label?: Translation
    placeholder?: Translation
    labelVariant?: 'standard' | 'body2'
    value: string
    onChange: (value: string) => void
}> &
    Pick<
        BaseTextFieldProps,
        | 'name'
        | 'id'
        | 'disabled'
        | 'required'
        | 'fullWidth'
        | 'type'
        | 'onKeyUp'
        | 'autoFocus'
        | 'size'
        | 'sx'
        | 'InputProps'
        | 'InputLabelProps'
        | 'autoComplete'
    >

export const TextField: FC<TextFieldProps> = ({ label, labelVariant = 'standard', placeholder = label, onChange, ...props }) => {
    const translator = useTranslator()
    const translatedLabel = label && translator.formatTranslation(label)

    return (
        <>
            {labelVariant === 'body2' && <Typography variant="body2">{translatedLabel}</Typography>}
            <BaseTextField
                label={labelVariant === 'standard' ? translatedLabel : undefined}
                placeholder={placeholder && translator.formatTranslation(placeholder)}
                onChange={({ target }) => onChange(target.value)}
                {...props}
            />
        </>
    )
}

export type LengthLimitedFieldProps = Pick<TextFieldProps, 'id' | 'disabled' | 'autoFocus' | 'InputLabelProps' | 'InputProps'> &
    Readonly<{ type: Extract<HTMLInputTypeAttribute, 'tel'>, value: string[], onChange: (value: string[]) => void }>

const valueMappers: ReadonlyRecord<LengthLimitedFieldProps['type'], (raw: string) => string | null> = {
    /**
     * Validate if a given input is a single digit (0-9), If it's single digit, it returns the digit as a string else, it returns null
     */
    tel: (rawValue) => (/^\d{1}$/.test(rawValue) ? rawValue : null),
}

const fixedLengthTextFieldWrapperStyle: SxProps<Theme> = { display: 'flex', justifyContent: 'space-between' } as const

const baseNumberedTextFieldProps = {
    required: true,
    fullWidth: false,
    sx: { mr: 1, '&:last-of-type': { mr: 0 } },
    InputProps: {
        sx: {
            [`& input.${inputBaseClasses.input}`]: { textAlign: 'center' },
            [[`& input.${inputBaseClasses.input}::-webkit-outer-spin-button', '& input.${inputBaseClasses.input}::-webkit-inner-spin-button`].join(', ')]: {
                WebkitAppearance: 'none',
            },
        },
    },
} as const

export const LengthLimitedField: FC<LengthLimitedFieldProps> = ({ id, autoFocus, type, value, onChange, InputLabelProps, InputProps = {}, ...props }) => {
    const extractValue = valueMappers[type]
    const parentRef = createRef<HTMLDivElement>()

    return (
        <Box ref={parentRef} id={id} sx={fixedLengthTextFieldWrapperStyle}>
            {value.map((part, k) => (
                <BaseTextField
                    key={k}
                    {...baseNumberedTextFieldProps}
                    InputLabelProps={InputLabelProps}
                    InputProps={{
                        ...baseNumberedTextFieldProps.InputProps,
                        ...InputProps,
                        sx: { ...baseNumberedTextFieldProps.InputProps.sx, ...InputProps.sx },
                    }}
                    id={id && `${id}-${k + 1}`}
                    sx={{ ...baseNumberedTextFieldProps.sx, width: `calc(100% / ${value.length})` }}
                    type={type}
                    autoFocus={autoFocus && k === 0}
                    value={part}
                    onKeyDown={(e) => {
                        let newPart = part
                        let clearPrevious = false

                        /** Highest the value, more to the right/down the node tree */
                        let move: -1 | 0 | 1 = 0

                        const validatedValue = extractValue(e.key)

                        if (validatedValue !== null) {
                            newPart = validatedValue
                            /** Done on this block, move to the next */
                            move = 1
                        }

                        if (wasKeyHit(e, 'Backspace')) {
                            /** User wants to delete the value */
                            newPart = ''
                            if (!part) {
                                /** Was already empty, move to the previous */
                                move = -1
                                clearPrevious = k > 0
                            }
                        }

                        if (wasKeyHit(e, 'ArrowLeft')) {
                            /** Just move to the previous one */
                            move = -1
                        }

                        if (wasKeyHit(e, 'ArrowRight')) {
                            /** Just move to the next one */
                            move = 1
                        }

                        if (wasKeyHit(e, 'Enter') && k + 1 !== value.length) {
                            /** User wants to move to next value unless they are on the last input */
                            move = 1
                        }

                        const hasChanged = newPart !== part
                        if (hasChanged || clearPrevious) {
                            const update = [...value]
                            if (hasChanged) {
                                update[k] = newPart
                            }
                            if (clearPrevious) {
                                update[k - 1] = ''
                            }
                            onChange(update)
                        }

                        const inputToMoveTo =
                            move !== 0 &&
                            parentRef.current &&
                            Array.from(
                                parentRef.current.querySelectorAll<HTMLInputElement>(
                                    `.${textFieldClasses.root} .${inputBaseClasses.root} input.${inputBaseClasses.input}`
                                )
                            ).find((_, k, all) => all[k - move] === e.target)

                        if (inputToMoveTo) {
                            inputToMoveTo.focus()
                        }
                    }}
                    onPaste={(e) => {
                        const copy = [...value]
                        const parsedValues = e.clipboardData.getData('Text').split('')

                        parsedValues.slice(0, value.length - k).forEach((rawValue, i) => {
                            const validatedValue = extractValue(rawValue)
                            if (validatedValue !== null) {
                                copy[k + i] = validatedValue
                            }
                        })

                        onChange(copy)
                    }}
                    {...props}
                />
            ))}
        </Box>
    )
}
