import type { CybusCatalogApplication, CybusCatalogServiceConfiguration, CybusCatalogServiceVersionData } from '../../domain'

export interface ConnectwareServicesCatalogService {
    /**
     * @throws `ConnectwareError`
     */
    fetchCatalogData(parameters: CybusCatalogServiceConfiguration): Promise<CybusCatalogServiceVersionData>

    /**
     * @throws `ConnectwareError`
     */
    fetchCatalogApplications(): Promise<CybusCatalogApplication[]>

    /**
     * @throws `ConnectwareError`
     */
    fetchCommissioningFile(application: CybusCatalogApplication): Promise<File>
}
