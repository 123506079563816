import React, { type FC, type ReactNode, useMemo } from 'react'
import { type FormatListOptions, FormattedList, FormattedMessage, FormattedRelativeTime, IntlProvider, type IntlShape, useIntl } from 'react-intl'

import type { ReadonlyRecord } from '../../../utils'
import { Translation } from '../../../domain'

export type TranslationProviderProps = Readonly<{
    internationalization: Pick<IntlShape, 'locale' | 'messages' | 'onError'>
}>

export const TranslationProvider: FC<TranslationProviderProps> = ({ internationalization, children }) => {
    const { locale, messages, onError } = internationalization

    return (
        <IntlProvider locale={locale} messages={messages} onError={onError}>
            {children}
        </IntlProvider>
    )
}

type FormatTranslationProps = Readonly<{
    /**
     * The targeted translation
     */
    id: Translation
    /**
     * The values to the translation
     */
    values?: ReadonlyRecord<string, ReactNode>
}>

type TranslatorHook = {
    formatTranslation(id: FormatTranslationProps['id'], values?: FormatTranslationProps['values']): string
    formatList(values: ReactNode[], options?: FormatListOptions): string
}

export const useTranslator = (): TranslatorHook => {
    const intl = useIntl()

    return useMemo(
        () => ({
            formatTranslation: (id, values) => intl.formatMessage({ id }, values) as string,
            formatList: (values, options) => intl.formatList(values, options) as string,
        }),
        [intl]
    )
}

export const FormattedTranslation: FC<FormatTranslationProps> = ({ id, values }: FormatTranslationProps) => <FormattedMessage id={id} values={values} />

export const FormattedTimePast: FC<Readonly<{ value: Date }>> = ({ value }) => (
    <FormattedRelativeTime unit="second" value={(value.getTime() - Date.now()) / 1_000} updateIntervalInSeconds={1} />
)

/**
 * Use this component to render dates
 * Formatting options are:
 *  - Datetime: both date and time are displayed
 *  - Time Only: only time is displayed
 *  - Date Only: only date is displayed
 */
export const FormattedDateTime: FC<Readonly<{ date: Date, format: 'datetime' | 'dateOnly' | 'timeOnly' }>> = ({ format, date }) => (
    <FormattedTranslation id={Translation.DATETIME} values={{ type: format, date }} />
)

export { FormattedList }
