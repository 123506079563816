import type { NonUndefined } from 'utility-types'
import React, { type DependencyList, type FC, type PropsWithChildren, type ReactNode, useEffect } from 'react'
import { createExtendedState, type Dispatcher, type Filter, type Selector } from 'react-extended-state'

import { areArrayEquals, isArrayNotEmpty, type NonEmptyArray, normalize, objectEntries, objectKeys, type ReadonlyRecord, search } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../domain'

import type { Column, Columns, CustomizedState, Line, Pagination, Props, Searcher, Selection, SortOrder, Translations } from './Types'

/**
 * This state provides internal usage of the props passed to the Table component
 */
const PropsState = createExtendedState<Props<Line>>({ ignorePropsChanges: false })
const useProp = PropsState.useExtendedState as <L extends Line, R,>(selector: Selector<Props<L>, R>, filterOrDependencies?: Filter<R> | DependencyList) => R
const usePropDispatcher = PropsState.useExtendedStateDispatcher as <L extends Line,>() => ReturnType<Dispatcher<Props<L>>>

const selectSearch = ({ search }: Pick<Props<Line>, 'search'>): string => (typeof search !== 'boolean' && search) || ''
const selectPageSizeOptions = ({ pagination }: Pick<Props<Line>, 'pagination'>): NonEmptyArray<number> | null => pagination?.pageSizeOptions ?? null
const selectPageSize = (s: Pick<Props<Line>, 'pagination'>): number | null => s.pagination?.pageSize ?? selectPageSizeOptions(s)?.[0] ?? null
const selectPage = <L extends Line,>(s: Props<L>): number => s.pagination?.page ?? 0
const selectOrientation = ({ orientation = 'vertical' }: Props<Line>): NonUndefined<Props<Line>['orientation']> => orientation
const selectSortOrder = <L extends Line,>(s: Props<L>): SortOrder<L> | null => s.sortOrder ?? null
const selectSelection = <L extends Line,>(s: Props<L>): Props<L>['selection'] => s.selection
const selectRequiredProperty = <L extends Line, T,>(s: Props<L>, selector: (props: Props<L>) => T | undefined, name: string): T => {
    const property = selector(s)

    if (!property) {
        throw new ConnectwareError(ConnectwareErrorType.STATE, 'Property is not setup', { name })
    }

    return property
}
const selectRequiredSelection = <L extends Line,>(s: Props<L>): Selection<L> => selectRequiredProperty(s, selectSelection, 'selection')
const selectRequiredPagination = (s: Props<Line>): Pagination => selectRequiredProperty(s, (s) => s.pagination, 'pagination')

/**
 * This state is used as an auxiliar variable to the props state
 * It allows for the user to perform custom actions that need to be stored internally
 * and also to calculate certain information that might be too costly to do on every render
 */
type TableAuxState<L extends Line,> = Readonly<{ selectedIds: (keyof L & string)[], visibleData: L[] }>
const AuxState = createExtendedState<TableAuxState<Line>>({ ignorePropsChanges: true })
const useAux = AuxState.useExtendedState as <L extends Line, R,>(selector: Selector<TableAuxState<L>, R>, filterOrDependencies?: Filter<R> | DependencyList) => R
const useAuxDispatcher = AuxState.useExtendedStateDispatcher as <L extends Line,>() => ReturnType<Dispatcher<TableAuxState<L>>>

const selectSelectedIds = (s: TableAuxState<Line>): TableAuxState<Line>['selectedIds'] => s.selectedIds

class VisibleDataMapper<L extends Line,> {
    private static readonly directionMap: ReadonlyRecord<SortOrder<never>['direction'], number> = { asc: 1, desc: -1 }
    private static readonly defaultSearcher: Searcher<unknown> = (value, { normalizedTerms }) => search(normalize(String(value)), normalizedTerms)

    constructor (private readonly props: Props<L>) {}

    private mapFiller (): L[] {
        const { data, pagination } = this.props

        /** Offset filler for external pagination */
        return pagination &&
            typeof pagination.totalCount === 'number' &&
            typeof pagination.pageSize === 'number' &&
            typeof pagination.page === 'number' &&
            isArrayNotEmpty(data)
            ? Array<L>(pagination.page * pagination.pageSize).fill(data[0])
            : []
    }

    private getFilteredData (): L[] {
        const { data, columns } = this.props

        const search = selectSearch(this.props)
        const filler = this.mapFiller()

        if (!search) {
            return [...filler, ...data]
        }

        const searchArgs = { normalizedTerms: normalize(search).trim().split(/\s+/), raw: search }

        return [
            ...filler,
            ...data.filter((row) =>
                objectEntries(columns as ReadonlyRecord<string, Column<unknown, unknown>>).some(([name, { searcher = VisibleDataMapper.defaultSearcher }]) =>
                    searcher(row[name], searchArgs)
                )
            ),
        ]
    }

    private getSortedData (): L[] {
        const data = this.getFilteredData()
        const sortOrder = selectSortOrder(this.props)
        const { columns } = this.props

        if (!sortOrder) {
            return data
        }

        const configuredSorter = columns[sortOrder.name]?.sort
        const sortValueExtractor = typeof configuredSorter === 'function' ? configuredSorter : (v: L[keyof L]) => String(v ?? '')

        return data.sort((a, b) => {
            const { name, direction } = sortOrder
            const compA = sortValueExtractor(a[name])
            const compB = sortValueExtractor(b[name])

            if (compA === compB) {
                return 0
            }

            return VisibleDataMapper.directionMap[direction] * (compA < compB ? -1 : 1)
        })
    }

    map (): L[] {
        const data = this.getSortedData()
        const pageSize = selectPageSize(this.props)
        const page = selectPage(this.props)
        return typeof pageSize === 'number' ? data.slice(page * pageSize, page * pageSize + pageSize) : data
    }
}

const VisibleDataUpdater: FC = () => {
    const visibleData = useProp(
        (s) => new VisibleDataMapper(s).map(),
        (a, b) => areArrayEquals(a, b, { sort: false })
    )
    const dispatch = useAuxDispatcher()

    useEffect(() => dispatch({ visibleData }), [visibleData])

    return null
}

export const PropsProvider = <L extends Line,>({ value, children }: PropsWithChildren<{ value: Props<L> }>): ReturnType<FC> => (
    <PropsState.Provider value={value as Props<Line>}>
        <AuxState.Provider value={{ selectedIds: [], visibleData: [] }}>
            <VisibleDataUpdater />
            {children}
        </AuxState.Provider>
    </PropsState.Provider>
)

export const useLoading = (): boolean => useProp((s) => Boolean(s.loading))
export const useBorderRadius = (): string | number => useProp(({ borderRadius = 1 }) => borderRadius)
export const useHeight = (): Props<never>['height'] => useProp((s) => s.height)
/**
 * Will return an infinite number if there is no page size available
 */
export const useTotalPages = (): number =>
    useProp((s) => {
        const pageSize = selectPageSize(s)
        if (pageSize === null) {
            return Infinity
        }
        const rowCount = s.pagination?.totalCount ?? s.data.length
        return Math.ceil(rowCount / pageSize)
    })

export const useExtendedToolbar = (): ReactNode => useProp((s) => s.extendedToolbar)
export const useHideJumpToPage = (): boolean => useProp((s) => Boolean(s.pagination?.hideJumpToPage))
export const useOnDownloadCSV = (): VoidFunction | void => useProp((s) => s.onDownloadCSV)
export const useOnRowClick = <L extends Line,>(): ((line: L) => void) | null => useProp((s) => s.onRowClick ?? null)
export const useIsSearching = (): boolean => useProp((s) => s.search !== false)
export const useSearch = (): string => useProp(selectSearch)
export const useTranslations = (): Translations => useProp((s) => s.translations) ?? {}
export const useOrientation = (): ReturnType<typeof selectOrientation> => useProp(selectOrientation)
export const useShouldShowHeader = (): boolean => useProp((s) => selectOrientation(s) === 'vertical' && !('hideHeader' in s && s.hideHeader))
export const useHasNumberedRows = (): boolean => useProp((s) => Boolean(s.numberedRows))
export const useSortOrder = <L extends Line,>(): SortOrder<L> | null => useProp(selectSortOrder)
export const useDense = (): boolean => useProp((s) => Boolean(s.dense))
export const useBorders = (): boolean => useProp((s) => Boolean(s.borders))
export const useColspan = (): number => useProp((s) => Number(Boolean(selectSelection(s))) + objectEntries(s.columns).length)
export const usePageSize = (): number | null => useProp(selectPageSize)
export const usePage = (): number => useProp(selectPage)
export const usePageSizeOptions = (): NonEmptyArray<number> | null => useProp(selectPageSizeOptions)
export const useColumnNames = <L extends Line,>(): (keyof Columns<L>)[] =>
    useProp<L, (keyof Columns<L>)[]>(
        (s) => objectKeys(s.columns),
        (a, b) => areArrayEquals(a, b, { sort: false })
    )
export const useColumn = <L extends Line, N extends keyof Columns<L>,>(name: N): Column<L[N], L> =>
    useProp<L, Column<L[N], L>>((s) => s.columns[name] as Column<L[N], L>, [name])
export const useVisibleRows = <L extends Line,>(): L[] => useAux<L, L[]>((s) => s.visibleData)

const useSelectionIdField = <L extends Line,>(): Selection<L>['idField'] | null => useProp((s) => selectSelection(s)?.idField ?? null)
const useSelectedRows = <L extends Line, T,>(mapper: (visibleRows: L[], isSelected: (line: L) => boolean) => T, nonSelected: T): T => {
    const visibleRows = useVisibleRows<L>()
    const idField = useSelectionIdField<L>()
    const selectedIds = useAux(selectSelectedIds)

    if (!selectedIds.length || !idField) {
        return nonSelected
    }

    const selected = new Set(selectedIds)
    return mapper(visibleRows, (line) => selected.has(line[idField] as string))
}

export const useSelected = <L extends Line,>(): L[] => useSelectedRows<L, L[]>((rows, isSelected) => rows.filter(isSelected), [])
export const useAreThereSelected = <L extends Line,>(): boolean => useSelectedRows<L, boolean>((rows, isSelected) => rows.some(isSelected), false)
export const useRequiredSelection = <L extends Line,>(): Selection<L> => useProp(selectRequiredSelection)

/**
 * Toggle and dispatcher to be used on the rows checkboxes
 */
export const useSelection = <L extends Line,>(row: L): [null, undefined] | [boolean, VoidFunction] => {
    const idField = useSelectionIdField()
    const selectedIds = useAux(selectSelectedIds)
    const dispatch = useAuxDispatcher()

    if (!idField) {
        return [null, undefined]
    }

    const id = row[idField] as string
    const index = selectedIds.indexOf(id)
    const selected = index >= 0

    return [
        selected,
        () =>
            dispatch({
                /**
                 * Either exclude current entry or append it
                 */
                selectedIds: selected ? [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1)] : [...selectedIds, id],
            }),
    ]
}

/**
 * Toggle and dispatcher to be used on the select all checkbox
 */
export const useSelectAll = <L extends Line,>(): [null, undefined] | [ReadonlyRecord<'all' | 'onlySome', boolean>, VoidFunction] => {
    const idField = useSelectionIdField<L>()
    const visible = useVisibleRows<L>()
    const selectedIds = useAux(selectSelectedIds)
    const dispatch = useAuxDispatcher()

    if (idField === null) {
        return [null, undefined]
    }

    const actuallySelected: string[] = []
    const allIds: string[] = []
    const idsLeft = new Set<string>(selectedIds)

    visible.forEach((row) => {
        const id = row[idField] as string
        if (idsLeft.delete(id)) {
            actuallySelected.push(id)
        }
        allIds.push(id)
    })

    return [
        {
            all: visible.length > 0 && actuallySelected.length === visible.length,
            onlySome: isArrayNotEmpty(actuallySelected) && actuallySelected.length < visible.length,
        },
        () =>
            dispatch({
                /**
                 * Either exclude current entry or append it
                 */
                selectedIds: isArrayNotEmpty(actuallySelected) ? [] : allIds,
            }),
    ]
}

export const useClearSelection = (): VoidFunction => {
    const dispatch = useAuxDispatcher()
    return () => dispatch({ selectedIds: [] })
}

/**
 * This utility function allows for the creation of hooks that
 * - Signal the onCustomized function
 * - Update the props
 */
const createDispatcherHookWithCustomization =
    <L extends Line, P,>(
        mapCurrentValue: (props: Props<L>) => P | unknown,
        mapChangedState: (parameter: P, props: Props<L>) => Partial<Props<L>>,
        mapCustomization: (parameter: P) => CustomizedState<L>
    ): (() => (parameter: P) => void) =>
    () => {
        const onCustomized = useProp<L, Props<L>['onCustomized']>((s) => s.onCustomized)
        const dispatch = usePropDispatcher<L>()
        const currentValue = useProp(mapCurrentValue)

        return (updatedValue) => {
            if (currentValue === updatedValue) {
                return
            }

            dispatch((props) => mapChangedState(updatedValue, props))

            if (onCustomized) {
                onCustomized(mapCustomization(updatedValue))
            }
        }
    }

export const useSearchDispatcher = createDispatcherHookWithCustomization<Line, string>(
    selectSearch,
    (search) => ({ search }),
    (search) => ({ search })
)

export const useSortOrderDispatcher = createDispatcherHookWithCustomization<Line, SortOrder<Line>>(
    selectSortOrder,
    (sortOrder) => ({ sortOrder }),
    (sortOrder) => ({ sortOrder })
) as <L extends Line,>() => (sort: SortOrder<L>) => void

export const usePageSizeDispatcher = createDispatcherHookWithCustomization<Line, number>(
    selectPageSize,
    (pageSize, s) => ({ pagination: { ...selectRequiredPagination(s), pageSize } }),
    (pageSize) => ({ pageSize })
)

export const usePageDispatcher = createDispatcherHookWithCustomization<Line, number>(
    selectPage,
    (page, s) => ({ pagination: { ...selectRequiredPagination(s), page } }),
    (page) => ({ page })
)
