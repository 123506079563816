import type { RoleCreationRequest } from '../../../../domain'

import type { PermissionPostRequest, RolePostRequest, RolePutRequest } from '../Types'
import { mapToDefault } from './Commons'
import { mapPermissionRequest } from './Permission'

export const mapRoleCreationRequestToPostRequest = ({ name, permissions, ldapGroupDn }: RoleCreationRequest): RolePostRequest => ({
    name,
    isShared: true,
    autoGenerated: false,
    permissions: permissions.map(mapPermissionRequest) as PermissionPostRequest[],
    ldapGroupDn: mapToDefault(ldapGroupDn, undefined),
})

export const mapRoleEditingRequestToPostRequest = ({ name, permissions, ldapGroupDn }: Omit<RoleCreationRequest, 'id'>): RolePutRequest => ({
    name,
    isShared: true,
    permissions: permissions.map(mapPermissionRequest),
    ldapGroupDn: mapToDefault(ldapGroupDn, undefined),
})
