import type { Optional } from 'utility-types'
import React, { type FC, useState } from 'react'
import { Menu, MenuItem, Chip as MuiChip, type SxProps, type Theme } from '@mui/material'
import { ArrowDropDown, Download, Replay } from '@mui/icons-material'

import { isArrayNotEmpty } from '../../../utils'
import { ConnectwareError, CybusLogLevel, type CybusLogSource, Translation } from '../../../domain'
import type { LogOptions } from '../../../application'

import { FormattedDateTime, FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppUsecase } from '../State'
import { useAsyncCallback } from '../Callback'
import { ErrorMessage } from '../ErrorMessage'
import { Table as BaseTable, ButtonDatePicker, Chip } from '../common'

import {
    LogsProvider,
    useEndDate,
    useEndDateDispatcher,
    useLevels,
    useLevelTogglerDispatcher,
    useLogs,
    useLogsLoader,
    useResource,
    useSelectedLogDispatcher,
    useSource,
    useStartDate,
    useStartDateDispatcher,
} from './State'
import { Modal } from './Modal'
import { LogLevel } from './LogLevel'

const DowloadRawLogsButton: FC = () => {
    const containerLogs = useAppUsecase('containerLogsUsecase')

    const resource = useResource()
    const source = useSource()

    const [downloadRawlogs] = useAsyncCallback(() => containerLogs.downloadRawlogs(source, resource), [containerLogs, source, resource])

    return <Chip label={Translation.DOWNLOAD_RAW_LOGS} avatar={Download} onClick={downloadRawlogs} />
}

const RefreshLogsButton: FC = () => {
    const loadLogs = useLogsLoader()
    return <Chip label={Translation.REFRESH_LOGS} avatar={Replay} onClick={loadLogs} />
}

const filterStyle: SxProps<Theme> = { backgroundColor: 'grey.200', '&:hover': { backgroundColor: 'grey.200' } }
const activeFilterStyle: SxProps<Theme> = { backgroundColor: 'grey.400', '&:hover': { backgroundColor: 'grey.400' } }
const LogTypesFilter: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const levelFilters = useLevels()
    const toggle = useLevelTogglerDispatcher()

    const closeMenu = (): void => setAnchorEl(null)

    const selectOption = (option: CybusLogLevel): void => {
        toggle(option)
        closeMenu()
    }

    return (
        <>
            <MuiChip
                data-testid="filter-button"
                label={<FormattedTranslation id={Translation.FILTER} />}
                icon={<ArrowDropDown />}
                sx={isArrayNotEmpty(levelFilters) ? activeFilterStyle : filterStyle}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            />

            <Menu data-testid="filter-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {Object.values(CybusLogLevel).map((level) => (
                    <MenuItem data-testid={`log-level-item-${level}`} key={level} onClick={() => selectOption(level)}>
                        {<FormattedTranslation id={Translation.LOG_LEVEL} values={{ level }} />}
                    </MenuItem>
                ))}
            </Menu>

            {levelFilters.map((level) => (
                <MuiChip
                    data-testid={`log-level-chip-${level}`}
                    key={level}
                    label={<FormattedTranslation id={Translation.LOG_LEVEL} values={{ level }} />}
                    onDelete={() => toggle(level)}
                />
            ))}
        </>
    )
}

const StartDateFilter: FC = () => {
    const startDate = useStartDate()
    const setStartDate = useStartDateDispatcher()

    return <ButtonDatePicker emptyLabel={Translation.START_TIME} value={startDate} onAccept={setStartDate} disableFuture />
}

const EndDateFilter: FC = () => {
    const startDate = useStartDate()
    const endDate = useEndDate()
    const setEndDate = useEndDateDispatcher()

    return <ButtonDatePicker emptyLabel={Translation.END_TIME} value={endDate} onAccept={setEndDate} disableFuture minDateTime={startDate ?? undefined} />
}

type InternalProps = (Readonly<{ id: string }> | Readonly<{ downloadSuffix: string }>) & Readonly<{ showDownloadRawLogs?: boolean, showServiceId?: boolean }>

const InternalTable: FC<InternalProps> = ({ showDownloadRawLogs = false, showServiceId = false, ...props }) => {
    const containerLogs = useAppUsecase('containerLogsUsecase')
    const translation = useTranslator()
    const selectLog = useSelectedLogDispatcher()

    const logs = useLogs()

    if (ConnectwareError.is(logs)) {
        return <ErrorMessage error={logs} stack extras="section" />
    }

    const data = logs ?? []
    const csvFileName = 'downloadSuffix' in props ? props.downloadSuffix : props.id

    return (
        <BaseTable
            loading={logs === null}
            data={data.map((l) => ({ ...l, date: l.timestamp }))}
            columns={{
                level: { label: Translation.LEVEL, customCellRender: (level) => <LogLevel level={level} />, sort: true },
                timestamp: {
                    label: Translation.TIME,
                    customCellRender: (date) => <FormattedDateTime date={date} format="datetime" />,
                    sort: (t) => t.getTime(),
                },
                ...(showServiceId ? { serviceId: { label: translation.formatTranslation(Translation.SERVICE, { count: 1 }), sort: true } } : {}),
                message: { label: Translation.MESSAGE, sort: true },
            }}
            pagination={{ pageSizeOptions: [50] }}
            sortOrder={{ name: 'timestamp', direction: 'desc' }}
            extendedToolbar={
                <>
                    {showDownloadRawLogs && <DowloadRawLogsButton />}
                    <RefreshLogsButton />
                    <LogTypesFilter />
                    <StartDateFilter />
                    <EndDateFilter />
                </>
            }
            onRowClick={selectLog}
            search
            translations={{ noResultsOrEmptyTable: Translation.NO_LOGS_FOUND }}
            onDownloadCSV={() => containerLogs.downloadCSV(data, csvFileName, showServiceId)}
        >
            <Modal />
        </BaseTable>
    )
}

type ExternalProps = InternalProps & Readonly<{ type: CybusLogSource }> & Optional<Pick<LogOptions, 'lines'>>

export const Table: FC<ExternalProps> = ({ type, lines, ...props }) => (
    <LogsProvider resource={'id' in props ? props.id : null} source={type} lines={lines}>
        <InternalTable {...props} />
    </LogsProvider>
)
