import type { Mutable } from 'utility-types'

import { executeOnce, isArrayNotEmpty, normalize } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, type PaginatedData } from '../../../domain'

import type { HttpRequestArgs } from '..'
import type { PaginationConfiguration } from './mappers'

type QueryParams = HttpRequestArgs<never, never>['queryParams']

type InternalArgs<T = never, R = never, M = unknown> = Readonly<{
    search: string
    exactSearch: boolean
    showInternal: boolean | null
    pageNumber: number
    pageSize: number
    mapper: (response: R, metadata: M) => T
    noMatches: T
}>

type FilterValuesMapperArgs = Pick<InternalArgs, 'search' | 'showInternal' | 'exactSearch'> & Readonly<{ rawSearch: string }>

/**
 * This is an utility class that helps the http user service to fetch roles, users and permissions data
 */
export class PaginatedDataFetcher<Domain, Index, Response, Metadata> {
    protected readonly pagination: PaginationConfiguration

    /** Function to yield indexes so everything is faster to search */
    private readonly requestIndex: () => Promise<Index[]>

    /** Function to actually retrieve the paginated response */
    private readonly requestInformation: <T>(params: QueryParams, pageMapper: (response: Response) => T) => Promise<T>

    /** Function to fetch metadata about the response */
    private readonly requestMetadata: () => Promise<Metadata>

    /** If the index should be used */
    private readonly shouldFilter?: (args: Pick<InternalArgs, 'showInternal'>) => boolean

    /** The parameter that will take in the filtered values */
    private readonly filterField: string

    /** The function to turn the yielded indexed values into filter parameters */
    private readonly filterValuesMapper: (index: Index, args: FilterValuesMapperArgs) => string[]

    /** Url parameters that ought to always be present */
    private readonly createStaticParameters?: (args: Pick<InternalArgs, 'showInternal'>) => QueryParams

    private readonly mapPageResponse: (response: Response, metadata: Metadata) => PaginatedData<Domain>

    constructor ({
        pagination,
        indexRequester,
        informationRequester,
        shouldFilter,
        filterParameter,
        createStaticParameters,
        mapPageResponse,
        filterParameterValuesMapper,
        metadataRequester: requestMetadata,
    }: {
        indexRequester: PaginatedDataFetcher<Domain, Index, Response, Metadata>['requestIndex']
        informationRequester: PaginatedDataFetcher<Domain, Index, Response, Metadata>['requestInformation']
        metadataRequester: PaginatedDataFetcher<Domain, Index, Response, Metadata>['requestMetadata']
        shouldFilter?: PaginatedDataFetcher<Domain, Index, Response, Metadata>['shouldFilter']
        filterParameter: PaginatedDataFetcher<Domain, Index, Response, Metadata>['filterField']
        filterParameterValuesMapper: PaginatedDataFetcher<Domain, Index, Response, Metadata>['filterValuesMapper']
        pagination: PaginatedDataFetcher<Domain, Index, Response, Metadata>['pagination']
        createStaticParameters?: PaginatedDataFetcher<Domain, Index, Response, Metadata>['createStaticParameters']
        mapPageResponse: PaginatedDataFetcher<Domain, Index, Response, Metadata>['mapPageResponse']
    }) {
        this.pagination = pagination
        this.requestIndex = indexRequester
        this.requestInformation = informationRequester
        this.shouldFilter = shouldFilter
        this.filterField = filterParameter
        this.createStaticParameters = createStaticParameters
        this.mapPageResponse = mapPageResponse
        this.filterValuesMapper = filterParameterValuesMapper
        this.requestMetadata = executeOnce(requestMetadata)
    }

    private async createParams ({
        search,
        exactSearch,
        showInternal,
        pageNumber,
        pageSize,
    }: Pick<InternalArgs, 'search' | 'showInternal' | 'pageNumber' | 'pageSize' | 'exactSearch'>): Promise<QueryParams | null> {
        const { shouldFilter = () => false, createStaticParameters = () => ({}), filterValuesMapper, filterField } = this
        const params: Mutable<QueryParams> = {}

        const args: FilterValuesMapperArgs = {
            search: normalize(search.trim()),
            showInternal,
            exactSearch,
            rawSearch: search,
        }

        if (args.exactSearch || Boolean(args.search) || shouldFilter(args) || Boolean(args.rawSearch)) {
            const matches = (await this.requestIndex()).flatMap((entry) => filterValuesMapper(entry, args))

            if (!isArrayNotEmpty(matches)) {
                /** No match was found */
                return null
            }

            params[filterField] = matches
        }

        /** Set pagination */
        params.rowsPerPage = String(pageSize)
        params.pageNumber = String(pageNumber)

        return {
            ...params,
            /** Append other static fields */
            ...createStaticParameters({ showInternal }),
        }
    }

    protected async request<T> ({ mapper, noMatches, ...paramsArgs }: InternalArgs<T, Response, Metadata>): Promise<T> {
        const [params, metadata] = await Promise.all([this.createParams(paramsArgs), this.requestMetadata()])
        return params ? this.requestInformation(params, (response) => mapper(response, metadata)) : noMatches
    }

    fetchPage (search: string, showInternal: boolean, pageNumber: number): Promise<PaginatedData<Domain>> {
        const {
            mapPageResponse: mapper,
            pagination: { pageSize },
        } = this

        return this.request({
            search,
            exactSearch: false,
            showInternal,
            pageNumber,
            pageSize,
            mapper,
            noMatches: { current: [], totalCount: 0, pageSize, page: 1 },
        })
    }
}

type ListDataFetcherArgs<Domain, Index, Response, Metadata> = ConstructorParameters<typeof PaginatedDataFetcher<Domain, Index, Response, Metadata>>[0] &
    Readonly<{ mapListResponse: ListDataFetcher<Domain, Index, Response, Metadata>['mapListResponse'] }>

export class ListDataFetcher<Domain, Index, Response, Metadata> extends PaginatedDataFetcher<Domain, Index, Response, Metadata> {
    private readonly mapListResponse: (response: Response, metadata: Metadata) => Domain[]

    constructor ({ mapListResponse, ...args }: ListDataFetcherArgs<Domain, Index, Response, Metadata>) {
        super(args)
        this.mapListResponse = mapListResponse
    }

    fetch (search: string): Promise<Domain[]> {
        const { mapListResponse, pagination } = this

        return this.request({
            search,
            exactSearch: false,
            showInternal: false,
            pageNumber: 1,
            pageSize: pagination.searchSize,
            mapper: mapListResponse,
            noMatches: [],
        })
    }

    fetchOne (name: string): Promise<Domain> {
        const { mapListResponse } = this

        return this.request({ search: name, exactSearch: true, showInternal: null, pageNumber: 1, pageSize: 1, mapper: mapListResponse, noMatches: [] }).then(
            ([first]) => first ?? Promise.reject(new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Entity not found', { name }))
        )
    }
}
