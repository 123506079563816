import React, { type FC } from 'react'
import { FormControlLabel, Switch } from '@mui/material'

import { selectUserManagementSelectedTabShowInternal, Translation, type UserManagementTabsTypes } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { useAppState, useAppUsecase } from '../State'

export const Filter: FC<{ selected: keyof UserManagementTabsTypes }> = ({ selected }) => {
    const usecase = useAppUsecase('filterUserManagementUsecase')
    const isShow = useAppState((s) => selectUserManagementSelectedTabShowInternal(s, selected), [selected])

    return (
        <FormControlLabel
            control={<Switch data-testid="show-internal" checked={isShow} onChange={() => usecase.toggleShowInternal(selected)} />}
            label={<FormattedTranslation id={Translation.SHOW_USER_MANAGEMENT_RESULTS} values={{ type: selected }} />}
        />
    )
}
