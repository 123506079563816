import type {
    AuthenticationInformation,
    AuthenticationRequest,
    AuthenticationStatus,
    AuthenticationWithBackupcode,
    AuthenticationWithOtpRequest,
} from '../../domain'

export type AuthenticationResponse = Pick<AuthenticationInformation, 'token' | 'expiresAt' | 'capabilities'>
export type SessionResponse = Pick<AuthenticationInformation, 'username' | 'expiresAt' | 'capabilities'>

export interface AuthenticationService {
    /**
     * Signals to the auth service that the given token is no longer going to be used
     */
    flushAuthentication(token: string): Promise<void>

    /**
     * Authenticates on the auth service
     * @throws `ConnectwareError` or `MfaRequiredError`
     * @returns Either a response that the user is authenticated or a secret to be used along side an OTP
     */
    authenticate(request: AuthenticationRequest | AuthenticationWithOtpRequest | AuthenticationWithBackupcode): Promise<AuthenticationResponse>

    /**
     * Retrieves the given token's session
     * @throws `ConnectwareError`
     */
    fetchSession(token: string): Promise<SessionResponse>

    /**
     * @throws `ConnectwareError`
     */
    fetchAuthenticationStatus(token: string): Promise<AuthenticationStatus>

    /**
     * Lists the topics the authenticated user can subscribe to
     * @throws `ConnectwareError`
     */
    fetchSubscribableTopics(): Promise<string[]>
}
