import { areCybusServiceDeviationsEquals, Capability, type CybusServiceDeviation } from '../../../../../../domain'

import { CybusServiceDeviationMapper, type CybusServiceProxyInfo } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DeviationsStrategy extends BaseSubscriptionStrategy<'serviceDeviations'> {
    readonly requiredPermissions = [Capability.SERVICE_DEVIATIONS_READ]

    protected override readonly areEquals = areCybusServiceDeviationsEquals

    override retrieveAll (): Promise<CybusServiceDeviation[]> {
        /**
         * @deprecated this endpoint will no longer provide info about whether service is deviated or not
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         *
         * Note: We need to identify how we retrieve deviations via the RST?
         *
         * Should we first call /api/services/info?
         * and using the serviceID from that call, we call the fetchIsDeviated method of the RST to identify deviated service?
         */
        return this.retrieve({
            capability: Capability.SERVICE_DEVIATIONS_READ,
            method: 'GET',
            path: '/api/services/info',
            mapper: (data: CybusServiceProxyInfo[]) => {
                const mapper = new CybusServiceDeviationMapper()
                data.forEach(({ id, isDeviated }) => mapper.add(id, isDeviated))
                return mapper.deviations
            },
        })
    }
}
