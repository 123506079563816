import React, { type FC, type PropsWithChildren } from 'react'
import { Box, Table as MuiTable, TableBody as MuiTableBody, TableContainer } from '@mui/material'

import { isArrayNotEmpty } from '../../../../utils'

import { extractDataTestId } from '../../utils'
import type { Line, Props } from './Types'
import { PropsProvider, useBorderRadius, useDense, useHeight, useLoading, useVisibleRows } from './State'
import { Header } from './Header'
import { Toolbar } from './Toolbar'
import { Row } from './Row'
import { EmptyTableBody } from './Empty'
import { LoadingTableBody } from './Loading'
import { Footer } from './Footer'

const Container: FC = ({ children }) => {
    const borderRadius = useBorderRadius()
    const maxHeight = useHeight()

    return <TableContainer sx={{ maxHeight, borderRadius }}>{children}</TableContainer>
}

const ContainerContent: FC = ({ children }) => {
    const dense = useDense()

    return (
        <MuiTable stickyHeader size={dense ? 'small' : 'medium'}>
            {children}
        </MuiTable>
    )
}

const LoadedBodyContent: FC = () => {
    const visibleRows = useVisibleRows()

    if (!isArrayNotEmpty(visibleRows)) {
        return <EmptyTableBody />
    }

    return (
        <>
            {visibleRows.map((row, rowIndex) => (
                <Row key={rowIndex} index={rowIndex} row={row} />
            ))}
        </>
    )
}

const BodyContent: FC = () => {
    const loading = useLoading()

    return loading ? <LoadingTableBody /> : <LoadedBodyContent />
}

export const Table = <L extends Line,>({ children, ...props }: PropsWithChildren<Props<L>>): ReturnType<FC> => (
    <PropsProvider value={props}>
        <Box data-testid={extractDataTestId(props) || 'common-table'}>
            <Toolbar />
            <Container>
                <ContainerContent>
                    <Header />
                    <MuiTableBody>
                        <BodyContent />
                    </MuiTableBody>
                </ContainerContent>
                <Footer />
            </Container>
            {children}
        </Box>
    </PropsProvider>
)
