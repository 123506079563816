import type { ArrayType } from '../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusCoreContainer, type CybusServiceContainer, type StatusType } from '../../domain'

import type { SubscriptionFilterArgs } from '../../application'
import { mapDockerContainerState, mapResourceNames, mapToStatusType } from './mappers'
import type { DockerContainerResponse, DockerContainersResponse, KubernetesContainerResponse, KubernetesContainersResponse } from './Types'

/**
 * Abstract class that helps the fetching of containers list into domain level entities
 * Needs to pass a promise of what is the backend orchestrator and implement fetchers
 */
export abstract class ContainersListFetcher {
    constructor (private readonly isDocker: Promise<boolean>, private readonly filter: SubscriptionFilterArgs) {}

    private fetchContainers<C extends CybusServiceContainer | CybusCoreContainer, M> (
        extractIdMetadata: (id: string) => M | null,
        mapCustomProperties: (
            baseValues: Pick<C, 'id' | 'status' | 'lastUpdate' | 'created'>,
            metadata: M,
            response: Omit<ArrayType<DockerContainersResponse | KubernetesContainersResponse>, 'Names' | 'State'>
        ) => C
    ): Promise<C[]> {
        /**
         * @deprecated this block will need to be revised when the RST is introduced
         * @todo consider refactoring it
         * @see https://cybusio.atlassian.net/browse/CC-2124
         */
        return this.fetchContainersResponse().then((containers) =>
            Promise.all(
                containers.map(async ({ Names, Names: [firstName], State, ...response }) => {
                    if (!firstName) {
                        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Names in container not in expected format', { Names })
                    }

                    // Name starts with slash, this removes them
                    const id = firstName.slice(1)
                    const idMetadata = extractIdMetadata(id)

                    if (idMetadata === null) {
                        // Can give up on this container as it is not relevant for this search
                        return null
                    }

                    const [metadata, isDocker] = await Promise.all([this.fetchContainerMetadata(id), this.isDocker])

                    const isMetadataMissingOrNonDocker = !metadata || !isDocker

                    const status: StatusType = isMetadataMissingOrNonDocker ? mapToStatusType(State?.toLowerCase()) : mapToStatusType(metadata.State.Status)

                    return mapCustomProperties(
                        {
                            id,
                            created: metadata?.Created ? new Date(metadata.Created) : null,
                            ...(isMetadataMissingOrNonDocker
                                ? { status, lastUpdate: null }
                                : mapDockerContainerState((metadata as DockerContainerResponse).State, status)),
                        },
                        idMetadata,
                        response
                    )
                })
            ).then((containers: (C | null)[]) => containers.filter((c): c is C => c !== null))
        )
    }

    protected abstract fetchContainersResponse (): Promise<DockerContainersResponse | KubernetesContainersResponse>

    /**
     * Function that yields the docker or kubernetes container metadata of the given container name
     * Yielding null is fine if the container can not be found
     */
    protected abstract fetchContainerMetadata (name: string): Promise<DockerContainerResponse | KubernetesContainerResponse | null>

    /**
     * @note status handling for core containers via RST will be handled in ADR15. Ticket/epic is not yet created.
     */
    fetchCoreContainers (): Promise<CybusCoreContainer[]> {
        return this.fetchContainers(
            (name) => ({ name }),
            (base, metatada) => ({ ...base, ...metatada })
        )
    }

    /**
     * status handling for service containers via RST will be handled in the epic
     * @see https://cybusio.atlassian.net/browse/CC-2124
     */
    fetchServiceContainers (): Promise<CybusServiceContainer[]> {
        return this.fetchContainers(
            (id) => {
                const [service, name] = mapResourceNames(id)

                const { service: serviceFilter } = this.filter
                if (serviceFilter && serviceFilter !== service) {
                    return null
                }

                return { name, service }
            },
            (base, metadata, { Mounts }) => ({ ...base, ...metadata, volumesCount: Mounts.length })
        )
    }
}
