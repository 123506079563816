import React, { type FC, useMemo } from 'react'
import type { FunctionKeys, PickByValueExact } from 'utility-types'
import { Link, type LinkProps } from '@mui/material'

import type { ReadonlyRecord } from '../../../utils'
import type { ContactInformationUsecase } from '../../../application'

import { useAppUsecase } from '../State'

type ContactInformationFunctionMap<F,> = ReadonlyRecord<'support' | 'sales', FunctionKeys<PickByValueExact<InstanceType<typeof ContactInformationUsecase>, F>>>

const emailTypes: ContactInformationFunctionMap<() => string> = { support: 'getSupportEmail', sales: 'getSalesEmail' }
const sendEmailTypes: ContactInformationFunctionMap<(subject?: string) => void> = { support: 'sendSupportEmail', sales: 'sendSalesEmail' }

type Props = Omit<LinkProps, 'href' | 'onClick'> & Readonly<{ subject?: string }>

const ContactEmailLink: FC<Props & { readonly type: keyof ContactInformationFunctionMap<unknown> }> = ({ type, children, subject, ...props }) => {
    const usecase = useAppUsecase('contactInformationUsecase')

    const actualChildren = useMemo(() => children || usecase[emailTypes[type]](), [usecase, type, children])

    return (
        <Link {...props} onClick={() => usecase[sendEmailTypes[type]](subject)}>
            {actualChildren}
        </Link>
    )
}

export const SalesEmailLink: FC<Props> = (props) => <ContactEmailLink {...props} type="sales" />
export const SupportEmailLink: FC<Props> = (props) => <ContactEmailLink {...props} type="support" />
