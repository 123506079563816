import { areCybusCoreContainersEquals, areCybusDetailedCoreContainersEquals, Capability, type StatusType } from '../../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../../application'

import { type DockerContainerResponse, type KubernetesContainerResponse, mapDetailedCoreContainer } from '../../../../../Connectware'

import { BaseCoreContainerStrategy } from './Base'

export class CoreContainerStrategy extends BaseCoreContainerStrategy<'coreContainers'> {
    readonly requiredPermissions = [Capability.CORE_CONTAINERS_READ]

    /** What fetch strategy should be used */
    protected override readonly fetchAllMethod = 'fetchCoreContainers'

    protected override readonly areEquals = areCybusCoreContainersEquals
}

export class DetailedCoreContainerStrategy extends BaseCoreContainerStrategy<'coreContainer'> {
    readonly requiredPermissions = [Capability.CORE_CONTAINER_READ]

    protected override readonly areEquals = areCybusDetailedCoreContainersEquals

    /** Maps the response to core containers, signaling to the base class that single retrieval is implemented */
    protected override readonly mapSingleRetrieval = (
        id: string,
        data: DockerContainerResponse | KubernetesContainerResponse,
        status: StatusType
    ): SubscriptionsTypes['coreContainer'] => mapDetailedCoreContainer(id, id, data, status)
}
