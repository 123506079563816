import { createInterval, EventListener } from '../../../utils'

import type { ConnectwareError } from '../../../domain'

type Retriever<R> = () => Promise<R>
type Comparator<R> = (a: R | ConnectwareError, b: R | ConnectwareError) => boolean

export class Fetcher<R> {
    private readonly listeners = new EventListener<R | ConnectwareError>()

    private cancel: VoidFunction | null = null

    private current: R | ConnectwareError | null = null

    /**
     * @description the utility class to assist the subscription of http connectware endpoints
     *
     * @param retrieve the function that will retrieve the async resources
     * @param comparator the function that will compare the current and previous results
     */
    constructor (private readonly retrieve: Retriever<R>, private readonly comparator: Comparator<R>) {}

    private trigger (data: R | ConnectwareError): void {
        this.listeners.trigger(data)
    }

    private async run (isFirst: boolean): Promise<void> {
        try {
            const data = await this.retrieve()

            /**
             * Swap previous with new value
             */
            const previous = this.current || data
            this.current = data

            if (isFirst || !this.comparator(previous, data)) {
                this.trigger(this.current)
            }
        } catch (e: unknown) {
            this.current = e as ConnectwareError
            this.trigger(this.current)
        }
    }

    /**
     * Makes another fetch request
     */
    update (): void {
        void this.run(false)
    }

    /**
     * Starts the timed pulling of resources
     *
     * Do **not** call this twice without stoping it first
     *
     * @param time in milliseconds
     *
     * needs to be less than 32-bit integer
     * @see https://developer.mozilla.org/en-US/docs/Web/API/setInterval#sect1
     */
    start (time: number): this {
        void this.run(true)
        this.cancel = createInterval(() => this.update(), time)
        return this
    }

    /**
     * Stops the previously started fetcher
     */
    stop (): void {
        this.cancel?.()
    }

    /**
     * @param handler will be called whenever changes are detected
     */
    onChange (handler: (e: R | ConnectwareError) => void): this {
        this.listeners.on(handler)
        return this
    }
}
