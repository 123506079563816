import type { ChangeEventListener } from '../../../../../utils'
import type { PageSubscriptionsTypes, SubscriptionPageEvent, SubscriptionPageOptions } from '../../../../../application'

import { SubscriptionHandlerType, type VrpcInstanceTypes } from '../handlers'
import { type DataMapper, InstanceToPageDataMapper } from '../mappers'
import { BaseSubscription } from './Base'

export class PageSubscription<T extends keyof PageSubscriptionsTypes> extends BaseSubscription<T, SubscriptionPageEvent<T>> {
    private changeListener: ChangeEventListener<SubscriptionPageOptions<T>> | null = null

    protected createMapper (): DataMapper<SubscriptionPageEvent<T>> | null {
        if (this.changeListener && PageSubscription.isHandlerOfType(this.handler, SubscriptionHandlerType.INSTANCE_ONE_TO_PAGE)) {
            /** Only one to page handlers can be mapped */
            return new InstanceToPageDataMapper<VrpcInstanceTypes[T], PageSubscriptionsTypes[T]>(this.changeListener, this.handler, this.filter)
        }

        return null
    }

    withChangeListener (changeListener: ChangeEventListener<SubscriptionPageOptions<T>>): this {
        this.changeListener = changeListener
        return this
    }
}
