import { executeOnce, objectEntries, type ReadonlyRecord } from '../../../../utils'

import { CybusUserAuthenticationMethod } from '../../../../domain'

import { type GrantType, type Method } from '../Types'

const grantTypesMap: ReadonlyRecord<Method, CybusUserAuthenticationMethod> = {
    password: CybusUserAuthenticationMethod.PASSWORD,
    token: CybusUserAuthenticationMethod.TOKEN,
    certificate: CybusUserAuthenticationMethod.CERTIFICATE,
}

const getAuthenticationMethodMap = executeOnce(
    () =>
        Object.fromEntries(objectEntries(grantTypesMap).map(([grantType, method]) => [method, grantType])) as ReadonlyRecord<
            CybusUserAuthenticationMethod,
            Method
        >
)

export const mapCybusUserAuthenticationMethodToAuthenticationMethod = (method: Method): CybusUserAuthenticationMethod => grantTypesMap[method]
export const mapGrantTypeToAuthenticationMethod = ({ method }: GrantType): CybusUserAuthenticationMethod =>
    mapCybusUserAuthenticationMethodToAuthenticationMethod(method)

export const mapAuthenticationMethodToGrantType = (method: CybusUserAuthenticationMethod): GrantType => ({
    method: getAuthenticationMethodMap()[method],
    isRequired: false,
})
