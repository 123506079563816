import type { CybusCoreContainer, CybusServiceContainer } from '../../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../../application'

import {
    CONTAINER_MANAGER_AGENT,
    ContainersListFetcher,
    type DockerContainerResponse,
    type DockerContainersResponse,
    type KubernetesContainerResponse,
    type KubernetesContainersResponse,
} from '../../../../../../Connectware'
import { CONTAINER_MANAGER_CLASSNAME, CONTAINER_MANAGER_CORE_INSTANCE, CONTAINER_MANAGER_DOCKER_INSTANCE } from '../../../../../constants'
import type { ContainerManagerOrchestratorProxy as ContainerManagerProxy } from '../../../../../proxies'
import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '../..'
import { createContainerManagerOrchestratorProxyHandler } from '../Listener'
import { fetchImplementationResolver } from '../Implementation'

class VrpcContainersListFetcher extends ContainersListFetcher {
    constructor (isDocker: Promise<boolean>, private readonly proxy: ContainerManagerProxy, filter: SubscriptionFilterArgs) {
        super(isDocker, filter)
    }

    protected fetchContainersResponse (): Promise<DockerContainersResponse | KubernetesContainersResponse> {
        return this.proxy.getContainers()
    }

    protected fetchContainerMetadata (name: string): Promise<DockerContainerResponse | KubernetesContainerResponse | null> {
        return this.proxy.inspectContainer(name).then((i) => i || null)
    }
}

type ContainersHandler<C> = VrpcInstanceToListSubscriptionHandler<ContainerManagerProxy, C>
type ContainersHandlerArgs<C> = VrpcHandlerMappingPropertiesArgs<ContainersHandler<C>>
abstract class VrpcContainerManagerOrchestratorProxyToContainerHandler<C extends CybusServiceContainer | CybusCoreContainer> implements ContainersHandler<C> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly agent = CONTAINER_MANAGER_AGENT

    readonly classNameFilter = CONTAINER_MANAGER_CLASSNAME

    readonly ignoreInstanceByFilter = null

    readonly ignoreInstances = null

    readonly requiredFilters = []

    readonly onChange = createContainerManagerOrchestratorProxyHandler

    constructor (
        private readonly fetchContainers: (fetcher: VrpcContainersListFetcher) => Promise<C[]>,
        readonly optionalFilters: ContainersHandler<C>['optionalFilters'],
        readonly sourceInstanceName: ContainersHandler<C>['sourceInstanceName']
    ) {}

    mapToDomain ({ instance, filter }: ContainersHandlerArgs<C>['DomainMapperArgs']): Promise<ContainersHandlerArgs<C>['Domain']> {
        const isDocker = fetchImplementationResolver(instance).then((c) => c.isDocker(instance))
        const fetcher = new VrpcContainersListFetcher(isDocker, instance, filter)
        return this.fetchContainers(fetcher)
    }
}

export class VrpcContainerManagerOrchestratorProxyToCoreContainerHandler extends VrpcContainerManagerOrchestratorProxyToContainerHandler<CybusCoreContainer> {
    constructor () {
        super((fetcher) => fetcher.fetchCoreContainers(), [], CONTAINER_MANAGER_CORE_INSTANCE)
    }
}

export class VrpcContainerManagerOrchestratorProxyToServiceContainerHandler extends VrpcContainerManagerOrchestratorProxyToContainerHandler<CybusServiceContainer> {
    constructor () {
        super((fetcher) => fetcher.fetchServiceContainers(), ['service'], CONTAINER_MANAGER_DOCKER_INSTANCE)
    }
}
