import type { SubscriptionsTypes } from '../../../../../application'

import type { SubscriptionListEvent } from '../../../../Connectware'
import { SubscriptionHandlerType } from '../handlers'
import { type DataMapper, InstanceToListDataMapper, RemotesToListDataMapper } from '../mappers'
import { BaseSubscription } from './Base'

export class ListSubscription<T extends keyof SubscriptionsTypes> extends BaseSubscription<T, SubscriptionListEvent<T>> {
    private static readonly supportedTypes = [
        SubscriptionHandlerType.REMOTE_TO_LIST,
        SubscriptionHandlerType.INSTANCE_ONE_TO_LIST,
        SubscriptionHandlerType.INSTANCE_ONE_TO_ONE,
    ] as const

    static supports (type: SubscriptionHandlerType): boolean {
        return this.isHandlerOfType({ type }, ...this.supportedTypes)
    }

    protected createMapper (): DataMapper<SubscriptionListEvent<T>> | null {
        if (ListSubscription.isHandlerOfType(this.handler, ListSubscription.supportedTypes[0])) {
            /** Can map remotes to many */
            return new RemotesToListDataMapper(this.handler, this.filter)
        }

        if (ListSubscription.isHandlerOfType(this.handler, ListSubscription.supportedTypes[1], ListSubscription.supportedTypes[2])) {
            /** Can map instances to one or to many */
            return new InstanceToListDataMapper(this.handler, this.filter)
        }

        return null
    }
}
