import type { VrpcProxy } from 'vrpc'

import type { ChangeEventListener, ReadonlyRecord, ReplaceValues } from '../../../../../../utils'
import type { PaginationParameters } from '../../../../../../domain'

import { getInstance, type ManagedVrpcRemote } from '../../../../utils'
import type {
    VrpcHandlerMappingPropertiesArgs as HandlerMappingArgs,
    VrpcInstanceToListSubscriptionHandler as ListHandler,
    VrpcInstanceToOneSubscriptionHandler as OneHandler,
    VrpcInstanceToPageSubscriptionHandler as PageHandler,
    VrpcInstanceToVirtualOneSubscriptionHandler as VirtualOneHandler,
} from '../../handlers'
import { BaseResourceManager } from './Base'

/**
 * Maps the given object, as long as they have an instance prop,
 * into props that have a promise for the same instance
 */
type PromisifyInstance<T extends Readonly<{ instance: unknown }>> = T extends Readonly<{ instance: infer VrpcInstance }>
    ? ReplaceValues<T, 'instance', Promise<VrpcInstance>>
    : never

const instanceHandlingErrorMessage = 'Error while interacting with instance'

/**
 * Manages VrpcInstances that are mapped into a single domain entry
 */
export class OneInstanceHandlerManager<VrpcInstance, Domain> extends BaseResourceManager<
    OneHandler<VrpcInstance, Domain>,
    PromisifyInstance<HandlerMappingArgs<OneHandler<VrpcInstance, Domain>>['DomainMapperArgs']>
> {
    protected readonly errorMessage = instanceHandlingErrorMessage

    protected async getMapToDomainArgs (): Promise<HandlerMappingArgs<OneHandler<VrpcInstance, Domain>>['DomainMapperArgs']> {
        const { instance, filter } = this.aux
        return { instance: await instance, filter }
    }

    protected async getOnChangeArgs (): Promise<HandlerMappingArgs<OneHandler<VrpcInstance, Domain>>['OnChangeArgs']> {
        const { instance } = this.aux
        return { instance: await instance, listener: this.createValueUpdater() }
    }
}

/**
 *  Manages VrpcInstances that are mapped into a single domain entry and require an id to fetch the needed information
 */
export class VirtualOneInstanceHandlerManager<VrpcInstance, Domain> extends BaseResourceManager<
    VirtualOneHandler<VrpcInstance, Domain>,
    PromisifyInstance<HandlerMappingArgs<VirtualOneHandler<VrpcInstance, Domain>>['DomainMapperArgs']>
> {
    protected readonly errorMessage = instanceHandlingErrorMessage

    protected override readonly errorExtras: Readonly<Record<string, unknown>> = { instance: this.aux.id }

    protected async getMapToDomainArgs (): Promise<HandlerMappingArgs<VirtualOneHandler<VrpcInstance, Domain>>['DomainMapperArgs']> {
        const { instance, id, filter } = this.aux
        return { instance: await instance, filter, id }
    }

    protected async getOnChangeArgs (): Promise<HandlerMappingArgs<OneHandler<VrpcInstance, Domain>>['OnChangeArgs']> {
        const { instance } = this.aux
        return { instance: await instance, listener: this.createValueUpdater() }
    }
}

/**
 * Manages VrpcInstances that are mapped each to many domain entries
 */
export class ListInstanceHandlerManager<VrpcInstance, Domain> extends BaseResourceManager<
    ListHandler<VrpcInstance, Domain>,
    PromisifyInstance<HandlerMappingArgs<ListHandler<VrpcInstance, Domain>>['DomainMapperArgs']>
> {
    protected readonly errorMessage = instanceHandlingErrorMessage

    protected async getMapToDomainArgs (): Promise<HandlerMappingArgs<ListHandler<VrpcInstance, Domain>>['DomainMapperArgs']> {
        const { instance, filter } = this.aux
        return { instance: await instance, filter }
    }

    protected async getOnChangeArgs (): Promise<HandlerMappingArgs<ListHandler<VrpcInstance, Domain>>['OnChangeArgs']> {
        const { instance } = this.aux
        return { instance: await instance, listener: this.createValueUpdater() }
    }
}

/**
 * Manages VrpcInstances that are mapped each to a page
 */
export class PageInstanceHandlerManager<VrpcInstance, Domain> extends BaseResourceManager<
    PageHandler<VrpcInstance, Domain>,
    PromisifyInstance<
        Readonly<{ pagination: Pick<ChangeEventListener<PaginationParameters<Domain>>, 'value' | 'onChange'>, remote: ManagedVrpcRemote }> &
            Pick<HandlerMappingArgs<PageHandler<VrpcInstance, Domain>>['DomainMapperArgs'], 'filter' | 'instanceName' | 'instance'>
    >
> {
    protected readonly errorMessage = instanceHandlingErrorMessage

    protected override readonly errorExtras: ReadonlyRecord<string, unknown> = { instance: this.aux.instanceName }

    protected async getMapToDomainArgs (): Promise<HandlerMappingArgs<PageHandler<VrpcInstance, Domain>>['DomainMapperArgs']> {
        const { instance, remote, instanceName, pagination, filter } = this.aux
        return {
            instance: await instance,
            filter,
            getInstance: <P extends VrpcProxy>(...args: Parameters<ManagedVrpcRemote['getInstance']>) => getInstance<P>(remote, ...args),
            instanceName,
            pagination: pagination.value,
        }
    }

    protected async getOnChangeArgs (): Promise<HandlerMappingArgs<PageHandler<VrpcInstance, Domain>>['OnChangeArgs']> {
        return { instance: await this.aux.instance, listener: this.createValueUpdater() }
    }

    override async start (): Promise<void> {
        await super.start()

        /** When done starting, listen to page changes, and if it changes, update things internally */
        this.onDrop(this.aux.pagination.onChange(this.createValueUpdater()))
    }
}
