import type { NonEmptyArray, ReadonlyRecord } from '../../utils'

import type { RuleEngineEndpoint, TopicConfiguration } from '../../domain'

import { type ConfigurationService, DocumentationType } from '../../application'

import type { ConnectwareHTTPTopicsService } from '../http'

/**
 * Gets versioned documentation URL
 * @example https://docs.cybus.io/v/1-7-0/
 * @returns {URL}
 */
const getBaseDocsURL = ({ version }: Config): URL => new URL(`https://docs.cybus.io/v/${version.replaceAll('.', '-')}/`)

/**
 * @deprecated move this to the backend
 * @see https://cybusio.atlassian.net/browse/CYB-3798
 */
const documentationPaths: ReadonlyRecord<DocumentationType, [baseURLCreator: (config: Config) => URL, path: string]> = {
    [DocumentationType.CYBUS_DOCS]: [getBaseDocsURL, ''],
    [DocumentationType.CYBUS_LEARN]: [() => new URL('https://www.cybus.io/learn/service-basics/'), ''],
    [DocumentationType.LICENSE_OFFLINE_ACTIVATION]: [getBaseDocsURL, 'documentation/connectware-licensing#activating-connectware-offline'],
    [DocumentationType.LICENSING]: [getBaseDocsURL, 'documentation/connectware-licensing'],
    [DocumentationType.MQTT_PREFIX]: [getBaseDocsURL, 'documentation/user-management/adding-a-mqtt-publish-prefix-for-users'],
    [DocumentationType.RULE_ENGINE]: [getBaseDocsURL, 'documentation/services/structure-of-commissioning-files/resources/rules-objects#expression'],
    [DocumentationType.RULE_ENGINE_PARSE_RULE]: [getBaseDocsURL, 'documentation/services/structure-of-commissioning-files/resources/rules-objects#parse'],
    [DocumentationType.SERVICES_COMMISSIONING_FILE]: [getBaseDocsURL, 'documentation/services/structure-of-commissioning-files'],
    [DocumentationType.SERVICES]: [getBaseDocsURL, 'documentation/services'],
    [DocumentationType.UI_ACCESS_PERMISSIONS]: [getBaseDocsURL, 'documentation/user-management/access-permissions-for-admin-ui/ui-access'],
}

type Config = Readonly<{ salesEmail: string, supportEmail: string, mqttEndpoints: URL[], version: string } & Pick<Location, 'origin' | 'host'>>

export class CybusConfigurationService implements ConfigurationService {
    constructor (private readonly config: Config, private readonly topicsService: ConnectwareHTTPTopicsService) {}

    getResourcesPaginationSizes (): [short: number, medium: number, large: number] {
        return [15, 50, 100]
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getClientRegistryRefreshRate (): number {
        return 5_000
    }

    getApplicationMetadata (): ReadonlyRecord<string, string> {
        return {
            'Content-Security-Policy': ['connect-src', "'self'", `https://${this.config.host}`, ...new Set(this.config.mqttEndpoints)].join(' '),
        }
    }

    getDocumentationURL (type: DocumentationType): URL {
        const [getBaseURL, path] = documentationPaths[type]
        return new URL(path, getBaseURL(this.config))
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getWorkbenchURL (): URL {
        return new URL('/workbench', this.config.origin)
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getCertificateFileTypes (): NonEmptyArray<string> {
        return ['pem']
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getSupportedServiceCommissioningFileTypes (): NonEmptyArray<string> {
        return ['yml', 'yaml']
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getSupportedLicenseFileTypes (): NonEmptyArray<string> {
        return ['lic']
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getServiceCommissioningFileType (): string {
        return 'yaml'
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getServiceCommissioningFileEncodingType (): string {
        return 'text/yaml;charset=utf-8'
    }

    /**
     *
     * @deprecated move this to the backend
     */
    getSupportedRestoreBackupFileExtensions (): NonEmptyArray<string> {
        return ['tgz']
    }

    getSalesEmail (): string {
        return this.config.salesEmail
    }

    getSupportEmail (): string {
        return this.config.supportEmail
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getInfoPageRefreshRate (): number {
        return 20_000
    }

    getUserManagementDelay (): number {
        return 200
    }

    fetchTopicsConfiguration (): Promise<TopicConfiguration[]> {
        return this.topicsService.fetchTopics()
    }

    fetchRuleEngineEndpointsConfiguration (): Promise<RuleEngineEndpoint[]> {
        return this.topicsService.fetchEndpoints()
    }
}
