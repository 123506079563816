import type { CybusDetailedEndpoint } from '../../../../../../domain'

import { mapDetailedEndpoint, mapToStatusType } from '../../../../../Connectware'

import { ENDPOINT_CLASSNAME_FILTER } from '../../../../constants'
import type { EndpointProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type CybusDetailedEndpointHandler = VrpcInstanceToOneSubscriptionHandler<EndpointProxy, CybusDetailedEndpoint>
type CybusDetailedEndpointHandlerArgs = VrpcHandlerMappingPropertiesArgs<CybusDetailedEndpointHandler>

export class VrpcDetailedCEndpointProxyInstanceHandler implements CybusDetailedEndpointHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = ENDPOINT_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    readonly ignoreInstanceByFilter = null

    readonly agent = null

    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<EndpointProxy>('state')

    mapToDomain ({ instance }: CybusDetailedEndpointHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedEndpointHandlerArgs['Domain']> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return Promise.all([instance.getParams(), instance.getTopics()]).then(([params, topics]) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            return mapDetailedEndpoint(params, currentStatus, topics)
        })
    }
}
