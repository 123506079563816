import { isArrayNotEmpty, type NonEmptyArray, type NullValues, type ReadonlyRecord } from '../utils'
import type { AvailableTopic, BufferType } from '.'

export type TopicPath = [string] | [...string[]]

export type TopicPathConfiguration = Readonly<{
    /**
     * The parsed path of the topic
     */
    path: TopicPath
    rawPath: string
}>

type TopicMessage = Readonly<{
    topic: TopicPathConfiguration['rawPath']
    payload: Buffer
    selectedType: BufferType
    possibleTypes: NonEmptyArray<BufferType>
    timestamp: Date
    /**
     * The subscribed topics that were listened to that yielded this message
     */
    sources: TopicPath[]
}>

export type TopicMessageEvent = Pick<TopicMessage, 'topic' | 'payload' | 'possibleTypes' | 'timestamp' | 'sources'>
export type UnloadedLastTopicMessage = NullValues<Pick<TopicMessage, 'payload'>> & Pick<TopicMessage, 'sources'>
export type LastTopicMessage = Pick<TopicMessage, 'payload' | 'selectedType' | 'possibleTypes' | 'sources'>
export type TailedTopicMessage = Pick<TopicMessage, 'topic' | 'payload' | 'selectedType' | 'timestamp'>

export type TopicSubscriptionError = 'invalid' | 'noPermissions' | 'unknown' | 'shared'

/**
 * Whenever a subscription to topics is made this object is returned
 *
 *  - subscribed: the topics that were actually subscribed
 *  - invalid: the topics that were not subscribed to and can never be subscribed to because they are invalid
 *  - noPermissions: the topics that were not subscribed to because the user lacks permissions
 *  - unknown: the topics that were not subscribed to due to unknown reasons and may be subscribed to if re-tried
 */
export type TopicSubscriptionResponse = ReadonlyRecord<'subscribed' | TopicSubscriptionError, AvailableTopic['rawPath'][]>

export const isTopicSubscriptionResponseSuccessful = ({
    invalid,
    noPermissions,
    unknown,
    shared,
}: Pick<TopicSubscriptionResponse, TopicSubscriptionError>): boolean =>
    !isArrayNotEmpty(invalid) && !isArrayNotEmpty(noPermissions) && !isArrayNotEmpty(unknown) && !isArrayNotEmpty(shared)
