import React, { type FC } from 'react'

import type { ReadonlyRecord } from '../../../utils'
import {
    canEndpointBeDisabled,
    canEndpointBeEnabled,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusEndpoint,
    selectDisabableLoadedEndpoints,
    selectEnabableLoadedEndpoints,
    selectEndpointsPage,
    selectLoadedEndpoints,
    Translation,
} from '../../../domain'

import { RedirectingResourcesTable } from '../Resources'
import { useTranslator } from '../Internationalization'
import { RelativeRoutePathWithId } from '../routing'
import { Status, useClearTableSelection } from '../common'
import { DeleteModal, DisableModal, EnableModal } from './Bulk'

const Modals: FC = () => {
    const clear = useClearTableSelection()
    return (
        <>
            <DeleteModal endpointsSelector={selectLoadedEndpoints} onConfirmed={clear} />
            <EnableModal endpointsSelector={selectEnabableLoadedEndpoints} onConfirmed={clear} />
            <DisableModal endpointsSelector={selectDisabableLoadedEndpoints} onConfirmed={clear} />
        </>
    )
}

const selectCanManageEndpoints = createIsAuthenticatedWithCapabilitiesSelector(Capability.ENDPOINTS_MANAGE)

export const Table: FC<Readonly<{ serviceId: string } | { connectionId: string } | ReadonlyRecord<string, never>>> = (props) => {
    const connection = 'connectionId' in props ? props.connectionId : null
    const service = 'serviceId' in props ? props.serviceId : null

    const translator = useTranslator()

    return (
        <RedirectingResourcesTable<
            'manageEndpointsUsecase',
            CybusEndpoint,
            CybusEndpoint,
            'bulkDeleteEndpointsUsecase' | 'bulkEnableEndpointsUsecase' | 'bulkDisableEndpointsUsecase'
        >
            subscriptionUsecase="manageEndpointsUsecase"
            filter={connection ? { connection } : service ? { service } : undefined}
            data={selectEndpointsPage}
            hasManagementCapabilitiesSelector={selectCanManageEndpoints}
            dataTableMapper={(endpoints) => endpoints}
            columns={{
                ...(service ? {} : { service: { label: Translation.SERVICE_ID, sort: true } }),
                name: { label: Translation.NAME, sort: true },
                ...(connection ? {} : { connection: { label: translator.formatTranslation(Translation.CONNECTION_ID, { count: 1 }), sort: true } }),
                protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                operation: { label: translator.formatTranslation(Translation.OPERATION, { count: 1 }), sort: true },
                agent: { label: translator.formatTranslation(Translation.AGENT, { count: 1 }), sort: true },
                status: { label: Translation.STATUS, customCellRender: (status) => <Status status={status} />, sort: true },
            }}
            short={Boolean(service || connection)}
            redirectOnRowclick={RelativeRoutePathWithId.ENDPOINT}
            selection={{
                DELETE: { usecase: 'bulkDeleteEndpointsUsecase' },
                DISABLE: { usecase: 'bulkDisableEndpointsUsecase', filter: canEndpointBeDisabled },
                ENABLE: { usecase: 'bulkEnableEndpointsUsecase', filter: canEndpointBeEnabled },
                resource: 'id',
                usecaseMethod: 'selectEndpoints',
            }}
            translations={{ noResultsOrEmptyTable: Translation.NO_ENDPOINTS_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
