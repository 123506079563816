import { type CybusCoreContainer, type CybusServiceContainer, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase, BulkDisableResourceUsecase, BulkEnableResourceUsecase, BulkReenableResourceUsecase } from '../Resources'

abstract class BulkDeleteContainersUsecase extends BulkDeleteResourceUsecase {
    selectContainers (ids: (CybusServiceContainer['id'] | CybusCoreContainer['id'])[] | null): void {
        this.select(ids)
    }

    override delete (): Promise<void> {
        return super.delete()
    }
}

export class BulkDeleteServiceContainersUsecase extends BulkDeleteContainersUsecase {
    protected readonly type = ResourceType.SERVICE_CONTAINER
}

abstract class BulkStartContainersUsecase extends BulkEnableResourceUsecase {
    selectContainers (ids: (CybusServiceContainer['id'] | CybusCoreContainer['id'])[] | null): void {
        this.select(ids)
    }

    start (): Promise<void> {
        return super.enable()
    }
}

export class BulkStartServiceContainersUsecase extends BulkStartContainersUsecase {
    protected readonly type = ResourceType.SERVICE_CONTAINER
}

export class BulkStartCoreContainersUsecase extends BulkStartContainersUsecase {
    protected readonly type = ResourceType.CORE_CONTAINER
}

abstract class BulkStopContainersUsecase extends BulkDisableResourceUsecase {
    selectContainers (ids: (CybusServiceContainer['id'] | CybusCoreContainer['id'])[] | null): void {
        this.select(ids)
    }

    stop (): Promise<void> {
        return super.disable()
    }
}

export class BulkStopServiceContainersUsecase extends BulkStopContainersUsecase {
    protected readonly type = ResourceType.SERVICE_CONTAINER
}

export class BulkStopCoreContainersUsecase extends BulkStopContainersUsecase {
    protected readonly type = ResourceType.CORE_CONTAINER
}

abstract class BulkRestartontainersUsecase extends BulkReenableResourceUsecase {
    selectContainers (ids: (CybusServiceContainer['id'] | CybusCoreContainer['id'])[] | null): void {
        this.select(ids)
    }

    restart (): Promise<void> {
        return super.reenable()
    }
}

export class BulkRestartServiceContainersUsecase extends BulkRestartontainersUsecase {
    protected readonly type = ResourceType.SERVICE_CONTAINER
}

export class BulkRestartCoreContainersUsecase extends BulkRestartontainersUsecase {
    protected readonly type = ResourceType.CORE_CONTAINER
}
