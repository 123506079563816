import type { Primitive } from 'utility-types'

import { areArrayEquals, executeOnce, nameFunction, objectEntries } from '.'

type Comparator<V> = (a: V, b: V) => boolean

export type Comparators<V> = { [P in keyof V]: Comparator<V[P]> | null }

export const createArrayComparator =
    <V>(customComparator?: Comparator<V>, sort: ((a: V, b: V) => number) | false = false): Comparator<V[]> =>
    (a, b) =>
        areArrayEquals(a, b, { sort, equals: customComparator })

export const createPrimitiveValueComparator =
    <V>(extractor: (value: V) => Primitive): Comparator<V> =>
    (a, b) =>
        extractor(a) === extractor(b)

export const createEqualityChecker = <D extends object>(comparators: Comparators<D>): Comparator<D> => {
    const getComparators = executeOnce(() => objectEntries(comparators))

    return (a: D, b: D): boolean =>
        a === b ||
        getComparators().every(([field, comparator]) => {
            const vA = a[field]
            const vB = b[field]

            return vA === vB || (comparator ? (comparator as Comparator<D[typeof field]>)(vA, vB) : false)
        })
}

export const createNamedEqualityChecker = <D extends object>(comparators: Comparators<D>, name: string): Comparator<D> =>
    nameFunction(createEqualityChecker(comparators), name)
