import { nameFunction, type ReadonlyRecord } from '../../utils'
import { ConnectwareError, createPageSelector, type Page, type PaginatedData, type TopicMessageEvent } from '..'
import { createLoadedSelector, type Loadable } from '../Loadable'
import {
    canServiceBeDisabled,
    canServiceBeEnabled,
    type CommissioningFileForm,
    type CybusDetailedService,
    type CybusLinkedService,
    type CybusService,
    type CybusServiceCatalog,
    type CybusServiceDataResource,
    type CybusServiceDeviation,
    type CybusServiceForm,
} from '.'

export type ServicesAppState = Readonly<{
    service: Loadable<CybusDetailedService>
    servicesDataPage: Page<CybusServiceDataResource>
    serviceTopicsMessages: ReadonlyRecord<TopicMessageEvent['topic'], Pick<TopicMessageEvent, 'payload' | 'possibleTypes' | 'timestamp'> | ConnectwareError>
    servicesPage: Page<CybusService>
    servicesLinksPage: Page<CybusLinkedService>
    serviceForm: CybusServiceForm | null
    catalog: CybusServiceCatalog
    serviceDeviationsPage: Page<CybusServiceDeviation>
    editTemplateForm: CommissioningFileForm
}>

export const selectService = (s: ServicesAppState): ServicesAppState['service'] => s.service

export const selectServicesDataPage = (s: ServicesAppState): ServicesAppState['servicesDataPage'] => s.servicesDataPage

export const selectServicesDataTopics = (s: ServicesAppState): string[] => {
    const page = selectServicesDataPage(s)
    return page && page.data && !ConnectwareError.is(page.data) && page.data.current ? page.data.current.flatMap((r) => r.topics) : []
}

export const selectServiceTopicsMessages = (s: ServicesAppState): ServicesAppState['serviceTopicsMessages'] => s.serviceTopicsMessages

export const selectServicesPage = (s: ServicesAppState): ServicesAppState['servicesPage'] => s.servicesPage

export const selectServicesLinksPage = (s: ServicesAppState): ServicesAppState['servicesLinksPage'] => s.servicesLinksPage

export const selectServiceForm = (s: ServicesAppState): ServicesAppState['serviceForm'] => s.serviceForm

export const selectServiceCatalog = (s: ServicesAppState): ServicesAppState['catalog'] => s.catalog

export const selectEditTemplate = (s: ServicesAppState): ServicesAppState['editTemplateForm'] => s.editTemplateForm

export const mapEditTemplateToState = (editTemplateForm: CommissioningFileForm): Partial<ServicesAppState> => ({ editTemplateForm })

const createServiceCatalogSelector =
    <K extends keyof ServicesAppState['catalog']>(name: K) =>
    (s: ServicesAppState): ServicesAppState['catalog'][K] =>
        selectServiceCatalog(s)[name]

export const selectServiceCatalogTemplate = createServiceCatalogSelector('template')
export const selectServiceCatalogFiltered = createServiceCatalogSelector('filtered')
export const selectServiceCatalogApplication = createServiceCatalogSelector('application')
export const selectServiceCatalogApplications = createServiceCatalogSelector('applications')
export const selectServiceCatalogSearch = createServiceCatalogSelector('search')

export const selectDisablableLoadedDetailedService = createLoadedSelector(selectService, 'id', canServiceBeDisabled)
export const selectDeletableLoadedDetailedService = createLoadedSelector(selectService, 'id')
export const selectEnabableLoadedDetailedService = createLoadedSelector(selectService, 'id', canServiceBeEnabled)

export const selectDisablableLoadedServices = createPageSelector(selectServicesPage, 'id', 'selectDisablableLoadedServices', canServiceBeDisabled)
export const selectDeletableLoadedServices = createPageSelector(selectServicesPage, 'id', 'selectDeletableLoadedServices')

/**
 *  @param transformData - Function that formats or transform the data into a desired format
 *  @param fallback - Default value returned when the data is missing
 *  @param name - Name of the function
 *
 *  @returns selector function that either returns transformed data or provides a fallback value
 *
 */
const createDeviationsPageSelector = <T, F>(
    transformData: (page: PaginatedData<CybusServiceDeviation>) => T,
    fallback: F,
    name: string
): ((s: ServicesAppState) => T | F) => {
    return nameFunction(({ serviceDeviationsPage }) => {
        if (serviceDeviationsPage === null || serviceDeviationsPage.data === null || ConnectwareError.is(serviceDeviationsPage.data)) {
            return fallback
        }

        const { current, totalCount } = serviceDeviationsPage.data
        const { page, pageSize } = serviceDeviationsPage.pagination

        return transformData({ current, page, pageSize, totalCount })
    }, name)
}

export const selectServiceDeviationPage = createDeviationsPageSelector((pageData) => pageData, null, 'selectServiceDeviationPage')

export const selectHasDeviations = createDeviationsPageSelector((pageData) => pageData.totalCount > 0, false, 'selectHasDeviations')
export const selectDeviationNames = createDeviationsPageSelector((pageData) => pageData.current.map((d) => d.name), [], 'selectDeviationNames')

export const selectDeviationPage = createDeviationsPageSelector((pageData) => pageData.page, 0, 'selectDeviationPage')
