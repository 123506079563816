import { executeOnce } from '../../../../../../utils'

import { ConnectwareError, ConnectwareErrorType } from '../../../../../../domain'

import { isTimeoutError } from '../../../../utils'
import type { ContainerManagerOrchestratorProxy, DockerProxy } from '../../../../proxies'

enum ContainerType {
    DOCKER,
    K8,
}

class Resolver {
    constructor (private readonly type: ContainerType) {}

    /**
     * The proxy is irrelevant, but typescript needs it for proper casting
     */
    private is (_: ContainerManagerOrchestratorProxy, type: ContainerType): boolean {
        return this.type === type
    }

    isDocker (proxy: ContainerManagerOrchestratorProxy): proxy is DockerProxy {
        return this.is(proxy, ContainerType.DOCKER)
    }
}

const resolveType = async (proxy: ContainerManagerOrchestratorProxy): Promise<ContainerType> =>
    proxy
        .getImplementationName()
        .catch((e: Error) => {
            if (isTimeoutError(e)) {
                throw new ConnectwareError(ConnectwareErrorType.SERVER_TIMEOUT, 'There was a time-out while retrieving the implementation of the instance')
            }

            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected error', { message: e.message })
        })
        .then((name) => {
            switch (name) {
                case 'Docker':
                    return ContainerType.DOCKER
                case 'Kubernetes':
                    return ContainerType.K8
                default:
                    throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected implementation', { name })
            }
        })

/**
 * Due to typescript limitations of having an async `is` operator (as seen in `Resolver.is`)
 * this is a work around to use it
 *
 * The type of the implementation is calculated asynchronously
 * Then just used synchronously for a cleaner code down the line
 *
 * This relies heavily on the assumption that
 * from the perspective of the front-end and throughout the life cycle of the Connectware
 * a container manager will not switch back and forth between implementations
 */
export const fetchImplementationResolver = executeOnce((proxy: ContainerManagerOrchestratorProxy) => resolveType(proxy).then((type) => new Resolver(type)))
