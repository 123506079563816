import type { ReadonlyRecord } from '../../../utils'

import {
    Capability,
    ConnectwareError,
    ConnectwareErrorType,
    isAuthenticatingWithOtp,
    type MfaActivationData,
    type MfaUpdateCredentials,
    Translation,
    type ValidOtp,
} from '../../../domain'

import type { MfaService, TranslationService } from '../../../application'
import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService, type HttpResponse } from '../Base'
import type { IsEnrolledResponse, MfaEnableResponse, MfaValidateResponse } from './Types'

export class ConnectwareHTTPMfaService extends FetchConnectwareHTTPService implements MfaService {
    private readonly errorHandlers = {
        500: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, this.translationService.translate(Translation.SERVER_ERROR)),
    }

    private static async mapBackupCodesResponse (response: HttpResponse): Promise<string[]> {
        return response.getJson<MfaValidateResponse>().then(({ backupCodes }) => backupCodes)
    }

    private static mapRequest ({ otp, backupCode }: MfaUpdateCredentials): ReadonlyRecord<string, string> {
        const body: Record<string, string> = {}

        if (otp) {
            body.otp = otp.join('')
        }

        if (backupCode) {
            body.backupCode = backupCode
        }

        return body
    }

    constructor (options: ConnectwareHTTPServiceOptions, private readonly translationService: TranslationService) {
        super(options)
    }

    private mapInvalidCredentials (credentials: MfaUpdateCredentials): ConnectwareError {
        return new ConnectwareError(
            ConnectwareErrorType.AUTHENTICATION,
            this.translationService.translate(Translation.WRONG_MFA_CREDENTAILS, { type: isAuthenticatingWithOtp(credentials) ? 'otp' : 'backupCode' })
        )
    }

    isEnabled (): Promise<boolean> {
        return this.request({
            capability: Capability.USE_MFA,
            method: 'GET',
            path: '/api/mfa/isenrolled',
            authenticate: true,
            handlers: { 200: (response) => response.getJson<IsEnrolledResponse>().then(({ isEnrolled }) => isEnrolled) },
        })
    }

    enable (): Promise<MfaActivationData> {
        return this.request({
            capability: Capability.USE_MFA,
            method: 'POST',
            path: '/api/mfa/enable',
            authenticate: true,
            handlers: {
                ...this.errorHandlers,
                200: async (response) => {
                    const { uri } = await response.getJson<MfaEnableResponse>()

                    const url = new URL(uri)
                    const secret = url.searchParams.get('secret')

                    if (!secret) {
                        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not find secret in uri', { uri })
                    }

                    return { uri: url, secret }
                },
                409: () =>
                    new ConnectwareError(
                        ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                        this.translationService.translate(Translation.MFA_ALREADY_ENABLED_ERROR)
                    ),
            },
        })
    }

    disable (credentials: MfaUpdateCredentials): Promise<void> {
        return this.request({
            capability: Capability.USE_MFA,
            method: 'POST',
            path: '/api/mfa/disable',
            authenticate: true,
            body: ConnectwareHTTPMfaService.mapRequest(credentials),
            handlers: { ...this.errorHandlers, 401: () => this.mapInvalidCredentials(credentials), 200: () => Promise.resolve() },
        })
    }

    confirmEnabling (otp: ValidOtp): Promise<string[]> {
        return this.request({
            capability: Capability.USE_MFA,
            method: 'POST',
            path: '/api/mfa/validate',
            authenticate: true,
            body: ConnectwareHTTPMfaService.mapRequest({ otp, backupCode: null }),
            handlers: {
                ...this.errorHandlers,
                200: ConnectwareHTTPMfaService.mapBackupCodesResponse,
                401: () => this.mapInvalidCredentials({ otp, backupCode: null }),
            },
        })
    }

    regenerateBackupCodes (credentials: MfaUpdateCredentials): Promise<string[]> {
        return this.request({
            capability: Capability.USE_MFA,
            method: 'POST',
            path: '/api/mfa/regenerate/backupcodes',
            authenticate: true,
            body: ConnectwareHTTPMfaService.mapRequest(credentials),
            handlers: { ...this.errorHandlers, 401: () => this.mapInvalidCredentials(credentials), 200: ConnectwareHTTPMfaService.mapBackupCodesResponse },
        })
    }
}
