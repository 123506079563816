import type { PickByValue } from 'utility-types'
import React, { type FC } from 'react'

import type { ReadonlyRecord } from '../../../utils'
import { type MfaSettings, MfaSettingsState, selectMfaUpdateCredentials, Translation } from '../../../domain'
import type { Usecases } from '../../../application'

import { FormattedTranslation } from '../Internationalization'
import { useAppUsecase } from '../State'
import { SmallParagraph, TextField } from '../common'

import { ActionButton, Block, Link, OtpField } from './Common'

type SupportedUsecases = keyof PickByValue<Usecases, { updateInput(value: string[] | string): void }>
type SupportedSettings = Extract<MfaSettings, Parameters<typeof selectMfaUpdateCredentials>[0]>
type EnabledActionProps<U,> = Readonly<{ idPrefix: string, usecase: U }> & SupportedSettings

const actions: ReadonlyRecord<SupportedSettings['state'], 'regenerateBackupCodes' | 'disable'> = {
    [MfaSettingsState.REGENERATING_BACKUP_CODES]: 'regenerateBackupCodes',
    [MfaSettingsState.DISABLING]: 'disable',
}

export const EnabledActionSection = <U extends SupportedUsecases,>({ idPrefix, usecase: usecaseName, ...settings }: EnabledActionProps<U>): ReturnType<FC> => {
    const usecase = useAppUsecase(usecaseName)

    const onChange = (value: string[] | string): void => usecase.updateInput(value)
    const authType = (settings.backupCode !== null && 'backupCodes') || (settings.otp !== null && 'otp')
    const action = actions[settings.state]

    return (
        <>
            <SmallParagraph id={Translation.MFA_ENABLED_ACTION_DESCRIPTION} values={{ action, method: authType }} />
            <Block>
                {settings.otp !== null && <OtpField id={`${idPrefix}-with-otp-input`} value={settings.otp} onChange={onChange} />}
                {settings.backupCode !== null && (
                    <TextField data-testid={`${idPrefix}-with-backupcode-input`} value={settings.backupCode} onChange={onChange} />
                )}
                <Link data-testid={`${idPrefix}-toggle-link`} onClick={() => usecase.toggle()}>
                    {/* Inversion will be done inside the translation */}
                    <FormattedTranslation id={Translation.TOGGLE_AUTHENTICATION_METHOD} values={{ method: authType }} />
                </Link>
                <Link data-testid={`${idPrefix}-cancel-link`} onClick={() => usecase.cancel()}>
                    <FormattedTranslation id={Translation.CANCEL} />
                </Link>
                <ActionButton<SupportedUsecases>
                    data-testid={`${idPrefix}-confirmation-button`}
                    usecase={usecaseName}
                    method="confirm"
                    error={settings.error}
                    label={Translation.MFA_ENABLED_ACTION_CONFIRMATION}
                    labelValues={{ action }}
                    disabled={selectMfaUpdateCredentials(settings) === null}
                />
            </Block>
        </>
    )
}
