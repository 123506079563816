import type { PickByValueExact } from 'utility-types'

import { isArrayNotEmpty } from '../utils'

import { ConnectwareError } from '.'

export type Loadable<T = never, E = never> = T | E | ConnectwareError | null

export const isLoadableLoaded = <T>(loadable: Loadable<T>): loadable is T => Boolean(loadable && !ConnectwareError.is(loadable))

type LoadableSelector<S, E> = (s: S) => Loadable<E>
type EntitySelector<S, E> = (s: S, id: string[]) => E[]

/** Creates a selector for the loadable items on the domain */
export const createLoadedSelector =
    <AppState, Entity extends object, IdField extends keyof PickByValueExact<Entity, string>>(
        select: LoadableSelector<AppState, Entity>,
        idField: IdField,
        filter?: (c: Entity) => boolean
    ): EntitySelector<AppState, Entity> =>
    (s, ids) => {
        /** Nothing is selected, load nothing */
        if (!isArrayNotEmpty(ids)) {
            return []
        }

        /** Select from the state */
        const loadable = select(s)

        if (!isLoadableLoaded(loadable) || (filter && !filter(loadable)) || !ids.includes(loadable[idField] as string)) {
            return []
        }

        return [loadable]
    }
