import type { StatusType } from '../../../domain'
import type { SubscriptionFilterArgs } from '../../../application'

/**
 * Simple base mapper class to help
 * its implementations to better map backend entities into domain entities
 */
export abstract class FilteredResourceMapper<Resource, Domain> {
    constructor (protected readonly filter: SubscriptionFilterArgs) {}

    protected abstract map (resource: Resource, status: StatusType): Domain | null

    protected abstract collect (domain: Domain): void

    push (resource: Resource, status: StatusType): void {
        const mapped = this.map(resource, status)

        if (mapped) {
            this.collect(mapped)
        }
    }
}
