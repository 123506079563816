import type { PickByValue, PickByValueExact } from 'utility-types'

import { objectEntries, objectKeys, type ReadonlyRecord } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../Error'
import {
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFieldType,
    type CommissioningFileValidatedValue,
    type CommissioningFileValidatedValues,
    type CommissioningFileValue,
    type CommissioningFileValues,
    mapCommissioningFileValidatedValue,
    type StringCommissioningFileField,
} from '.'

export abstract class CommissioningFileFinder<
    Args extends unknown[],
    Field extends CommissioningFileField,
    ValueTupple extends [CommissioningFileValidatedValues, CommissioningFileValidatedValue<Field>] | [CommissioningFileValues, CommissioningFileValue<Field>]
> {
    protected static mapSubProperty<V extends ReadonlyRecord<string, unknown>, K extends string & keyof V> (values: V, name: K): V[K] {
        if (!(name in values)) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Could not find value in properties', { values, name })
        }
        return values[name] as V[K]
    }

    constructor (protected readonly fields: CommissioningFileFields, protected readonly values: ValueTupple[0]) {}

    abstract findField (...args: Args): Field

    abstract findValue (...args: Args): ValueTupple[1]

    abstract find (...args: Args): [field: Field, value: ValueTupple[1]]
}

export class MetadataCommissioningFileFinder<
    ValueTupple extends
        | [CommissioningFileValidatedValues, CommissioningFileValidatedValue<StringCommissioningFileField>]
        | [CommissioningFileValues, CommissioningFileValue<StringCommissioningFileField>]
> extends CommissioningFileFinder<[name: string], StringCommissioningFileField, ValueTupple> {
    findValue (name: string): ValueTupple[1] {
        return CommissioningFileFinder.mapSubProperty(this.values.metadata, name)
    }

    findField (name: string): StringCommissioningFileField {
        return CommissioningFileFinder.mapSubProperty(this.fields.metadata, name)
    }

    find (...args: [string]): [field: StringCommissioningFileField, value: ValueTupple[1]] {
        return [this.findField(...args), this.findValue(...args)]
    }
}

export abstract class ResourceCommissioningFileFinder<
    ValueTupple extends
        | [CommissioningFileValidatedValues, CommissioningFileValidatedValue<CommissioningFileField>]
        | [CommissioningFileValues, CommissioningFileValue<CommissioningFileField>]
> extends CommissioningFileFinder<[position: number, fieldName: string], CommissioningFileField, ValueTupple> {
    protected abstract fieldsName: keyof PickByValueExact<CommissioningFileFields, ReadonlyRecord<string, CommissioningFileField>>

    protected abstract valuesName: keyof PickByValue<
        CommissioningFileValidatedValues | CommissioningFileValues,
        ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField> | CommissioningFileValidatedValue<CommissioningFileField>>[]
    >

    private findFieldRecursively (
        position: number,
        fieldName: string,
        currentFields: ReadonlyRecord<string, CommissioningFileField>
    ): CommissioningFileField | null {
        if (currentFields[fieldName]) {
            return CommissioningFileFinder.mapSubProperty(currentFields, fieldName)
        }

        /**
         * Iterate through the current list
         */
        for (const [name, field] of objectEntries(currentFields)) {
            if (field.type !== CommissioningFileFieldType.OPTIONS) {
                continue
            }

            /**
             * If its an options field, then find the selected option, and iterate through those fields
             */
            const optionValue = this.findValue(position, name)

            const plainOptionValue = optionValue && typeof optionValue === 'object' && 'value' in optionValue ? optionValue.value : optionValue
            if (typeof plainOptionValue !== 'string') {
                throw new ConnectwareError(ConnectwareErrorType.STATE, 'Selected option value type is not as expected', {
                    name: [this.valuesName, name],
                    position,
                    optionValue: plainOptionValue,
                })
            }

            /** Retrieve the specific options from the 'options' object */
            const selectedOptionFields = field.options[plainOptionValue]

            if (!selectedOptionFields) {
                throw new ConnectwareError(ConnectwareErrorType.STATE, 'Selected option does not exist within the expected value range', {
                    value: plainOptionValue,
                    field,
                })
            }

            /** Now go deeper */
            const nestedField = this.findFieldRecursively(position, fieldName, selectedOptionFields)
            if (nestedField) {
                return nestedField
            }
        }

        /** Just give up, and hope that somewhere else the field is found */
        return null
    }

    findValue (position: number, name: string): ValueTupple[1] {
        const values = this.values[this.valuesName]
        const resource = values[position]

        if (!resource) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Resource does not exist at given position', { values, position })
        }

        return CommissioningFileFinder.mapSubProperty(resource, name)
    }

    findField (position: number, fieldName: string): CommissioningFileField {
        const match = this.findFieldRecursively(position, fieldName, this.fields[this.fieldsName])

        if (!match) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Field not found', { position, fieldName })
        }

        return match
    }

    findFieldsToRemove (position: number, fieldName: string): string[] {
        let toRemove: string[] = [fieldName]
        const field = this.findField(position, fieldName)

        if (field.type === CommissioningFileFieldType.OPTIONS) {
            const value = this.findValue(position, fieldName)
            const previousOptions = value && typeof value === 'object' && 'value' in value && typeof value.value === 'string' && field.options[value.value]

            if (previousOptions) {
                toRemove = [...toRemove, ...objectKeys(previousOptions).flatMap((internalField) => this.findFieldsToRemove(position, internalField))]
            }
        }

        return toRemove
    }

    findFieldsToAdd (
        position: number,
        fieldName: string,
        newValue: CommissioningFileValue<CommissioningFileField> | null
    ): ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>> {
        const field = this.findField(position, fieldName)
        const valuesToAdd: ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>> = {
            [fieldName]: mapCommissioningFileValidatedValue(field, newValue),
        }

        if (field.type !== CommissioningFileFieldType.OPTIONS) {
            return valuesToAdd
        }

        const nextOptions = typeof newValue === 'string' && field.options[newValue]

        if (!nextOptions) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Option value does not exist within the available options', {
                optionsKey: objectKeys(field.options),
                newValue,
            })
        }

        return objectEntries(nextOptions).reduce((r, [name, nestedField]) => {
            if (nestedField.optional) {
                return r
            }

            return { ...r, [name]: mapCommissioningFileValidatedValue(nestedField, nestedField.default) }
        }, valuesToAdd)
    }

    find (...args: [number, string]): [field: CommissioningFileField, value: ValueTupple[1]] {
        return [this.findField(...args), this.findValue(...args)]
    }
}

export class ConnectionsCommissioningFileFinder<
    ValueTupple extends
        | [CommissioningFileValidatedValues, CommissioningFileValidatedValue<CommissioningFileField>]
        | [CommissioningFileValues, CommissioningFileValue<CommissioningFileField>]
> extends ResourceCommissioningFileFinder<ValueTupple> {
    protected readonly fieldsName: 'connection' = 'connection'
    protected readonly valuesName: 'connections' = 'connections'
}

export class EndpointsCommissioningFileFinder<
    ValueTupple extends
        | [CommissioningFileValidatedValues, CommissioningFileValidatedValue<CommissioningFileField>]
        | [CommissioningFileValues, CommissioningFileValue<CommissioningFileField>]
> extends ResourceCommissioningFileFinder<ValueTupple> {
    protected readonly fieldsName: 'endpoint' = 'endpoint'
    protected readonly valuesName: 'endpoints' = 'endpoints'
}
