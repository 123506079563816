import type { Intersection, ValuesType } from 'utility-types'

import { objectEntries } from '../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    type CybusDetailedEndpoint,
    type CybusEndpoint,
    type CybusEndpointAddress,
    type StatusType,
} from '../../../domain'

import type { EndpointProxyParams } from '../proxies'
import { mapResourceNames } from './Resource'
import { FilteredResourceMapper } from './Filter'

const isExpectedAddressValueType = (value: ValuesType<EndpointProxyParams['address']>): value is ValuesType<CybusEndpointAddress> =>
    Boolean(
        typeof value === 'number' ||
            typeof value === 'boolean' ||
            typeof value === 'string' ||
            (value && typeof value === 'object' && Object.values(value).every((v) => typeof v === 'string'))
    )

const isExpectedAddressType = (address: EndpointProxyParams['address']): address is CybusEndpointAddress =>
    typeof address === 'object' && objectEntries(address).every(([key, value]) => typeof key === 'string' && isExpectedAddressValueType(value))

const mapToAddress = (address: EndpointProxyParams['address']): CybusEndpointAddress => {
    if (!isExpectedAddressType(address)) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Unexpected address type', address)
    }

    return address
}

const mapBaseEndpoint = (
    { id, operation, protocol, agentName }: Pick<EndpointProxyParams, 'id' | 'operation' | 'protocol' | 'agentName'>,
    status: StatusType
): Intersection<CybusDetailedEndpoint, CybusEndpoint> => {
    const [service, name] = mapResourceNames(id)
    return { id, name, service, operation, protocol, status, agent: agentName || null }
}

export class CybusEndpointMapper extends FilteredResourceMapper<EndpointProxyParams, CybusEndpoint> {
    readonly endpoints: CybusEndpoint[] = []

    protected map ({ connectionId, ...params }: EndpointProxyParams, status: StatusType): CybusEndpoint | null {
        const { service, connection } = this.filter

        if (connection && connection !== connectionId) {
            return null
        }

        const endpoint: CybusEndpoint = { ...mapBaseEndpoint(params, status), connection: connectionId || null }
        if (service && service !== endpoint.service) {
            return null
        }

        return endpoint
    }

    protected collect (endpoint: CybusEndpoint): void {
        this.endpoints.push(endpoint)
    }
}

export const mapDetailedEndpoint = ({ address, rules, ...params }: EndpointProxyParams, status: StatusType, topics: string[]): CybusDetailedEndpoint => ({
    ...mapBaseEndpoint(params, status),
    address: mapToAddress(address),
    rules: rules || null,
    topics,
})
