import React, { type FC, type PropsWithChildren, useCallback, useEffect } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'
import { AddRounded } from '@mui/icons-material'

import type { ArrayType } from '../../../utils'
import {
    ConnectwareError,
    selectIsLoading,
    selectUserManagementSelectedTabData,
    selectUserManagementSelectedTabPage,
    selectUserManagementSelectedTabSearch,
    Translation,
    type UserManagementTabsTypes,
} from '../../../domain'

import { AbsoluteRouteOnlyPath } from '../routing'
import { useAppState, useAppUsecase } from '../State'
import { ErrorMessage } from '../ErrorMessage'
import { RouteTabs } from '../Tabs'
import { Table as BaseTable, type TableProps as BaseTableProps, Chip, type TableCustomizedState, type TableLine } from '../common'

import { Filter } from './Filter'

import { UserCreationModal, UserEditModal } from './Users'
import { RoleCreationModal, RoleEditModal } from './Roles'
import { PermissionViewModal } from './Permissions'

const errorStyle: SxProps<Theme> = { pt: 2 }

type TableProps<L extends TableLine,> = Readonly<{
    selected: keyof UserManagementTabsTypes
    notFound: Translation
    onAdd?: VoidFunction
    addLabel?: Translation
}> &
    Pick<BaseTableProps<L>, 'columns' | 'onRowClick'>

const Table = <L extends TableLine,>({ selected, notFound, columns, ...props }: PropsWithChildren<TableProps<L>>): ReturnType<FC> => {
    const data = useAppState((s) => selectUserManagementSelectedTabData(s, selected), [selected])
    const search = useAppState((s) => selectUserManagementSelectedTabSearch(s, selected), [selected])
    const page = useAppState((s) => selectUserManagementSelectedTabPage(s, selected), [selected])
    const isLoading = useAppState(selectIsLoading)
    const hasNoTableData = data === null

    const usecase = useAppUsecase('filterUserManagementUsecase')

    const onCustomized = useCallback(
        (s: TableCustomizedState<L>) => {
            if (isLoading) {
                return
            }
            if ('page' in s) {
                usecase.setPage(selected, s.page + 1)
            }
            if ('search' in s) {
                usecase.search(selected, s.search)
            }
        },
        [usecase, selected, isLoading]
    )

    // TODO Move this inside the table
    if (ConnectwareError.is(data)) {
        return <ErrorMessage sx={errorStyle} data-testid="users-management-error" error={data} stack extras="section" />
    }

    const [onAdd, addLabel, onRowClick] = isLoading ? [] : [props.onAdd, props.addLabel, props.onRowClick]

    return (
        <BaseTable
            columns={columns}
            data={hasNoTableData ? [] : (data.current as (L & ArrayType<typeof data.current>)[])}
            translations={{ emptyTable: notFound }}
            extendedToolbar={onAdd && addLabel && <Chip data-testid="add-button" label={addLabel} avatar={AddRounded} onClick={onAdd} />}
            pagination={hasNoTableData ? undefined : { pageSize: data.pageSize, pageSizeOptions: [data.pageSize], page: page - 1, totalCount: data.totalCount }}
            onRowClick={onRowClick}
            search={search === null || search}
            loading={hasNoTableData}
            onCustomized={onCustomized}
        />
    )
}

export const Page = <L extends TableLine,>({ selected, children, ...props }: PropsWithChildren<TableProps<L>>): ReturnType<FC> => {
    const loadUsecase = useAppUsecase('loadUserManagementDataUsecase')
    useEffect(() => loadUsecase.invoke(selected), [loadUsecase, selected])

    const tab = {
        documentationTitle: Translation.USERS_AND_ROLES_DOCUMENTATION_TITLE,
        documentationBody: Translation.USERS_AND_ROLES_DOCUMENTATION_BODY,
        titleActions: <Filter selected={selected} />,
    } as const

    return (
        <Box data-testid="management-table">
            <RouteTabs
                title={Translation.USERS_AND_ROLES}
                tabs={{
                    [AbsoluteRouteOnlyPath.USER_MANAGEMENT_USERS]: tab,
                    [AbsoluteRouteOnlyPath.USER_MANAGEMENT_ROLES]: tab,
                    [AbsoluteRouteOnlyPath.USER_MANAGEMENT_PERMISSIONS]: tab,
                }}
            />

            <Table selected={selected} {...props} />

            {/* The modals need to be here in order to be shown in every page */}

            <UserEditModal />
            <UserCreationModal />
            <RoleEditModal />
            <RoleCreationModal />
            <PermissionViewModal />
        </Box>
    )
}
