import { BelatedThrottler, Droppable } from '../../../utils'
import { areUsersManagementSelectedTabInformationFiltersEquals, type UserManagementTabsTypes } from '../../../domain'

import { TabManagementUsecase } from './Tab'

export class LoadUserManagementDataUsecase extends TabManagementUsecase {
    invoke (tab: keyof UserManagementTabsTypes): VoidFunction {
        const droppable = new Droppable()

        /** So the load does not get triggered every time there is a state change */
        const throttler = new BelatedThrottler(this.configurationService.getUserManagementDelay())

        /** Listen to state changes, and after the calls are throttled, then load again */
        droppable.onDrop(
            this.subscribeToState(
                (a, b) => areUsersManagementSelectedTabInformationFiltersEquals(tab, a, b),
                () => droppable.onDrop(throttler.run(() => void this.loadTab(tab, false)))
            )
        )

        /** Finally load everything */
        void this.loadTab(tab, false)

        return () => droppable.drop()
    }
}
