import React, { type FC, type PropsWithChildren } from 'react'
import { Link, type LinkProps } from '@mui/material'

import { type AllUnionKeys, isEnumOf } from '../../../utils'

import { useInternalRoutingHook } from './Provider'
import { useHasCapabilities } from './Hooks'
import {
    type AbsoluteRouteOnlyPath,
    type AbsoluteRoutePathWithId,
    type AbsoluteRoutePathWithServiceId,
    type AbsoluteRouteUpdateArgs,
    RelativeRoutePathWithId,
} from './Domain'
import type { RouteParentPath } from './Config'

type RoutingProps = Readonly<
    | { path: AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | RelativeRoutePathWithId, id: string }
    | { path: AbsoluteRouteOnlyPath }
    | { parent: RouteParentPath }
>

type CosmeticProps = Pick<LinkProps, 'color' | 'sx' | 'title'>
type WrapperProps = Readonly<{ authorized: boolean }>
type WrapperAsArgsProps = Readonly<{ wrapper?: FC<WrapperProps> }>

type InternalHook = ReturnType<typeof useInternalRoutingHook>

type InternalProps = RoutingProps &
    CosmeticProps &
    WrapperAsArgsProps &
    Readonly<{ routing: InternalHook, hasCapabilities: ReturnType<typeof useHasCapabilities> }>

const splitRoutingProps = <P extends RoutingProps,>(props: P): [RoutingProps, Omit<P, AllUnionKeys<RoutingProps>>] => {
    type OutType = Omit<P, AllUnionKeys<RoutingProps>>

    if ('parent' in props) {
        const { parent, ...parentLessProps } = props
        return [{ parent }, parentLessProps as unknown as OutType]
    }

    if ('id' in props) {
        const { path, id, ...idLessProps } = props
        return [{ path, id }, idLessProps as unknown as OutType]
    }

    const { path, ...pathLessProps } = props
    return [{ path }, pathLessProps as unknown as OutType]
}

const resolveAbsoluteRouteUpdateArgs = (routing: InternalHook, props: RoutingProps): AbsoluteRouteUpdateArgs => {
    if ('id' in props) {
        const route = isEnumOf(RelativeRoutePathWithId, props.path) ? routing.getAbsoluteRoute(props.path) : props.path
        return [route, props.id]
    }

    if ('parent' in props) {
        return routing.getParentAbsoluteRouteUpdateArgs(props.parent)
    }

    return [props.path]
}

const defaultWrapper: FC<WrapperProps> = ({ children }) => <>{children}</>

const InternalLink: FC<PropsWithChildren<InternalProps>> = (allProps) => {
    const [routeProps, { wrapper: Wrapper = defaultWrapper, routing, hasCapabilities, children, ...props }] =
        splitRoutingProps<PropsWithChildren<InternalProps>>(allProps)

    const args = resolveAbsoluteRouteUpdateArgs(routing, routeProps)

    const [route] = args
    const authorized = hasCapabilities(route)

    return (
        <Wrapper authorized={authorized}>
            {authorized ? (
                <Link {...props} data-testid={`permissioned-link-${route}`} underline="none" href={routing.createHref(...args)}>
                    {children}
                </Link>
            ) : (
                children
            )}
        </Wrapper>
    )
}

type ExternalProps = RoutingProps & CosmeticProps & WrapperAsArgsProps

export const usePermissionedLink: () => FC<ExternalProps> = () => {
    const hasCapabilities = useHasCapabilities()
    const routing = useInternalRoutingHook()
    return (props) => <InternalLink {...props} routing={routing} hasCapabilities={hasCapabilities} />
}

export const PermissionedLink: FC<ExternalProps> = (props) => {
    const InternalLink = usePermissionedLink()
    return <InternalLink {...props} />
}
