import React, { type FC } from 'react'

import { Translation } from '../../../domain'

import { AbsoluteRouteOnlyPath } from '../routing'
import { RouteTabs } from '../Tabs'

import { RefreshLicenseButton, UploadLicenseFileButton } from './License/Buttons'
import { SendMetricsToPortalButton } from './Metrics/Buttons'

const tabs = {
    [AbsoluteRouteOnlyPath.SYSTEM_CONTAINERS]: {
        documentationTitle: Translation.CORE_CONTAINERS_DOCUMENTATION_TITLE,
        documentationBody: Translation.CORE_CONTAINERS_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.SYSTEM_INTERNET_CONNECTIVITY]: {
        documentationTitle: Translation.INTERNET_CONNECTIVITY_DOCUMENTATION_TITLE,
        documentationBody: Translation.INTERNET_CONNECTIVITY_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.SYSTEM_METRICS]: {
        documentationTitle: Translation.METRICS_DOCUMENTATION_TITLE,
        documentationBody: Translation.METRICS_DOCUMENTATION_BODY,
        titleActions: <SendMetricsToPortalButton />,
    },
    [AbsoluteRouteOnlyPath.SYSTEM_AGENTS]: {
        documentationTitle: Translation.AGENT_DOCUMENTATION_TITLE,
        documentationBody: Translation.AGENT_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.SYSTEM_LICENSE]: {
        documentationTitle: Translation.INFO_PAGE_DOCUMENTATION_TITLE,
        documentationBody: Translation.INFO_PAGE_DOCUMENTATION_BODY,
        titleActions: (
            <>
                <RefreshLicenseButton />
                <UploadLicenseFileButton />
            </>
        ),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_INFORMATION]: {
        documentationTitle: Translation.SYSTEM_INFORMATION_DOCUMENTATION_TITLE,
        documentationBody: Translation.SYSTEM_INFORMATION_DOCUMENTATION_BODY,
    },
}

export const SystemStatusTabs: FC = () => <RouteTabs title={Translation.SYSTEM_STATUS} tabs={tabs} />
