import type { HttpResponse } from '..'

/**
 * This function detects if a response given by envoy is stating:
 * 'no sufficient permissions for this user'
 *
 * @see https://cybusio.atlassian.net/browse/CC-1844
 */
export const isMissingPermissionsResponse = (response: HttpResponse): boolean =>
    response.getStatus() === 403 && response.getHeaders().some(([name, value]) => name === 'x-missing-permissions' && value === 'authorization failed')
