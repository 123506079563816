import type { AuthenticationInformation } from '../../domain'

export interface BrowserService {
    authenticate(info: AuthenticationInformation | null): void
    reload(): void
    redirect(url: URL): void
    getRedirectionURL(): URL | null
    getToken(): string | null
    open(url: URL, name: string | null): void
    copy(value: unknown): string | null
    sendEmail(target: string, subject: string | null): void
    isInspecting(): boolean
}
