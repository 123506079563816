import React from 'react'
import ReactDOM from 'react-dom'

import { createUsecases, initialState } from '../../application'

import { App, AppWrapper, cybusTheme } from '../react'

import configPromise from './config'

import { resolveInjections } from './injections'

void configPromise.then((config) => {
    const { services, state, internationalization, missingPermissionsListener } = resolveInjections(initialState, config)
    const usecases = createUsecases(services)

    /** In case there are authentication issues, then handle the problem */
    // eslint-disable-next-line no-void
    missingPermissionsListener.on(() => void usecases.missingPermissionsUsecase.updateObsolence())

    ReactDOM.render(
        <AppWrapper usecases={usecases} manager={state} internationalization={internationalization} theme={cybusTheme}>
            <App />
        </AppWrapper>,
        document.getElementById('root')
    )
})
