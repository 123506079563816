/**
 * This is a simple utility class that helps the codebase create a promise
 * that does not have to be resolved internally, but can solved elsewhere
 */
export class ManagedPromise<T, E = unknown> {
    readonly promise = new Promise<T>((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolve: (value: T | PromiseLike<T>) => void

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    reject: (reason: E) => void
}
