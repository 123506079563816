import type { ReadonlyRecord } from '../utils'
import type { ConnectwareError } from './Error'

export enum ResourceType {
    AGENT = 'AGENT',
    CONNECTION = 'CONNECTION',
    CORE_CONTAINER = 'CORE_CONTAINER',
    ENDPOINT = 'ENDPOINT',
    MAPPING = 'MAPPING',
    SERVER = 'SERVER',
    SERVICE = 'SERVICE',
    SERVICE_CONTAINER = 'SERVICE_CONTAINER',
    VOLUME = 'VOLUME',
}

type ActionType = 'delete' | 'disable' | 'enable' | 'reenable'

type ResourceState = Readonly<{
    ids: string[] | null
    /**
     * If loading then true
     * If not loading then false
     * If last load yielded an error then {ConnectwareError}
     */
    load: ConnectwareError | boolean
}>

export type ResourcesAppState = Readonly<{ resources: ReadonlyRecord<ResourceType, ReadonlyRecord<ActionType, ResourceState>> }>

export const selectResources = (s: ResourcesAppState): ResourcesAppState['resources'] => s.resources

export const selectResourcesByDimensionAndAction = (s: ResourcesAppState, dimension: ResourceType, action: ActionType): ResourceState =>
    selectResources(s)[dimension][action]
