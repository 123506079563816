import React, { type FC } from 'react'
import { Box, Link, type SxProps, type Theme, Typography } from '@mui/material'
import classnames from 'classnames'

import { isObjectEmpty } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { createFormatter, FormattedTranslation } from '../Internationalization'
import { Validation } from '../common'

import { type ErrorMessageProps, errorMessagesClasses } from '.'
import { ErrorPopover } from './Popover'
import { Extras, StackTrace } from './Code'

const timeoutLinkStyle: SxProps<Theme> = { cursor: 'pointer' }
const headerSectionStyle: SxProps<Theme> = {
    backgroundColor: 'error.main',
    p: 1.7,
    my: 4,
    borderRadius: 1,
    [`&.${errorMessagesClasses.header} > *`]: { color: 'white' },
}

const hasExtras = (
    actual: ErrorMessageProps<ConnectwareError>['extras'],
    expected: Extract<ErrorMessageProps<ConnectwareError>['extras'], string>,
    error: ConnectwareError
): boolean => actual === expected && !isObjectEmpty(error.extras)

const ErrorHeader = <E extends ConnectwareError = ConnectwareError,>({
    customTitle,
    titleVariant,
    error,
    extras,
}: Required<Pick<ErrorMessageProps<E>, 'customTitle' | 'titleVariant' | 'error' | 'extras'>>): ReturnType<FC<ErrorMessageProps<E>>> => {
    const hasSection = extras === 'section'
    const title = customTitle(error) || <FormattedTranslation id={Translation.ERROR_TITLE} values={{ message: error.message }} />

    const validation = (
        <Validation
            sx={hasSection ? headerSectionStyle : {}}
            outlined={hasSection}
            className={errorMessagesClasses.header}
            error
            labelVariant={hasSection ? 'subtitle2' : titleVariant}
        >
            {title}
        </Validation>
    )

    return hasExtras(extras, 'popover', error) ? <ErrorPopover error={error}>{validation}</ErrorPopover> : validation
}

const ErrorTimeoutHeader: FC = () => {
    const network = useAppUsecase('fixNetworkIssueUsecase')

    return (
        <Typography variant="subtitle1" color="error" data-testid="error-timeout-text">
            <FormattedTranslation
                id={Translation.TIMEOUT_ERROR}
                values={{ link: createFormatter(Link, { onClick: () => network.invoke(), sx: timeoutLinkStyle }) }}
            />
        </Typography>
    )
}

export const ErrorMessage = <E extends ConnectwareError = ConnectwareError,>({
    stack = false,
    extras = false,
    error,
    titleVariant = 'h6',
    customTitle = () => null,
    extrasTitleSuffix = null,
    sx,
    className,
    ...other
}: ErrorMessageProps<E>): ReturnType<FC<ErrorMessageProps<E>>> => (
    <Box {...other} className={classnames(className, errorMessagesClasses.root)} sx={{ color: 'error.main', ...sx }}>
        <ErrorHeader customTitle={customTitle} titleVariant={titleVariant} error={error} extras={extras} />
        {ConnectwareError.isOfTypes(error, ConnectwareErrorType.SERVER_TIMEOUT) && <ErrorTimeoutHeader />}
        {stack && <StackTrace error={error} />}

        {hasExtras(extras, 'section', error) && <Extras variant="subtitle2" error={error} extrasTitleSuffix={extrasTitleSuffix} />}
    </Box>
)
