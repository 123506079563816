import type { SubscriptionsTypes } from '../../../../../../application'

import { mapService, mapToStatusType, SERVICE_AGENT } from '../../../../../Connectware'

import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type ServiceHandler = VrpcInstanceToOneSubscriptionHandler<ServiceProxy, SubscriptionsTypes['services']>
type ServiceHandlerArgs = VrpcHandlerMappingPropertiesArgs<ServiceHandler>
type DomainMappingArgs = Pick<ServiceHandlerArgs['DomainMapperArgs'], 'instance'>

export class VrpcServiceProxyInstanceHandler implements ServiceHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    /**
     * @deprecated these handlings will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    mapToDomain ({ instance }: DomainMappingArgs): Promise<ServiceHandlerArgs['Domain']> {
        return Promise.all([instance.getInfo(), instance.getLinks()]).then(([info, links]) => {
            /**
             * @deprecated update code block so deviation is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const isDeviated = info.isDeviated

            /**
             * @note currently, the 'state' field contains the value of currentState
             * In the future, it will contains the targeState value instead
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(info.state)
            return mapService(info, currentStatus, isDeviated, links)
        })
    }
}
