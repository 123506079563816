import React, { type FC } from 'react'
import { Box, chipClasses, type SxProps, TextField, type Theme } from '@mui/material'
import { Delete, Download, PauseCircleFilled, Replay, Search, type SvgIconComponent, ToggleOn } from '@mui/icons-material'

import type { ReadonlyRecord } from '../../../../utils'
import { Translation } from '../../../../domain'

import type { ActionName, Line } from './Types'
import {
    useAreThereSelected,
    useExtendedToolbar,
    useIsSearching,
    useOnDownloadCSV,
    useRequiredSelection,
    useSearch,
    useSearchDispatcher,
    useSelected,
} from './State'

import { useTranslator } from '../../Internationalization'
import { Chip } from '..'

const wrapperStyle: SxProps<Theme> = { display: 'flex', my: 2 }
const Wrapper: FC<Readonly<{ justifyContent: 'start' | 'space-between', alignItems?: 'start' | 'center' }>> = ({
    justifyContent,
    alignItems = 'center',
    children,
}) => <Box sx={[wrapperStyle, { justifyContent, alignItems }]}>{children}</Box>

const chipStyle: SxProps<Theme> = { my: 0.5, mx: 1, '&:first-of-type': { ml: 0 } }

const defaultIcons: ReadonlyRecord<ActionName, SvgIconComponent> = { DELETE: Delete, DISABLE: PauseCircleFilled, ENABLE: ToggleOn, RESTART: Replay }
const defaultTranslations: ReadonlyRecord<ActionName, Translation> = {
    DELETE: Translation.DELETE,
    DISABLE: Translation.DISABLE,
    ENABLE: Translation.ENABLE,
    RESTART: Translation.RESTART,
}

const ToolbarButton = <L extends Line,>({ action }: Readonly<{ action: ActionName }>): ReturnType<FC> => {
    const {
        icons: { [action]: Avatar = defaultIcons[action] },
        labels: { [action]: label = defaultTranslations[action] },
        onSelect,
    } = useRequiredSelection<L>()
    const lines = useSelected<L>()
    const { [action]: onClick } = onSelect(lines)

    return onClick ? <Chip sx={chipStyle} avatar={Avatar} label={label} onClick={() => onClick(lines)} /> : null
}

const searchIconStyle: SxProps<Theme> = { mr: 1 }
const buttonsWrapper: SxProps<Theme> = { [`& .${chipClasses.root}`]: { my: 0.5, mr: 2, '&:last-of-type': { mr: 0 } } }

const UnselectedToolbar: FC = () => {
    const translator = useTranslator()

    const extendedToolbar = useExtendedToolbar()
    const onDownloadCSV = useOnDownloadCSV()

    const isSearching = useIsSearching()
    const searchText = useSearch()
    const dispatchSearch = useSearchDispatcher()

    if (!isSearching && !extendedToolbar && !onDownloadCSV) {
        /**
         * If there is nothing to display (no Search & extendedToolBar & DownloadCSV)
         * then render nothing
         * */
        return null
    }

    return (
        <Wrapper justifyContent="space-between" alignItems="start">
            <Box sx={buttonsWrapper}>
                {onDownloadCSV && <Chip label={Translation.DOWNLOAD_CSV} avatar={Download} onClick={onDownloadCSV} />}
                {extendedToolbar}
            </Box>
            {isSearching && (
                <TextField
                    data-testid="table-search-input"
                    placeholder={translator.formatTranslation(Translation.SEARCH)}
                    size="small"
                    margin="none"
                    InputProps={{ endAdornment: <Search sx={searchIconStyle} fontSize="small" color="primary" /> }}
                    value={searchText}
                    onChange={({ target }): void => dispatchSearch(target.value)}
                />
            )}
        </Wrapper>
    )
}

export const Toolbar = <L extends Line,>(): ReturnType<FC> => {
    const selected = useAreThereSelected<L>()

    return selected ? (
        <Wrapper justifyContent="start">
            <Box>
                <ToolbarButton action="ENABLE" />
                <ToolbarButton action="DISABLE" />
                <ToolbarButton action="DELETE" />
                <ToolbarButton action="RESTART" />
            </Box>
        </Wrapper>
    ) : (
        <UnselectedToolbar />
    )
}
