import { executeOnce, type ReadonlyRecord } from '../../../../utils'
import type { Translation } from '../../../../domain'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
} from '../Domain'
import { getRouteConfig, type RouteParentPath } from '../Config'

/**
 * Either a stand-in title or an actual route
 */
type BreadcrumbParentConfig = Readonly<{
    title: Translation
    isTitleSingular: boolean
    id: AbsoluteRouteOnlyPath | AbsoluteRoutePathWithServiceId
}>

type BreadcrumbConfig = Readonly<{
    title: Translation
    isTitleSingular: boolean
    path: string
    parents: BreadcrumbParentConfig[]
}>

const getParents = (parent: RouteParentPath): BreadcrumbParentConfig[] => {
    const firstParent = getRouteConfig(parent)

    const parents: BreadcrumbParentConfig[] = [
        {
            title: firstParent.title,
            isTitleSingular: Boolean(firstParent.isTitleSingular),
            id: parent,
        },
    ]

    if (firstParent.parent) {
        /**
         * Recursive parent retrieval
         */
        parents.push(...getParents(firstParent.parent))
    }

    return parents
}

const getBreadcrumbs: () => ReadonlyRecord<AbsoluteRoutePath, BreadcrumbConfig> = executeOnce(() =>
    [
        ...Object.values(AbsoluteRouteOnlyPath),
        ...Object.values(AbsoluteRoutePathWithId),
        ...Object.values(AbsoluteRoutePathWithServiceId),
        ...Object.values(AbsoluteRoutePathWithServiceAndResourceId),
    ].reduce<ReturnType<typeof getBreadcrumbs>>((r, routePath) => {
        const route = getRouteConfig(routePath)

        const breadcrumb: BreadcrumbConfig = {
            title: route.title,
            isTitleSingular: Boolean(route.isTitleSingular),
            path: route.path,
            parents: route.parent ? getParents(route.parent) : [],
        }

        return { ...r, [routePath]: breadcrumb }
    }, {} as ReturnType<typeof getBreadcrumbs>)
)

/**
 * Retrieve the breadcrumbs config by the given route
 * They are completely generated by the routes
 */
export const getBreadcrumbConfig = (path: AbsoluteRoutePath): BreadcrumbConfig => getBreadcrumbs()[path]
