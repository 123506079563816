import React, { type FC, useEffect } from 'react'
import { Box, Paper, Stack, type SxProps, type Theme, Typography } from '@mui/material'

import { ArrowDownward } from '@mui/icons-material'

import { isArrayNotEmpty } from '../../../utils'
import { ConnectwareError, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { CircularLoader, DetailsHeader } from '../common'
import { useCertificatesByOrganization } from './Hooks'

import { ErrorMessage } from '../ErrorMessage'
import { CertificatesGroup } from './Group'
import { Add } from './Add'

const downArrowStyle: SxProps<Theme> = { ml: 0.4, color: 'grey.700' }
const certificateTitleStyle: SxProps<Theme> = { p: 2, display: 'flex', alignItems: 'center', backgroundColor: 'grey.100' }

export const Certificates: FC = () => {
    const certificates = useCertificatesByOrganization()

    const usecase = useAppUsecase('loadCertificatesUsecase')
    useEffect(() => usecase.invoke(), [usecase])

    return (
        <Stack className="certificates-list" spacing={2}>
            <DetailsHeader
                title={[Translation.CERTIFICATES, { count: 0 }]}
                titleActions={Array.isArray(certificates) ? <Add /> : undefined}
                description={[Translation.CERTIFICATES_DETAILS_DOCUMENTATION_TITLE, Translation.CERTIFICATES_DETAILS_DOCUMENTATION_BODY]}
            />
            {certificates === null && <CircularLoader />}
            {ConnectwareError.is(certificates) && <ErrorMessage data-testid="certificates-error" error={certificates} stack extras="section" />}

            {Array.isArray(certificates) && (
                <Paper>
                    <Box data-testid="certificate-title" sx={certificateTitleStyle}>
                        <Typography variant="subtitle2">
                            <FormattedTranslation id={Translation.CERTIFICATES} values={{ count: certificates.length }} />
                        </Typography>
                        <ArrowDownward fontSize="small" sx={downArrowStyle} />
                    </Box>

                    {certificates.map(([, certs], k) => (
                        <CertificatesGroup key={k} certificates={certs} divider={k > 0} />
                    ))}

                    {!isArrayNotEmpty(certificates) && (
                        <Typography className="no-certificates" align="center" variant="body2">
                            <FormattedTranslation id={Translation.NO_CERTIFICATES} />
                        </Typography>
                    )}
                </Paper>
            )}
        </Stack>
    )
}
