import type { Intersection } from 'utility-types'

import type { SubscriptionsTypes } from '../../../../../application'

import type {
    ConnectionProxy,
    ContainerManagerOrchestratorProxy,
    EndpointProxy,
    MappingProxy,
    NodeProxy,
    ResourceStateListenerProxy,
    ServerProxy,
    ServiceProxy,
} from '../../../proxies'

import {
    VrpcDetailedServiceProxyInstanceHandler,
    VrpcLinkedServiceProxyInstanceHandler,
    VrpcServiceProxyInstanceHandler,
    VrpcServiceProxyInstanceToNotificationHandler,
    VrpcServiceProxyToServiceDataResourceInstanceHandler,
} from './Service'
import { VrpcConnectionProxyInstanceHandler, VrpcDetailedConnectionProxyInstanceHandler } from './Connection'
import { VrpcDetailedMappingProxyInstanceHandler, VrpcMappingProxyInstanceHandler } from './Mapping'
import { VrpcDetailedCEndpointProxyInstanceHandler, VrpcResourceStateListenerEndpointProxyInstanceHandler } from './Endpoint'
import { VrpcEndpointProxyInstanceStatusHandler, VrpcMappingProxyInstanceStatusHandler, VrpcNodeProxyInstanceStatusHandler } from './Status'
import {
    VrpcContainerManagerOrchestratorProxyToCoreContainerHandler,
    VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler,
    VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler,
    VrpcContainerManagerOrchestratorProxyToDetailedVolumeHandler,
    VrpcContainerManagerOrchestratorProxyToServiceContainerHandler,
    VrpcContainerManagerOrchestratorProxyToVolumeHandler,
} from './ContainerManager'
import { VrpcNodeProxyToCybusNodeHandler } from './Node'
import { VrpcServerProxyToCybusServerHandler, VrpcServerProxyToCybusServersHandler } from './Server'
import { VrpcRemoteToCybusAgentHandler } from './Agent'

import type { VrpcSubscriptionHandler } from '.'

/**
 * Collections of all handlers with the most strict type they can have
 */
const handlers = {
    service: VrpcDetailedServiceProxyInstanceHandler,
    services: VrpcServiceProxyInstanceHandler,
    serviceDeviations: VrpcServiceProxyInstanceToNotificationHandler,
    servicesLinks: VrpcLinkedServiceProxyInstanceHandler,
    serviceResources: VrpcServiceProxyToServiceDataResourceInstanceHandler,
    connection: VrpcDetailedConnectionProxyInstanceHandler,
    connections: VrpcConnectionProxyInstanceHandler,
    mappings: VrpcMappingProxyInstanceHandler,
    mapping: VrpcDetailedMappingProxyInstanceHandler,
    mappingStatus: VrpcMappingProxyInstanceStatusHandler,
    endpointStatus: VrpcEndpointProxyInstanceStatusHandler,
    nodeStatus: VrpcNodeProxyInstanceStatusHandler,
    endpoints: VrpcResourceStateListenerEndpointProxyInstanceHandler,
    endpoint: VrpcDetailedCEndpointProxyInstanceHandler,
    volume: VrpcContainerManagerOrchestratorProxyToDetailedVolumeHandler,
    volumes: VrpcContainerManagerOrchestratorProxyToVolumeHandler,
    serviceContainers: VrpcContainerManagerOrchestratorProxyToServiceContainerHandler,
    serviceContainer: VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler,
    coreContainers: VrpcContainerManagerOrchestratorProxyToCoreContainerHandler,
    coreContainer: VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler,
    nodes: VrpcNodeProxyToCybusNodeHandler,
    servers: VrpcServerProxyToCybusServersHandler,
    server: VrpcServerProxyToCybusServerHandler,
    agents: VrpcRemoteToCybusAgentHandler,
} as const

/**
 * The types expected from VRPC
 */
export interface VrpcInstanceTypes {
    services: ServiceProxy
    service: ServiceProxy
    serviceDeviations: ServiceProxy
    servicesLinks: ServiceProxy
    serviceResources: ServiceProxy
    connection: ConnectionProxy
    connections: ConnectionProxy
    mappings: MappingProxy
    mapping: MappingProxy
    mappingStatus: MappingProxy
    endpointStatus: EndpointProxy
    nodeStatus: NodeProxy
    endpoints: ResourceStateListenerProxy
    endpoint: EndpointProxy
    volume: ContainerManagerOrchestratorProxy
    volumes: ContainerManagerOrchestratorProxy
    coreContainer: ContainerManagerOrchestratorProxy
    coreContainers: ContainerManagerOrchestratorProxy
    serviceContainer: ContainerManagerOrchestratorProxy
    serviceContainers: ContainerManagerOrchestratorProxy
    nodes: NodeProxy
    servers: ServerProxy
    server: ServerProxy
    agents: never
}

type HandlersConstraintEnforcerType<DomainTypes, VrpcTypes> = {
    [P in keyof DomainTypes & keyof VrpcTypes]: new () => VrpcSubscriptionHandler<VrpcTypes[P], DomainTypes[P]>
}
const constrainedHandlers: HandlersConstraintEnforcerType<SubscriptionsTypes, VrpcInstanceTypes> = handlers

type SupportedHandlerNames = keyof Intersection<SubscriptionsTypes, VrpcInstanceTypes>

/**
 * Gets the vrpc instance handler that can map VRPC events/instances into useful domain objects
 */
export const getVrpcSubscriptionHandler = <T extends SupportedHandlerNames>(
    name: T
): VrpcSubscriptionHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]> & InstanceType<(typeof handlers)[T]> => {
    const handler = new constrainedHandlers[name]()
    /**
     * This casting allows the underlying type of each of the implemented handler to leak through
     * Helps to write less code to check the handlers type
     */
    return handler as typeof handler & InstanceType<(typeof handlers)[T]>
}
