import { isEnumOf } from '../../utils'

import { type AuthenticationInformation, Capability } from '../../domain'

import type { AuthenticationPersistenceService } from '../../application'

import { BaseWebStorage } from './Base'

/**
 * This localStorage field is shared across the applicaiton
 * Do **not** change its value
 */
const SHARED_TOKEN_FIELD = 'token'

export type SerializedAuthenticationInformation = [
    AuthenticationInformation['token'],
    AuthenticationInformation['username'],
    ReturnType<AuthenticationInformation['expiresAt']['getTime']>,
    AuthenticationInformation['capabilities']
]

export class WebStorageAuthenticationPersistenceService
    extends BaseWebStorage<AuthenticationInformation, null, SerializedAuthenticationInformation>
    implements AuthenticationPersistenceService {
    protected readonly fallbackValue = null

    protected readonly key = 'authKey'

    private readonly rawStorage: Pick<Storage, 'removeItem' | 'setItem'>

    constructor (...[storage, version]: ConstructorParameters<typeof BaseWebStorage<AuthenticationInformation, null, SerializedAuthenticationInformation>>) {
        super(storage, version)
        this.rawStorage = storage
    }

    // eslint-disable-next-line class-methods-use-this
    protected toSerializable ({ token, username, expiresAt, capabilities }: AuthenticationInformation): SerializedAuthenticationInformation {
        return [token, username, expiresAt.getTime(), capabilities]
    }

    // eslint-disable-next-line class-methods-use-this
    protected isValidSerializable (unknownValue: unknown): unknownValue is SerializedAuthenticationInformation {
        if (!Array.isArray(unknownValue) || unknownValue.length !== 4) {
            return false
        }

        const [token, username, expiresAt, capabilities] = unknownValue

        if (
            typeof token !== 'string' ||
            typeof username !== 'string' ||
            typeof expiresAt !== 'number' ||
            !Array.isArray(capabilities) ||
            capabilities.some((c) => !isEnumOf(Capability, c))
        ) {
            return false
        }

        return true
    }

    // eslint-disable-next-line class-methods-use-this
    protected fromSerializable ([token, username, expiresAt, capabilities]: SerializedAuthenticationInformation): AuthenticationInformation {
        return { token, username, expiresAt: new Date(expiresAt), capabilities, obsolete: null }
    }

    retrieve (): AuthenticationInformation | null {
        return this.retrieveDeserialize()
    }

    flush (): void {
        this.remove()
        this.rawStorage.removeItem(SHARED_TOKEN_FIELD)
    }

    persist (info: AuthenticationInformation): void {
        this.persistSerialize(info)
        this.rawStorage.setItem(SHARED_TOKEN_FIELD, info.token)
    }
}
