import React, { type FC } from 'react'

import { Autocomplete, Button, TextField } from '@mui/material'
import { FileCopy } from '@mui/icons-material'

import { isArrayNotEmpty } from '../../../../utils'
import { type CybusRole, type CybusUser, type PermissionedForm, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { EntryInputLabel, GridFormEntry, type GridFormEntryProps } from '../Modal'
import { NoOptions, Option } from '../Autocomplete'

type RequiredUsecase = Readonly<{
    applyTemplate(userOrRole: CybusUser | CybusRole): void
    searchTemplates(input: string): void
    selectTemplate(userOrRole: CybusUser | CybusRole | null): void
}>

type Props = Readonly<{ disabled: boolean, usecase: RequiredUsecase }> &
    Pick<PermissionedForm, 'templateInput' | 'templates' | 'selectedTemplate'> &
    Pick<GridFormEntryProps, 'endAdornmentSize' | 'expand'>

export const PermissionsTemplateSection: FC<Props> = ({ expand, endAdornmentSize = 1, disabled, usecase, templateInput, templates, selectedTemplate }) => {
    const noData = !templateInput && Array.isArray(templates) && !isArrayNotEmpty(templates)

    return (
        <GridFormEntry
            expand={expand}
            labelSize={3}
            endAdornmentSize={endAdornmentSize}
            label={<EntryInputLabel icon={FileCopy} label={Translation.COPY_PERMISSIONS} />}
            endAdornment={
                <Button
                    data-testid="apply-template-button"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    size="small"
                    disabled={
                        disabled ||
                        !selectedTemplate ||
                        !('username' in selectedTemplate ? selectedTemplate.allPermissions.length : selectedTemplate.permissions.length)
                    }
                    onClick={selectedTemplate ? () => usecase.applyTemplate(selectedTemplate) : undefined}
                >
                    <FormattedTranslation id={Translation.ADD} />
                </Button>
            }
        >
            <Autocomplete
                data-testid="template"
                filterSelectedOptions
                size="medium"
                options={Array.isArray(templates) ? templates : []}
                getOptionLabel={(option) => ('username' in option ? option.username : option.name)}
                isOptionEqualToValue={(a, b) => a === b || a.id === b.id}
                renderOption={(props, raw) => <Option {...props} value={raw} />}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        size="medium"
                        value={templateInput}
                        onChange={(e) => usecase.searchTemplates(e.currentTarget.value)}
                    />
                )}
                noOptionsText={<NoOptions data={templates} notFound={Translation.NO_TEMPLATES_AVAILABLE} />}
                value={selectedTemplate}
                disabled={disabled || noData}
                onChange={(_, data) => usecase.selectTemplate(data)}
                onOpen={() => usecase.searchTemplates(templateInput || '')}
            />
        </GridFormEntry>
    )
}
