import { areArrayEquals, isEnumOf } from '../../../utils'

import { type Capability, createIndexedCapabilities, selectAuthenticatedCapabilities } from '../../../domain'

import { useAppState } from '../State'
import { useTranslator } from '../Internationalization'

import { getRouteCapabilities, getRouteConfig } from './Config'
import { type AbsoluteRoutePath, type AbsoluteRoutePathWithId, RelativeRoutePathWithId } from './Domain'
import { useInternalRoutingHook } from './Provider'

/**
 * This file contains the external hooks
 * Meant to be used outside of the routing hooks folder
 */

export const useRouteFormatter = (): Readonly<{
    formatTitle(routePath: AbsoluteRoutePath): string
    formatDescription(routePath: AbsoluteRoutePath): string | null
}> => {
    const translator = useTranslator()

    return {
        formatTitle (routePath) {
            const { title, isTitleSingular = false } = getRouteConfig(routePath)
            return translator.formatTranslation(title, { count: Number(isTitleSingular) })
        },
        formatDescription (routePath) {
            const config = getRouteConfig(routePath)
            return 'description' in config && config.description ? translator.formatTranslation(config.description) : null
        },
    }
}

export const useHasCapabilities = (): ((requiredCapabilitiesAlternativesOrRoute: Capability[] | AbsoluteRoutePath) => boolean) => {
    const [hasCapabilities] = useAppState(
        (s) => [createIndexedCapabilities(s), selectAuthenticatedCapabilities(s) ?? []],
        ([, a]: [unknown, Capability[]], [, b]: [unknown, Capability[]]) => areArrayEquals(a, b, { sort: false })
    )
    return (alternativesOrRoute) => {
        const alternatives = Array.isArray(alternativesOrRoute)
            ? [alternativesOrRoute]
            : getRouteCapabilities(alternativesOrRoute).map((capabilities) => capabilities.required)
        return alternatives.some(hasCapabilities)
    }
}

export const useRouteWithIdRedirect = <I>(
    route: AbsoluteRoutePathWithId | RelativeRoutePathWithId,
    idMapper: (input: I) => string
): ((input: I) => void) | void => {
    const hasCapabilities = useHasCapabilities()
    const router = useInternalRoutingHook()

    const absoluteRoute = isEnumOf(RelativeRoutePathWithId, route) ? router.getAbsoluteRoute(route) : route

    return hasCapabilities(absoluteRoute) ? (i: I) => router.redirect(absoluteRoute, idMapper(i)) : undefined
}
