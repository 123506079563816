import type { AppVersions, BackupMaintenanceStatus, ConnectwareLicense, Metrics, SystemConnectivity } from '../../domain'

/**
 * Service to fetch information important to the system
 *
 * Such as the connectivity of it to the backend services, versioning or license
 */
export interface SystemService {
    /**
     * System's version
     */
    getUIVersion(): string
    fetchVersions(): Promise<AppVersions>

    /**
     * System connectivity
     */

    fetchConnectivity(): Promise<SystemConnectivity>

    /**
     * System's License
     */

    fetchLicense(): Promise<ConnectwareLicense>

    /**
     * @throw ConnectwareError
     *  if the `ConnectwareError['type']` is CONFIGURATION_ERROR, it means an issue with the configured license
     */
    refreshLicense(): Promise<void>

    /**
     * @throw ConnectwareError
     *  if the `ConnectwareError['type']` is CONFIGURATION_ERROR, it means an issue with the configured license
     */
    uploadLicenseFile(content: string): Promise<void>

    /**
     * System's Metrics
     * @throw ConnectwareError
     */
    fetchMetrics(): Promise<Metrics>

    /* @throw ConnectwareError
     * Send all not transmitted metrics to the portal if connection is available
     */
    sendMetricsToPortal(): Promise<void>

    /**
     * System's backup status
     * @throw ConnectwareError
     */
    fetchBackupStatus(): Promise<BackupMaintenanceStatus>

    createBackup(): Promise<void>

    downloadBackup(): Promise<File>

    uploadBackup(fileContent: File): Promise<void>
}
