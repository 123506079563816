import { Droppable, EventListener } from '../../../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../../../domain'

import type { ResourceManager } from './Types'

/**
 * Helper to simplify management of the entities the mappers need to take care of
 */
export class ResourceManagerCollection<UniqueManagerKey, Manager extends ResourceManager<Value>, Value> {
    private readonly droppable = new Droppable()

    private readonly changeListeners = new EventListener<void>()

    private readonly internalManagers = new Map<UniqueManagerKey, Manager>()

    /**
     * Adds new manager, triggers change internally
     */
    addManager (key: UniqueManagerKey, manager: Manager): Promise<void> {
        const current = this.internalManagers.get(key)

        if (current) {
            return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'There was already a value manager', { key }))
        }

        this.droppable.onDrop(manager.onChange(() => this.trigger()))

        this.droppable.onDrop(() => {
            const manager = this.internalManagers.get(key)

            if (manager) {
                this.internalManagers.delete(key)
                manager.end(false)
            }
        })

        /** Finally register it */
        this.internalManagers.set(key, manager)

        /**
         * Lastly initialize internal manager
         * Should trigger another load
         */
        return manager.start()
    }

    /**
     * Removes internal manager, triggers change internally
     */
    removeManager (key: UniqueManagerKey, isGone: boolean): Promise<void> {
        const current = this.internalManagers.get(key)

        if (!current) {
            return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'There was no manager', { key }))
        }

        this.internalManagers.delete(key)

        current?.end(isGone)

        this.trigger()

        return Promise.resolve()
    }

    getValues<V> (reduceNonNullValue: (initial: V, value: Exclude<Manager['value'], null>) => V, initialValue: V): V {
        let reducedValue = initialValue

        for (const { value } of this.internalManagers.values()) {
            if (value !== null) {
                reducedValue = reduceNonNullValue(reducedValue, value as Exclude<Manager['value'], null>)
            }
        }

        return reducedValue
    }

    trigger (): void {
        this.changeListeners.trigger()
    }

    onChange (handler: VoidFunction): VoidFunction {
        return this.changeListeners.on(handler)
    }

    drop (): void {
        this.droppable.drop()
    }
}
