import React, { type FC } from 'react'

import { Box, Link, type SxProps, type Theme } from '@mui/material'

import { Capability, createIsAuthenticatedWithCapabilitiesSelector, selectChangePasswordForm, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'

import { Section } from '../Section'
import { ChangePasswordForm } from './Form'
import { SmallParagraph, Title } from '../../common'

const openPasswordLink: SxProps<Theme> = { cursor: 'pointer' }
const disablePasswordLink: SxProps<Theme> = { color: 'gray' }

const selectUserCanChangePassword = createIsAuthenticatedWithCapabilitiesSelector(Capability.MANAGE_OWN_PASSWORD, Capability.RETRIEVE_PASSWORD_POLICY)

const ShowChangePasswordFormLink: FC = () => {
    const usecase = useAppUsecase('changePasswordUsecase')
    const canChangePassword = useAppState(selectUserCanChangePassword)

    return (
        <Link
            data-testid={canChangePassword ? 'change-password-link' : 'disabled-change-password-link'}
            sx={canChangePassword ? openPasswordLink : disablePasswordLink}
            onClick={() => (canChangePassword ? usecase.toggle() : undefined)}
        >
            <FormattedTranslation id={Translation.CHANGE_PASSWORD} />
        </Link>
    )
}

const settingsStyle: SxProps<Theme> = { pt: 3 }

export const ChangePasswordSettings: FC = () => {
    const form = useAppState(selectChangePasswordForm)
    const canChangePassword = useAppState(selectUserCanChangePassword)

    return (
        <Section width={600}>
            <Title id={Translation.CHANGE_PASSWORD} />
            <SmallParagraph id={canChangePassword ? Translation.CHANGE_PASSWORD_DESCRIPTION : Translation.NO_CHANGE_PASSWORD_PERMISSION_DESCRIPTION} />
            <Box data-testid="change-password-settings" sx={settingsStyle}>
                {form ? <ChangePasswordForm form={form} /> : <ShowChangePasswordFormLink />}
            </Box>
        </Section>
    )
}
