import React, { type FC } from 'react'
import type { SxProps, Theme } from '@mui/material'

import { objectEntries } from '../../../../utils'

import { Capability, type CybusDetailedEndpoint, CybusLogSource, Translation } from '../../../../domain'

import Logs, { useAreLogsAvailable } from '../../Logs'
import { Tab, Tabs } from '../../Tabs'
import { Json, NoReRenderOnHover, Table } from '../../common'

const rulesStyle: SxProps<Theme> = { my: 2, mx: 1 }

export const Content: FC<{ resource: CybusDetailedEndpoint }> = ({ resource: { id, address, rules } }) => {
    const available = useAreLogsAvailable(CybusLogSource.ENDPOINT, id)

    return (
        <Tabs>
            <Tab title={Translation.ENDPOINT_LOGS} disabled={!available} requiredCapabilities={[Capability.LOGS_READ]}>
                <Logs id={id} type={CybusLogSource.ENDPOINT} />
            </Tab>
            <Tab title={Translation.ADDRESS}>
                <Table
                    data={objectEntries(address).map(([name, value]) => ({
                        name,
                        value:
                            value instanceof Object ? (
                                <NoReRenderOnHover>
                                    <Json src={value} />
                                </NoReRenderOnHover>
                            ) : (
                                String(value)
                            ),
                    }))}
                    columns={{ name: { label: Translation.NAME, sort: true }, value: { label: Translation.VALUE } }}
                />
            </Tab>
            <Tab title={Translation.RULE} disabled={rules === null}>
                {rules && (
                    <NoReRenderOnHover sx={rulesStyle}>
                        <Json src={rules} />
                    </NoReRenderOnHover>
                )}
            </Tab>
        </Tabs>
    )
}
