import type { ReadonlyRecord } from '../../utils'
import type { AuthenticationInformation, CustomizedTable, TopicPathConfiguration } from '../../domain'

export interface AuthenticationPersistenceService {
    /**
     * Retrieves persisted authentication information
     * It should NOT check if the token is already expired
     */
    retrieve(): AuthenticationInformation | null

    /**
     * Quietly drops current authentication information
     */
    flush(): void

    /***
     * Persists authentication information
     */
    persist(info: AuthenticationInformation): void
}

export type PersistedTopics = ReadonlyRecord<'selected' | 'custom', TopicPathConfiguration[]>

export interface TopicsPersistenceService {
    retrieveTopics(): PersistedTopics
    persistTopics(topics: PersistedTopics): void
}

export interface TablePersistenceService {
    retrieveTable<L extends ReadonlyRecord<string, unknown>>(id: string): Partial<CustomizedTable<L>>
    /**
     * @param table a partial table will **not** be merged with current persistence
     */
    persistTable<L extends ReadonlyRecord<string, unknown>>(id: string, table: Partial<CustomizedTable<L>>): void
}
