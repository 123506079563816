import type { VrpcProxy } from 'vrpc'

import type { ChangeEventListener, ReadonlyRecord } from '../../../../../utils'
import type { PaginatedData, PaginationParameters } from '../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../application'

import type { ManagedVrpcRemote, VrpcDomainType } from '../../../utils'

export enum SubscriptionHandlerType {
    INSTANCE_ONE_TO_LIST = 'INSTANCE_ONE_TO_LIST',
    INSTANCE_ONE_TO_ONE = 'INSTANCE_ONE_TO_ONE',
    INSTANCE_ONE_TO_PAGE = 'INSTANCE_ONE_TO_PAGE',
    INSTANCE_ONE_TO_VIRTUAL_ONE = 'INSTANCE_ONE_TO_VIRTUAL_ONE',
    REMOTE_TO_LIST = 'REMOTE_TO_LIST',
}

export type UnsubFunction<Args extends unknown[] = never[]> = (...args: Args) => Promise<void> | void

/**
 * The filters that are supported and required on the given handler
 */
type VrpcSubscriptionHandlerFilters = ReadonlyRecord<'optionalFilters' | 'requiredFilters', (keyof SubscriptionFilterArgs)[]>

/**
 * The base mapping properties of the handler
 */
export type VrpcHandlerMappingProperties<OnChangeArgs, OnChangeUnsubHook extends UnsubFunction, MapToDomainArgs, Domain> = Readonly<{
    /**
     * @param listener function, should be called once there is an internal change on the vrpc remote
     * @returns Promise of an unsub method
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    onChange(args: OnChangeArgs): Promise<OnChangeUnsubHook>

    /**
     * @description will be called once initially, and after every `onChange` call
     * @returns
     *  the domain specific entity or a Map of entities
     *
     *  if a map is yielded, then it will cause a replacement of other entities
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    mapToDomain(args: MapToDomainArgs): Promise<Domain>
}>

/**
 * Extracts the generic types of the given handler for manipulation
 */
export type VrpcHandlerMappingPropertiesArgs<T extends VrpcHandlerMappingProperties<unknown, UnsubFunction, unknown, unknown>> =
    T extends VrpcHandlerMappingProperties<infer OnChangeArgs, infer OnChangeUnsub, infer DomainMapperArgs, infer Domain>
        ? { OnChangeArgs: OnChangeArgs, OnChangeUnsub: OnChangeUnsub, DomainMapperArgs: DomainMapperArgs, Domain: Domain }
        : never

/**
 * @param isGone if the unsub function was called because the instance was removed
 * @throws `Error`, convertion to `ConnectwareError` is expected to be done in `mapError`
 */
export type UnsubFromInstanceFunction = UnsubFunction<[isGone: boolean]>

type VrpcInstanceDomainMapperArgs<VrpcInstance> = Readonly<{ instance: VrpcInstance, filter: SubscriptionFilterArgs }>
type VrpcInstanceChangeArgs<VrpcInstance> = Readonly<{ instance: VrpcInstance, listener: VoidFunction }>

type VrpcBaseInstanceSubscriptionHandler<OnChangeArgs, DomainMappingArgs, Domain> = Readonly<{
    /** The class name used to filter out irrelevant instances */
    classNameFilter: RegExp | string

    /**
     * The agent of the desired instances
     *
     * If null then will attempt to read **all** agents
     */
    agent: string | null

    /**
     * Certain instances should be ignored by having a particular name
     */
    ignoreInstances: RegExp | null

    /**
     * Some handlers only get their information from specific instances
     * In this case, this name needs to be specified here
     */
    sourceInstanceName: string | null

    /**
     * Some instances follow a pattern that makes it useful to exclude their loading by their name and the given set of filters
     * This function enables this behaviour
     */
    ignoreInstanceByFilter: ((instanceName: string, args: SubscriptionFilterArgs) => boolean) | null
}> &
    VrpcHandlerMappingProperties<OnChangeArgs, UnsubFromInstanceFunction, DomainMappingArgs, Domain>

type VrpcBaseSubscriptionHandler<Type, Extension = ReadonlyRecord<never, never>> = Readonly<{ type: Type } & VrpcSubscriptionHandlerFilters & Extension>

export type VrpcInstanceToPageSubscriptionHandler<VrpcInstance, Domain> = VrpcBaseSubscriptionHandler<
    SubscriptionHandlerType.INSTANCE_ONE_TO_PAGE,
    VrpcBaseInstanceSubscriptionHandler<
        /** The change args need extra parameters to detect pagination changes */
        VrpcInstanceChangeArgs<VrpcInstance>,
        /** The domain mapping args need extra parameters to map pages */
        VrpcInstanceDomainMapperArgs<VrpcInstance> &
            Readonly<{
                instanceName: string
                getInstance: <P extends VrpcProxy>(...args: Parameters<ManagedVrpcRemote['getInstance']>) => Promise<P>
                pagination: ChangeEventListener<PaginationParameters<Domain>>['value']
            }>,
        PaginatedData<Domain> | null
    >
>

export type VrpcInstanceToOneSubscriptionHandler<VrpcInstance, Domain> = VrpcBaseSubscriptionHandler<
    SubscriptionHandlerType.INSTANCE_ONE_TO_ONE,
    VrpcBaseInstanceSubscriptionHandler<VrpcInstanceChangeArgs<VrpcInstance>, VrpcInstanceDomainMapperArgs<VrpcInstance>, Domain>
>

export type VrpcInstanceToVirtualOneSubscriptionHandler<VrpcInstance, Domain> = VrpcBaseSubscriptionHandler<
    SubscriptionHandlerType.INSTANCE_ONE_TO_VIRTUAL_ONE,
    VrpcBaseInstanceSubscriptionHandler<
        VrpcInstanceChangeArgs<VrpcInstance>,
        /** The domain mapping args with the mandatory instance id */
        VrpcInstanceDomainMapperArgs<VrpcInstance> & Readonly<{ id: string }>,
        Domain
    > &
        /** Force source name to be given */
        Readonly<{ sourceInstanceName: string }>
>

export type VrpcInstanceToListSubscriptionHandler<VrpcInstance, Domain> = VrpcBaseSubscriptionHandler<
    SubscriptionHandlerType.INSTANCE_ONE_TO_LIST,
    VrpcBaseInstanceSubscriptionHandler<VrpcInstanceChangeArgs<VrpcInstance>, VrpcInstanceDomainMapperArgs<VrpcInstance>, Domain[]>
>

export type VrpcRemoteToListSubscriptionHandler<Domain> = VrpcBaseSubscriptionHandler<
    SubscriptionHandlerType.REMOTE_TO_LIST,
    VrpcHandlerMappingProperties<
        /** Change setup args */
        Readonly<{ remote: ManagedVrpcRemote, listener: VoidFunction }>,
        /** Change unsub arg */
        UnsubFunction<[]>,
        /** Domain mapping args */
        Readonly<{ remote: ManagedVrpcRemote }>,
        Domain[]
    > &
        /** If any extra remote with custom domains need to be added for this handler to tackle */
        Readonly<{ domains: VrpcDomainType[] }> &
        /** Filters are never supported */
        ReadonlyRecord<keyof VrpcSubscriptionHandlerFilters, never[]>
>

export type VrpcSubscriptionHandler<VrpcInstance, Domain, Type extends SubscriptionHandlerType = SubscriptionHandlerType> = Extract<
    /** All supported handlers */
    | VrpcInstanceToPageSubscriptionHandler<VrpcInstance, Domain>
    | VrpcInstanceToOneSubscriptionHandler<VrpcInstance, Domain>
    | VrpcInstanceToVirtualOneSubscriptionHandler<VrpcInstance, Domain>
    | VrpcInstanceToListSubscriptionHandler<VrpcInstance, Domain>
    | VrpcRemoteToListSubscriptionHandler<Domain>,
    /** Against the subset of wanted handlers */
    VrpcBaseSubscriptionHandler<Type>
>

export type VrpcSubscriptionHandlerArgs<T extends VrpcSubscriptionHandler<any, any>> = T extends VrpcSubscriptionHandler<infer VrpcInstance, infer Domain>
    ? { VrpcInstance: VrpcInstance, Domain: Domain }
    : never
