import React, { createRef, type FC, type ReactNode, useMemo } from 'react'
import { Box, Button, type ButtonProps, type SxProps, type Theme, Tooltip } from '@mui/material'
import { Add, Sync } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../../domain'

import { createClickerHandler, readFirstFileAsText } from '../..'
import { useAppUsecase, useAppUsecases } from '../../State'
import { useAsyncCallback } from '../../Callback'
import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { HiddenRawFileUpload } from '../../common'
import { useCanUpdateLicense, useIsPortalReachable } from './Hooks'

const hiddenStyle: SxProps<Theme> = { display: 'none' }
const buttonStyle: SxProps<Theme> = { ml: 2, textTransform: 'capitalize', borderColor: 'divider', boxShadow: 0, ':hover': { boxShadow: 0 } }

type Props = Readonly<{
    tooltip: Translation
    disabled?: boolean
    Hidden?: FC<{ setLoading: (loading: Promise<void>) => void }>
    onClick: () => Promise<void> | void
    startIcon: ReactNode
}> &
    Pick<ButtonProps, 'variant'>

const LicenseActionButton: FC<Props> = ({ variant = 'contained', tooltip, Hidden, disabled, onClick, startIcon, children }) => {
    const translator = useTranslator()
    const canUpdateLicense = useCanUpdateLicense()
    const [load, loading, result] = useAsyncCallback((promise: Promise<void>) => promise, [])

    return (
        <Tooltip title={!loading && ConnectwareError.is(result) ? result.message : translator.formatTranslation(tooltip)}>
            {/*  Added a <Box> wrapper to resolve a logging error caused by having a disabled button as a direct child of a tooltip */}
            <Box>
                <Button
                    variant={variant}
                    size="large"
                    color={ConnectwareError.is(result) ? 'error' : variant === 'outlined' ? 'inherit' : 'primary'}
                    startIcon={startIcon}
                    disabled={disabled || !canUpdateLicense || loading}
                    onClick={() => {
                        const result = onClick()
                        if (result instanceof Promise) {
                            load(result)
                        }
                    }}
                    sx={buttonStyle}
                >
                    {Hidden && (
                        <Box sx={hiddenStyle}>
                            <Hidden setLoading={load} />
                        </Box>
                    )}
                    {children}
                </Button>
            </Box>
        </Tooltip>
    )
}

export const UploadLicenseFileButton: FC = () => {
    const uploadLicenseFileUsecase = useAppUsecase('uploadLicenseFileUsecase')

    const supportedFileTypes = useMemo(() => uploadLicenseFileUsecase.getSupportedLicenseFilesExtensions(), [uploadLicenseFileUsecase])
    const ref = createRef<HTMLInputElement>()

    return (
        <LicenseActionButton
            variant="outlined"
            tooltip={Translation.UPLOAD_LICENSE_FILE}
            onClick={createClickerHandler(ref)}
            startIcon={<Add fontSize="small" />}
            /**
             * This button relies on a hidden file upload field
             * Whenever the user clicks on the button, the file input is triggered
             *
             * If there is a file upload, then attempt loading
             */
            Hidden={({ setLoading }) => (
                <HiddenRawFileUpload
                    ref={ref}
                    accept={supportedFileTypes}
                    onChange={(files) =>
                        setLoading(
                            /** Read the selected file and upload its content */
                            readFirstFileAsText(files).then((content) => (content ? uploadLicenseFileUsecase.upload(content) : Promise.resolve()))
                        )
                    }
                />
            )}
        >
            <FormattedTranslation id={Translation.UPLOAD_LICENSE_FILE} />
        </LicenseActionButton>
    )
}

export const RefreshLicenseButton: FC = () => {
    const { refreshLicenseUsecase } = useAppUsecases()
    const connectivity = useIsPortalReachable()

    return (
        <LicenseActionButton
            tooltip={Translation.REFRESH_LICENSE_FILE_FROM_PORTAL}
            onClick={() => refreshLicenseUsecase.refresh()}
            startIcon={<Sync fontSize="small" />}
            disabled={connectivity === null || !connectivity.reachable}
        >
            <FormattedTranslation id={Translation.REFRESH_LICENSE} />
        </LicenseActionButton>
    )
}
