import type { MfaActivationData, MfaUpdateCredentials, ValidOtp } from '../../domain'

export interface MfaService {
    /**
     * Yields if the user has mfa enabled or not
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    isEnabled(): Promise<boolean>

    /**
     * Starts the enabling process
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    enable(): Promise<MfaActivationData>

    /**
     * Finishes the enabling process
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    confirmEnabling(otp: ValidOtp): Promise<string[]>

    /**
     * With the given credentials, attempts to disable MFA
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    disable(credentials: MfaUpdateCredentials): Promise<void>

    /**
     * With the given credentials, attempts to regerate backup codes
     * @throws `ConnectwareError`
     * @see {ConnectwareError}
     */
    regenerateBackupCodes(credentials: MfaUpdateCredentials): Promise<string[]>
}
