import { Box, Grid, type SxProps, type Theme } from '@mui/material'
import React, { type FC } from 'react'

import { isArrayNotEmpty } from '../../../../utils'
import { type CybusDetailedService, Translation } from '../../../../domain'
import { Status } from '../../common'
import { DetailsEntry } from '../../Details'
import { AbsoluteRouteOnlyPath, PermissionedLink } from '../../routing'
import { FormattedDateTime, useTranslator } from '../../Internationalization'
import { CrossReferenceServiceLinks } from '../ServiceLink'
import { ServiceLinks } from '../Links'

const logoWrapperStyle: SxProps<Theme> = { my: 'auto', mr: 2, textAlign: 'center' }
const logoStyle: SxProps<Theme> = { maxWidth: '100%' }
const infoStyle: SxProps<Theme> = { px: 1, flexGrow: 1 }
const preformattedStyle: SxProps<Theme> = { whiteSpace: 'pre-wrap' }
const linkStyle: SxProps<Theme> = { color: 'primary.700' }
const permissionedLinkStyle: SxProps<Theme> = { '& > a': { ...linkStyle, textDecoration: 'unset' } }

export const HeaderDetails: FC<{ resource: CybusDetailedService }> = ({ resource: service }) => {
    const translator = useTranslator()

    return (
        <Grid container justifyContent="space-between">
            <Grid sx={infoStyle} item xs={service.icon ? 7 : 9}>
                <DetailsEntry title={Translation.SERVICE_ID} type="text">
                    {service.id}
                </DetailsEntry>
                <DetailsEntry title={Translation.NAME} type="text">
                    {service.name}
                </DetailsEntry>
                <DetailsEntry title={Translation.DESCRIPTION}>
                    <Box sx={preformattedStyle}>{service.description}</Box>
                </DetailsEntry>
                {service.provider && (
                    <DetailsEntry title={Translation.PROVIDER}>
                        <Box id="services-catalog-link" sx={permissionedLinkStyle}>
                            {service.provider}
                            {service.catalog && (
                                <>
                                    {' '}
                                    (
                                    <>
                                        {translator.formatTranslation(Translation.FROM, {
                                            value: (
                                                <PermissionedLink path={AbsoluteRouteOnlyPath.SERVICES_CATALOG}>
                                                    {translator.formatTranslation(Translation.SERVICES_CATALOG).toLowerCase()}
                                                </PermissionedLink>
                                            ),
                                        })}
                                    </>
                                    )
                                </>
                            )}
                        </Box>
                    </DetailsEntry>
                )}
                {service.updatedAt && (
                    <DetailsEntry title={Translation.LAST_UPDATED}>
                        <FormattedDateTime date={service.updatedAt} format="datetime" />
                    </DetailsEntry>
                )}
                {service.version && <DetailsEntry title={Translation.VERSION}>{service.version}</DetailsEntry>}
                <DetailsEntry title={Translation.STATUS}>
                    <Status status={service.status} />
                </DetailsEntry>
                <DetailsEntry title={Translation.DEVIATION}>
                    <Status status={service.deviation} />
                </DetailsEntry>
                {isArrayNotEmpty(service.links) ? (
                    <DetailsEntry title={translator.formatTranslation(Translation.LINK, { count: service.links.length })}>
                        <ServiceLinks links={service.links} isDetailedView />
                    </DetailsEntry>
                ) : null}
                {isArrayNotEmpty(service.dependencies.dependent) && (
                    <CrossReferenceServiceLinks title={Translation.DEPENDENT_SERVICES} services={service.dependencies.dependent} />
                )}
                {isArrayNotEmpty(service.dependencies.dependsOn) && (
                    <CrossReferenceServiceLinks title={Translation.DEPENDS_ON_SERVICES} services={service.dependencies.dependsOn} />
                )}
            </Grid>

            {service.icon && (
                <Grid item sx={logoWrapperStyle} xs={2}>
                    <Box component="img" src={service.icon} sx={logoStyle} />
                </Grid>
            )}
        </Grid>
    )
}
