import { useMemo } from 'react'
import { isPending, usePromise } from 'react-sync-promise'

import type { CybusLogSource } from '../../../domain'

import { useAppUsecase } from '../State'

export const useAreLogsAvailable = (type: CybusLogSource, id: string): boolean => {
    const containerLogsUsecase = useAppUsecase('containerLogsUsecase')

    const logs = usePromise<boolean, never>(useMemo(() => containerLogsUsecase.areLogsAvailable(type, id), [containerLogsUsecase, id, type]))

    return isPending(logs) ? false : logs.value
}
