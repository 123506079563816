import type { Primitive } from 'utility-types'
import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import type { ReadonlyRecord } from '../../../utils'
import { type CybusRuleEngineExecutionError, Translation } from '../../../domain'

import { boldFormatter, FormattedTranslation } from '../Internationalization'
import { Json } from '../common'
import { ErrorMessage, type ErrorMessageProps } from '../ErrorMessage'

type Props = Readonly<{ executionError: CybusRuleEngineExecutionError }>

const jsonStyle: SxProps<Theme> = { maxWidth: 600, maxHeight: 300, overflow: 'auto' }

export const RuleEngineErrorTitle: FC<Props & Pick<ErrorMessageProps<Props['executionError']>, 'id' | 'sx'>> = ({ executionError, ...props }) => (
    <ErrorMessage
        {...props}
        titleVariant="body1"
        customTitle={({ ruleName }) => <FormattedTranslation id={Translation.RULE_ERROR} values={{ b: boldFormatter, ruleName }} />}
        error={executionError}
    />
)

export const RuleEngineErrorMessage: FC<Props & { id?: string, sx?: SxProps<Theme> }> = ({ executionError: { messagePrefix, messageDetails }, ...props }) => (
    <Typography {...props} variant="body2" color="error">
        {messagePrefix && (
            <>
                {messagePrefix}:<br />
            </>
        )}
        <b>{messageDetails}</b>
    </Typography>
)

const Display: FC<{ title: Translation, titleArgs?: ReadonlyRecord<string, Primitive>, json: unknown }> = ({ title, titleArgs, json }) => (
    <>
        <b>
            <FormattedTranslation id={title} values={titleArgs} />:
        </b>
        {json instanceof Object ? (
            <Box sx={jsonStyle}>
                <Json src={json} displayObjectSize={false} collapsed={1} />
            </Box>
        ) : (
            String(json)
        )}
    </>
)

export const RuleEngineErrorPayload: FC<Props> = ({ executionError: { payload } }) => <Display title={Translation.INPUT} json={payload} />

export const RuleEngineErrorRule: FC<Props> = ({ executionError: { rule } }) => <Display title={Translation.RULE} titleArgs={{ count: 1 }} json={rule} />
