import { createNamedEqualityChecker } from '../../utils'

import type { CybusService, StatusType } from '..'

export type CybusResourceDeviation = Readonly<{ actual: StatusType, expected: StatusType, resourceId: string, reason: string | null }>

export const areCybusResourceDeviationsEqual = createNamedEqualityChecker<CybusResourceDeviation>(
    { actual: null, expected: null, resourceId: null, reason: null },
    'areCybusResourceDeviationsEqual'
)

export type CybusServiceDeviation = Readonly<{ name: CybusService['id'] }>

export const areCybusServiceDeviationsEquals = createNamedEqualityChecker<CybusServiceDeviation>({ name: null }, 'areCybusServiceDeviationsEquals')
