import { type CybusEndpoint, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase, BulkDisableResourceUsecase, BulkEnableResourceUsecase } from '../Resources'

export class BulkDeleteEndpointsUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.ENDPOINT

    selectEndpoints (ids: CybusEndpoint['id'][] | null): void {
        this.select(ids)
    }

    override delete (): Promise<void> {
        return super.delete()
    }
}

export class BulkEnableEndpointsUsecase extends BulkEnableResourceUsecase {
    protected readonly type = ResourceType.ENDPOINT

    selectEndpoints (ids: CybusEndpoint['id'][] | null): void {
        this.select(ids)
    }

    override enable (): Promise<void> {
        return super.enable()
    }
}

export class BulkDisableEndpointsUsecase extends BulkDisableResourceUsecase {
    protected readonly type = ResourceType.ENDPOINT

    selectEndpoints (ids: CybusEndpoint['id'][] | null): void {
        this.select(ids)
    }

    override disable (): Promise<void> {
        return super.disable()
    }
}
