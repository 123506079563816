import React, { type FC } from 'react'
import { List } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { useConnectionHostTranslator, useConnectionPortTranslator } from '../../Connections'
import { FormattedTranslation } from '../../Internationalization'

import { ResourcesList } from './ResourcesList'
import { ItemText, TextBlock } from './Commons'

const Label: FC<{ translation: Translation, value: string | null }> = ({ translation, value }) => {
    if (!value) {
        return null
    }

    return (
        <TextBlock>
            <FormattedTranslation id={translation} values={{ count: 1 }} />
            {`: ${value}`}
        </TextBlock>
    )
}

export const Connections: FC<Pick<CybusDetailedService['configuredResources'], 'connections'>> = ({ connections }) => {
    const translateHost = useConnectionHostTranslator()
    const translatePort = useConnectionPortTranslator()

    return (
        <ResourcesList title={Translation.CONNECTION} subtitle={Translation.AUTHORIZE_CONNECTIONS}>
            <List data-testid="service-connections">
                {connections.map(({ name, protocol, ...props }, k) => (
                    <ItemText
                        key={`connection-${k}`}
                        primary={name}
                        secondary={
                            <>
                                <Label translation={Translation.PROTOCOL} value={protocol} />
                                <Label translation={Translation.HOST} value={translateHost(props)} />
                                <Label translation={Translation.PORT} value={translatePort(props)} />
                            </>
                        }
                    />
                ))}
            </List>
        </ResourcesList>
    )
}
