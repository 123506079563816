import { selectUserManagementSelectedTabPage, selectUserManagementSelectedTabShowInternal, type UserManagementTabsTypes } from '../../../domain'

import { initialState } from '../..'
import { TabManagementUsecase } from './Tab'

export class FilterUserManagementUsecase extends TabManagementUsecase {
    search (tab: keyof UserManagementTabsTypes, search: string): void {
        this.updateTab(tab, { search, page: selectUserManagementSelectedTabPage(initialState, tab), data: null })
    }

    toggleShowInternal (tab: keyof UserManagementTabsTypes): void {
        this.updateTab(tab, {
            showInternal: !selectUserManagementSelectedTabShowInternal(this.getState(), tab),
            page: selectUserManagementSelectedTabPage(initialState, tab),
            data: null,
        })
    }

    setPage (tab: keyof UserManagementTabsTypes, page: number): void {
        this.updateTab(tab, { page, data: null })
    }
}
