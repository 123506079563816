import type { ValuesType } from 'utility-types'

import {
    type CommissioningFileField,
    type CommissioningFileValidatedValues,
    type CommissioningFileValue,
    ConnectionsCommissioningFileFinder,
} from '../../../../domain'
import { EditServiceTemplateResourceUsecase, type ResourceFinderConstructor } from './Resource'

export class EditServiceTemplateConnectionUsecase extends EditServiceTemplateResourceUsecase {
    protected resourceType: 'connections' = 'connections'

    protected readonly FinderClass = ConnectionsCommissioningFileFinder as typeof ConnectionsCommissioningFileFinder & ResourceFinderConstructor

    updateConnection<F extends CommissioningFileField> (
        position: number,
        fieldName: keyof ValuesType<CommissioningFileValidatedValues[EditServiceTemplateResourceUsecase['resourceType']]>,
        newValue: CommissioningFileValue<F> | null
    ): void {
        this.updateResource(position, fieldName, newValue)
    }
}
