import React, { type FC, useCallback } from 'react'
import { Button, Link as MuiLink, type SxProps, type Theme, Typography, type TypographyProps } from '@mui/material'

import {
    ConnectwareError,
    InvalidOtpError,
    isLoginFormUsingBackupCodes,
    isLoginFormUsingOtp,
    isMfaRequiredOnLoginForm,
    isOtpValid,
    type LoginForm as LoginFormModel,
    selectAuthentication,
    selectCanAuthenticateWithLoginForm,
    selectLoginForm,
    TokenExpiredError,
    Translation,
} from '../../../domain'

import { useRouting } from '../routing'
import { createFormatter, FormattedTranslation } from '../Internationalization'
import { useAsyncCallback } from '../Callback'
import { useAppState, useAppUsecase } from '../State'
import { createOnEnterHandler } from '..'

import { Validation, validationClasses } from '../common'
import { Field, Form, LengthLimitedField, Link } from './Common'

const validationsStyle: SxProps<Theme> = {
    [`& .${validationClasses.icon}`]: { my: 0 },
    [`& .${validationClasses.root}`]: { mb: 2 },
    [`& .${validationClasses.label}`]: { color: 'grey.200', textAlign: 'start' },
}
const linkStyle: SxProps<Theme> = { color: 'white', fontWeight: 'bold' }

const useOnLoginFormChange = <K extends keyof LoginFormModel,>(name: K): ((value: LoginFormModel[K]) => void) => {
    const usecase = useAppUsecase('loginFormUsecase')
    return useCallback((value) => usecase.updateLoginForm({ [name]: value }), [usecase, name])
}

export const LoginForm: FC = () => {
    const form = useAppState(selectLoginForm)
    const usecase = useAppUsecase('loginFormUsecase')

    const canLogin = useAppState(selectCanAuthenticateWithLoginForm)
    const authentication = useAppState(selectAuthentication)
    const router = useRouting()

    const [login, isLoading] = useAsyncCallback(async () => {
        if (await usecase.login()) {
            router.redirectHome()
        }
    }, [usecase])

    const setUsername = useOnLoginFormChange('username')
    const setPassword = useOnLoginFormChange('password')
    const setBackup = useOnLoginFormChange('backup')
    const setOtp = useOnLoginFormChange('otp')

    const requiredMfa = isMfaRequiredOnLoginForm(form)
    const usingOtp = isLoginFormUsingOtp(form)
    const usingBackup = isLoginFormUsingBackupCodes(form)

    const authType = (usingBackup && 'backupCodes') || (usingOtp && 'otp') || null
    const cantRequestLogin = !canLogin || isLoading
    const loginOnKeyUp = cantRequestLogin ? undefined : createOnEnterHandler(login)

    return (
        <Form id="login-form" title={Translation.LOGIN_FORM_TITLE}>
            {!requiredMfa && (
                <Field
                    id="login-form-username"
                    name="login-form-username"
                    label={Translation.USERNAME}
                    type="text"
                    value={form.username}
                    disabled={isLoading}
                    autoComplete="off"
                    onChange={setUsername}
                    autoFocus
                />
            )}
            {!requiredMfa && (
                <Field
                    id="login-form-password"
                    name="login-form-password"
                    label={Translation.PASSWORD}
                    type="password"
                    value={form.password}
                    disabled={isLoading}
                    autoComplete="one-time-code"
                    onChange={setPassword}
                    onKeyUp={loginOnKeyUp}
                />
            )}
            {requiredMfa && (
                <FormattedTranslation
                    id={Translation.LOGIN_FORM_MFA_EXPLANATION}
                    values={{
                        method: authType,
                        paragraph: createFormatter<TypographyProps>(Typography, { id: 'login-form-otp-explanation', variant: 'body2', color: 'white' }),
                    }}
                />
            )}
            {requiredMfa && usingBackup && (
                <Field
                    id="login-form-backupcodes"
                    name="backupcodes"
                    label={Translation.LOGIN_FORM_BACKUP_CODE}
                    type="password"
                    value={form.backup}
                    disabled={isLoading}
                    autoComplete="one-time-code"
                    onChange={setBackup}
                    onKeyUp={loginOnKeyUp}
                />
            )}
            {usingOtp && (
                <LengthLimitedField
                    id="login-form-otp"
                    type="tel"
                    disabled={isLoading}
                    value={form.otp}
                    onChange={(otp) => {
                        setOtp(otp)
                        if (isOtpValid(otp)) {
                            login()
                        }
                    }}
                    autoFocus
                />
            )}

            {ConnectwareError.is(authentication) && (
                <Validation error sx={validationsStyle} outlined>
                    {TokenExpiredError.is(authentication) ? (
                        <FormattedTranslation
                            id={Translation.TOKEN_EXPIRED_ERROR}
                            values={{ link: createFormatter(MuiLink, { sx: linkStyle, id: 'login-page-link', onClick: () => usecase.cancelMfa() }) }}
                        />
                    ) : InvalidOtpError.is(authentication) ? (
                        <FormattedTranslation
                            id={Translation.INVALID_OTP}
                            values={{
                                minutesUntilRetry: authentication.extras.minutesUntilRetry,
                                triesLeft: authentication.extras.triesLeft,
                                banned: !authentication.extras.triesLeft,
                            }}
                        />
                    ) : (
                        <FormattedTranslation id={Translation.AUTHENTICATION_ERROR} values={{ error: authentication.type, authType }} />
                    )}
                </Validation>
            )}

            {!usingOtp && (
                <Button id="login-form-sign-in-button" variant="contained" color="secondary" fullWidth disabled={cantRequestLogin} onClick={login}>
                    <FormattedTranslation id={Translation.SIGN_IN} />
                </Button>
            )}

            {requiredMfa && (
                <Link id="login-form-toggle-mfa-button" disabled={isLoading} onClick={() => usecase.toggleMfaMethod()}>
                    {/* Inversion will be done inside the translation */}
                    <FormattedTranslation id={Translation.TOGGLE_AUTHENTICATION_METHOD} values={{ method: authType }} />
                </Link>
            )}

            {requiredMfa && (
                <Link id="login-form-cancel-mfa" color="white" disabled={isLoading} onClick={() => usecase.cancelMfa()}>
                    <FormattedTranslation id={Translation.LOGIN_FORM_CANCEL_MFA} />
                </Link>
            )}
        </Form>
    )
}
