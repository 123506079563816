import type { CybusServiceDeviation, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase } from '../Resources'

export class ManageDeviationsUsecase extends ResourcePageSubscriptionUsecase<'serviceDeviations'> {
    protected readonly pageName = 'serviceDeviations'

    protected readonly pageAddress = 'serviceDeviationsPage'

    protected readonly initialSortColumn: SortableColumn<CybusServiceDeviation> = 'name'
}
