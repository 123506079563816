import { areArrayEquals, createArrayComparator, createEqualityChecker } from '../../utils'
import { type PermissionedEntity, type PermissionedForm, UserManagementEditMode } from './Base'
import { arePermissionArrayEquals, type CybusUser, isPermissionValid } from '.'

export type CybusRole = PermissionedEntity<UserManagementEditMode.ALL | UserManagementEditMode.NONE> &
    Readonly<{
        /**
         * The name of the role
         */
        name: string

        /**
         * List of users that are associated with this role
         */
        users: CybusUser['username'][]

        ldapGroupDn: string | null
    }>

type FullRoleForm = Pick<CybusRole, 'name' | 'id' | 'isProtected' | 'editMode' | 'users' | 'ldapGroupDn'> & PermissionedForm

export type RoleCreationRequest = Pick<FullRoleForm, 'name' | 'permissions' | 'ldapGroupDn'>
export type RoleCreationForm = Pick<FullRoleForm, 'name' | 'permissions' | 'templateInput' | 'selectedTemplate' | 'templates' | 'ldapGroupDn'>

export type RoleEditingRequest = Pick<FullRoleForm, 'name' | 'permissions' | 'id' | 'ldapGroupDn'>
export type RoleEditingForm = Pick<
    FullRoleForm,
    'name' | 'permissions' | 'id' | 'isProtected' | 'editMode' | 'users' | 'templateInput' | 'selectedTemplate' | 'templates' | 'ldapGroupDn'
>

const areRolesEquals = createEqualityChecker<CybusRole>({
    name: null,
    id: null,
    isProtected: null,
    editMode: null,
    ldapGroupDn: null,
    users: createArrayComparator(),
    permissions: arePermissionArrayEquals,
})

export const areRoleArraysEquals = (a: CybusRole[], b: CybusRole[]): boolean => areArrayEquals(a, b, { sort: false, equals: areRolesEquals })

/** Compares the editable parts of the role form */
export const areRoleFormsEqual = createEqualityChecker<Pick<RoleCreationForm | RoleEditingForm, 'name' | 'permissions' | 'ldapGroupDn'>>({
    name: null,
    ldapGroupDn: null,
    permissions: arePermissionArrayEquals,
})

export const isRoleCreatable = ({ name, permissions }: RoleCreationForm): boolean => name.length > 0 && permissions.every(isPermissionValid)
export const isRoleUpdatable = (form: RoleEditingForm): boolean => form.editMode !== UserManagementEditMode.NONE && isRoleCreatable(form)
export const isRoleRemovable = (r: RoleEditingForm): boolean => Boolean(r.id) && !r.isProtected
