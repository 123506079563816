import type { ConnectwareError } from '../../../../../domain'

import type { ManagedVrpcRemote, VrpcDomainType } from '../../../utils'

import type { VrpcRemoteToListSubscriptionHandler } from '../handlers'
import { VrpcRemoteManagerDataMapper } from './BaseRemote'
import { RemoteHandlerManager, ResourceManagerCollection } from './Manager'

export class RemotesToListDataMapper<Domain> extends VrpcRemoteManagerDataMapper<VrpcRemoteToListSubscriptionHandler<Domain>, (Domain | ConnectwareError)[]> {
    private readonly managersHelper = new ResourceManagerCollection<ManagedVrpcRemote, RemoteHandlerManager<Domain>, Domain[]>()

    constructor (
        ...args: ConstructorParameters<typeof VrpcRemoteManagerDataMapper<VrpcRemoteToListSubscriptionHandler<Domain>, (Domain | ConnectwareError)[]>>
    ) {
        super(...args)

        this.onDrop(() => this.managersHelper.drop())
        this.onDrop(this.managersHelper.onChange(() => this.triggerChange()))
    }

    protected override get remoteDomains (): VrpcDomainType[] {
        return [...super.remoteDomains, ...this.handler.domains]
    }

    protected feedManagedRemote (remote: ManagedVrpcRemote): void {
        /**
         * Errors here are not expected as each remote gets called only once
         * So there should not be a conflict
         */
        void this.managersHelper.addManager(remote, new RemoteHandlerManager(this.handler, remote))
    }

    protected getValue (): (Domain | ConnectwareError)[] {
        return this.managersHelper.getValues<(Domain | ConnectwareError)[]>((r, value) => [...r, ...(Array.isArray(value) ? value : [value])], [])
    }
}
