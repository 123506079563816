import type { NonEmptyArray, NullableValues } from '../../utils'
import type { CybusLog, CybusLogLevel, CybusLogSource } from '../../domain'

type BaseLogOptions = Readonly<{
    /** If the logs should be filtered by the log levels */
    levels: NonEmptyArray<CybusLogLevel>

    /** If the logs should be filtered by start date */
    start: Date

    /** If the logs should be filtered by the end date */
    end: Date

    /** If the logs should be filtered by a particular date */
    resource: string

    /** If all or only some logs should be fetched */
    lines: 'limited' | 'all'
}>

export type LogOptions = NullableValues<Pick<BaseLogOptions, 'levels' | 'start' | 'end' | 'resource'>> & Pick<BaseLogOptions, 'lines'>

export type RawLogOptions = NullableValues<Pick<BaseLogOptions, 'resource'>> & Pick<BaseLogOptions, 'lines'>

export interface ConnectwareLogsService {
    /**
     * Yields a promise if logging is available for the given resource (not if there are logs present)
     *
     * @throw `never`
     */
    doesCollectLogs(type: CybusLogSource, id: string): Promise<boolean>

    /**
     * @throws `ConnectwareError`
     */
    fetchLogs(type: CybusLogSource, options: LogOptions): Promise<CybusLog[]>

    /**
     * @throws `ConnectwareError`
     */
    fetchAllRawLogs(type: CybusLogSource, options: RawLogOptions): Promise<File>
}
