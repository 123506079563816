import React, { type FC } from 'react'
import { Link, type SxProps, type Theme } from '@mui/material'

import { Translation } from '../../../../domain'
import { DocumentationType } from '../../../../application'

import { useDocumentation } from '../../Documentation'
import { createFormatter, FormattedTranslation } from '../../Internationalization'

const documentationLinkStyle: SxProps<Theme> = { cursor: 'pointer' }
const props = { sx: documentationLinkStyle, color: 'inherit' }

export const CommissioningFileDocumentationLink: FC<{ renderer?: typeof FormattedTranslation }> = ({ renderer: Renderer = FormattedTranslation }) => {
    const openCommissioningFile = useDocumentation(DocumentationType.SERVICES_COMMISSIONING_FILE)

    return (
        <Renderer
            id={Translation.EDIT_SERVICE_PAGE_CHECKOUT_DOCUMENTATION}
            values={{ link: createFormatter(Link, { ...props, onClick: openCommissioningFile }) }}
        />
    )
}
