import { areCybusEndpointEquals, Capability, type CybusEndpoint } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusEndpointMapper, type EndpointProxyParams, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class EndpointsStrategy extends BaseSubscriptionStrategy<'endpoints'> {
    readonly requiredPermissions = [Capability.ENDPOINTS_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service', 'connection']

    protected override readonly areEquals = areCybusEndpointEquals

    override retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusEndpoint[]> {
        return this.retrieve({
            capability: Capability.ENDPOINTS_READ,
            method: 'GET',
            path: '/api/endpoints',
            mapper: (data: EndpointProxyParams[]) => {
                const mapper = new CybusEndpointMapper(filter)

                data.forEach((params) => {
                    /**
                     * @deprecated update code block so status is fetched externally
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const currentStatus = mapToStatusType(params.currentState)

                    mapper.push(params, currentStatus)
                })

                return mapper.endpoints
            },
        })
    }
}
