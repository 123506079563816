import type { Intersection } from 'utility-types'

import type { UserCreationRequest, UserEditingRequest } from '../../../../domain'

import type { PermissionPostRequest, UserPostRequest, UserPutRequest } from '../Types'
import { mapToDefault } from './Commons'

import { mapAuthenticationMethodToGrantType } from './Grant'
import { mapPermissionRequest } from './Permission'

const mapCommon = ({
    password,
    mqttPublishPrefix,
    roles,
    isMfaEnforced,
}: Intersection<Omit<UserEditingRequest, 'id'>, UserCreationRequest>): Intersection<UserPutRequest, UserPostRequest> => ({
    roles: roles?.map(({ id }) => id),
    password: mapToDefault(password, undefined),
    mqttPublishPrefix: mqttPublishPrefix === null ? '' : mapToDefault(mqttPublishPrefix, undefined),
    enforceMFAEnrollment: isMfaEnforced === null ? undefined : isMfaEnforced,
})

export const mapUserEditingRequestToUserPutRequest = ({
    authenticationMethods,
    isMfaEnabled,
    username,
    permissions,
    ...request
}: Omit<UserEditingRequest, 'id'>): UserPutRequest => ({
    ...mapCommon(request),
    grantTypes: authenticationMethods?.map(mapAuthenticationMethodToGrantType),
    // Set "disableMfa" to true for the user if request.mfa is false, otherwise keep MFA enabled if request.mfa is true
    disableMfa: typeof isMfaEnabled === 'boolean' ? !isMfaEnabled : undefined,
    username,
    permissions: permissions?.map(mapPermissionRequest),
})

export const mapUserCreationRequestToUserPostRequest = ({
    username,
    permissions,
    authenticationMethods,
    ...request
}: UserCreationRequest): UserPostRequest => ({
    ...mapCommon(request),
    grantTypes: authenticationMethods.map(mapAuthenticationMethodToGrantType),
    username,
    initialPermissions: permissions.map(mapPermissionRequest) as PermissionPostRequest[],
    identityProvider: 'local',
})
