import type { ConnectwareError } from '../../../../../domain'
import type { CountSubscriptionsTypes } from '../../../../../application'

import { SubscriptionHandlerType } from '../handlers'
import { type DataMapper, InstancesToCountDataMapper } from '../mappers'
import { BaseSubscription } from './Base'

type Data = number | ConnectwareError

export class CountSubscription<T extends keyof CountSubscriptionsTypes> extends BaseSubscription<T, Data> {
    protected createMapper (): DataMapper<Data> | null {
        if (CountSubscription.isHandlerOfType(this.handler, SubscriptionHandlerType.INSTANCE_ONE_TO_ONE)) {
            /** Can only be map with one to one */
            return new InstancesToCountDataMapper(this.handler, this.filter)
        }

        return null
    }
}
