import { ConnectwareError, type CybusResourceDeviation, type CybusService, type StatusType } from '../../../domain'
import type {
    DeviationFetcher,
    DeviationsSubscription,
    ResourceDeviationSupportedType,
    RSTAdapter,
    ServiceDeviationSupportedType,
    StatusSupportedType,
    StatusTypeFetcher,
    StatusTypeSubscription,
} from '.'

/**
 * @ignoreUnneededExport
 */
export class RSTAdapterMixin implements RSTAdapter {
    constructor (
        private readonly statusTypeFetcher: StatusTypeFetcher,
        private readonly deviationFetcher: DeviationFetcher,
        private readonly statusTypeSubscription: StatusTypeSubscription,
        private readonly deviationsSubscription: DeviationsSubscription
    ) {}

    fetchIsDeviated (type: ServiceDeviationSupportedType, serviceId: CybusService['id']): Promise<boolean> {
        const isDeviated = this.deviationsSubscription.useCachedIsDeviated(type, serviceId) ?? this.deviationFetcher.fetchIsDeviated(type, serviceId)
        return ConnectwareError.is(isDeviated) ? Promise.reject(isDeviated) : Promise.resolve(isDeviated)
    }

    fetchResourceDeviations (type: ResourceDeviationSupportedType, serviceId: CybusService['id']): Promise<CybusResourceDeviation[]> {
        return this.deviationFetcher.fetchResourceDeviations(type, serviceId)
    }

    fetchStatusType (type: StatusSupportedType, resourceId: string): Promise<StatusType> {
        const state = this.statusTypeSubscription.useCachedStatusType(type, resourceId) ?? this.statusTypeFetcher.fetchStatusType(type, resourceId)
        return ConnectwareError.is(state) ? Promise.reject(state) : Promise.resolve(state)
    }

    subscribeToStatusType (type: StatusSupportedType, resourceId: string, handler: VoidFunction): Promise<VoidFunction> {
        return this.statusTypeSubscription.subscribeToStatusType(type, resourceId, handler)
    }

    subscribeToServiceDeviationCount (type: ServiceDeviationSupportedType, serviceId: CybusService['id'], handler: VoidFunction): Promise<VoidFunction> {
        return this.deviationsSubscription.subscribeToServiceDeviationCount(type, serviceId, handler)
    }
}
