import React, { type FC } from 'react'

import { type CybusDetailedService, type StatusType, Translation } from '../../../../domain'

import { Status, Table, useTableStatusSearcher } from '../../common'

export const Deviations: FC<Pick<CybusDetailedService, 'deviations'>> = ({ deviations }) => {
    const searcher = useTableStatusSearcher()
    const customCellRender = (s: StatusType): JSX.Element => <Status status={s} />

    return (
        <Table
            data={deviations}
            columns={{
                resourceId: { label: Translation.LOGICAL_ID, sort: true },
                expected: { label: Translation.EXPECTED, sort: true, searcher, customCellRender },
                actual: { label: Translation.ACTUAL, sort: true, searcher, customCellRender },
                reason: { label: Translation.REASON },
            }}
            pagination={{ pageSize: 15, pageSizeOptions: [15] }}
        />
    )
}
