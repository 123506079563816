import type { PickByValueExact } from 'utility-types'

import { copyObjectWithoutKeys, type ReadonlyRecord } from '../../../../utils'
import {
    appendIndexInvalidities,
    assertCommissioningFileValidatedValueType,
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFormState,
    type CommissioningFileValidatedValue,
    type CommissioningFileValidatedValues,
    type CommissioningFileValue,
    type CommissioningFileValues,
    type ResourceCommissioningFileFinder,
} from '../../../../domain'
import { EditServiceTemplateOutputUsecase } from './Output'

type ResourceFinder = ResourceCommissioningFileFinder<[CommissioningFileValidatedValues, CommissioningFileValidatedValue<CommissioningFileField>]>

export type ResourceFinderConstructor = new (fields: CommissioningFileFields, values: CommissioningFileValidatedValues) => ResourceFinder

export abstract class EditServiceTemplateResourceUsecase extends EditServiceTemplateOutputUsecase {
    protected abstract resourceType: keyof PickByValueExact<CommissioningFileValues, ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>[]>

    protected abstract FinderClass: ResourceFinderConstructor

    protected updateResource<F extends CommissioningFileField> (
        position: number,
        fieldName: keyof ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>,
        newValue: CommissioningFileValue<F> | null
    ): void {
        const { fields, file, values } = this.getTypedForm([CommissioningFileFormState.LOADED_OUTPUT, CommissioningFileFormState.LOADING_OUTPUT])

        const finder = new this.FinderClass(fields, values)
        const [field, value] = finder.find(position, fieldName)

        if (value.value === newValue) {
            /** Values are still the same, do not go forward */
            return
        }

        /** Make sure types are as expected */
        assertCommissioningFileValidatedValueType(field, newValue)

        /** Update */
        this.setForm({
            type: CommissioningFileFormState.LOADING_OUTPUT,
            fields,
            file,
            values: appendIndexInvalidities(fields, {
                ...values,
                [this.resourceType]: values[this.resourceType].map((entry, k) => {
                    if (k !== position) {
                        return entry
                    }

                    return {
                        ...copyObjectWithoutKeys(entry, ...finder.findFieldsToRemove(position, fieldName)),
                        ...finder.findFieldsToAdd(position, fieldName, newValue),
                    }
                }),
            }),
        })

        /** And schedule the refresh finally */
        this.scheduleOutputRefresh()
    }
}
