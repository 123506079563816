import type { NullableValues } from '../../utils'

import type {
    CommissioningFileFields,
    CommissioningFileOutput,
    CommissioningFileValues,
    CybusCatalogServiceConfiguration,
    CybusCatalogServiceVersionData,
    CybusDetailedService,
    CybusServiceForm,
    CybusServiceParameters,
    CybusServiceSchema,
} from '../../domain'

export type ServiceCreationOrUpdateRequest = Readonly<{
    isCreation: boolean
    id: CybusDetailedService['id']
    commissioningFile: CybusDetailedService['commissioningFile']
    parameters: CybusDetailedService['parameters']
    catalog: (CybusCatalogServiceConfiguration & Pick<CybusCatalogServiceVersionData, 'version' | 'updatedAt'>) | null
}>

export interface ConnectwareServicesService {
    /**
     * Receives the content of a yaml file as string, and yields a Promise of the json schema and initial values (null parameters if there is no default)
     *
     * @param file the content of the file, plain text, no encoding
     * @param id if not null, service should exist on backend
     * @throws `ConnectwareError`
     */
    parseCommissioningFile(
        file: string,
        id: CybusServiceForm['id']
    ): Promise<[schema: CybusServiceSchema, initialId: CybusServiceForm['id'], initialParameters: NullableValues<CybusServiceParameters>]>

    /**
     * @throws never
     */
    validate(schema: CybusServiceSchema, id: CybusServiceForm['id'], parameters: CybusServiceParameters): boolean

    /**
     * @throws `ConnectwareError`
     */
    createOrUpdate(request: ServiceCreationOrUpdateRequest): Promise<void>

    /**
     * Creates file from the service commissioning file's content
     * @throws never
     */
    createFile(service: CybusDetailedService): File

    /**
     * Yields the structure of a commissioning file, with the metadata necessary to create one
     * @throws `ConnectwareError`
     */
    generateCommissioningFileFields(): Promise<CommissioningFileFields>

    /**
     * Generates the values of the commissioning file from the given file
     * @throws `ConnectwareError`
     */
    generateCommissioningFileValues(file: File): Promise<CommissioningFileValues>

    /**
     * Creates the outputed file when the updated values are applied to it
     * Also generates validation
     * @throws `ConnectwareError` only if there was a major issue with the call
     */
    generateCommissioningFileOutput(file: File, updated: CommissioningFileValues): Promise<CommissioningFileOutput>
}
