import React, { type FC } from 'react'

import { Box, Button, CircularProgress, Divider, Stack, type SxProps, type Theme, Typography } from '@mui/material'
import type { SvgIconComponent } from '@mui/icons-material'

import type { Translation } from '../../../domain'
import { FormattedTranslation } from '../Internationalization'

const rootStyle: SxProps<Theme> = { mb: 5 }
const dividerStyle: SxProps<Theme> = { mb: 1 }
const buttonStyle: SxProps<Theme> = { my: 1.5 }
const loaderStyle: SxProps<Theme> = { mt: 'auto' }

export const MaintenanceSection: FC<
    Readonly<{
        title: Translation
        description: Translation
        buttonProps: Readonly<{
            label: Translation
            icon: SvgIconComponent
            onClick: VoidFunction
        }>
        loading: boolean
    }>
> = ({ title, description, buttonProps: { icon: ButtonIcon, label: buttonLabel, ...buttonProps }, loading, children, ...props }) => (
    <Box {...props} sx={rootStyle} component="section">
        <Typography variant="h5">
            <FormattedTranslation id={title} />
        </Typography>

        <Divider sx={dividerStyle} />

        <Typography variant="subtitle1">
            <FormattedTranslation id={description} />
        </Typography>

        <Stack sx={buttonStyle} direction="row" gap={1}>
            <Button size="small" variant="contained" startIcon={<ButtonIcon />} {...buttonProps}>
                <FormattedTranslation id={buttonLabel} />
            </Button>
            {loading && <CircularProgress sx={loaderStyle} size={30} />}
        </Stack>

        {children}
    </Box>
)
