import { isEnumOf, type ReadonlyRecord, throwCaptured } from '../../../../utils'

import { ConnectwareError, ConnectwareErrorType } from '../../../../domain'

import { AbsoluteRoutePathWithId, AbsoluteRoutePathWithServiceAndResourceId, AbsoluteRoutePathWithServiceId, type RouterState } from '../Domain'
import { useConnectwareRouting } from './InternalRouter'

const createStateChecker =
    <T>(enumType: ReadonlyRecord<string, string | number>) =>
    (state: RouterState): state is Extract<RouterState, { path: T }> =>
        isEnumOf(enumType, state.path)

const isAbsoluteRoutePathWithResourceId = createStateChecker<AbsoluteRoutePathWithId>(AbsoluteRoutePathWithId)
const isAbsoluteRoutePathWithServiceId = createStateChecker<AbsoluteRoutePathWithServiceId>(AbsoluteRoutePathWithServiceId)
const isAbsoluteRoutePathWithServiceAndResourceId = createStateChecker<AbsoluteRoutePathWithServiceAndResourceId>(AbsoluteRoutePathWithServiceAndResourceId)

export const useId = (): string => {
    const state = useConnectwareRouting()

    if (isAbsoluteRoutePathWithResourceId(state) || isAbsoluteRoutePathWithServiceAndResourceId(state)) {
        return state.resourceId
    }

    if (isAbsoluteRoutePathWithServiceId(state)) {
        return state.serviceId
    }

    return throwCaptured(new ConnectwareError(ConnectwareErrorType.STATE, 'Id not retrievable under this route', state), useId)
}
