import React, { type FC } from 'react'

import type { ReadonlyRecord } from '../../../utils'
import {
    canConnectionBeConnected,
    canConnectionBeDisconnected,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusConnection,
    selectConnectableLoadedDetailedConnections,
    selectConnectionsPage,
    selectDisconnectableLoadedDetailedConnections,
    selectLoadedConnections,
    Translation,
} from '../../../domain'

import { RedirectingResourcesTable } from '../Resources'
import { RelativeRoutePathWithId } from '../routing'
import { useTranslator } from '../Internationalization'
import { Status, useClearTableSelection } from '../common'

import { useConnectionHostTranslator, useConnectionPortTranslator } from '.'
import { DeleteModal, DisableModal, EnableModal } from './Bulk'

const Modals: FC = () => {
    const clear = useClearTableSelection()

    return (
        <>
            <DeleteModal connectionsSelector={selectLoadedConnections} onConfirmed={clear} />
            <EnableModal connectionsSelector={selectConnectableLoadedDetailedConnections} onConfirmed={clear} />
            <DisableModal connectionsSelector={selectDisconnectableLoadedDetailedConnections} onConfirmed={clear} />
        </>
    )
}

const selectCanManageConnections = createIsAuthenticatedWithCapabilitiesSelector(Capability.CONNECTIONS_MANAGE)

export const Table: FC<Readonly<{ serviceId?: string }>> = ({ serviceId = null }) => {
    const translator = useTranslator()
    const translateHost = useConnectionHostTranslator()
    const translatePort = useConnectionPortTranslator()

    return (
        <RedirectingResourcesTable<
            'manageConnectionsUsecase',
            CybusConnection,
            Pick<CybusConnection, 'id' | 'service' | 'name' | 'protocol' | 'status' | 'agent'> & ReadonlyRecord<'hostLabel' | 'portLabel', string | null>,
            'bulkDeleteConnectionsUsecase' | 'bulkDisconnectConnectionsUsecase' | 'bulkConnectConnectionsUsecase'
        >
            subscriptionUsecase="manageConnectionsUsecase"
            filter={serviceId ? { service: serviceId } : undefined}
            data={selectConnectionsPage}
            hasManagementCapabilitiesSelector={selectCanManageConnections}
            dataTableMapper={(connections) =>
                connections.map((c) => ({
                    id: c.id,
                    service: c.service,
                    name: c.name,
                    protocol: c.protocol,
                    hostLabel: translateHost(c),
                    portLabel: translatePort(c),
                    agent: c.agent,
                    status: c.status,
                }))
            }
            columns={{
                ...(serviceId ? {} : { service: { label: Translation.SERVICE_ID, sort: true } }),
                name: { label: Translation.CONNECTION_ID, sort: true },
                protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                hostLabel: { label: translator.formatTranslation(Translation.HOST, { count: 1 }) },
                portLabel: { label: translator.formatTranslation(Translation.PORT, { count: 1 }) },
                agent: { label: translator.formatTranslation(Translation.AGENT, { count: 1 }), sort: true },
                status: { label: Translation.STATUS, customCellRender: (status) => <Status status={status} />, sort: true },
            }}
            short={Boolean(serviceId)}
            redirectOnRowclick={RelativeRoutePathWithId.CONNECTION}
            selection={{
                DELETE: { usecase: 'bulkDeleteConnectionsUsecase' },
                DISABLE: { usecase: 'bulkDisconnectConnectionsUsecase', filter: canConnectionBeDisconnected, label: Translation.DISCONNECT },
                ENABLE: { usecase: 'bulkConnectConnectionsUsecase', filter: canConnectionBeConnected, label: Translation.CONNECT },
                resource: 'id',
                usecaseMethod: 'selectConnections',
            }}
            translations={{ noResultsOrEmptyTable: Translation.NO_CONNECTIONS_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
