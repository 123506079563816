import {
    type AuthenticationAppState,
    type AuthenticationInformation,
    type Capability,
    createAreAllCapabilitiesIncludedChecker,
    selectAuthenticationCapabilities,
    selectAuthenticationObsolescence,
    selectAuthenticationToken,
    selectAuthenticationUsername,
} from '.'
import { ConnectwareError } from '../Error'

export const selectAuthentication = (s: AuthenticationAppState): AuthenticationAppState['authentication'] => s.authentication

export const isAuthenticated = (auth: AuthenticationAppState['authentication']): auth is AuthenticationInformation =>
    Boolean(auth && !ConnectwareError.is(auth))

export const selectIsAuthenticated = (s: AuthenticationAppState): boolean => isAuthenticated(selectAuthentication(s))

const createAuthenticationSelector =
    <T>(selector: (info: AuthenticationInformation) => T, defaultValue: T): ((s: AuthenticationAppState) => T) =>
    (s) => {
        const auth = selectAuthentication(s)
        return isAuthenticated(auth) ? selector(auth) : defaultValue
    }

export const selectAuthenticatedToken = createAuthenticationSelector(selectAuthenticationToken, null)
export const selectAuthenticatedUsername = createAuthenticationSelector(selectAuthenticationUsername, null)
export const selectAuthenticatedCapabilities = createAuthenticationSelector(selectAuthenticationCapabilities, null)
export const selectAuthenticatedCapabilitiesWithDefault = createAuthenticationSelector(selectAuthenticationCapabilities, [])
export const selectAuthenticatedObsolence = createAuthenticationSelector(selectAuthenticationObsolescence, null)

const selectAreAllCapabilitiesIncludedChecker = createAuthenticationSelector(createAreAllCapabilitiesIncludedChecker, null)
export const createIndexedCapabilities = (s: AuthenticationAppState): ((requiredCapabilities: Capability[]) => boolean) => {
    const check = selectAreAllCapabilitiesIncludedChecker(s)
    return (requiredCapabilities) => Boolean(check?.(requiredCapabilities))
}

export const createIsAuthenticatedWithCapabilitiesSelector =
    (...requiredPermissions: Capability[]): ((s: AuthenticationAppState) => boolean) =>
    (s) =>
        createIndexedCapabilities(s)(requiredPermissions)
