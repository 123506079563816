import type { GetInstanceOptions, VrpcProxy } from 'vrpc'

import { isArrayNotEmpty, objectKeys, type ReadonlyRecord } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, ResourceType, Translation } from '../../../domain'
import type { ConnectwareResourcesManagementService, TranslationService } from '../../../application'

import { CONTAINER_MANAGER_AGENT } from '../../Connectware'
import { CONTAINER_MANAGER_CLASSNAME, CONTAINER_MANAGER_DOCKER_INSTANCE } from '../constants'
import { getInstance, isTimeoutError, type ManagedVrpcRemote, type VrpcRemoteManager, withConnection } from '../utils'
import type { ConnectionProxy, ContainerManagerOrchestratorProxy, EndpointProxy, MappingProxy, ServerProxy } from '../proxies'

/** Not all types are implemented, this controls which ones are not */
type ManageableTypes = Exclude<ResourceType, ResourceType.SERVICE | ResourceType.CORE_CONTAINER>
type RemoteTypes = Extract<ManageableTypes, ResourceType.AGENT>
type InstanceTypes = Exclude<ManageableTypes, RemoteTypes>

interface InstanceResourcesMap extends ReadonlyRecord<InstanceTypes, VrpcProxy> {
    [ResourceType.CONNECTION]: ConnectionProxy
    [ResourceType.ENDPOINT]: EndpointProxy
    [ResourceType.MAPPING]: MappingProxy
    [ResourceType.SERVER]: ServerProxy
    [ResourceType.SERVICE_CONTAINER]: ContainerManagerOrchestratorProxy
    [ResourceType.VOLUME]: ContainerManagerOrchestratorProxy
}

const instanceRetrievalOptions: ReadonlyRecord<InstanceTypes, [string | null, GetInstanceOptions | undefined]> = {
    [ResourceType.CONNECTION]: [null, undefined],
    [ResourceType.ENDPOINT]: [null, undefined],
    [ResourceType.MAPPING]: [null, undefined],
    [ResourceType.SERVER]: [null, undefined],
    [ResourceType.SERVICE_CONTAINER]: [CONTAINER_MANAGER_DOCKER_INSTANCE, { agent: CONTAINER_MANAGER_AGENT, className: CONTAINER_MANAGER_CLASSNAME }],
    [ResourceType.VOLUME]: [CONTAINER_MANAGER_DOCKER_INSTANCE, { agent: CONTAINER_MANAGER_AGENT, className: CONTAINER_MANAGER_CLASSNAME }],
}

enum ActionType {
    DELETE = 'DELETE',
    DISABLE = 'DISABLE',
    ENABLE = 'ENABLE',
    RE_ENABLE = 'RE_ENABLE',
}

const actionErrorsMessages = {
    [ActionType.DELETE]: {
        [ResourceType.AGENT]: Translation.BULK_ACTION_DELETE_AGENTS_ERROR,
        [ResourceType.CONNECTION]: Translation.BULK_ACTION_DELETE_CONNECTIONS_ERROR,
        [ResourceType.ENDPOINT]: Translation.BULK_ACTION_DELETE_ENDPOINTS_ERROR,
        [ResourceType.MAPPING]: Translation.BULK_ACTION_DELETE_MAPPINGS_ERROR,
        [ResourceType.SERVER]: Translation.BULK_ACTION_DELETE_SERVERS_ERROR,
        [ResourceType.SERVICE_CONTAINER]: Translation.BULK_ACTION_DELETE_CONTAINERS_ERROR,
        [ResourceType.VOLUME]: Translation.BULK_ACTION_DELETE_VOLUMES_ERROR,
    },
    [ActionType.DISABLE]: {
        [ResourceType.CONNECTION]: Translation.BULK_ACTION_DISCONNECT_CONNECTIONS_ERROR,
        [ResourceType.ENDPOINT]: Translation.BULK_ACTION_DISABLE_ENDPOINTS_ERROR,
        [ResourceType.MAPPING]: Translation.BULK_ACTION_DISABLE_MAPPINGS_ERROR,
        [ResourceType.SERVER]: Translation.BULK_ACTION_DISABLE_SERVERS_ERROR,
        [ResourceType.SERVICE_CONTAINER]: Translation.BULK_ACTION_DISABLE_CONTAINERS_ERROR,
    },
    [ActionType.ENABLE]: {
        [ResourceType.CONNECTION]: Translation.BULK_ACTION_CONNECT_CONNECTIONS_ERROR,
        [ResourceType.ENDPOINT]: Translation.BULK_ACTION_ENABLE_ENDPOINTS_ERROR,
        [ResourceType.MAPPING]: Translation.BULK_ACTION_ENABLE_MAPPINGS_ERROR,
        [ResourceType.SERVER]: Translation.BULK_ACTION_ENABLE_SERVERS_ERROR,
        [ResourceType.SERVICE_CONTAINER]: Translation.BULK_ACTION_ENABLE_CONTAINERS_ERROR,
    },
    [ActionType.RE_ENABLE]: {
        [ResourceType.SERVICE_CONTAINER]: Translation.BULK_ACTION_RE_ENABLE_CONTAINERS_ERROR,
    },
} as const

/**
 * @deprecated
 * @todo remove when possible
 */
export class VrpcConnectwareResourcesManagementService implements ConnectwareResourcesManagementService {
    private static handleBulkError (e: unknown, extras: ReadonlyRecord<string, unknown>): ConnectwareError {
        if (ConnectwareError.is(e)) {
            return e
        }

        const args = { ...extras, original: (e as Error).message }

        if (isTimeoutError(e)) {
            return new ConnectwareError(ConnectwareErrorType.SERVER_TIMEOUT, 'Bulk operation timed-out', args)
        }

        return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not perform bulk operation', args)
    }

    /**
     * Some bulk actions are to be done not on the instance itself
     * But on its manager
     *
     * This function makes the distinction between the two
     * Creating a difference between what instances should be retrieved (sometimes the manager) and what actions should be applied on it (simply id)
     */
    private static getInstanceRetrievalArgs<T extends InstanceTypes> (
        type: T,
        ids: string[]
    ): [ids: [retrievalId: string, operationalId: string][], retrievalArgs: GetInstanceOptions | undefined] {
        const [instanceId, instanceArgs] = instanceRetrievalOptions[type]
        return [ids.map((id) => [instanceId || id, id]), instanceArgs]
    }

    constructor (private readonly remote: VrpcRemoteManager, private readonly translationService: TranslationService) {}

    private mapResponses (errorTranslation: Translation, ids: [string, string][] | string[], responses: (ConnectwareError | void)[]): void | never {
        const errors: unknown[] = []
        const extras: ReadonlyRecord<string, unknown>[] = []
        const types = new Set<ConnectwareErrorType>()

        responses.forEach((response, k) => {
            if (ConnectwareError.is(response)) {
                errors.push(ids[k])
                extras.push({ ...response.extras, message: response.message, type: response.type })
                types.add(response.type)
            }
        })

        if (isArrayNotEmpty(errors)) {
            throw new ConnectwareError(
                types.size === 1 ? (Array.from(types)[0] as ConnectwareErrorType) : ConnectwareErrorType.SERVER_ERROR,
                this.translationService.translate(errorTranslation, { count: errors.length }),
                { resources: ids, errors, extras }
            )
        }
    }

    private bulkInstancesAction<T extends InstanceTypes> (
        type: T,
        targetIds: string[],
        builkOperation: (resource: InstanceResourcesMap[T], id: string, remote: ManagedVrpcRemote) => Promise<unknown>,
        errorTranslation: Translation
    ): Promise<void> {
        const [ids, retrievalArgs] = VrpcConnectwareResourcesManagementService.getInstanceRetrievalArgs(type, targetIds)

        return withConnection(this.remote, (remote) =>
            Promise.all(
                // For each id pair
                ids.map(([retrievalId, operationalId]) =>
                    // Retrieve the instance
                    getInstance<InstanceResourcesMap[T]>(remote, retrievalId, retrievalArgs)
                        // Make an operation
                        .then((resource) => builkOperation(resource, operationalId, remote))
                        // Handle the errors
                        .catch((e) =>
                            VrpcConnectwareResourcesManagementService.handleBulkError(e, { args: [retrievalId, retrievalArgs || null], operationalId })
                        )
                        // Ignore any response that is not the error
                        .then((e) => (ConnectwareError.is(e) ? e : undefined))
                )
            )
                // Collect all responses into a single error
                .then((responses) => this.mapResponses(errorTranslation, ids, responses))
        )
    }

    private bulkRemoteAction (
        targetIds: string[],
        builkOperation: (remote: ManagedVrpcRemote, id: string) => Promise<unknown>,
        errorTranslation: Translation
    ): Promise<void> {
        return withConnection(this.remote, (remote) =>
            Promise.all(
                // For each id
                targetIds.map((id) =>
                    // Make an operation on the remote
                    builkOperation(remote, id)
                        // Handle the errors
                        .catch((e) => VrpcConnectwareResourcesManagementService.handleBulkError(e, { id }))
                        // Ignore any response that is not the error
                        .then((e) => (ConnectwareError.is(e) ? e : undefined))
                )
            )
                // Collect all responses into a single error
                .then((responses) => this.mapResponses(errorTranslation, targetIds, responses))
        )
    }

    delete (type: ResourceType, ids: string[]): Promise<void> {
        switch (type) {
            case ResourceType.CONNECTION:
            case ResourceType.MAPPING:
            case ResourceType.ENDPOINT:
            case ResourceType.SERVER:
                return this.bulkInstancesAction(
                    type,
                    ids,
                    /** Delete the actual service and tell vrpc to also delete the instance */
                    (proxy, id, remote) => proxy.disableAndDelete().then(() => remote.delete(id)),
                    actionErrorsMessages[ActionType.DELETE][type]
                )
            case ResourceType.VOLUME:
                /** Tell the container to delete volumes */
                return this.bulkInstancesAction(type, ids, (c, id) => c.removeVolume(id), actionErrorsMessages[ActionType.DELETE][type])
            case ResourceType.SERVICE_CONTAINER:
                /** Tell the core container manager to delete service containers */
                return this.bulkInstancesAction(type, ids, (c, id) => c.removeContainer(id), actionErrorsMessages[ActionType.DELETE][type])
            case ResourceType.AGENT:
                /** Tell vrpc to unregister the agent */
                return this.bulkRemoteAction(
                    ids,
                    async (remote, agent) => {
                        if (!(await remote.unregisterAgent(agent))) {
                            const info = remote.getSystemInformation()
                            throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not delete agent', {
                                agent,
                                agents: objectKeys(info),
                                agentInfo: info[agent] || null,
                            })
                        }
                    },
                    actionErrorsMessages[ActionType.DELETE][type]
                )
            default:
                return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Not implemented', { type, ids }))
        }
    }

    disable (type: ResourceType, ids: string[]): Promise<void> {
        switch (type) {
            case ResourceType.CONNECTION:
                return this.bulkInstancesAction(type, ids, (c) => c.disconnect(), actionErrorsMessages[ActionType.DISABLE][type])
            case ResourceType.SERVER:
                return this.bulkInstancesAction(type, ids, (s) => s.disable(), actionErrorsMessages[ActionType.DISABLE][type])
            case ResourceType.ENDPOINT:
            case ResourceType.MAPPING:
                return this.bulkInstancesAction(type, ids, (r) => r.processEvent('disable'), actionErrorsMessages[ActionType.DISABLE][type])
            case ResourceType.SERVICE_CONTAINER:
                /** Tell the container manager to stop containers */
                return this.bulkInstancesAction(type, ids, (c, id) => c.stopContainer(id), actionErrorsMessages[ActionType.DISABLE][type])
            default:
                return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Not implemented', { type, ids }))
        }
    }

    enable (type: ResourceType, ids: string[]): Promise<void> {
        switch (type) {
            case ResourceType.CONNECTION:
                return this.bulkInstancesAction(type, ids, (c) => c.connect(), actionErrorsMessages[ActionType.ENABLE][type])
            case ResourceType.SERVER:
                return this.bulkInstancesAction(type, ids, (s) => s.enable(), actionErrorsMessages[ActionType.ENABLE][type])
            case ResourceType.ENDPOINT:
            case ResourceType.MAPPING:
                return this.bulkInstancesAction(type, ids, (r) => r.processEvent('enable'), actionErrorsMessages[ActionType.ENABLE][type])
            case ResourceType.SERVICE_CONTAINER:
                /** Tell the container manager to stop containers */
                return this.bulkInstancesAction(type, ids, (c, id) => c.startContainer(id), actionErrorsMessages[ActionType.ENABLE][type])
            default:
                return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Not implemented', { type, ids }))
        }
    }

    reenable (type: ResourceType, ids: string[]): Promise<void> {
        switch (type) {
            case ResourceType.SERVICE_CONTAINER:
                /** Tell the container manager to restart containers */
                return this.bulkInstancesAction(type, ids, (c, id) => c.restartContainer(id), actionErrorsMessages[ActionType.RE_ENABLE][type])
            default:
                return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Not implemented', { type, ids }))
        }
    }
}
