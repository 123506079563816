import { type CybusPermission, selectUsersManagementViewPermission } from '../../../domain'
import { initialState } from '../../InitialState'
import { UserManagementUsecase } from './Base'

export class ViewPermissionUsecase extends UserManagementUsecase {
    show (viewPermission: CybusPermission): void {
        this.setUsersManagement({ viewPermission })
    }

    close (): void {
        this.setUsersManagement({ viewPermission: selectUsersManagementViewPermission(initialState) })
    }
}
