import React, { type FC, type PropsWithChildren } from 'react'
import { Box, CircularProgress, generateUtilityClasses, type SvgIconProps, Typography, type TypographyProps } from '@mui/material'
import classes from 'classnames'

import { CheckCircle, CheckCircleOutline, ErrorOutlineRounded, ErrorRounded } from '@mui/icons-material'

import { isArrayNotEmpty } from '../../../../utils'
import { ConnectwareError } from '../../../../domain'

import type { BaseCommonProps } from '../Base'

const validationsWrapperStyle: BaseCommonProps['sx'] = { textAlign: 'center' }
const validationsLoaderStyle: BaseCommonProps['sx'] = { mx: 'auto' }
const validationStyle: BaseCommonProps['sx'] = { display: 'flex', width: '100%' }
const validationIconStyle: BaseCommonProps['sx'] = { my: 'auto', mr: 1 }
const validationLabelStyle: BaseCommonProps['sx'] = { my: 'auto' }

export const validationsClasses = generateUtilityClasses('CybusValidations', ['root', 'loader', 'validation'])
export const validationClasses = generateUtilityClasses('CybusValidation', ['root', 'icon', 'label'])

type BaseProps = BaseCommonProps &
    Readonly<{
        /**
         * The style of the label
         * @default 'body2'
         */
        labelVariant?: TypographyProps['variant']

        /**
         * The style of the validation will be outline
         * @default {false}
         */
        outlined?: boolean
    }>

type ValidationsProps = BaseProps & Readonly<{ validations: (ConnectwareError | string)[] }>

export type ValidationProps = BaseProps &
    Readonly<{
        /**
         * The style of the validation
         */
        error?: boolean
    }>

export const Validation: FC<PropsWithChildren<ValidationProps>> = ({
    className,
    sx,
    labelVariant = 'body2',
    error = false,
    outlined = false,
    children,
    ...props
}) => {
    const [Icon, color]: [FC<SvgIconProps>, string] = error
        ? [outlined ? ErrorOutlineRounded : ErrorRounded, 'error.light']
        : [outlined ? CheckCircleOutline : CheckCircle, 'success.light']
    return (
        <Box {...props} className={classes(className, validationClasses.root)} sx={{ ...validationStyle, ...sx }}>
            <Icon className={validationClasses.icon} sx={{ color, ...validationIconStyle }} />
            <Typography className={validationClasses.label} sx={{ color, ...validationLabelStyle }} variant={labelVariant}>
                {children}
            </Typography>
        </Box>
    )
}

export const Validations: FC<ValidationsProps> = ({ className, sx, validations, labelVariant, outlined, ...props }) => (
    <Box {...props} className={classes(className, validationsClasses.root)} sx={{ ...validationsWrapperStyle, ...sx }}>
        {!isArrayNotEmpty(validations) && <CircularProgress className={validationsClasses.loader} sx={validationsLoaderStyle} />}
        {validations.map((v, k) => {
            const error = ConnectwareError.is(v)
            const label = error ? v.message : v

            return (
                <Validation key={k} className={validationsClasses.validation} labelVariant={labelVariant} error={error} outlined={outlined}>
                    {label}
                </Validation>
            )
        })}
    </Box>
)
