import React, { type FC } from 'react'
import { createExtendedState } from 'react-extended-state'

type NavigationState = Readonly<{ rightColumnOpen: boolean }>

const {
    Provider,
    useExtendedState: useNavigationState,
    useExtendedStateDispatcher: useNavigationDispatcher,
} = createExtendedState<NavigationState>({ ignorePropsChanges: true })

const initialState: NavigationState = { rightColumnOpen: true }

export const NavigationProvider: FC = ({ children }) => <Provider value={initialState}>{children}</Provider>

export const useIsRightColumnOpen = (): boolean => useNavigationState((s) => s.rightColumnOpen)

export const useRightColumnToggler = (): VoidFunction => {
    const dispatch = useNavigationDispatcher()
    return () => dispatch(({ rightColumnOpen }) => ({ rightColumnOpen: !rightColumnOpen }))
}
