export {
    getAbsoluteRoute,
    getContext,
    getRouteCapabilities,
    getRouteConfig,
    getRouteInternalPath,
    isRouteParent,
    isSecondLevelRoute,
    isRouteProxy,
    type RouteParentPath,
} from './routes'
export { getBreadcrumbConfig } from './breadcrumbs'
