import { Box, Button, CircularProgress } from '@mui/material'

import React, { type FC, useEffect, useMemo } from 'react'

import { useSnackbar } from 'notistack'

import { Send } from '@mui/icons-material'

import { isResolved, usePromise } from 'react-sync-promise'

import { Capability, ConnectwareError, createIsAuthenticatedWithCapabilitiesSelector, type Metrics as MetricsModel, Translation } from '../../../../domain'
import { useAppState, useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'
import { FormattedTranslation } from '../../Internationalization'

const selectCanManageMetrics = createIsAuthenticatedWithCapabilitiesSelector(Capability.SYSTEM_METRICS_MANAGE)

export const SendMetricsToPortalButton: FC = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const isAuthenticated = useAppState(selectCanManageMetrics)
    const systemMetricsUsecase = useAppUsecase('systemMetricsUsecase')
    const [sendMetricsToPortal, isLoading, result] = useAsyncCallback(() => systemMetricsUsecase.sendMetricsToPortal(), [systemMetricsUsecase])
    const sentResponse = isLoading || result === null ? false : result

    const metrics = usePromise<MetricsModel, ConnectwareError>(useMemo(() => systemMetricsUsecase.fetchMetrics(), [systemMetricsUsecase]))

    useEffect(() => {
        if (sentResponse === false) {
            /** Nothing has happened yet */
            return
        }

        const key = enqueueSnackbar(
            <Box data-testid="metrics-sent-response">
                {ConnectwareError.is(result) ? result.message : <FormattedTranslation id={Translation.SUCCESSFULLY_SENT_METRICS_TO_PORTAL} />}
            </Box>,
            { autoHideDuration: 5_000, anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, variant: ConnectwareError.is(result) ? 'error' : 'success' }
        )

        return () => closeSnackbar(key)
    }, [sentResponse])

    if (isResolved(metrics) && !metrics.value.isPortalReachable) {
        return null
    }

    return (
        <Button
            id="sendMetricsButton"
            color="primary"
            onClick={sendMetricsToPortal}
            disabled={!isAuthenticated || isLoading}
            startIcon={isLoading ? <CircularProgress size="1rem" /> : <Send />}
        >
            <FormattedTranslation id={Translation.SEND_METRICS_TO_PORTAL} />
        </Button>
    )
}
