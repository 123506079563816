import React, { type FC } from 'react'
import { InfoOutlined } from '@mui/icons-material'

import { Box, type SxProps, type Theme } from '@mui/material'

import { AnchorProvider, Popover, useAnchorCloser, useAnchorSetter } from '../common'
import { errorMessagesClasses } from '../ErrorMessage'

import type { ErrorMessageExtrasProps } from './Props'
import { Extras } from './Code'

const errorPopoverStyle: SxProps<Theme> = { display: 'flex', width: '100%' }
const infoIconStyle: SxProps<Theme> = { m: 'auto' }
const contentWrapperStyle: SxProps<Theme> = { mt: 1, mx: 1 }

const PopoverContent: FC<Pick<ErrorMessageExtrasProps, 'error'>> = ({ error }) => {
    const setOrigin = useAnchorSetter()
    const close = useAnchorCloser()

    return (
        <>
            <InfoOutlined className={errorMessagesClasses['popover-button']} sx={infoIconStyle} onClick={(e) => setOrigin(e.currentTarget)} />
            <Popover
                className={errorMessagesClasses['popover-content']}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={close}
            >
                <Box className={errorMessagesClasses['popover-extras']} sx={contentWrapperStyle}>
                    <Extras variant="subtitle2" error={error} />
                </Box>
            </Popover>
        </>
    )
}

export const ErrorPopover: FC<Pick<ErrorMessageExtrasProps, 'error'>> = ({ error, children }) => (
    <Box className={errorMessagesClasses.popover} sx={errorPopoverStyle}>
        {children}
        <AnchorProvider>
            <PopoverContent error={error} />
        </AnchorProvider>
    </Box>
)
