import type { VolumeInspectInfo as BaseVolumeInspectInfo } from 'dockerode'

interface VolumeInspectInfo extends BaseVolumeInspectInfo {
    readonly CreatedAt: string
}

export type DockerVolume = Pick<VolumeInspectInfo, 'CreatedAt' | 'Name'>

export type DockerVolumesResponse = Readonly<{
    Volumes: DockerVolume[]
    Warnings: string[]
}>

export type KubernetesVolumesResponse = Readonly<{
    Volumes: []
    Warnings: null
}>

export type DockerDetailedVolumeResponse = Pick<VolumeInspectInfo, 'CreatedAt' | 'Name' | 'Labels'>
