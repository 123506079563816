import type { EventHandler, KeyboardEvent, KeyboardEventHandler, RefObject, SyntheticEvent } from 'react'

export const wasKeyHit = (e: KeyboardEvent<unknown>, key: string): boolean => e.key === key

export const createOnEnterHandler =
    <E>(callback: (e: KeyboardEvent<E>) => unknown): KeyboardEventHandler<E> =>
    (e) =>
        wasKeyHit(e, 'Enter') && callback(e)

export const createHandlerWithoutPropagation =
    <E extends SyntheticEvent, R>(callback?: (e: E) => R): EventHandler<E> =>
    (e) => {
        e.stopPropagation()
        callback?.(e)
    }

export const createClickerHandler =
    (ref: RefObject<HTMLElement>): VoidFunction =>
    () =>
        ref.current?.click()
