import type { ParserError } from './Parser'

const withErrorMessage = <T>(data: unknown, messageParser: (message: string) => T): T | null =>
    data && typeof data === 'object' && 'message' in data && typeof data.message === 'string' ? messageParser(data.message) : null

/**
 * Extracts the error message errors
 * @example {"code":"InvalidContent","message":"Error in commissioning file: []"}
 */
export const extractErrors = (data: unknown): ParserError[] | null =>
    withErrorMessage(data, (message) => {
        const errors = /^Error in commissioning file: (?<errors>.{2,})$/.exec(message)?.groups?.errors

        if (typeof errors !== 'string') {
            return null
        }

        try {
            return JSON.parse(errors) as ParserError[]
        } catch {
            return null
        }
    })

/**
 * Extracts if the information of a service already existing
 * @example {"code":"InvalidContent","message":"Provided service id connectivitylayer already exists"}
 */
export const extractAlreadyExists = (data: unknown): boolean | null =>
    withErrorMessage(data, (message) => /^Provided service id .+ already exists$/.test(message))

/**
 * Extracts the error message errors
 * @example {"code":"InvalidContent","message":"Error on creating service connectivitylayer: ..."}
 */
export const extractCreationError = (data: unknown): string | null =>
    withErrorMessage(data, (message) => {
        const error = /^Error on creating service .+: (?<error>.+)$/.exec(message)?.groups?.error
        return typeof error === 'string' ? error : null
    })
