import { createEqualityChecker, ManagedPromise, type NonEmptyArray, Queue } from '../../../utils'
import { ConnectwareError } from '../../../domain'

import type { RSTHttpMethod, RSTJsonResponseContent, RSTPaths, RSTQueryParameters, RSTResponseStatuses } from '../../Connectware'
import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService, type HttpRequestArgs } from '../Base'

type Request<Args, Type> = Readonly<{ args: Args, promise: ManagedPromise<Type, ConnectwareError> }>

export type RSTFetchingStrategy<QueryParamArgs extends unknown[], Path extends RSTPaths, Response> = Pick<
    HttpRequestArgs<never, never>,
    'method' | 'path' | 'capability'
> &
    Readonly<{
        method: RSTHttpMethod<Path>
        path: Path

        status: RSTResponseStatuses<Path>
        buildQueryParams: (...args: QueryParamArgs) => RSTQueryParameters<Path> & HttpRequestArgs<never, never>['queryParams']
        mapJsonResponse: (response: RSTJsonResponseContent<Path>) => Response
    }>

export type RSTBatchFetchingStrategy<Args, Path extends RSTPaths, BatchResponse> = RSTFetchingStrategy<[args: Args[]], Path, BatchResponse>

const doBatchStrategiesMatch = createEqualityChecker<Omit<RSTBatchFetchingStrategy<unknown, RSTPaths, unknown>, 'capability'>>({
    method: null,
    path: null,
    status: null,
    buildQueryParams: null,
    mapJsonResponse: null,
}) as <Args, Path extends RSTPaths, BatchResponse>(
    a: RSTBatchFetchingStrategy<Args, Path, BatchResponse>,
    b: RSTBatchFetchingStrategy<Args, Path, BatchResponse>
) => boolean

/**
 * An abstract class that manages batching of API requests.
 * It groups multiple similar requests into fewer API calls to optimize network usage.
 */
export abstract class RSTBatchedConnectwareHTTPService<Args, Path extends RSTPaths, BatchResponse, Response> extends FetchConnectwareHTTPService {
    /**
     * This queue is here simply to ensure only one request at a time can be made
     * Causing them to bunch up and done in batches
     */
    private readonly queue = new Queue()

    /**
     * Stores all requests that are waiting to be batched and sent out
     */
    private readonly pendingRequests: Request<Args, Response>[] = []

    constructor (options: ConnectwareHTTPServiceOptions, protected readonly pageSize: number) {
        super(options)
    }

    /**
     * @returns a batch of requests from the queue, grouped according batch/page size limits
     */
    private extractNextBatch (): Readonly<{
        strategy: RSTBatchFetchingStrategy<Args, Path, BatchResponse>
        requests: NonEmptyArray<Request<Args, Response>>
    }> | null {
        const [first, ...rest] = this.pendingRequests.splice(0, this.pendingRequests.length)

        if (!first) {
            /** Everything is empty, so no need to do anything */
            return null
        }

        const strategy = this.resolveBatchFetchingStrategy(first.args)

        const requests: NonEmptyArray<Request<Args, Response>> = [first]

        for (const next of rest) {
            if (requests.length < this.pageSize && doBatchStrategiesMatch(strategy, this.resolveBatchFetchingStrategy(next.args))) {
                /** Add request because it both fits and the strategies align */
                requests.push(next)
            } else {
                /** Just reject it and put it back in the requests */
                this.pendingRequests.push(next)
            }
        }

        return { strategy, requests }
    }

    /**
     * Executes the batched API call and resolves each request based on the response
     */
    private async resolveRequests (): Promise<void> {
        const batch = this.extractNextBatch()

        if (batch === null) {
            return
        }

        const { status, buildQueryParams, mapJsonResponse, ...strategy } = batch.strategy
        const batchArgs = batch.requests.map(({ args }) => args)

        const result = await this.request({
            ...strategy,
            authenticate: true,
            queryParams: buildQueryParams(batchArgs),
            handlers: { [status]: (response) => response.getJson<RSTJsonResponseContent<Path>>().then(mapJsonResponse) },
        }).catch((e: ConnectwareError) => e)

        batch.requests.forEach(({ args, promise }) => {
            const match = ConnectwareError.is(result) ? result : this.resolveBatchResponse(result, args)

            if (!ConnectwareError.is(match)) {
                promise.resolve(match)
            } else {
                promise.reject(match)
            }
        })
    }

    protected abstract resolveBatchFetchingStrategy (args: Args): RSTBatchFetchingStrategy<Args, Path, BatchResponse>

    protected abstract resolveBatchResponse (response: BatchResponse, args: Args): Response | ConnectwareError

    protected fetchBatched (args: Args): Promise<Response> {
        const promise = new ManagedPromise<Response, ConnectwareError>()

        /** Add new request and queue a new fetching for it (in case its needed) */
        this.pendingRequests.push({ args, promise })
        void this.queue.push(() => this.resolveRequests())

        return promise.promise
    }
}
