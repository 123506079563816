import type { CybusServer } from '../../../../../../domain'

import { mapCybusServer, mapToStatusType } from '../../../../../Connectware'
import { OPCUA_SERVER_CLASSNAME_FILTER } from '../../../../constants'
import type { ServerProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    excludeInstanceByServiceName,
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToOneSubscriptionHandler,
} from '..'

type BaseCybusServerHandler = VrpcInstanceToOneSubscriptionHandler<ServerProxy, CybusServer>
type BaseCybusServerHandlerArgs = VrpcHandlerMappingPropertiesArgs<BaseCybusServerHandler>

abstract class VrpcBaseServerProxyToCybusServerHandler implements BaseCybusServerHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly requiredFilters = []

    readonly classNameFilter = OPCUA_SERVER_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    readonly agent = null

    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServerProxy>('state')

    constructor (
        readonly optionalFilters: BaseCybusServerHandler['optionalFilters'],
        readonly ignoreInstanceByFilter: BaseCybusServerHandler['ignoreInstanceByFilter']
    ) {}

    mapToDomain ({ instance }: BaseCybusServerHandlerArgs['DomainMapperArgs']): Promise<BaseCybusServerHandlerArgs['Domain']> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getParams().then((params) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            return mapCybusServer(params, currentStatus)
        })
    }
}

export class VrpcServerProxyToCybusServersHandler extends VrpcBaseServerProxyToCybusServerHandler {
    constructor () {
        super(['service'], excludeInstanceByServiceName)
    }
}

export class VrpcServerProxyToCybusServerHandler extends VrpcBaseServerProxyToCybusServerHandler {
    constructor () {
        super([], null)
    }
}
