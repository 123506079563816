import type { ReadonlyRecord } from '../../../../../utils'
import type { SubscriptionFilterArgs } from '../../../../../application'

import { mapResourceNames } from '../../../../Connectware'
import type { SubscriptionHandlerType, UnsubFromInstanceFunction, VrpcHandlerMappingPropertiesArgs, VrpcSubscriptionHandler } from '.'

type SupportedTypes = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST | SubscriptionHandlerType.INSTANCE_ONE_TO_ONE | SubscriptionHandlerType.INSTANCE_ONE_TO_PAGE
type ChangeArgs<VrpcIsntance> = VrpcHandlerMappingPropertiesArgs<VrpcSubscriptionHandler<VrpcIsntance, unknown, SupportedTypes>>['OnChangeArgs']

type ListenerInstance<EventName extends string> = ReadonlyRecord<'on' | 'off', (eventNames: EventName, handler: VoidFunction) => Promise<unknown>>
type ListenerEvents<Instance> = Instance extends ListenerInstance<infer E> ? E : never

export const createProxyEventsHandler = <Instance extends ListenerInstance<any & string>>(
    ...eventNames: ListenerEvents<Instance>[]
): ((args: ChangeArgs<Instance>) => Promise<UnsubFromInstanceFunction>) => {
    /**
     * Create listener
     */
    return async ({ instance, listener }) => {
        /**
         * Start actually listening
         */

        /** Listen to all events */
        await Promise.all(eventNames.map((eventName) => instance.on(eventName, listener)))

        return (isGone) => {
            /**
             * Time to drop listeners
             */
            if (isGone) {
                /**
                 * @see https://i.imgur.com/MPbX6OD.jpg
                 *
                 * No need to drop anything
                 */
                return Promise.resolve()
            }

            /** Drop all listeners */
            return Promise.all(eventNames.map((eventName) => instance.off(eventName, listener))).then(() => Promise.resolve())
        }
    }
}

/**
 * This function filters out the instances to be loaded by their name
 * If their name is part of a service that is being filtered out
 * It will exclude it
 */
export const excludeInstanceByServiceName = (instance: string, { service }: SubscriptionFilterArgs): boolean => {
    if (!service) {
        return false
    }
    const [serviceName] = mapResourceNames(instance)
    return service !== serviceName
}
