import type { Optional } from 'utility-types'
import React, { type FC } from 'react'

import { type AppState, type CybusServer, ResourceType, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Server = Pick<CybusServer, 'id' | 'name' | 'service'>

type Props = Readonly<{ serversSelector: (s: AppState, id: string[]) => Server[], onConfirmed: VoidFunction }>

export const EnableModal: FC<Optional<Props, 'onConfirmed'>> = ({ serversSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkEnableServersUsecase')

    return (
        <EnableResourceModal
            title={Translation.ENABLE_SERVERS}
            subtitle={Translation.ENABLE_SERVERS_SUBTITLE}
            confirm={Translation.ENABLE}
            resourceSelector={serversSelector}
            dimension={ResourceType.SERVER}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectServers(null)}
            onConfirm={async () => {
                await usecase.enable()
                onConfirmed?.()
            }}
        />
    )
}

export const DisableModal: FC<Optional<Props, 'onConfirmed'>> = ({ serversSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDisableServersUsecase')

    return (
        <DisableResourceModal
            title={Translation.DISABLE_SERVERS}
            subtitle={Translation.DISABLE_SERVERS_SUBTITLE}
            confirm={Translation.DISABLE}
            resourceSelector={serversSelector}
            dimension={ResourceType.SERVER}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectServers(null)}
            onConfirm={async () => {
                await usecase.disable()
                onConfirmed?.()
            }}
        />
    )
}

export const DeleteModal: FC<Props> = ({ serversSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDeleteServersUsecase')

    return (
        <DeleteResourceModal
            title={Translation.DELETE_SERVERS}
            subtitle={Translation.DELETE_SERVERS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={serversSelector}
            dimension={ResourceType.SERVER}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectServers(null)}
            onConfirm={async () => {
                await usecase.delete()
                onConfirmed?.()
            }}
        />
    )
}
