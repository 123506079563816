import type { CybusEndpoint } from '../../../../../../domain'

import { CybusEndpointMapper, type EndpointProxyParams, mapToStatusType } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER } from '../../../../constants'
import type { EndpointProxy, ResourceStateListenerProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type EndpointsHandler = VrpcInstanceToListSubscriptionHandler<ResourceStateListenerProxy, CybusEndpoint>
type EndpointsHandlerArgs = VrpcHandlerMappingPropertiesArgs<EndpointsHandler>

/** Retrieve the content on the pattern */
const ENDPOINT_PATTERN = ENDPOINT_CLASSNAME_FILTER.toString().replace(/\//g, '')

export class VrpcResourceStateListenerEndpointProxyInstanceHandler implements EndpointsHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = ['connection' as const, 'service' as const]

    readonly requiredFilters = []

    readonly classNameFilter = PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER

    readonly agent = null

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ResourceStateListenerProxy>('state', 'registered', 'unregistered')

    mapToDomain ({ instance, filter: { connection, service } }: EndpointsHandlerArgs['DomainMapperArgs']): Promise<EndpointsHandlerArgs['Domain']> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance
            .getParams<EndpointProxy>(ENDPOINT_PATTERN, { serviceIds: service ? [service] : undefined, connectionIds: connection ? [connection] : undefined })
            .then((params: EndpointProxyParams[]) => {
                const mapper = new CybusEndpointMapper({})

                /**
                 * @deprecated update code block so status is fetched externally
                 * @see https://cybusio.atlassian.net/browse/CC-1251
                 */
                params.forEach((params) => mapper.push(params, mapToStatusType(params.currentState)))

                return mapper.endpoints
            })
    }
}
