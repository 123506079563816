import type { PickByValue, PickByValueExact } from 'utility-types'

import { objectKeys, type ReadonlyRecord } from '../../../../utils'
import {
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFieldType,
    type CommissioningFileValue,
    type CommissioningFileValues,
    type OptionsCommissioningFileField,
} from '../../../../domain'
import type { CommissioningDataResourceType } from '../../../Connectware'

/**
 * The endpoint has an operating mode that is not explicitily named on the backend schemas
 * So it is named here instead
 */
export const ENDPOINT_MODE_FIELD_NAME = 'mode'

/**
 * There is no name for the name property of resources
 * So we highjack the Object schema mapper
 * And introduce an id which it can internally use
 */
export const INDEX_FIELD_NAME = 'name'

type ResourceMappingStrategy = ReadonlyRecord<
    Extract<CommissioningDataResourceType, 'Cybus::Connection' | 'Cybus::Endpoint'>,
    Readonly<{
        fieldName: keyof PickByValueExact<CommissioningFileFields, ReadonlyRecord<string, CommissioningFileField>>
        valueName: keyof PickByValue<CommissioningFileValues, ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>[]>

        fromDomainToJson?: Readonly<{
            /**
             * Properties that will not be mapped back to the outputed json
             */
            ignoreProperties: string[]
        }>
        fromJsonToDomain?: Readonly<{
            /**
             * Manipulate the properties that will be mapped as values to the domain layer
             */
            remapProperties: (
                field: ReadonlyRecord<string, CommissioningFileField>,
                properties: ReadonlyRecord<string, unknown>
            ) => ReadonlyRecord<string, unknown>
        }>
    }>
>

const collectOptionFields = (fields: ReadonlyRecord<string, CommissioningFileField>): OptionsCommissioningFileField[] =>
    Object.values(fields).flatMap((field) =>
        field.type !== CommissioningFileFieldType.OPTIONS ? [] : [field, ...Object.values(field.options).flatMap(collectOptionFields)]
    )

/** Strategies for updating different types of resources */
export const resourceStrategies: ResourceMappingStrategy = {
    'Cybus::Connection': {
        fieldName: 'connection',
        valueName: 'connections',
    },
    'Cybus::Endpoint': {
        fieldName: 'endpoint',
        valueName: 'endpoints',
        fromJsonToDomain: {
            remapProperties: (fields, properties) => {
                const keys = new Set(objectKeys(properties))

                /** Find an option that is not set yet */
                const missingOption = collectOptionFields(fields).find((f) => !keys.has(f.name))

                /** Now from the keys, get the one that can be used in the missing option */
                const missingOptionValue = missingOption?.allowableValues?.find((v) => keys.has(v))

                /** Finally add the option */
                return missingOption && missingOptionValue ? { [missingOption.name]: missingOptionValue, ...properties } : properties
            },
        },
        fromDomainToJson: {
            /**
             * Field is actually not used on the backend
             */
            ignoreProperties: [ENDPOINT_MODE_FIELD_NAME],
        },
    },
}
