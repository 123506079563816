import { nameFunction, type ReadonlyRecord } from '../../../utils'
import { ConnectwareError } from '../../Error'
import type { CommissioningFileFields, CommissioningFileValidatedValues } from '.'

export enum CommissioningFileFormState {
    INITIAL = 'INITIAL',
    LOADED_FIELDS = 'LOADED_FIELDS',
    LOADED_OUTPUT = 'LOADED_OUTPUT',
    LOADED_VALUES = 'LOADED_VALUES',
    LOADING_FIELDS = 'LOADING_FIELDS',
    LOADING_OUTPUT = 'LOADING_OUTPUT',
    LOADING_VALUES = 'LOADING_VALUES',
}

export type CommissioningFileOutput = [file: File, validation: ConnectwareError | null]

type BaseCommissioningFileForm<T extends CommissioningFileFormState, P = ReadonlyRecord<never, never>> = Readonly<{ type: T } & P>

type LoadedFields<E = never> = { fields: CommissioningFileFields | E }
type WithFile = { file: File }
type LoadedValues<V = CommissioningFileValidatedValues> = { values: V }

export type CommissioningFileForm =
    | BaseCommissioningFileForm<CommissioningFileFormState.INITIAL>
    | BaseCommissioningFileForm<CommissioningFileFormState.LOADING_FIELDS>
    | BaseCommissioningFileForm<CommissioningFileFormState.LOADED_FIELDS, LoadedFields<ConnectwareError>>
    | BaseCommissioningFileForm<CommissioningFileFormState.LOADING_VALUES, LoadedFields & WithFile>
    | BaseCommissioningFileForm<CommissioningFileFormState.LOADED_VALUES, LoadedFields & WithFile & LoadedValues<ConnectwareError>>
    | BaseCommissioningFileForm<CommissioningFileFormState.LOADING_OUTPUT, LoadedFields & WithFile & LoadedValues>
    | BaseCommissioningFileForm<
          CommissioningFileFormState.LOADED_OUTPUT,
          LoadedFields & WithFile & LoadedValues & { output: CommissioningFileOutput | ConnectwareError }
      >

export const isCommissioningFileFormInState = <T extends CommissioningFileFormState>(
    form: CommissioningFileForm,
    ...types: T[]
): form is Extract<CommissioningFileForm, { type: T }> => types.includes(form.type as T)

const createCommissioningFileFormSelector = <P extends string, V extends Extract<CommissioningFileForm, ReadonlyRecord<P, unknown>>[P]>(
    prop: P
): ((form: CommissioningFileForm) => V | null) =>
    nameFunction((form) => (prop in form ? (form[prop as keyof CommissioningFileForm] as V) : null), `selectCommissioningFileForm.${prop}`)

export const selectCommissioningFileFormValues = createCommissioningFileFormSelector('values')

export const selectCommissioningFileFormFields = createCommissioningFileFormSelector('fields')

export const selectCommissioningFileFormOutput = createCommissioningFileFormSelector('output')

export const selectCommissioningFileFormError = (form: CommissioningFileForm): ConnectwareError | null =>
    [selectCommissioningFileFormFields(form), selectCommissioningFileFormValues(form)].find((v): v is ConnectwareError => ConnectwareError.is(v)) ?? null
