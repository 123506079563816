import React, { type FC, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { FormattedNumber } from 'react-intl'

import { Box, type SxProps, type Theme } from '@mui/material'

import { type ConnectwareError, type Metrics as MetricsModel, selectLicenseId, Translation } from '../../../../domain'

import { FormattedDateTime } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'
import { ErrorMessage } from '../../ErrorMessage'
import { Tab, Tabs } from '../../Tabs'
import { CircularLoader, Table } from '../../common'

const dateSort = (t: Date): number => t.getTime()

const wrapperStyle: SxProps<Theme> = { py: 4 }

export const Metrics: FC = () => {
    const systemMetricsUsecase = useAppUsecase('systemMetricsUsecase')

    const licenseID = useAppState(selectLicenseId)

    const metrics = usePromise<MetricsModel, ConnectwareError>(useMemo(() => systemMetricsUsecase.fetchMetrics(), [systemMetricsUsecase]))

    if (isPending(metrics)) {
        return <CircularLoader />
    }

    if (isRejected(metrics)) {
        return <ErrorMessage error={metrics.value} stack extras="section" />
    }

    return (
        <Box sx={wrapperStyle}>
            <Tabs variant="button">
                <Tab title={Translation.MESSAGE_PER_HOUR}>
                    <Table
                        columns={{
                            timestamp: {
                                label: Translation.TIME,
                                customCellRender: (date) => <FormattedDateTime date={date} format="datetime" />,
                                sort: dateSort,
                            },
                            numberOfConnectedDatapoints: { label: Translation.NUMBER_OF_DATAPOINTS, sort: true },
                            numberOfReceivedMessages: {
                                label: Translation.NUMBER_OF_MESSAGES_PER_HOUR,
                                customCellRender: (value) => <FormattedNumber value={value} style="decimal" />,
                                sort: true,
                            },
                        }}
                        sortOrder={{ name: 'timestamp', direction: 'desc' }}
                        pagination={{ pageSize: 15, pageSizeOptions: [15, 50, 100] }}
                        data={metrics.value.hourly}
                        translations={{ noResultsOrEmptyTable: Translation.NO_METRICS }}
                        search
                        onDownloadCSV={() => systemMetricsUsecase.downloadHourlyMetricCSV(metrics.value.hourly)}
                    />
                </Tab>

                <Tab title={Translation.MESSAGE_PER_DAY}>
                    <Table
                        columns={{
                            date: {
                                label: Translation.DATE,
                                customCellRender: (date) => <FormattedDateTime date={date} format="dateOnly" />,
                                sort: dateSort,
                            },
                            numberOfConnectedDatapoints: { label: Translation.NUMBER_OF_DATAPOINTS, sort: true },
                            numberOfReceivedMessages: {
                                label: Translation.NUMBER_OF_MESSAGES_PER_DAY,
                                customCellRender: (value) => <FormattedNumber value={value} style="decimal" />,
                                sort: true,
                            },
                        }}
                        sortOrder={{ name: 'date', direction: 'desc' }}
                        pagination={{ pageSize: 15, pageSizeOptions: [15, 50, 100] }}
                        data={metrics.value.daily.map((d) => ({ licenseID, ...d }))}
                        translations={{ noResultsOrEmptyTable: Translation.NO_METRICS }}
                        search
                        onDownloadCSV={() => systemMetricsUsecase.downloadDailyMetricCSV(metrics.value.daily)}
                    />
                </Tab>
            </Tabs>
        </Box>
    )
}
