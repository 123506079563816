import React, { type FC } from 'react'
import { Box } from '@mui/material'

import type { ReadonlyRecord } from '../../../../utils'
import { type CybusDetailedService, Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'
import { Table } from '../../common'
import { ResourcesList } from './ResourcesList'

type TableLine = ReadonlyRecord<'subscribe' | 'publish' | 'subscribeExternalBroker' | 'publishExternalBroker', string | null>

const useCustomCellRender = (): ((broker: string | null) => string) => {
    const translator = useTranslator()

    return (broker) => (broker ? `${translator.formatTranslation(Translation.EXTERNAL)} (<${broker}>)` : translator.formatTranslation(Translation.INTERNAL))
}

export const Mappings: FC<Pick<CybusDetailedService['configuredResources'], 'mappings'>> = ({ mappings }) => {
    const customCellRender = useCustomCellRender()

    return (
        <ResourcesList title={Translation.MAPPING} subtitle={Translation.AUTHORIZE_MAPPINGS_EXPLANATION}>
            <Box data-testid="service-mappings">
                <Table
                    data={mappings.reduce<TableLine[]>(
                        (r, { publish, subscribe }) => [
                            ...r,
                            ...subscribe.map((subscribe) => ({
                                subscribe: subscribe.topic ?? subscribe.endpoint,
                                publish: publish.topic ?? publish.endpoint,
                                subscribeExternalBroker: subscribe.externalBroker,
                                publishExternalBroker: publish.externalBroker,
                            })),
                        ],
                        []
                    )}
                    columns={{
                        subscribe: { label: Translation.SUBSCRIBE, sort: true },
                        publish: { label: Translation.PUBLISH, sort: true },
                        subscribeExternalBroker: { label: Translation.SUBSCRIBE_BROKER, sort: true, customCellRender },
                        publishExternalBroker: { label: Translation.PUBLISH_BROKER, sort: true, customCellRender },
                    }}
                    sortOrder={{ name: 'subscribe', direction: 'desc' }}
                />
            </Box>
        </ResourcesList>
    )
}
