import type { ConnectwareError } from '../../../../domain'

import { BaseCatalogUsecase } from './Base'

export class LoadCatalogServicesUsecase extends BaseCatalogUsecase {
    invoke (): void {
        this.setCatalog({ application: null, template: null })

        void this.connectwareServicesCatalogService
            .fetchCatalogApplications()
            .catch((applications: ConnectwareError) => applications)
            .then((applications) => this.setCatalog({ applications }))
    }
}
