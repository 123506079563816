import { areDetailedCybusEndpointEquals, Capability, type CybusDetailedEndpoint } from '../../../../../../domain'
import { type EndpointProxyParams, mapDetailedEndpoint, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedEndpointStrategy extends BaseSubscriptionStrategy<'endpoint'> {
    readonly requiredPermissions = [Capability.ENDPOINT_READ]

    protected override readonly areEquals = areDetailedCybusEndpointEquals

    override async retrieveOne (id: string): Promise<CybusDetailedEndpoint> {
        return Promise.all<[Promise<EndpointProxyParams>, Promise<string[]>]>([
            this.retrieve({
                capability: Capability.ENDPOINT_READ,
                method: 'GET',
                path: '/api/endpoints/+',
                pathParams: [id],
                mapper: (data: EndpointProxyParams) => data,
                handleNotFound: true,
            }),
            this.retrieve({
                capability: Capability.ENDPOINT_READ,
                method: 'GET',
                path: '/api/endpoints/+/topics',
                pathParams: [id],
                mapper: (data: string[]) => data,
                handleNotFound: true,
            }),
        ]).then(([params, topics]) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            return mapDetailedEndpoint(params, currentStatus, topics)
        })
    }
}
