import { Capability, type ConnectwareError, createIsAuthenticatedWithCapabilitiesSelector, selectSandbox } from '../../../domain'

import { initialState } from '../..'
import { FilterSandboxUsecase } from '../RuleEngine'
import { BaseSandboxUsecase } from './Base'

const canReadTopicsMetadata = createIsAuthenticatedWithCapabilitiesSelector(Capability.TOPICS_SUBSCRIPTION_METADATA)

export class StartSandboxUsecase extends BaseSandboxUsecase {
    invoke (): VoidFunction {
        const canRead = canReadTopicsMetadata(this.getState())

        /** Load endpoints again if it can read it */
        const configuration = canRead ? this.configurationService.fetchRuleEngineEndpointsConfiguration() : Promise.resolve([])

        configuration
            .then((endpoints) => this.setSandbox({ endpoints }))
            .catch((endpoints: ConnectwareError) => {
                // Move to initial source (away from endpoints)
                this.getUsecase(FilterSandboxUsecase).selectType(selectSandbox(initialState).type)
                this.setSandbox({ endpoints })
            })

        /** Reset to init state */
        return () => this.setSandbox(selectSandbox(initialState))
    }
}
