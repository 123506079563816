import type { Optional } from 'utility-types'
import React, { type FC } from 'react'

import { type AppState, type CybusEndpoint, ResourceType, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Endpoint = Pick<CybusEndpoint, 'id' | 'name' | 'service'>

type Props = Readonly<{ endpointsSelector: (s: AppState, id: string[]) => Endpoint[], onConfirmed: VoidFunction }>

export const EnableModal: FC<Optional<Props, 'onConfirmed'>> = ({ endpointsSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkEnableEndpointsUsecase')

    return (
        <EnableResourceModal
            title={Translation.ENABLE_ENDPOINTS}
            subtitle={Translation.ENABLE_ENDPOINTS_SUBTITLE}
            confirm={Translation.ENABLE}
            resourceSelector={endpointsSelector}
            dimension={ResourceType.ENDPOINT}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectEndpoints(null)}
            onConfirm={async () => {
                await usecase.enable()
                onConfirmed?.()
            }}
        />
    )
}

export const DisableModal: FC<Optional<Props, 'onConfirmed'>> = ({ endpointsSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDisableEndpointsUsecase')

    return (
        <DisableResourceModal
            title={Translation.DISABLE_ENDPOINTS}
            subtitle={Translation.DISABLE_ENDPOINTS_SUBTITLE}
            confirm={Translation.DISABLE}
            resourceSelector={endpointsSelector}
            dimension={ResourceType.ENDPOINT}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectEndpoints(null)}
            onConfirm={async () => {
                await usecase.disable()
                onConfirmed?.()
            }}
        />
    )
}

export const DeleteModal: FC<Props> = ({ endpointsSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDeleteEndpointsUsecase')

    return (
        <DeleteResourceModal
            title={Translation.DELETE_ENDPOINTS}
            subtitle={Translation.DELETE_ENDPOINTS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={endpointsSelector}
            dimension={ResourceType.ENDPOINT}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectEndpoints(null)}
            onConfirm={async () => {
                await usecase.delete()
                onConfirmed()
            }}
        />
    )
}
