import React, { type FC } from 'react'

import { type CommissioningFileField, Translation } from '../../../../domain'
import { useConnection } from './State'
import { ResourceFieldInput, ResourceTable } from './Resources'

const ConnectionFieldInput: FC<{ position: number, name: string }> = ({ position, name }) => {
    const connection = useConnection(position, name)

    return (
        <ResourceFieldInput<'editServiceTemplateConnectionUsecase', CommissioningFileField>
            usecase="editServiceTemplateConnectionUsecase"
            data={connection}
            name={name}
            onChange={(usecase, value) => usecase.updateConnection(position, name, value)}
        />
    )
}

export const ConnectionsTable: FC = () => (
    <ResourceTable
        sectionTitle={Translation.CONNECTION}
        emptyResourceTranslationId={Translation.NO_CONNECTIONS}
        valuesResourceName="connections"
        fieldResourceName="connection"
        ValueComponent={ConnectionFieldInput}
    />
)
