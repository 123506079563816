import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import { Cancel } from '@mui/icons-material'

import { Translation } from '../../../../../domain'

import { FormattedTranslation } from '../../../Internationalization'

import { useCanUpdateLicense, useIsPortalReachable } from '../Hooks'

const portalStyle: SxProps<Theme> = { display: 'flex', mb: 2, px: 0, '& > *': { my: 'auto', mx: 0 } }
const portalIconStyle: SxProps<Theme> = { mr: 2, color: 'red.500' }

export const PortalReachabilityError: FC = () => {
    const canUpdateLicense = useCanUpdateLicense()
    const connectivity = useIsPortalReachable()

    if (!canUpdateLicense || connectivity === null || connectivity?.reachable) {
        /**
         * If its loaded, render nothing
         */
        return null
    }

    return (
        <Box sx={portalStyle} data-testid="portal-reachability-issues">
            <Cancel sx={portalIconStyle} />
            <Box>
                <Typography variant="body2">
                    <FormattedTranslation
                        id={Translation.IS_URL_REACHABLE}
                        values={{ url: connectivity.url?.toString() || <FormattedTranslation id={Translation.CYBUS_PORTAL} />, reachable: false }}
                    />
                </Typography>
                <Typography variant="body2">
                    <FormattedTranslation id={Translation.LICENSE_REFRESH_REQUIRES_INTERNET} />
                </Typography>
            </Box>
        </Box>
    )
}
