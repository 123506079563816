import type { Optional } from 'utility-types'
import React, { type FC } from 'react'

import { type AppState, type CybusConnection, ResourceType, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Connection = Pick<CybusConnection, 'id' | 'name' | 'service'>

type Props = Readonly<{ connectionsSelector: (s: AppState, id: string[]) => Connection[], onConfirmed: VoidFunction }>

export const EnableModal: FC<Optional<Props, 'onConfirmed'>> = ({ connectionsSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkConnectConnectionsUsecase')

    return (
        <EnableResourceModal
            title={Translation.CONNECT_CONNECTIONS}
            subtitle={Translation.CONNECT_CONNECTIONS_SUBTITLE}
            confirm={Translation.CONNECT}
            resourceSelector={connectionsSelector}
            dimension={ResourceType.CONNECTION}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectConnections(null)}
            onConfirm={async () => {
                await usecase.connect()
                onConfirmed?.()
            }}
        />
    )
}

export const DisableModal: FC<Optional<Props, 'onConfirmed'>> = ({ connectionsSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDisconnectConnectionsUsecase')

    return (
        <DisableResourceModal
            title={Translation.DISCONNECT_CONNECTIONS}
            subtitle={Translation.DISCONNECT_CONNECTIONS_SUBTITLE}
            confirm={Translation.DISCONNECT}
            resourceSelector={connectionsSelector}
            dimension={ResourceType.CONNECTION}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectConnections(null)}
            onConfirm={async () => {
                await usecase.disconnect()
                onConfirmed?.()
            }}
        />
    )
}

export const DeleteModal: FC<Props> = ({ connectionsSelector, onConfirmed }) => {
    const usecase = useAppUsecase('bulkDeleteConnectionsUsecase')

    return (
        <DeleteResourceModal
            title={Translation.DELETE_CONNECTIONS}
            subtitle={Translation.DELETE_CONNECTIONS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={connectionsSelector}
            dimension={ResourceType.CONNECTION}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => usecase.selectConnections(null)}
            onConfirm={async () => {
                await usecase.delete()
                onConfirmed()
            }}
        />
    )
}
