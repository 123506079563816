import React, { type FC } from 'react'

import { type CommissioningFileField, Translation } from '../../../../domain'
import { useEndpoint } from './State'
import { ResourceFieldInput, ResourceTable } from './Resources'

const EndpointFieldInput: FC<{ position: number, name: string }> = ({ position, name }) => {
    const endpoint = useEndpoint(position, name)

    return (
        <ResourceFieldInput<'editServiceTemplateEndpointUsecase', CommissioningFileField>
            usecase="editServiceTemplateEndpointUsecase"
            data={endpoint}
            name={name}
            onChange={(usecase, value) => usecase.updateEndpoint(position, name, value)}
        />
    )
}

export const EndpointsTable: FC = () => (
    <ResourceTable
        sectionTitle={Translation.ENDPOINT}
        emptyResourceTranslationId={Translation.NO_ENDPOINTS}
        valuesResourceName="endpoints"
        fieldResourceName="endpoint"
        ValueComponent={EndpointFieldInput}
    />
)
