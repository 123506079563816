const createClassNameFilter = (...parts: string[]): RegExp => new RegExp(`^${parts.join('|')}$`)

export const SERVICE_CLASSNAME = 'Service'

export const CONTAINER_MANAGER_CORE_INSTANCE = 'core'
export const CONTAINER_MANAGER_DOCKER_INSTANCE = 'docker'
export const CONTAINER_MANAGER_CLASSNAME = 'ProtectedDocker'

export const PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER = 'ResourceStateListener'
export const MAPPING_CLASSNAME_FILTER = 'Mapping'
export const CONNECTION_CLASSNAME_FILTER = createClassNameFilter('.+Connection')

export const ENDPOINT_CLASSNAME_FILTER = createClassNameFilter('.+Endpoint')
export const NODE_CLASSNAME_FILTER = createClassNameFilter('.+Node')
export const OPCUA_SERVER_CLASSNAME_FILTER = createClassNameFilter('.+Server')
