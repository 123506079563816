import React, { type FC } from 'react'

import { isArrayNotEmpty } from '../../../../utils'
import { type CybusUser, StatusType, Translation } from '../../../../domain'

import { FormattedList, FormattedTimePast, useTranslator } from '../../Internationalization'
import { useAppUsecases } from '../../State'
import { useAsyncCallback } from '../../Callback'

import { Page } from '../Page'
import { Status } from '../../common'

export const UsersPage: FC = () => {
    const translator = useTranslator()
    const { createUserUsecase, editUserUsecase } = useAppUsecases()
    const [onUserClick] = useAsyncCallback((u: CybusUser) => editUserUsecase.load(u))

    return (
        <Page
            selected="users"
            columns={{
                username: { label: Translation.USERNAME },
                roles: {
                    label: translator.formatTranslation(Translation.ROLE, { count: 2 }),
                    customCellRender: (roles) =>
                        isArrayNotEmpty(roles) ? <FormattedList value={roles} style="narrow" /> : translator.formatTranslation(Translation.NO_ROLES),
                },
                allPermissions: {
                    label: translator.formatTranslation(Translation.PERMISSION, { count: 0 }),
                    customCellRender: (permissions) =>
                        translator.formatTranslation(Translation.TOTAL_PERMISSIONS, { count: new Set(permissions.map((p) => p.resource)).size }),
                },
                authenticationMethods: {
                    label: Translation.AUTHENTICATION_METHODS,
                    customCellRender: (methods) =>
                        isArrayNotEmpty(methods) ? (
                            <FormattedList
                                value={methods.map((method) => translator.formatTranslation(Translation.USER_AUTHENTICATION_METHOD, { method })).sort()}
                                style="narrow"
                            />
                        ) : (
                            translator.formatTranslation(Translation.CANNOT_AUTHENTICATE)
                        ),
                },
                isMfaEnabled: {
                    label: Translation.MULTI_FACTOR_AUTHENTICATION,
                    customCellRender: (mfa) => <Status status={mfa ? StatusType.ENABLED : StatusType.NOT_ENABLED} />,
                },
                lastSeen: {
                    label: Translation.LAST_SEEN,
                    customCellRender: (lastSeen) =>
                        lastSeen === null ? translator.formatTranslation(Translation.NOT_AVAILABLE) : <FormattedTimePast value={lastSeen} />,
                },
                provider: {
                    label: Translation.PROVIDER,
                    customCellRender: (provider) => translator.formatTranslation(Translation.USER_PROVIDER, { provider }),
                },
            }}
            notFound={Translation.NO_USERS_FOUND}
            onAdd={() => createUserUsecase.start()}
            addLabel={Translation.ADD_USER}
            onRowClick={onUserClick}
        />
    )
}
