import { INVALID_CONNECTION_INSTANCE_PATTERN, mapConnection } from '../../../../../Connectware'

import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'
import { excludeInstanceByServiceName } from '..'
import { VrpcBaseConnectionProxyInstanceHandler } from './Base'

export class VrpcConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connections'> {
    protected readonly mapConnection = mapConnection

    readonly optionalFilters = ['service' as const]

    readonly requiredFilters = []

    readonly classNameFilter = CONNECTION_CLASSNAME_FILTER

    // Ignore __internal__ connection
    readonly ignoreInstances = INVALID_CONNECTION_INSTANCE_PATTERN

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName
}
