import React, { createRef, type FC } from 'react'
import { Box, Button, LinearProgress, type SxProps, type Theme } from '@mui/material'
import { Add as AddIcon, Warning } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../domain'

import { createClickerHandler, readFirstFileAsText } from '..'
import { useAsyncCallback } from '../Callback'
import { FormattedTranslation } from '../Internationalization'
import { useAppUsecase } from '../State'
import { HiddenRawFileUpload } from '../common'

const wrapperStyle: SxProps<Theme> = { display: 'flex' }
const addStyle: SxProps<Theme> = { ml: 'auto' }

export const Add: FC = () => {
    const ref = createRef<HTMLInputElement>()

    const addCertificate = useAppUsecase('addCertificateUsecase')

    const [onChange, loading, value] = useAsyncCallback(
        (files: File[]) => readFirstFileAsText(files).then((content) => (content ? addCertificate.add(content) : Promise.resolve())),
        [addCertificate]
    )

    return (
        <Box sx={wrapperStyle} className="add-certificate">
            <Button
                sx={addStyle}
                size="medium"
                color="primary"
                variant="contained"
                title={ConnectwareError.is(value) ? value.message : undefined}
                startIcon={<AddIcon />}
                endIcon={ConnectwareError.is(value) && <Warning data-testid="new-certificate-error" color="error" />}
                onClick={createClickerHandler(ref)}
            >
                <FormattedTranslation id={Translation.ADD_CERTIFICATE} />
                {loading && <LinearProgress />}
            </Button>
            <HiddenRawFileUpload ref={ref} onChange={onChange} />
        </Box>
    )
}
