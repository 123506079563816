import { mapMapping } from '../../../../../Connectware'

import type { MappingProxy } from '../../../../proxies'
import { createProxyEventsHandler, excludeInstanceByServiceName } from '..'
import { VrpcMappingToOneSubscriptionHandler } from './Base'

export class VrpcMappingProxyInstanceHandler extends VrpcMappingToOneSubscriptionHandler<'mappings', void> {
    readonly optionalFilters = ['service' as const]

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName

    /**
     * @deprecated this will no longer be supported
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<MappingProxy>('state')

    protected readonly mapMapping = mapMapping

    protected fetchInformation (): Promise<void> {
        return Promise.resolve()
    }
}
