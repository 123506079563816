import type { ArrayType } from '../utils'
import { ConnectwareError, ConnectwareErrorType } from './Error'

const otpValidator = /^\d{1}$/

export type ValidOtp = `${number}`[]

export const isOtpValid = (otp: (string | ArrayType<ValidOtp>)[]): otp is ValidOtp => otp.every((v) => otpValidator.test(v))

type InvalidOtpExtras = Readonly<{
    /** minutes to wait before the next OTP attempt is allowed */
    minutesUntilRetry: number
    /** number of remaining attempts allowed before the user is banned */
    triesLeft: number | null
}>

export class InvalidOtpError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, InvalidOtpExtras> {
    static override is (e: unknown): e is InvalidOtpError {
        return e instanceof InvalidOtpError
    }

    constructor (extras: InvalidOtpExtras) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Invalid OTP', extras)
    }
}
