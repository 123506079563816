import { capture, Droppable, EventListener, type NonUnion, type ReadonlyRecord } from '../../../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../../../domain'

import type { UnsubFunction, VrpcHandlerMappingProperties, VrpcHandlerMappingPropertiesArgs } from '../../handlers'
import type { ResourceManager } from './Types'

type SupportedManageableHandlers = VrpcHandlerMappingProperties<unknown, UnsubFunction<[isGone: boolean]>, unknown, unknown>

export abstract class BaseResourceManager<Handler extends SupportedManageableHandlers, Aux>
    implements ResourceManager<VrpcHandlerMappingPropertiesArgs<Handler>['Domain']> {
    private readonly changeListeners = new EventListener<void>()

    private readonly droppable = new Droppable()

    private internalValue: VrpcHandlerMappingPropertiesArgs<Handler>['Domain'] | ConnectwareError | null = null

    private stopListening: UnsubFunction<[isGone: boolean]> | null = null

    /**
     * If errors are thrown, this is the message of the error
     */
    protected abstract readonly errorMessage: string

    /**
     * If errors are thrown, extras can be set here
     */
    protected readonly errorExtras: ReadonlyRecord<string, unknown> = {}

    constructor (private readonly handler: Handler, protected readonly aux: Aux) {}

    get value (): VrpcHandlerMappingPropertiesArgs<Handler>['Domain'] | ConnectwareError | null {
        return this.internalValue
    }

    private mapError (e: unknown): ConnectwareError {
        if (ConnectwareError.is(e)) {
            return e
        }

        if (e instanceof Error) {
            const cwError = new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, this.errorMessage, {
                ...this.errorExtras,
                message: e.message,
                name: e.name,
            })

            cwError.stack = e.stack

            return cwError
        }

        return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Thrown error is not an error object', { ...this.errorExtras, error: e })
    }

    /**
     * Method that will run its arg function
     * If there is an issue, it will store the error as the value
     */
    private async runSafely<V> (run: () => Promise<V>): Promise<void> {
        try {
            await run()
        } catch (e: unknown) {
            if (e instanceof Error) {
                capture(e, run)
            }

            this.setValue(this.mapError(e))
        }
    }

    /**
     * Sets up internal listener for changes and a hook to stop listening
     * @see {safelyRemoveListener}
     */
    private async safelyAddListener (): Promise<void> {
        await this.runSafely(async () => {
            const args = await this.getOnChangeArgs()
            this.stopListening = await this.handler.onChange(args)
            this.onDrop(() => this.safelyRemoveListener(false))
        })
    }

    /**
     * Drops the change listener
     * @see {safelyAddListener}
     */
    private safelyRemoveListener (isGone: boolean): void {
        const { stopListening } = this

        /**
         * Drop reference already
         */
        this.stopListening = null

        void stopListening?.(isGone)
    }

    private setValue (value: VrpcHandlerMappingPropertiesArgs<Handler>['Domain'] | ConnectwareError): void {
        if (this.internalValue === null && value === null) {
            /**
             * If there was no value, and there is no value to update to
             * Then do nothing and ignore the update
             */
            return
        }

        this.internalValue = value
        this.changeListeners.trigger()
    }

    private async safelySetValue (): Promise<void> {
        await this.runSafely(async () => {
            const args = await this.getMapToDomainArgs()
            const value = await this.handler.mapToDomain(args)
            this.setValue(value)
        })
    }

    protected onDrop (handler: VoidFunction): void {
        this.droppable.onDrop(handler)
    }

    protected createValueUpdater (): VoidFunction {
        return () => void this.safelySetValue()
    }

    /**
     * The args that will be used to retrieve the domain entity
     *
     * @see {NonUnion} is here to prevent downstream yield of values with missing props
     */
    protected abstract getMapToDomainArgs (): Promise<NonUnion<VrpcHandlerMappingPropertiesArgs<Handler>['DomainMapperArgs']>>

    /**
     * The args that will be used to setup the change listener
     *
     * @see {NonUnion} is here to prevent downstream yield of values with missing props
     */
    protected abstract getOnChangeArgs (): Promise<NonUnion<VrpcHandlerMappingPropertiesArgs<Handler>['OnChangeArgs']>>

    onChange (listener: VoidFunction): VoidFunction {
        const unsubToChanges = this.changeListeners.on(listener)
        this.onDrop(unsubToChanges)
        return unsubToChanges
    }

    async start (): Promise<void> {
        await Promise.all([this.safelyAddListener(), this.safelySetValue()])
    }

    end (isGone: boolean): void {
        this.safelyRemoveListener(isGone)
        this.droppable.drop()
    }
}
