import React, { type FC } from 'react'
import { Link, type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, ConnectwareErrorType, selectAuthenticatedCapabilities, selectAuthenticatedUsername, Translation } from '../../../../domain'

import { DocumentationType } from '../../../../application'

import { ErrorMessage } from '../../ErrorMessage'
import { useAppState } from '../../State'
import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { useDocumentation } from '../../Documentation'

import type { AbsoluteRoutePath } from '..'
import { getRouteCapabilities, getRouteInternalPath } from '../Config'

const linkStyle: SxProps<Theme> = { ml: 1, cursor: 'pointer', mt: 0.2 }

export const DeniedAccess: FC<{ path: AbsoluteRoutePath }> = ({ path }) => {
    const translator = useTranslator()

    const route = getRouteInternalPath(path)
    const capabilitiesAlternatives = getRouteCapabilities(path)
    const username = useAppState(selectAuthenticatedUsername)
    const userCapabilities = useAppState(selectAuthenticatedCapabilities)
    const openUIAccessDocument = useDocumentation(DocumentationType.UI_ACCESS_PERMISSIONS)

    return (
        <ErrorMessage
            error={
                new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, translator.formatTranslation(Translation.AUTHENTICATION_ERROR_TITLE), {
                    routeId: path,
                    route,
                    capabilitiesAlternatives,
                    username,
                    userCapabilities,
                })
            }
            customTitle={(e) => e.message}
            extrasTitleSuffix={
                <Link onClick={openUIAccessDocument} sx={linkStyle} data-testid="ui-access-documentation" variant="body2">
                    <FormattedTranslation id={Translation.ABOUT_UI_ACCESS_PERMISSIONS} />
                </Link>
            }
            extras="section"
        />
    )
}
