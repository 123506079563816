import React, { type FC } from 'react'

import type { CybusService } from '../../../domain'

type Resource = Readonly<{ name: string, id: string }> | Readonly<{ service: CybusService['id'] | null, name: string, id: string }>

/**
 * The standard component to render service related resources
 * in the body of the bulk action modal
 */
export const ServiceModalBody: FC<Readonly<{ resource: Resource }>> = ({ resource }) => (
    <>{'service' in resource && resource.service ? `${resource.name} (${resource.service})` : resource.id}</>
)
