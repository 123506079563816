import React, { type FC, Fragment, memo, type ReactNode, useCallback, useMemo } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'
import { Security } from '@mui/icons-material'

import { isArrayNotEmpty } from '../../../../../utils'
import { type CybusPermissionOperations, type EditableCybusPermissionWithInheritance, Translation } from '../../../../../domain'

import { FormattedTranslation, useTranslator } from '../../../Internationalization'
import { useAppUsecase } from '../../../State'
import { Table, type TableColumns, TiniestButton } from '../../../common'
import { TiniestCopy } from '../../../Copy'

import { OperationsList } from '../Operations'
import { useContextResourceTranslation } from '../Hooks'
import { useCanLink, useChangeDispatcher, useContext, useDisabled, usePermissions, useRemoveDispatcher } from './State'

type TablePermission = Readonly<{
    resource: EditableCybusPermissionWithInheritance['resource']
    operations: EditableCybusPermissionWithInheritance
    actions: EditableCybusPermissionWithInheritance
}>

const wrapperStyle: SxProps<Theme> = { display: 'flex', '& > *': { my: 'auto', mx: 0 } }
const resourseStyle: SxProps<Theme> = { mr: 2 }

const PermissionLabel: FC<{ resource: string }> = memo(({ resource }) => {
    return (
        <Box data-testid={`permission-${resource}`} sx={wrapperStyle}>
            <Box sx={resourseStyle}>{resource}</Box>
            <TiniestCopy>{resource}</TiniestCopy>
        </Box>
    )
})

/**
 * @todo add tests for when a permission is required but without inheritance
 */
const PermissionOperationsList: FC<{ permission: EditableCybusPermissionWithInheritance }> = memo(({ permission }) => {
    const translator = useTranslator()
    const disabled = useDisabled()
    const change = useChangeDispatcher()

    const onChange = useCallback(({ read, write }: CybusPermissionOperations) => change(permission, { read, write }), [permission, change])

    const { inheritanceRoles, inheritancesRead, inheritancesWrite, read, write, required } = permission
    const title = isArrayNotEmpty(inheritanceRoles)
        ? translator.formatTranslation(Translation.INHERITED_FROM, { value: translator.formatList(inheritanceRoles) })
        : undefined

    return (
        <OperationsList
            readTitle={title}
            writeTitle={title}
            disabled={disabled}
            disabledRead={inheritancesRead || required?.read}
            disabledWrite={inheritancesWrite || required?.write}
            read={read}
            write={write}
            onChange={onChange}
        />
    )
})

const actionsWrapperStyle: SxProps<Theme> = { display: 'flex', flexWrap: 'wrap', '& > *': { mr: 0.5, mb: 0.5 } }
const actionButtonStyle: SxProps<Theme> = { mr: 0 }
const roleIconStyle: SxProps<Theme> = { height: '0.75rem', width: '0.75rem', color: 'secondary.main', verticalAlign: 'middle' }

const Actions: FC<{ permission: EditableCybusPermissionWithInheritance }> = memo(
    ({ permission: { permission, required, inheritancesRead, inheritancesWrite, inheritanceRoles } }) => {
        const canLink = useCanLink()
        const disabled = useDisabled()
        const remove = useRemoveDispatcher()
        const editRoleUsecase = useAppUsecase('editRoleUsecase')

        const tupples: [label: ReactNode, listener: VoidFunction][] = []

        if (permission && !required && (!isArrayNotEmpty(inheritanceRoles) || permission.read > inheritancesRead || permission.write > inheritancesWrite)) {
            tupples.push([
                <FormattedTranslation key={tupples.length} id={inheritanceRoles.length ? Translation.REMOVE_CUSTOMIZATION : Translation.REMOVE} />,
                () => remove(permission),
            ])
        }

        if (!disabled && canLink) {
            inheritanceRoles.forEach((role) =>
                tupples.push([
                    <FormattedTranslation
                        key={tupples.length}
                        id={Translation.GO_TO}
                        values={{
                            destination: (
                                <>
                                    <Security sx={roleIconStyle} /> {role}
                                </>
                            ),
                        }}
                    />,
                    () => editRoleUsecase.load(role),
                ])
            )
        }

        return (
            <Box sx={actionsWrapperStyle}>
                {!isArrayNotEmpty(tupples) && <FormattedTranslation id={Translation.NOT_AVAILABLE} />}
                {tupples.map(([label, onClick], k) => (
                    <Fragment key={k}>
                        <TiniestButton data-testid="permissions-table-action" disabled={disabled} onClick={onClick} sx={actionButtonStyle}>
                            {label}
                        </TiniestButton>
                    </Fragment>
                ))}
            </Box>
        )
    }
)

export const InternalPermissionsTable: FC = memo(() => {
    const translator = useTranslator()
    const translateContextResource = useContextResourceTranslation()

    const context = useContext()

    const [searching, permissions] = usePermissions()

    const displayed: TablePermission[] = useMemo(
        () => permissions.map((p) => ({ resource: p.resource, operations: p, actions: p })).sort((a, b) => (a.resource > b.resource ? 1 : -1)),
        [permissions]
    )

    const columns = useMemo<TableColumns<TablePermission>>(
        () => ({
            resource: { label: translateContextResource(context, 0), customCellRender: (p) => <PermissionLabel resource={p} /> },
            operations: {
                label: translator.formatTranslation(Translation.OPERATION, { count: 0 }),
                customCellRender: (p) => <PermissionOperationsList permission={p} />,
            },
            actions: { label: translator.formatTranslation(Translation.ACTION, { count: 0 }), customCellRender: (p) => <Actions permission={p} /> },
        }),
        [translator, translateContextResource, context]
    )

    const translations = useMemo(
        () => ({
            noResultsOrEmptyTable: translator.formatTranslation(searching ? Translation.NO_PERMISSIONS_FOUND : Translation.TOTAL_PERMISSIONS, { count: 0 }),
        }),
        [translator, searching]
    )

    return (
        <Box data-testid="permissions-table">
            {/* Disable default table search as custom search is used here */}
            <Table columns={columns} data={displayed} translations={translations} height={200} search={false} />
        </Box>
    )
})
