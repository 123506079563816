import type { PickByValueExact } from 'utility-types'

import { createArrayComparator, createEqualityChecker, isArrayNotEmpty, nameFunction } from '../utils'
import { areSortConfigurationEquals, ConnectwareError, type SortConfiguration } from '.'
import { isLoadableLoaded, type Loadable } from './Loadable'

export type PaginationOptions = Readonly<{ pageSize: number, page: number }>

export const arePaginationOptionsEquals = createEqualityChecker<PaginationOptions>({ pageSize: null, page: null })

type PageData<T> = Readonly<{ current: T[], totalCount: number }>

export type PaginatedData<T> = PageData<T> & PaginationOptions

export type PaginationParameters<T> = Readonly<{ search: string | null, sort: SortConfiguration<T>, pagination: PaginationOptions }>

export type Page<T> = (Readonly<{ data: Loadable<PageData<T>> }> & PaginationParameters<T>) | null

const arePageDataEquals = createEqualityChecker<PageData<unknown>>({ current: createArrayComparator(), totalCount: null })
const areNonNullablePagesEquals = createEqualityChecker<Exclude<Page<unknown>, null>>({
    pagination: arePaginationOptionsEquals,
    data: (a, b) => Boolean(a && b && !ConnectwareError.is(a) && !ConnectwareError.is(b) && arePageDataEquals(a, b)),
    search: null,
    sort: areSortConfigurationEquals,
})

export const arePagesEquals = <Resource>(a: Page<Resource>, b: Page<Resource>): boolean =>
    a === null || b === null ? a === b : areNonNullablePagesEquals(a as Exclude<Page<unknown>, null>, b as Exclude<Page<unknown>, null>)

type LoadableSelector<S, E> = (s: S) => Page<E>
type EntitySelector<S, E> = (s: S, id: string[]) => E[]

/** Creates a selector for the loadable page items on the domain */
export const createPageSelector = <State, Entity extends object, IdField extends keyof PickByValueExact<Entity, string>, Name extends string>(
    select: LoadableSelector<State, Entity>,
    idField: IdField,
    name: Name,
    filter?: (c: Entity) => boolean
): EntitySelector<State, Entity> => {
    const emptyArray: never[] = []

    return nameFunction((s, ids) => {
        /** Nothing is selected, load nothing */
        if (!isArrayNotEmpty(ids)) {
            return emptyArray
        }

        /** Select from the state */
        const page = select(s)

        if (page === null || !isLoadableLoaded(page.data)) {
            return emptyArray
        }

        return page.data.current.filter(
            (e): e is Entity =>
                /** If there is a filter, make sure the status or the actual filter pass */
                (filter ? filter(e) : true) &&
                /** Finally then check if the ids selected include it */
                ids.includes(e[idField] as string)
        )
    }, name)
}
