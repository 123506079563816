import React, { type FC, type ReactNode } from 'react'
import { TableCell, TableRow, Typography } from '@mui/material'

import { isEnumOf } from '../../../../utils'
import { Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'

import { useColspan, useSearch, useTranslations } from './State'

export const EmptyTableBody: FC = () => {
    const translator = useTranslator()
    const search = useSearch()
    const colSpan = useColspan()

    const { noResultsOrEmptyTable = Translation.NO_RESULTS_FOUND, emptyTable = noResultsOrEmptyTable, noResults = noResultsOrEmptyTable } = useTranslations()

    let target: ReactNode = search ? noResults : emptyTable
    target = isEnumOf(Translation, target) ? translator.formatTranslation(target) : target

    return (
        <TableRow data-testid="empty-table-body">
            <TableCell colSpan={colSpan}>
                <Typography variant="body1" textAlign="center">
                    {target}
                </Typography>
            </TableCell>
        </TableRow>
    )
}
