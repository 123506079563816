import type { StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { type ConnectionProxyParams, mapToStatusType } from '../../../../../Connectware'
import type { ConnectionProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type BaseConnectionHandler<T extends keyof SubscriptionsTypes> = VrpcInstanceToOneSubscriptionHandler<ConnectionProxy, SubscriptionsTypes[T]>
type BaseConnectionHandlerArgs<T extends keyof SubscriptionsTypes> = VrpcHandlerMappingPropertiesArgs<BaseConnectionHandler<T>>

export abstract class VrpcBaseConnectionProxyInstanceHandler<T extends keyof SubscriptionsTypes> implements BaseConnectionHandler<T> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    abstract readonly optionalFilters: BaseConnectionHandler<T>['optionalFilters']

    abstract readonly requiredFilters: BaseConnectionHandler<T>['requiredFilters']

    abstract readonly classNameFilter: BaseConnectionHandler<T>['classNameFilter']

    abstract readonly ignoreInstances: BaseConnectionHandler<T>['ignoreInstances']

    abstract readonly ignoreInstanceByFilter: BaseConnectionHandler<T>['ignoreInstanceByFilter']

    readonly sourceInstanceName = null

    readonly agent = null

    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ConnectionProxy>('state')

    protected abstract mapConnection (connection: ConnectionProxyParams, statusType: StatusType): SubscriptionsTypes[T]

    mapToDomain ({ instance }: BaseConnectionHandlerArgs<T>['DomainMapperArgs']): Promise<BaseConnectionHandlerArgs<T>['Domain']> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getParams().then((params) => {
            const currentStatus = mapToStatusType(params.currentState)

            return this.mapConnection(params, currentStatus)
        })
    }
}
