import { Link as BaseLink, Box, Button, type LinkProps, type SxProps, type Theme } from '@mui/material'
import React, { type FC, type ReactNode } from 'react'

import type { PickByValue } from 'utility-types'

import type { ReadonlyRecord } from '../../../utils'
import type { ConnectwareError, Translation } from '../../../domain'
import type { Usecases } from '../../../application'

import { useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { ErrorMessage } from '../ErrorMessage'
import { useAsyncCallback } from '../Callback'
import { LengthLimitedField, type LengthLimitedFieldProps } from '../common'

type UsecaseProps<U extends keyof Usecases,> = Readonly<{ usecase: U }>
type MethodType = (() => Promise<void>) | VoidFunction
type UsecaseConfirmProps<U extends keyof Usecases,> = UsecaseProps<U> & Readonly<{ method: keyof PickByValue<Usecases[U], MethodType> }>

const otpFieldStyle: SxProps<Theme> = { width: 400 }
export const OtpField: FC<Pick<LengthLimitedFieldProps, 'id' | 'disabled' | 'value' | 'onChange'>> = ({ ...props }) => (
    <Box sx={otpFieldStyle}>
        <LengthLimitedField {...props} type="tel" />
    </Box>
)

type ActionButtonProps = Readonly<{
    disabled?: boolean
    dense?: boolean
    label: Translation
    labelValues?: ReadonlyRecord<string, ReactNode>
    error?: ConnectwareError | null
}>
const actionButtonWrapperStyles = { regular: { mt: 3 }, dense: { mt: 1 } } as const
export const ActionButton = <U extends keyof Usecases,>({
    usecase: usecaseName,
    method,
    disabled = false,
    dense = false,
    label,
    labelValues,
    error = null,
    ...props
}: ActionButtonProps & UsecaseConfirmProps<U>): ReturnType<FC> => {
    const usecase: Usecases[U] = useAppUsecase(usecaseName)
    const [confirm, confirming] = useAsyncCallback(() => Promise.resolve((usecase[method] as MethodType)()), [usecase, method])

    return (
        <Box sx={actionButtonWrapperStyles[dense ? 'dense' : 'regular']} {...props}>
            <Button variant="contained" fullWidth size="large" disabled={confirming || disabled} onClick={confirm}>
                <FormattedTranslation id={label} values={labelValues} />
            </Button>
            {error && <ErrorMessage error={error} extras="section" />}
        </Box>
    )
}

const linkStyle: SxProps<Theme> = { cursor: 'pointer', mt: 1 }
export const Link: FC<Omit<LinkProps, 'sx'>> = (props) => (
    <Box sx={linkStyle}>
        <BaseLink {...props} />
    </Box>
)

const blockStyle: SxProps<Theme> = { pt: 3 }
export const Block: FC = (props) => <Box sx={blockStyle} {...props} />
