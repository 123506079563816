import Ajv from 'ajv'

import { executeOnce, nameFunction, type ReadonlyRecord } from '../../utils'

/**
 * This function uses jsonschemas to create validators
 * Once created, validators can be used to quickly check if an object is of the given type
 *
 * @warning the schema is expected to be behaving correctly, otherwise adverse effects may occur
 * @returns validator based on the given schema
 */
export const createValidator = <V>(schema: ReadonlyRecord<string, unknown>, name: string): ((obj: unknown) => obj is V) => {
    const createInternalValidator = executeOnce(() => {
        /** Unique reference to name of the schema */
        const name = Math.random().toString()

        return [name, new Ajv({ useDefaults: true, allErrors: true }).addSchema(schema, name)] as const
    })

    return nameFunction((obj: unknown): obj is V => {
        const [name, ajv] = createInternalValidator()
        return ajv.validate(name, obj) === true
    }, name)
}
